<template>
	<div class="stat-box">
		<div class="stat-box-header">
			<div class="flex align-items-center surface-card">
				<div style="width:48px;height:48px;border-radius:10px" class="bg-purple-100 inline-flex align-items-center justify-content-center mr-3">
					<i class="pi pi-chart-line text-purple-500 text-3xl"></i>
				</div>
				<div>
					<span class="text-900 text-xl font-medium mb-2">{{ $t('historique') }}</span>
					<p class="mt-1 mb-0 text-600 font-medium text-sm">{{ $t('des_volumes') }}
						<tool-source url='' :txt="'Adequation au ' + app.stats.prix_avec_stat.intitule_colonne[0]"/>
					</p>
				</div>
				<div class="ml-auto">
					<div class="speeddial-tooltip-demo">
						<SpeedDial className="speeddial-left" :model="items" direction="down" showIcon="pi pi-bars" hideIcon="pi pi-times" buttonClassName="p-button-outlined"/>
					</div>
				</div>
			</div>
			<TabPeriode/>
		</div>
		<div class="stat-box-content">
			<div class="table">
				<table id='historique_volume'>
					<tbody v-if="app.tab == 'trimestres'">
						<tr>
							<th id="volume_11">{{app.livedata.volume_periode_1.titre}}</th>
							<th id="volume_12">{{app.livedata.volume_periode_1.intitule_colonne[0]}}</th>
							<th id="volume_13">{{app.livedata.volume_periode_1.intitule_colonne[1]}}</th>
							<th id="volume_14">{{app.livedata.volume_periode_1.intitule_colonne[2]}}</th>
						</tr>
						<tr>
							<th id="volume_21">{{app.livedata.volume_periode_1.nbprog.libelle}}</th>
							<td>{{app.livedata.volume_periode_1.nbprog.tableau[0]}}</td>
							<td>{{app.livedata.volume_periode_1.nbprog.tableau[1]}}</td>
							<td>{{app.livedata.volume_periode_1.nbprog.tableau[2]}}</td>
						</tr>
						<tr>
							<th id="volume_31">{{app.livedata.volume_periode_1.mev.libelle}}</th>
							<td>{{app.livedata.volume_periode_1.mev.tableau[0]}}</td>
							<td>{{app.livedata.volume_periode_1.mev.tableau[1]}}</td>
							<td>{{app.livedata.volume_periode_1.mev.tableau[2]}}</td>
						</tr>
						<tr>
							<th id="volume_41">{{app.livedata.volume_periode_1.vente.libelle}}</th>
							<td>{{app.livedata.volume_periode_1.vente.tableau[0]}}</td>
							<td>{{app.livedata.volume_periode_1.vente.tableau[1]}}</td>
							<td>{{app.livedata.volume_periode_1.vente.tableau[2]}}</td>
						</tr>
						<tr>
							<th id="volume_51">{{app.livedata.volume_periode_1.offre.libelle}}</th>
							<td>{{app.livedata.volume_periode_1.offre.tableau[0]}}</td>
							<td>{{app.livedata.volume_periode_1.offre.tableau[1]}}</td>
							<td>{{app.livedata.volume_periode_1.offre.tableau[2]}}</td>
						</tr>
					</tbody>
					<tbody v-if="app.tab == 'mois'" >
						<tr>
							<th id="volume_61">{{app.livedata.volume_periode_2.titre}}</th>
							<th id="volume_62">{{app.livedata.volume_periode_2.intitule_colonne[0]}}</th>
							<th id="volume_63">{{app.livedata.volume_periode_2.intitule_colonne[1]}}</th>
							<th id="volume_64">{{app.livedata.volume_periode_2.intitule_colonne[2]}}</th>
							<th id="volume_65">{{app.livedata.volume_periode_2.intitule_colonne[3]}}</th>
							<th id="volume_66">{{app.livedata.volume_periode_2.intitule_colonne[4]}}</th>
							<th id="volume_67">{{app.livedata.volume_periode_2.intitule_colonne[5]}}</th>
							<th id="volume_68">{{app.livedata.volume_periode_2.intitule_colonne[6]}}</th>
							<th id="volume_69">{{app.livedata.volume_periode_2.intitule_colonne[7]}}</th>
						</tr>
						<tr>
							<th id="volume_71">{{app.livedata.volume_periode_2.nbprog.libelle}}</th>
							<td>{{app.livedata.volume_periode_2.nbprog.tableau[0]}}</td>
							<td>{{app.livedata.volume_periode_2.nbprog.tableau[1]}}</td>
							<td>{{app.livedata.volume_periode_2.nbprog.tableau[2]}}</td>
							<td>{{app.livedata.volume_periode_2.nbprog.tableau[3]}}</td>
							<td>{{app.livedata.volume_periode_2.nbprog.tableau[4]}}</td>
							<td>{{app.livedata.volume_periode_2.nbprog.tableau[5]}}</td>
							<td>{{app.livedata.volume_periode_2.nbprog.tableau[6]}}</td>
							<td>{{app.livedata.volume_periode_2.nbprog.tableau[7]}}</td>
						</tr>
						<tr>
							<th id="volume_81">{{app.livedata.volume_periode_2.mev.libelle}}</th>
							<td>{{app.livedata.volume_periode_2.mev.tableau[0]}}</td>
							<td>{{app.livedata.volume_periode_2.mev.tableau[1]}}</td>
							<td>{{app.livedata.volume_periode_2.mev.tableau[2]}}</td>
							<td>{{app.livedata.volume_periode_2.mev.tableau[3]}}</td>
							<td>{{app.livedata.volume_periode_2.mev.tableau[4]}}</td>
							<td>{{app.livedata.volume_periode_2.mev.tableau[5]}}</td>
							<td>{{app.livedata.volume_periode_2.mev.tableau[6]}}</td>
							<td>{{app.livedata.volume_periode_2.mev.tableau[7]}}</td>
						</tr>
						<tr>
							<th id="volume_91">{{app.livedata.volume_periode_2.vente.libelle}}</th>
							<td>{{app.livedata.volume_periode_2.vente.tableau[0]}}</td>
							<td>{{app.livedata.volume_periode_2.vente.tableau[1]}}</td>
							<td>{{app.livedata.volume_periode_2.vente.tableau[2]}}</td>
							<td>{{app.livedata.volume_periode_2.vente.tableau[3]}}</td>
							<td>{{app.livedata.volume_periode_2.vente.tableau[4]}}</td>
							<td>{{app.livedata.volume_periode_2.vente.tableau[5]}}</td>
							<td>{{app.livedata.volume_periode_2.vente.tableau[6]}}</td>
							<td>{{app.livedata.volume_periode_2.vente.tableau[7]}}</td>
						</tr>
						<tr>
							<th id="volume_101">{{app.livedata.volume_periode_2.offre.libelle}}</th>
							<td>{{app.livedata.volume_periode_2.offre.tableau[0]}}</td>
							<td>{{app.livedata.volume_periode_2.offre.tableau[1]}}</td>
							<td>{{app.livedata.volume_periode_2.offre.tableau[2]}}</td>
							<td>{{app.livedata.volume_periode_2.offre.tableau[3]}}</td>
							<td>{{app.livedata.volume_periode_2.offre.tableau[4]}}</td>
							<td>{{app.livedata.volume_periode_2.offre.tableau[5]}}</td>
							<td>{{app.livedata.volume_periode_2.offre.tableau[6]}}</td>
							<td>{{app.livedata.volume_periode_2.offre.tableau[7]}}</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import SpeedDial from 'primevue/speeddial';
import TabPeriode from '../TabPeriode.vue';
import ToolSource from '../../../../global/ToolSource';

export default {
	name: 'ArrayHistorique',
	
	data: function() {
		return {
			tab: 'mois',
			items: [
				{label: 'Data', icon: 'pi pi-fw pi-download', command:() => {this.$store.dispatch('ExportToXls',{id: 'historique_volume', name: 'Historique des volumes'});} },
				{label: 'Image', icon: 'pi pi-fw pi-image', command:() => {this.$store.dispatch('ExportToPng',{id: 'historique_volume'});} },
			]
		}
	},

	methods: {
		ChangeTab: function(periodicite) {
			this.$store.dispatch('change_periodicite', { periodicite: periodicite })
		},

		toggle(event) {
			this.$refs.menu.toggle(event);
		}
	},

	computed: Object.assign(mapGetters([ 'app' ]), {
		
	}),

	components: { ToolSource, SpeedDial, TabPeriode }
}
</script>

<style lang="stylus" scoped>
@import '../../../../../assets/css/app'

.p-menuitem-link
	padding 0.75rem 1.25rem !important
	color #495057
	border-radius 0
	transition box-shadow 0.2s
	user-select none

:deep(.speeddial-tooltip-demo) {
	.p-speeddial-direction-up {
		&.speeddial-left {
			left: 0;
			bottom: 0;
		}
		&.speeddial-right {
			right: 0;
			bottom: 0;
		}
	}
	.p-speeddial-button.p-button.p-button-icon-only {
		width: 3rem !important;
		height: 3rem !important;
	}

}	
</style>
