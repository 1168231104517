/*eslint no-unused-vars: ["error", { "argsIgnorePattern": "^_" }]*/
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/store'
import filters from './filters/filters'
import Vuebar from 'vuebar'
import Icon from './components/global/Icon'
import Loader from './components/global/Loader'
import api from './libs/api' 
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueAnalytics from 'vue-analytics'
import VueApexCharts from 'vue-apexcharts'
import ToggleButton from 'vue-js-toggle-button'
import './assets/css/theme_efocus.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import PrimeVue from 'primevue/config';
import StyleClass from 'primevue/styleclass';
import "primeflex/primeflex.css";
import Ripple from 'primevue/ripple';
import ToastService from 'primevue/toastservice';
import BadgeDirective from 'primevue/badgedirective';
import Tooltip from 'primevue/tooltip';
import VueI18n from 'vue-i18n';

import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import JsonExcel from "vue-json-excel";
//import { loadGoogleMaps } from './libs/loadGoogleMaps';


window.$ = require('jquery')
require('smoothscroll-polyfill').polyfill();
require('md5');

api.init(store)

Vue.use(VueI18n);

Vue.use(VueAxios, axios)
Vue.use(filters)
Vue.use(Vuebar)
Vue.use(VueApexCharts)


Vue.use(PrimeVue, {ripple: true});
Vue.use(ToastService);
Vue.use(BadgeDirective);

Vue.directive('ripple', Ripple);
Vue.directive('styleclass', StyleClass);
Vue.directive('badge', BadgeDirective);
Vue.directive('tooltip', Tooltip);
Vue.component('apexchart', VueApexCharts)


Vue.directive('focus', {
  // Quand l'élément lié est inséré dans le DOM...
  inserted: function (el) {
    // Mettre le focus sur l'élément
    el.focus();
  }
});


Vue.use(VueAnalytics, {
  id: 'UA-157352966-2'
})

Vue.use(ToggleButton)
Vue.component('icon', Icon);
Vue.component('loader', Loader);

Vue.component("downloadExcel", JsonExcel);
Vue.config.productionTip = false


function loadLocaleMessages() {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
  const messages = {};
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}

const i18n = new VueI18n({
  locale: 'fr',
  fallbackLocale: 'fr',
  messages: loadLocaleMessages()
});

export { i18n };

new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')


