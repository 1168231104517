 <template>
	<div class="stat-box" v-if="app.livedata.synoptique_ap.length">
		<div class="stat-box-header">
			<div class="flex align-items-center surface-card">
				<div style="width:48px;height:48px;border-radius:10px" class="bg-green-100 inline-flex align-items-center justify-content-center mr-3">
					<i class="pi pi-list text-green-600 text-3xl"></i>
				</div>
				<div>
					<span class="text-900 text-xl font-medium mb-2">{{ $t('synoptique') }}</span>
					<p class="mt-1 mb-0 text-600 font-medium text-sm">{{ $t('de_votre_selection_d_avant_premiere') }}
						<tool-source url='' :txt="'Adequation au ' + app.stats.prix_avec_stat.intitule_colonne[0]"/>
					</p>
				</div>
				<div class="ml-auto">
					<div class="speeddial-tooltip-demo">
						<SpeedDial className="speeddial-left" :model="items" direction="down" showIcon="pi pi-bars" hideIcon="pi pi-times" buttonClassName="p-button-outlined"/>
					</div>
				</div>
			</div>
		</div>
		<div class="stat-box-content">
			<DataTable showGridlines stripedRows rowGroupMode="subheader" groupRowsBy="secteur" dataKey="id" sortMode="single" sortField="secteur" responsiveLayout="scroll" class="synoptique" id="synoap"
			:value="synojson"
			:selection.sync="selectedPrograms"
			:sortOrder="1"
			:expandableRowGroups="true"
			:expandedRowGroups.sync="expandedRowGroups"
			>
				<ColumnGroup type="header">
					<Row>
						<Column header="" :headerStyle="{width: '30px'}">
							<template #header>
								<div class="table-header">
									<Button icon="pi pi-trash" class="p-button-danger p-button-sm" @click="supprime_selection()" :disabled="!selectedPrograms || !selectedPrograms.length" />
								</div>
							</template>
						</Column>
						<Column field="id" :header="$t('ID')" :headerStyle="{width: '80px'}" :sortable="true"/>
						<Column field="nom_promoteur" :header="$t('programme')" :sortable="true"/>
						<Column field="forme" :header="$t('f.u.')" :headerStyle="{width: '40px'}" />
						<Column field="stock_initial" :header="$t('nombre_de_logement')" :sortable="true"/>
					</Row>
				</ColumnGroup>
				<Column selectionMode="multiple" :styles="{'min-width': '3rem', 'text-align': 'center' }"></Column>
				<Column field="id" :header="$t('nom')" :styles="{'width': '50px', 'border-left': '0' }">
					<template #body="{data}">
						<div style="width:100%;text-align:center;">
							<div style="border-radius: 50%; height:28px; width:28px;line-height:28px;color:white;background-color: #79397f;">
								{{ data.id }}
							</div>
						</div>
					</template>				
				</Column>
				<Column field="nom_promoteur" :header="$t('nom')" :styles="{'max-width': '300px', 'border-left': '0' }">
					<template #body="{data}">
					<!---->
						<div style="width: 100%;height: 100%;display: flex;align-items: center;cursor: pointer;"  @click="expand_program(data.program_id)">
							<div style="width:80px;"><div style="text-align: center;vertical-align: middle;">
									<ImagePreview @error="handleImageError" :src="data.url_photo" alt="Image" height="40" :imageStyle="{'max-width': '80px' }" />
								</div>
							</div>
							<div><p class="ellipsis-text2"><b>{{ data.nom }}</b></p><p class="ellipsis-text2">{{ data.promoteur }}</p><p class="ellipsis-text2">{{ data.adresse }}</p><!--<p class="ellipsis-text2">{{ data.ville }}</p>--></div>
						</div>
						<!---->
					</template>
				</Column>
				<Column field="forme" :header="$t('f.u.')">
					<template #body="{data}">
						<div class="forme-urbaine" v-if="data.forme_urbaine.toUpperCase()=='COLLECTIF'"><icon file="building" :size="24" style="color:#cccccc" /></div>
						<div class="forme-urbaine" v-else><icon file="home" :size="24" style="color:#cccccc" /></div>
					</template>
				</Column>
				<Column field="stock_initial" :header="$t('nombre_de_logement')">
					<template #body="{data}">
						<div style="text-align: right;">{{ data.stock_initial }}</div>
					</template>
				</Column>
				<template #groupheader="slotProps">
					<span class="image-text">{{slotProps.data.secteur}}</span>
				</template>
			</DataTable>




			<div class="table">
				<table id='synoptiqueap' style='visibility: collapse;'>
					<tbody>
						<tr>
							<th>{{ $t('nom') }}</th>
							<th>{{ $t('nom_promoteur') }}</th>
							<th>{{ $t('forme') }}</th>
							<th>{{ $t('ville') }}</th>
							<th>{{ $t('stock_initial') }}</th>
						</tr>
						<tr v-for="prog in syno"  v-bind:key="prog.id" :class="{trprog:!prog.rupture}" style="height:46px;">
							<td v-if="prog.rupture" class="rupture" colspan=20>{{prog.rupture}}</td>
							<td v-if="!prog.rupture" class="cell" style="text-align: left;">{{prog.nom}}</td>
							<td v-if="!prog.rupture" class="cell" style="text-align: left;">{{prog.promoteur}}</td>
							<td v-if="!prog.rupture" class="cell center">{{prog.forme}}</td>
							<td v-if="!prog.rupture" class="cell" style="text-align: left;">{{prog.ville}}</td>
							<td v-if="!prog.rupture" class="cell" style="text-align: left;">{{prog.stock_initial}}</td>
						</tr>
					</tbody>
				</table>
				<br>
				&nbsp;&nbsp; {{ $t('legende_syno_pc') }}
				<br>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import ColumnGroup from 'primevue/columngroup';
import Row from 'primevue/row';
import Button from 'primevue/button';
import SpeedDial from 'primevue/speeddial';
import ImagePreview from 'primevue/imagepreview';
import ToolSource from '../../../../global/ToolSource';

export default {
	name: 'ArraySynoptiqueAp',

	data: function() {
		return {
			items: [
				{label: 'Data', icon: 'pi pi-fw pi-download', command:() => {this.$store.dispatch('ExportToXls',{id: 'synoptiqueap', name: 'Synoptique des avant-premieres'});} },
				{label: 'Image', icon: 'pi pi-fw pi-image', command:() => {this.$store.dispatch('ExportToPng',{id: 'synoap'});} },
			],
			expandedRowGroups: [],
			selectedPrograms: null,
			secteurencours: '',
			prog_select: false,
			tab: 'mois'
		}
	},

	mounted: function() {
		this.expandedRowGroups = this.app.livedata.tab_sp
	},

	computed: Object.assign(mapGetters([ 'app' ]), {
		synojson:function(){
			
			let tabsyno = [];
			for(let i = 0; i < this.app.livedata.synoptique_ap.length; i++){
				for(let k = 0; k < this.app.livedata.synoptique_ap[i].tableau.length; k++){
					let p = this.app.livedata.synoptique_ap[i].tableau[k]
					p.secteur= this.app.livedata.synoptique_ap[i].secteur
					p.forme = p.forme_urbaine.toUpperCase().substr(0,3)
					tabsyno.push(p);
				}
			}
			return tabsyno
		},

		syno:function(){
			
			let tabsyno = [];
			for(let i = 0; i < this.app.livedata.synoptique_ap.length; i++){
				tabsyno.push({rupture : this.app.livedata.synoptique_ap[i].secteur})
				for(let k = 0; k < this.app.livedata.synoptique_ap[i].tableau.length; k++){
					let p = this.app.livedata.synoptique_ap[i].tableau[k]
					p.rupture= false
					p.forme = p.forme_urbaine.toUpperCase().substr(0,3)
					tabsyno.push(p);
				}
			}
			return tabsyno
		}
	}),

	methods: {
		handleImageError(event) {
			event.target.src = this.defaultImageUrl; // Remplacer par une image par défaut en cas d'erreur
		},
		supprime_selection: function() {
			let tabsyno = [];
			for(let ii = 0; ii < this.app.livedata.synoptique.length; ii++){
				for(let k = 0; k < this.app.livedata.synoptique[ii].tableau.length; k++){
					tabsyno.push(this.app.livedata.synoptique[ii].tableau[k]);
				}
			}
			let tabsyno_ap = [];
			for(let iiap = 0; iiap < this.app.livedata.synoptique_ap.length; iiap++){
				for(let kap = 0; kap < this.app.livedata.synoptique_ap[iiap].tableau.length; kap++){
					tabsyno_ap.push(this.app.livedata.synoptique_ap[iiap].tableau[kap]);
				}
			}
			let tabsyno_pc = [];
			for(let iipc = 0; iipc < this.app.livedata.synoptique_pc.length; iipc++){
				for(let kpc = 0; kpc < this.app.livedata.synoptique_pc[iipc].tableau.length; kpc++){
					tabsyno_pc.push(this.app.livedata.synoptique_pc[iipc].tableau[kpc]);
				}
			}
			let tabsyno_rs = [];
			for(let iirs = 0; iirs < this.app.livedata.synoptique_rs.length; iirs++){
				for(let krs = 0; krs < this.app.livedata.synoptique_rs[iirs].tableau.length; krs++){
					tabsyno_rs.push(this.app.livedata.synoptique_rs[iirs].tableau[krs]);
				}
			}
			let t = []
			let p = []
			let r = []
			let a = []
			for (let i in tabsyno_ap) {
				let b = true
				for (let j in this.selectedPrograms) {
					
					if (this.selectedPrograms[j].program_id == tabsyno_ap[i].program_id) {
						b = false
						break
					}
				}
				if (b) a.push({code : tabsyno_ap[i].program_id})
			}

			for (let ip in tabsyno) {
				t.push({code : tabsyno[ip].program_id})
			}
			for (let ip in tabsyno_pc) {
				p.push({code : tabsyno_pc[ip].program_id})
			}
			for (let ir in tabsyno_rs) {
				r.push({code : tabsyno_rs[ir].program_id})
			}
			this.selectedPrograms = null
			this.$store.dispatch('report_by_id', { programs: t, pc: p, avantpremiere: a, rss: r, view: this.app.source_view})
		},

		expand_program: function(id) {
			this.$store.dispatch('expand_program_from_syno', { id: id, pc: true })
		},
	},

	components: { ToolSource, DataTable, Column, ColumnGroup, Row, Button, SpeedDial, ImagePreview }

}
</script>

<style lang="stylus" scoped>
@import '../../../../../assets/css/app'

.rupture
	background secondary
	color white
	font-weight 500 
	text-align center
	text-transform uppercase

.center
	text-align center
.right
	text-align right

th
	background #f0f2f4
	color #666
	text-transform uppercase
	border 1px solid #ccc !important
	
tr:nth-child(even) {background: #f9f9f9}
tr:nth-child(odd) {background: #FFF}
tr
	border-bottom 1px dotted #ccc !important


.prix
	text-align right
	font-weight 800

:deep(.p-progressbar) {
	height: .5rem;
	background-color: #D8DADC;

	.p-progressbar-value {
		background-color: #607D8B;
	}
}
:deep(.p-datatable.synoptique) {
	.p-rowgroup-header{
		background-color: #eeeeee !important;
		color: #333 !important;
		font-size: 1rem;
	}
	.p-rowgroup-header .image-text {
		margin-left: 10px;
	}
    .p-datatable-header {
        padding: 1rem;
        text-align: center;
        font-size: 1rem;
    }
	.p-column-title {
		text-align: center;
		font-size: 0.9rem;
	}
	.p-column-header-content {
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.p-datatable-tbody {
		font-size: 0.75rem;
	}
    .p-paginator {
        padding: 1rem;
    }

    .p-datatable-thead > tr > th {
        text-align: center;
    }

    .p-datatable-tbody > tr > td {
        cursor: auto;
		padding: 0.5rem 0.5rem
    }
}
.product-image {
	text-align: center;
    width: auto;
	max-height : 50px
}
</style>
