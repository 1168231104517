<template>
	<div class="stat-box" id='graph_offre'>
		<div class="stat-box-header">
			<div class="flex align-items-center surface-card">
				<div style="width:48px;height:48px;border-radius:10px" class="bg-blue-100 inline-flex align-items-center justify-content-center mr-3">
					<i class="pi pi-building text-blue-500 text-3xl"></i>
				</div>
				<div>
					<span class="text-900 text-xl font-medium mb-2">{{ $t('historique') }}</span>
					<p class="mt-1 mb-0 text-600 font-medium text-sm">{{ $t('de_la_ventilation_de_loffre') }}</p>
				</div>
				<div class="ml-auto">
					<div>
						<Button  icon="pi pi-image" class="p-button-rounded" @click="$store.dispatch('ExportToPng',{id: 'groff'})" />
					</div>
				</div>
			</div>
		</div>
		<div class="stat-box-content" id="groff">
			<div class="graph">
				<highcharts :options="chartOptions"></highcharts>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import { Chart } from 'highcharts-vue'
import Button from 'primevue/button';

export default {
	name: 'GraphOffre',

	data: function() {
		return {
			items: [
				{label: 'Image', icon: 'pi pi-fw pi-image', command:() => {this.$store.dispatch('ExportToPng',{id: 'groff'});} },
			],
			chartOptions: {
				accessibility: {
					enabled: false
				},
				credits: {
					enabled: false
				},
				title: '',
				chart: {
					height: 280,
					type: 'area',
					style: {
						fontFamily: 'Nunito',
					}
				},
                xAxis: {
                        categories: ['1750', '1800', '1850', '1900', '1950', '1999', '2050'],
                        tickmarkPlacement: 'on',
                        title: {
                            enabled: false
                        }
                },
                yAxis: {
                    labels: {
                        format: '{value}%'
                    },
                    title: {
                        enabled: false
                    }
                },
                plotOptions: {
                    area: {
                        stacking: 'percent',
                        lineColor: '#ffffff',
                        lineWidth: 1,
                        marker: {
                            lineWidth: 1,
                            lineColor: '#ffffff'
                        },
                        accessibility: {
                            pointDescriptionFormatter: function (point) {
                                function round(x) {
                                    return Math.round(x * 100) / 100;
                                }
                                return (point.index + 1) + ', ' + point.category + ', ' +
                                    point.y + ' millions, ' + round(point.percentage) + '%, ' +
                                    point.series.name;
                            }
                        }
                    }
                },
				series: [
					{
						color: '#f44336',
						name: "Sur plan",
						data: [0,0,0,0,0,0,0,0],
						fillColor: {
							linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
							stops: [
								[0, 'rgba(244,67,54,0.3)'],
							]
						},
						marker: {
							symbol: 'circle',
							enabled: false
						}
					},
					{
						color: '#8BC34A',
						name: "En chantier",
						data: [0,0,0,0,0,0,0,0],
						fillColor: {
							linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
							stops: [
								[0, 'rgba(139,195,74,0.3)'],
							]
						},
						marker: {
							symbol: 'circle',
							enabled: false
						}
					},
					{
						color: '#2196F3',
						name: "livrée",
						data: [0,0,0,0,0,0,0,0],
						fillColor: {
							linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
							stops: [
								[0, 'rgba(33,150,243,0.3)'],
							]
						},
						marker: {
							symbol: 'circle',
							enabled: false
						}
					},
				],
			}
		}
	},

	computed: Object.assign(mapGetters([ 'app' ]), {
		
	}),

	methods: {

		maj: function() {
			this.chartOptions.series[0].data = [
				this.app.livedata.volume_offre.offre_plan.tableau[0], 
				this.app.livedata.volume_offre.offre_plan.tableau[1], 
				this.app.livedata.volume_offre.offre_plan.tableau[2], 
				this.app.livedata.volume_offre.offre_plan.tableau[3], 
				this.app.livedata.volume_offre.offre_plan.tableau[4],
				this.app.livedata.volume_offre.offre_plan.tableau[5],
				this.app.livedata.volume_offre.offre_plan.tableau[6],
				this.app.livedata.volume_offre.offre_plan.tableau[7]
			]
			this.chartOptions.series[1].data = [
				this.app.livedata.volume_offre.offre_chantier.tableau[0], 
				this.app.livedata.volume_offre.offre_chantier.tableau[1], 
				this.app.livedata.volume_offre.offre_chantier.tableau[2], 
				this.app.livedata.volume_offre.offre_chantier.tableau[3], 
				this.app.livedata.volume_offre.offre_chantier.tableau[4],
				this.app.livedata.volume_offre.offre_chantier.tableau[5],
				this.app.livedata.volume_offre.offre_chantier.tableau[6],
				this.app.livedata.volume_offre.offre_chantier.tableau[7]
			]
			this.chartOptions.series[2].data = [
				this.app.livedata.volume_offre.offre_livre.tableau[0], 
				this.app.livedata.volume_offre.offre_livre.tableau[1], 
				this.app.livedata.volume_offre.offre_livre.tableau[2], 
				this.app.livedata.volume_offre.offre_livre.tableau[3], 
				this.app.livedata.volume_offre.offre_livre.tableau[4],
				this.app.livedata.volume_offre.offre_livre.tableau[5],
				this.app.livedata.volume_offre.offre_livre.tableau[6],
				this.app.livedata.volume_offre.offre_livre.tableau[7]
			]
			this.chartOptions.xAxis.categories= [
				this.app.livedata.volume_offre.intitule_colonne[0], 
				this.app.livedata.volume_offre.intitule_colonne[1], 
				this.app.livedata.volume_offre.intitule_colonne[2], 
				this.app.livedata.volume_offre.intitule_colonne[3], 
				this.app.livedata.volume_offre.intitule_colonne[4],
				this.app.livedata.volume_offre.intitule_colonne[5],
				this.app.livedata.volume_offre.intitule_colonne[6],
				this.app.livedata.volume_offre.intitule_colonne[7]
			]
		}
	},

	mounted: function() {
		this.maj()
	},

	watch: {
		'app.livedata': function() {
			if (this.app.view == 'report' || this.app.view == 'report_B') {
				this.maj()
			}
		},
	},
	
	components: { highcharts: Chart, Button }
}
</script>

<style lang="stylus" scoped>
@import '../../../../../assets/css/app'

.stat-box
	.stat-box-content
		padding 0 16px

.graph
	min-height 200px
	text-align center
	line-height @height


</style>
