<template>
	<transition name="pane-program">
		<div class="pane pane-program" id="progencours">
			<div class="content vb-content" style="display: block; overflow: hidden scroll; height: 100%; width: 100%; box-sizing: content-box; padding-right: 20px;background-color: white;">
				<div class="pan-titre-programme" id="pan-titre-programme">
					<div class="module_ret">
						<div class="btns fermer" title="Fermer"  @click="expand_program();$store.dispatch('set_help_prog', {aide:false})"><icon file="fermer" :size="16" /></div>
						<div class="btns aide" :title="$t('aide')" @click="$store.dispatch('set_help_prog', {aide:!app.aide_prog})"><icon file="help-circle" :size="24" /></div>
						<div class="btns print" :title="$t('imprimer')" @click="generateProgImage(expanded_program.id);"><icon file="download" :size="24" />&nbsp;</div>
						<!--						<div class="btns chatbox"><Button style="height:60px;" class="p-button-text" icon="pi pi-comments" onclick="$('#zohohc-asap-web-launcherbox').click();" /></div>-->
						<div class="btns chatbox" onclick="$('#zohohc-asap-web-launcherbox').click();"><icon file="question" :size="24" />&nbsp;</div>
						<div v-if="expanded_program.pc && !expanded_program.avantpremiere" class="indice_confiance" :class="{ pc : true }">
							{{ $t('fiche_projet') }}
						</div>
						<div v-if="expanded_program.pc && expanded_program.avantpremiere" class="indice_confiance" :class="{ bas : true }">
							{{ $t('Programme en veille') }}
						</div>
						<div v-if="expanded_program.indice_confiance >= 7 && !expanded_program.avant_premiere" class="indice_confiance" :class="{ haut : true }">
							{{$t('Indice de confiance')}} : {{$t('données optimales')}}
						</div>
						<div v-if="expanded_program.indice_confiance >= 5 && expanded_program.indice_confiance < 7 && !expanded_program.avant_premiere" class="indice_confiance" :class="{ milieu: expanded_program.indice_confiance >= 5 && expanded_program.indice_confiance < 7 }">
							{{$t('Indice de confiance')}} : {{$t('données augmentées')}}
						</div>
						<div v-if="expanded_program.indice_confiance < 5 && !expanded_program.avant_premiere" class="indice_confiance" :class="{ bas: expanded_program.indice_confiance < 5 }">
							{{$t('Indice de confiance')}} : {{$t('données standard')}}
						</div>
						<div v-if="expanded_program.avant_premiere" class="indice_confiance" :class="{ bas: expanded_program.indice_confiance < 5 }">
							{{$t('Indice de confiance')}} : {{$t('données non communicable, avant-première')}}
						</div>
					</div>
					<br/>
				</div>
				<div style="height:61px;">&nbsp;</div>				
				<div class="program" id="ProgEx" :class="{ inactif: expanded_program.commercialisation == 'termine', selected: expanded_program.selected }" style="background-color: white;">
					<div class="program-main" style="height:285px;padding: 0px;" v-if="!expanded_program.pc || expanded_program.avantpremiere">
						<div class="image" style="top:0; left:0;width:100%">
							<div style="width: 100%; display:flex; justify-content: center;height:100%">
								<ImagePreview v-if="expanded_program.url_photo!='-' && expanded_program.url_photo!=null" @error="handleImageError" :src="expanded_program.url_photo" alt="Image" height="285" preview />
								<StreetView :id="'streetviewprog'" v-else-if="expanded_program && expanded_program.location" :coordinates="adresseComplete()" />
							</div>
						</div>
					</div>
					<div class="program-main" style="height:285px;" v-else>
						<div class="image">
							<div style="width: 100%; display:flex; justify-content: center;height:100%">
								<StreetView v-if="expanded_program && expanded_program.location" :coordinates="adresseComplete()" />
							</div>
						</div>
					</div>
					<div class="property-card " v-if="!expanded_program.pc">
						<div class="property-header">
							<div style="padding-left:15px; padding-right: 15px; padding-bottom: 15px">
								<h1 class="property-title">{{ expanded_program.nom }}</h1>
								<div style="font-size: 15px;margin-bottom: 5px;">{{$t('Promoteur(s)')}} : {{ expanded_program.promoteur }}</div>
								<div style="font-size: 15px;">{{ expanded_program.stock_initial }} {{$t('logements')}}</div>
								
								<div class="property-details">
									<div class="badge-container">
										<span class="badge badge-green" v-if="expanded_program.qeb">{{ expanded_program.qeb }}</span>
										<span class="badge badge-blue" v-if="expanded_program.tva_reduite_prix_m2_parking_sans">5.5%</span>
										<span class="badge badge-blue" v-if="expanded_program.psla">PSLA</span>
										<span class="badge badge-blue" v-if="expanded_program.brs">BRS</span>
										<span class="badge badge-orange" v-if="expanded_program.liste_veb && expanded_program.liste_veb.length && vebacte()" v-tooltip.bottom="$t('VEB actée, cliquez pour avoir plus d’info')" @click="veb = true;$store.dispatch('send_event_backend', {module: 'neuf', action: 'veb', option: 'ouverture'})">VEB</span>
										<span class="badge badge-orange" v-if="expanded_program.liste_veb && expanded_program.liste_veb.length && vebresa()" v-tooltip.bottom="$t('VEB reservée, cliquez pour avoir plus d’info')" @click="veb = true;$store.dispatch('send_event_backend', {module: 'neuf', action: 'veb', option: 'ouverture'})">VEB</span>
									</div>
									<div class="address">
										<span >{{ expanded_program.adresse }} {{ expanded_program.codepostal }} {{ expanded_program.ville }}</span>
										<span class="location-pin" v-if="expanded_program && expanded_program.location && expanded_program.location.lat && expanded_program.location.lon">
											<a target="_blank" :href="'https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=' + expanded_program.location.lat + ',' + expanded_program.location.lon"><icon file="googlestreetview" :size="24"/></a>
										</span>
									</div>
									<div class="badge-container">
										<span class="badge badge-purple" v-if="expanded_program.forme_urbaine">{{ expanded_program.forme_urbaine.toUpperCase() }}</span>
										<span class="badge badge-purple" v-if="expanded_program.nombre_etage">{{ expanded_program.nombre_etage }} {{$t('étage')}}<span v-if="expanded_program.nombre_etage>1">s</span></span>
									</div>

									<div class="sold-out-notice" v-if="!expanded_program.offre">
										<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
											<path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
											<polyline points="22 4 12 14.01 9 11.01"></polyline>
										</svg>
										<span style="margin-left:10px">Programme entièrement commercialisé</span>
									</div>
								</div>
							</div>
							<div class="tabs" style="margin-bottom:15px;justify-content: space-between;">
								<div style="display: flex;">
									<div class="tab" @click="activeTab_histo_graph='dates'" 	:class="{active: activeTab_histo_graph=='dates'}">Dates</div>
									<!--<div v-if="expanded_program.offre!=expanded_program.stock_initial" class="tab" @click="activeTab_histo_graph='ventes'" 	:class="{active: activeTab_histo_graph=='ventes'}">Ventes</div>-->
									<div v-if="expanded_program.offre!=expanded_program.stock_initial" class="tab" @click="activeTab_histo_graph='prevision'" 	:class="{active: activeTab_histo_graph=='prevision'}">Ventes</div>
									<div v-if="app.active_segmentation[2]" class="tab" @click="activeTab_histo_graph='offres'" 	:class="{active: activeTab_histo_graph=='offres'}">Offres</div>
<!--									<div v-if="expanded_program.offre && expanded_program.offre!=expanded_program.stock_initial" class="tab" @click="activeTab_histo_graph='prevision'" :class="{active: activeTab_histo_graph=='prevision'}" v-tooltip.top="$t('Prévision des ventes futures basée sur le taux d\'écoulement sélectionné')">Prévision</div>-->
								</div>
								<div style="display: flex;padding: 8px 15px;font-size: 13px;transition: all 0.2s ease;border-bottom: 2px solid transparent;">
									<span v-if="expanded_program.datemaj">{{ $t('donnees_a_jour_au') }} {{expanded_program.datemaj.substring(8,10)}}/{{expanded_program.datemaj.substring(5,7)}}/{{expanded_program.datemaj.substring(0, 4)}}</span>
								</div>
							</div>

							<div class="chart-container" v-if="app.module_histo_prog">
								<div class="refreshprog" v-if="app.refresh_prog">
									<div class="infinite-loader"><div class="loader"><icon file="loader" :size="128" style="color:#CCCCCC;" /></div></div>
								</div>
								<div v-else>
									<div v-if="activeTab_histo_graph=='dates'">
										<div class="tab-content-panel">
												<div style="text-wrap: nowrap;width:90%;" v-if="aff_histo == 1">
													<Timeline :value="events1" align="bottom" layout="horizontal">
														<template #marker="slotProps">
															<span>
																<i v-if="!slotProps.item.color" class="custom-marker" style="color:#333" :class="slotProps.item.icon"></i>
																<i v-else class="custom-marker" :class="slotProps.item.icon" :style="{backgroundColor: slotProps.item.color}"></i>
															</span>
														</template>
														<template #opposite="slotProps">
															<small class="p-text-secondary">{{slotProps.item.date}}</small>
														</template>
														<template #content="slotProps">
															{{slotProps.item.status}}
														</template>
													</Timeline>
												</div>
											</div>

									</div>

									<div v-if="activeTab_histo_graph=='ventes'">
										<div class="tab-content-panel">
											<highcharts style="width:100%" ref="h_v" id="h_v" :options="chartOptions_histo_vente"></highcharts>
										</div>
									</div>

									<div v-if="activeTab_histo_graph=='offres'">
										<div class="tab-content-panel">
											<highcharts style="width:100%" ref="h_o" id="h_o" :options="chartOptions_histo_offre"></highcharts>
										</div>
									</div>

									<div v-if="activeTab_histo_graph=='prevision'">
										<div class="tab-content-panel">
											<highcharts style="width:100%" ref="h_p" id="h_p" :options="chartOptions_histo_offre_prev"></highcharts>
										</div>

									</div>
								</div>

							</div>
							<div class="chart-container" v-else>
								<div style="text-wrap: nowrap;width:90%;">
									<Timeline :value="events1" align="bottom" layout="horizontal">
										<template #marker="slotProps">
											<span>
												<i v-if="!slotProps.item.color" class="custom-marker" style="color:#333" :class="slotProps.item.icon"></i>
												<i v-else class="custom-marker" :class="slotProps.item.icon" :style="{backgroundColor: slotProps.item.color}"></i>
											</span>
										</template>
										<template #opposite="slotProps">
											<small class="p-text-secondary">{{slotProps.item.date}}</small>
										</template>
										<template #content="slotProps">
											{{slotProps.item.status}}
										</template>
									</Timeline>
								</div>
							</div>
						</div>						
					</div>

					<div v-if="expanded_program.avantpremiere">
						<div class="property-card ">
							<div class="property-header">
								<div style="padding-left:15px; padding-right: 15px; padding-bottom: 15px">
									<h1 class="property-title">{{ expanded_program.nom }}</h1>
									<div style="font-size: 15px;margin-bottom: 5px;">{{$t('Promoteur(s)')}} : {{ expanded_program.promoteur }}</div>
									<div style="font-size: 15px;" v-if="expanded_program.stock_initial">{{ expanded_program.stock_initial }} {{$t('logements')}}</div>
									
									<div class="property-details">
										<div class="badge-container">
											<span class="badge badge-green" v-if="expanded_program.qeb">{{ expanded_program.qeb }}</span>
											<span class="badge badge-blue" v-if="expanded_program.tva_reduite_prix_m2_parking_sans">5.5%</span>
											<span class="badge badge-blue" v-if="expanded_program.psla">PSLA</span>
											<span class="badge badge-blue" v-if="expanded_program.brs">BRS</span>
											<span class="badge badge-orange" v-if="expanded_program.liste_veb && expanded_program.liste_veb.length && vebacte()" v-tooltip.bottom="$t('VEB actée, cliquez pour avoir plus d’info')" @click="veb = true;$store.dispatch('send_event_backend', {module: 'neuf', action: 'veb', option: 'ouverture'})">VEB</span>
											<span class="badge badge-orange" v-if="expanded_program.liste_veb && expanded_program.liste_veb.length && vebresa()" v-tooltip.bottom="$t('VEB reservée, cliquez pour avoir plus d’info')" @click="veb = true;$store.dispatch('send_event_backend', {module: 'neuf', action: 'veb', option: 'ouverture'})">VEB</span>
										</div>
										<div class="address">
											<span >{{ expanded_program.adresse }} {{ expanded_program.codepostal }} {{ expanded_program.ville }}</span>
											<span class="location-pin" v-if="expanded_program && expanded_program.location && expanded_program.location.lat && expanded_program.location.lon">
												<a target="_blank" :href="'https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=' + expanded_program.location.lat + ',' + expanded_program.location.lon"><icon file="googlestreetview" :size="24"/></a>
											</span>
										</div>
										<div class="badge-container">
											<span class="badge badge-purple" v-if="expanded_program.forme_urbaine">{{ expanded_program.forme_urbaine.toUpperCase() }}</span>
											<span class="badge badge-purple" v-if="expanded_program.nombre_etage">{{ expanded_program.nombre_etage }} {{$t('étage')}}<span v-if="expanded_program.nombre_etage>1">s</span></span>
										</div>

										<div class="veille-notice">
											<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="currentColor">
												<path d="M600-160q-134 0-227-93t-93-227q0-133 93-226.5T600-800q133 0 226.5 93.5T920-480q0 134-93.5 227T600-160Zm0-80q100 0 170-70t70-170q0-100-70-170t-170-70q-100 0-170 70t-70 170q0 100 70 170t170 70Zm91-91 57-57-108-108v-144h-80v177l131 132ZM80-600v-80h160v80H80ZM40-440v-80h200v80H40Zm40 160v-80h160v80H80Zm520-200Z"/>
											</svg>
											<span style="margin-left:10px">Programme en veille</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
						<div class="info-panel" v-if="expanded_program.avantpremiere">
							<p>
								{{ $t('Ce programme a été détecté et est actuellement suivi par nos équipes de collecte. Il sera affiché parmi les programmes en cours dès que nous disposerons de la grille de prix et que la commercialisation sera lancée.')}}
							</p>
						</div>
					<div class="program-main" v-if="expanded_program.avantpremiere">












						<!--
						<div class="program-detail">
							<div class="program-detail-content" style="padding-top:0px !important">
								<div class="program-detail-info">									
									<div class="program-maj" style="margin-bottom: 10px;">
										<span v-if="expanded_program.datemaj">{{ $t('donnees_a_jour_au') }} {{expanded_program.datemaj.substring(8,10)}}/{{expanded_program.datemaj.substring(5,7)}}/{{expanded_program.datemaj.substring(0, 4)}}</span>
										<span v-if="monthsDifference(expanded_program.datemaj) > 6">&nbsp;&nbsp;<i v-tooltip.top="$t('Cette fiche programme n\'a pas été mise à jour depuis plus de 6 mois')" class="pi pi-exclamation-triangle" style="color:orange"></i></span>
									</div>
									<div class="program-detail-info-content" style="padding:15px;">
										<div style="display: flex;justify-content: space-between;padding-bottom: 10px;">
											<div class="nom"><strong>{{ expanded_program.nom }}</strong></div>
											<div>{{ expanded_program.adresse }} {{ expanded_program.codepostal }} {{ expanded_program.ville }}</div>
										</div>
										<div style="display: flex;justify-content: space-between">
											<div>{{$t('Promoteur(s)')}} : {{ expanded_program.promoteur }}</div>
											<div v-if="expanded_program.forme_urbaine">{{ expanded_program.forme_urbaine.toUpperCase() }}</div>
											<div v-if="expanded_program.nombre_etage">{{ expanded_program.nombre_etage }} {{$t('étage')}}<span v-if="expanded_program.nombre_etage>1">s</span></div>
											<div v-if="expanded_program.stock_initial && expanded_program.stock_initial > 5">{{ expanded_program.stock_initial }} {{$t('logements')}}</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						
						<div class="program-detail">
							<div class="program-detail-content" style="padding-top:0px !important">
								<div class="program-detail-info">									
									<div class="program-detail-info-content" style="padding:15px;">
										<div style="display: flex;justify-content: space-between;padding-bottom: 10px;">
											<div><i class="pi pi-info-circle" style="color: slateblue"></i></div><div>&nbsp;</div><div>{{ $t('Ce programme a été détecté et est actuellement suivi par nos équipes de collecte. Il sera affiché parmi les programmes en cours dès que nous disposerons de la grille de prix et que la commercialisation sera lancée.')}}</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						
						<div class="program-content">
							<br/>
							<p style="text-align:center;" v-if="expanded_program">
								<span><a target="_blank" :href="'https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=' + expanded_program.location.lat + ',' + expanded_program.location.lon"><img alt="streetview" src="https://www.myadequation.fr/EFOCUS/streetview.png"/>&nbsp;{{ $t('voir_sur_google_street_view') }}</a></span>
							</p>
						</div>
						-->
					</div>

					<div v-if="expanded_program && !expanded_program.avant_premiere && !expanded_program.pc">
						<!--
						<div class="program-detail-info">
							<div class="program-maj">
								<span v-if="expanded_program.datemaj">{{ $t('donnees_a_jour_au') }} {{expanded_program.datemaj.substring(8,10)}}/{{expanded_program.datemaj.substring(5,7)}}/{{expanded_program.datemaj.substring(0, 4)}}</span>
							</div>
							<br>
						</div>
						-->
						<div class="program-detail-info">

							<div class="stats-filters" v-if="expanded_program.offre!=expanded_program.stock_initial">
								<animated-tabs 
								:tabs="tabs_te" 
								:initial-active-index="0" 
								@tab-change="handleTabChange_te"								
								></animated-tabs>
							</div>

							<div class="results" style="margin-top:10px" v-if="expanded_program.offre!=expanded_program.stock_initial">
								<div class="result-card" style="transform: translateY(0px);" id="img_estimmo_nb">
									<!--<div class="result-icon"><span class="material-symbols-outlined">shopping_bag_speed</span></div>-->
									<div class="result-label">Ventes par mois</div>
									<div class="result-value" id="vte_par_mois" v-if="activeTab_te=='3'">{{expanded_program.rythme.lancement.vtemois}}</div>
									<div class="result-value" id="vte_par_mois" v-if="activeTab_te=='90'">{{expanded_program.rythme.sans_fin_prog.vtemois}}</div>
									<div class="result-value" id="vte_par_mois" v-if="activeTab_te=='croisiere'">{{expanded_program.rythme.croisiere.vtemois}}</div>
									<div class="result-value" id="vte_par_mois" v-if="activeTab_te=='global'">{{expanded_program.rythme.total.vtemois}}</div>
								</div>
								<div class="result-card" style="transform: translateY(0px);" id="img_estimmo_px">
									<!--<div class="result-icon"><span class="material-symbols-outlined">percent</span></div>-->
									<div class="result-label">Taux d'écoulement</div>
									<div class="result-value" id="te" v-if="activeTab_te=='3'">{{expanded_program.rythme.lancement.te}} %</div>
									<div class="result-value" id="te" v-if="activeTab_te=='90'">{{expanded_program.rythme.sans_fin_prog.te}} %</div>
									<div class="result-value" id="te" v-if="activeTab_te=='croisiere'">{{expanded_program.rythme.croisiere.te}} %</div>
									<div class="result-value" id="te" v-if="activeTab_te=='global'">{{expanded_program.rythme.total.te}} %</div>
								</div>
								<div v-if="expanded_program.offre" class="result-card" style="transform: translateY(0px);" id="img_estimmo_sr">
									<!--<div class="result-icon"><span class="material-symbols-outlined">calendar_clock</span></div>-->
									<div class="result-label">Mois de commercialisation</div>
									<div class="result-value" id="te" v-if="activeTab_te=='3'"><span v-if="expanded_program.rythme.lancement.duree">{{expanded_program.rythme.lancement.duree}} mois</span><span v-else>-</span></div>
									<div class="result-value" id="te" v-if="activeTab_te=='90'"><span v-if="expanded_program.rythme.sans_fin_prog.duree">{{expanded_program.rythme.sans_fin_prog.duree}} mois</span><span v-else>-</span></div>
									<div class="result-value" id="te" v-if="activeTab_te=='croisiere'"><span v-if="expanded_program.rythme.croisiere.duree">{{expanded_program.rythme.croisiere.duree}} mois</span><span v-else>-</span></div>
									<div class="result-value" id="te" v-if="activeTab_te=='global'"><span v-if="expanded_program.rythme.total.duree">{{expanded_program.rythme.total.duree}} mois</span><span v-else>-</span></div>
								</div>
							</div>							
							<div class="tabs" style="margin-bottom:15px;justify-content: space-between;">
								<div style="display: flex;">
									<div class="tab" @click="activeTab_type='s'" 	:class="{active: activeTab_type=='s'}">Tous les logements</div>
									<div v-if="expanded_program.offre!=expanded_program.stock_initial && expanded_program.offre" class="tab" @click="activeTab_type='v'" 	:class="{active: activeTab_type=='v'}">Des ventes</div>
									<div v-if="expanded_program.offre!=expanded_program.stock_initial && expanded_program.offre" class="tab" @click="activeTab_type='o'" 	:class="{active: activeTab_type=='o'}">De l'offre</div>
								</div>
								<div style="display: flex;padding: 8px 15px;cursor: pointer;font-size: 13px;transition: all 0.2s ease;border-bottom: 2px solid transparent;">
									<div style="height:15px" @click="ExportTable()" v-if="!exportencours"><span id="expt" class="material-symbols-outlined">download</span><span style="margin-left:5px;margin-right:10px;top:-8px;">Télécharger</span></div>
								</div>
							</div>

							<div class="program-detail">
							<div class="program-detail-content" style="padding-top:0px !important;">
								<!--
									<animated-tabs
									:tabs="tabs_type_computed" 
									:initial-active-index="0" 
									@tab-change="handleTabChange_type"
									></animated-tabs>
								-->									
									<div style="display:flex">
										<animated-tabs 
										:tabs="tabs_tva_computed" 
										:initial-active-index="tabs_tva_computed.findIndex(item => item.name === activeTab_tva)" 
										@tab-change="handleTabChange_tva"
										style="width: auto;margin-right: 20px;"
										></animated-tabs>
										
										<div style="display: flex;align-items: center;height: 45px;"><span style="text-wrap-mode: nowrap;margin-right:10px;">Parking inclus</span><InputSwitch v-model="avec_pk" /></div>
										
<!--
										<animated-tabs 
										:tabs="tabs_pk_computed" 
										:initial-active-index="tabs_pk_computed.findIndex(item => item.name === activeTab_pk)" 
										@tab-change="handleTabChange_pk"
										></animated-tabs>
-->										
									</div>

									<div class="results" style="margin-top:10px">
										<div class="result-card" style="transform: translateY(0px);" id="img_estimmo_nb">
											<!--<div class="result-icon"><span class="material-symbols-outlined">euro</span></div>-->
											<div class="result-label">Prix au m² du Programme<tool-source url='' :txt='getInfoPrix'/></div>
											<div class="result-value">{{ DonneValeurPrixProg }}</div>
										</div>

										<div class="result-card" style="transform: translateY(0px);" id="img_estimmo_px">
											<!--<div class="result-icon"><span class="material-symbols-outlined">garage</span></div>-->
											<div class="result-label">{{ $t('Prix du stationnement') }}<tool-source v-if="activeTab_tva == 'tva_reduite'" url='' txt="Prix moyen d'un stationnement en TVA réduite"/><tool-source v-else url='' txt="Prix moyen d'un stationnement en TVA pleine"/></div>
											<div class="result-value" v-if="activeTab_tva != 'tva_reduite'">{{ DonneValeurPrix(expanded_program.PUM_Stat, 100, "") }}</div>
											<div class="result-value" v-else>{{ DonneValeurPrix(expanded_program.pum_stat_reduite, 100, "") }}</div>
										</div>

										<div v-if="activeTab_tva == 'tva_reduite' && expanded_program.brs" class="result-card" style="transform: translateY(0px);" id="img_estimmo_sr">
											<!--<div class="result-icon"><span class="material-symbols-outlined">payments</span></div>-->
											<div class="result-label">{{ $t('redevance_brs') }}</div>
											<div class="result-value" id="te">{{ DonneValeurPrix(expanded_program.brs_redevance, 0.01, "/m²") }}</div>
										</div>
									</div>
									
									<div class="refreshprog" v-if="app.refresh_prog">
										<div class="infinite-loader"><div class="loader"><icon file="loader" :size="128" style="color:#CCCCCC;" /></div></div>
									</div>
									<div v-else>
										<div v-if="mode_aff == 'historique'" class="program-detail-info">
											<div class="program-detail-info-content">
												<div><highcharts :options="chartOptions_trim"></highcharts></div>
											</div>
										</div>
										
										<div v-if="mode_aff == 'granulaire' && app.efocus_type=='A'" class="program-detail-info">

											<animated-tabs 
											:tabs="tabs_typo" 
											:initial-active-index="0" 
											@tab-change="handleTabChange_typo"
											></animated-tabs>
																						
											<DataTable :value="data1" class="p-datatable-sm text-sm" ref="dt" id="granulometrie" :rowClass="rowClass" showGridlines>
											<!--
												<template #header>
													<div style="text-align: left">
														<div class="flex align-items-center">
															<div style="width:48px;height:48px;border-radius:10px">
															</div>
															<div>
															</div>
															<div class="ml-auto">
																<div class="speeddial-tooltip-demo">
																	<SpeedDial className="speeddial-left" :model="items" direction="down" showIcon="pi pi-bars" hideIcon="pi pi-times" buttonClassName="p-button-outlined"/>
																</div>
															</div>
														</div>
													</div>
												</template>
											-->
												<ColumnGroup type="header">
													<Row>
														<Column header="" :rowspan="2">
															<div class="speeddial-tooltip-demo">
																<SpeedDial className="speeddial-left" :model="items" direction="down" showIcon="pi pi-bars" hideIcon="pi pi-times" buttonClassName="p-button-outlined"/>
															</div>
														</Column>
														<Column :header="$t('Volumes')" :colspan="3" />
														<Column :header="$t('surfaces_en_m²')" :colspan="3" />
														<Column :header="$t('prix')" :colspan="4" />
													</Row>
													<Row>
														<Column field="code" :header="$t('Stock Ini.')"></Column>
														<Column field="name" :header="$t('ventes')"></Column>
														<Column field="category" :header="$t('Offres')"></Column>
														<Column field="quantity" :header="$t('min')"></Column>
														<Column field="quantity" :header="$t('moy.')"></Column>
														<Column field="quantity" :header="$t('max')"></Column>
														<Column field="quantity" :header="$t('min')"></Column>
														<Column field="quantity" :header="$t('moy.')"></Column>
														<Column field="quantity" :header="$t('max')"></Column>
														<Column field="quantity" :header="$t('Moy. m²')"></Column>
													</Row>
												</ColumnGroup>
												<Column field="id"></Column>
												<Column field="si"></Column>
												<Column field="vte"></Column>
												<Column field="off"></Column>
												<Column field="smin"></Column>
												<Column field="smoy"></Column>
												<Column field="smax"></Column>
												<Column field="pmin"></Column>
												<Column field="pmoy"></Column>
												<Column field="pmax"></Column>
												<Column field="pmmoy"></Column>
											</DataTable>
											
											<table id='tableau_typo' style='visibility: collapse;'>
												<tbody>
													<tr>
														<th>{{$t('Id')}}</th>
														<th>{{$t('Stock initial')}}</th>
														<th>{{$t('Ventes')}}</th>
														<th>{{$t('Offre')}}</th>
														<th>{{$t('Surface min en m²')}}</th>
														<th>{{$t('Surface moy en m²')}}</th>
														<th>{{$t('Surface max en m²')}}</th>
														<th>{{$t('Prix unitaire min en €')}}</th>
														<th>{{$t('Prix unitaire moy en €')}}</th>
														<th>{{$t('Prix unitaire max en €')}}</th>
														<th>{{$t('Prix en €/m²')}}</th>
													</tr>
													<tr v-for="prog in data1" :key="prog.id">
														<td>{{prog.id}}</td>
														<td>{{prog.si}}</td>
														<td>{{prog.vte}}</td>
														<td>{{prog.off}}</td>
														<td>{{prog.smin}}</td>
														<td>{{prog.smoy}}</td>
														<td>{{prog.smax}}</td>
														<td>{{prog.pmin_xls}}</td>
														<td>{{prog.pmoy_xls}}</td>
														<td>{{prog.pmax_xls}}</td>
														<td>{{prog.pmmoy_xls}}</td>
													</tr>
												</tbody>
											</table>
											
											
											
											
											
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div v-if="expanded_program.pc && !expanded_program.avantpremiere">
						<div class="program-detail">
							<div class="program-detail-content">
								<div class="program-detail-info">
									<div class="program-detail-info-filters">
										<h1>{{ $t('les_evenements') }}</h1>
									</div>
									<br/>
									<Timeline :value="events2" align="bottom" layout="horizontal" class="customized-timeline">
										<template #marker="slotProps">
											<span>
												<i v-if="!slotProps.item.color" class="custom-marker" style="color:#333" :class="slotProps.item.icon"></i>
												<i v-else class="custom-marker" :class="slotProps.item.icon" :style="{backgroundColor: slotProps.item.color}"></i>
											</span>
										</template>
										<template #opposite="slotProps">
											<small class="p-text-secondary">{{AfficheDate(slotProps.item.date)}}</small>
										</template>
										<template #content="slotProps">
											{{slotProps.item.status}}
										</template>
									</Timeline>
									
									<div class="surface-card border-round p-4" id="insee_rs_chiffres_img" style="background: #f6f6f6!important; width: calc(100% - 7px);">
										<div class="flex align-items-start mb-5">
											<div class="ml-3" style="text-align: left;">
												<span class="block text-900 mb-1 text-xl font-medium">{{ $t('permis_de_construire') }}</span>
												<p class="text-600 mt-0 mb-0"></p>
											</div>
										</div>
										<ul class="list-none p-0 m-0">
											<li class="mb-5" v-if="expanded_program.nopc">
												<div style="display:flex">
													<div style="width:100%">
														<div class="flex justify-content-between align-items-center">
															<span class="text-900 inline-flex justify-content-between align-items-center">
																<i class="pi pi-hashtag mr-2" style="color:var(--primary-500) !important"></i>
																<span class="font-medium text-900">{{ $t('numero_de_permis_de_construire') }}</span>
															</span>
															<span class="text-purple-600 font-medium">{{expanded_program.nopc}}<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></span>
														</div>
													</div>
												</div>
											</li>
											<li class="mb-5">
												<div style="display:flex">
													<div style="width:100%">
														<div class="flex justify-content-between align-items-center">
															<span class="text-900 inline-flex justify-content-between align-items-center">
																<i class="pi pi-user mr-2" style="color:var(--primary-500) !important"></i>
																<span class="font-medium text-900" v-if="expanded_program.promoteur">{{expanded_program.promoteur}}</span><span class="font-medium text-900" v-else>{{ $t('n.c.') }}</span>
															</span>
															<span class="text-purple-600 font-medium" v-if="expanded_program.nature_depositaire">{{expanded_program.nature_depositaire}}<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></span>
														</div>
													</div>
												</div>
											</li>
											<li class="mb-5">
												<div style="display:flex">
													<div style="width:100%">
														<div class="flex justify-content-between align-items-center">
															<span class="text-900 inline-flex justify-content-between align-items-center">
																<i class="pi pi-map mr-2" style="color:var(--primary-500) !important"></i>
																<span class="font-medium text-900">{{ expanded_program.adresse }} | {{ expanded_program.codepostal }} {{ expanded_program.ville }}</span>
															</span>
															<span class="text-purple-600 font-medium"><span><a target="_blank" :href="'https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=' + expanded_program.location.lat + ',' + expanded_program.location.lon"><img alt="streetview" src="https://www.myadequation.fr/EFOCUS/streetview.png"/>&nbsp;{{ $t('voir_sur_google_street_view') }}</a><span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></span></span>
														</div>
													</div>
												</div>
											</li>
											<li class="mb-5">
												<div style="display:flex">
													<div style="width:100%">
														<div class="flex justify-content-between align-items-center">
															<span class="text-900 inline-flex justify-content-between align-items-center">
																<div class="badge badge-purple" v-if="expanded_program.forme_urbaine.toUpperCase()=='COLLECTIF'"><icon file="building" :size="16" style="color: var(--primary-500) !important" /></div>
																<div class="badge badge-purple" v-else><icon file="home" :size="16" style="color:var(--primary-500) !important" /></div>
																<span class="font-medium text-900">&nbsp;&nbsp;{{expanded_program.nb_log_pc}} {{ $t('logement') }}<span v-if="expanded_program.nb_log_pc>1">s</span> {{ $t('toutes_destinations_confondues') }}</span>
															</span>
															<span class="text-purple-600 font-medium" v-if="expanded_program.pdc_superficie">{{ $t('sur_un_terrain_de') }} {{expanded_program.pdc_superficie}} m²<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></span>
														</div>
													</div>
												</div>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			
			<Dialog header="Ventes en bloc " :modal="true" :visible.sync="veb">
				<p class="m-0" v-if="vebacte()">{{ vebacte() }} {{ $t('logements complémentaires en VEB actée') }}</p>
				<p class="m-0" v-if="vebresa()">{{ vebresa() }} {{ $t('logements complémentaires en VEB reservée') }}</p>
				<template #footer>
					<!---->
					<Button :label="$t('Retrouvez les tendances des VEB sur FIL')" icon="pi pi-chart-line" onclick='window.open("https://residentiel.adequation.fr/login", "_blank");' @click="veb = !veb;$store.dispatch('send_event_backend', {module: 'neuf', action: 'veb', option: 'fil'})" class="p-button-text"/>
					<!---->
					<Button :label="$t('Fermer')" icon="pi pi-times" @click="veb = !veb"/>
				</template>
			</Dialog>
			
		</div>
	</transition>
</template>

<script>
import { mapGetters } from 'vuex'
import { i18n } from '../main';
import { Chart } from 'highcharts-vue'
import Icon from './global/Icon.vue'
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import ColumnGroup from 'primevue/columngroup';
import Row from 'primevue/row';
import Chip from 'primevue/chip';
import Timeline from 'primevue/timeline';
import ImagePreview from 'primevue/imagepreview';
import domtoimage from 'dom-to-image'
import SpeedDial from 'primevue/speeddial';
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import StreetView from "./global/StreetView.vue";
// eslint-disable-next-line
import VueApexCharts from 'vue-apexcharts'
import AnimatedTabs from './global/AnimatedTabs.vue';
import ToolSource from './global/ToolSource';
import InputSwitch from 'primevue/inputswitch';

export default {
	name: 'PaneProgram',

	data: function() {
		return {
			exportencours: false,

		tabs_typo: [
		{ label: 'Typologie', name: 'typologie' },
		{ label: 'Echelle résidentielle', name: 'echelle' },
		],
		// Configuration des onglets principaux
		tabs_histo_graph: [
		{ label: 'Dates', name: 'dates' },
		{ label: 'Ventes', name: 'ventes' },
		{ label: 'Offres', name: 'offres' },
		{ label: 'Prévision', name: 'prevision' }
		],
		tabs_te: [
		{ label: '3 premiers mois', name: '3', tooltip: 'Rythme calculé sur les ventes des 3 premiers mois après lancement' },
		{ label: '90% de commercialisation', name: '90', tooltip: 'Rythme calculé jusqu\'à l\'atteinte de 90% des ventes totales' },
		{ label: 'Rythme de croisière', name: 'croisiere', tooltip: 'Rythme hors périodes de lancement et fin (entre 10% et 90% des lots)' },
		{ label: 'Global', name: 'global', tooltip: 'Rythme moyen depuis le lancement jusqu\'à aujourd\'hui' }
		],
		activeTab_typo: 'typologie',
		activeTab_histo_graph: 'dates',
		activeTab_te: '3',
		activeTab_type : 's',
		activeTab_tva : 'tva_pleine_libre',
		activeTab_pk : 'prix_sp',
		avec_pk : false,

			defaultImageUrl: 'https://www.myadequation.fr/EFOCUS./no-camera2.png',
			veb: false,
			items: [
				{label: 'Data', icon: 'pi pi-fw pi-download', command:() => {this.ExportTable();} },
			],
		data2: [
			{"id": "", "si": "", "vte": "", "off": "", "smin": "", "smoy": "", "smax": "", "pmin": "", "pmoy": "", "pmax": "", "pmmoy": ""},
		],
		series: [],
		aff_histo: 1,
		chartOptions: {
			chart: {
			height: 350,
			type: 'line',
			stacked: false,
			},
			stroke: {
			width: [0, 2, 5],
			curve: 'smooth'
			},
			plotOptions: {
			bar: {
				columnWidth: '50%'
			}
			},
			
			fill: {
			opacity: [0.85, 0.25, 1],
			gradient: {
				inverseColors: false,
				shade: 'light',
				type: "vertical",
				opacityFrom: 0.85,
				opacityTo: 0.55,
				stops: [0, 100, 100, 100]
			}
			},
			labels: [],
			markers: {
			size: 0
			},
			xaxis: {
			},
			yaxis: {
			title: {
			}
			},
			tooltip: {
			shared: true,
			intersect: false,
			y: {
				formatter: function (y) {
				return y;
			
				}
			}
			}
		},
		chartOptions_trim: {
				credits: {
					enabled: false
				},
				title: '',
				chart: {
					height: 280,
					type: 'areaspline',
					style: {
						fontFamily: 'Nunito',
					}
				},
				series: [
				{
					color: '#8BC34A',
					name: "Prix du programme",
					data: [0,0,0],
					fillColor: {
						linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
						stops: [
						[0, 'rgba(139,195,74,0.3)'],
						[1, 'transparent']
						]
					},
					marker: {
						symbol: 'circle',
						enabled: false
					}
				},
				{
					color: '#f44336',
					name: "Prix de l'offre",
					data: [0,0,0],
					fillColor: {
						linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
						stops: [
						[0, 'rgba(244,67,54,0.3)'],
						[1, 'transparent']
						]
					},
					marker: {
						symbol: 'circle',
						enabled: false
					}
				},
				{
					color: '#2196F3',
					name: "Prix des ventes",
					data: [0,0,0],
					fillColor: {
						linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
						stops: [
						[0, 'rgba(33,150,243,0.3)'],
						[1, 'transparent']
						]
					},
					marker: {
						symbol: 'circle',
						enabled: false
					}
				},
				],
				xAxis: {
					type : 'category',
					categories : ['', '', ''],
					title: {
						text: ''
					},
					allowDecimals: false,
					lineWidth: 1,
					lineColor: '#ddd',
				},
				yAxis: {
					title: {
						text: ''
					},
					allowDecimals: true,
					lineWidth: 0,
					gridLineColor: '#ddd',
					gridLineDashStyle: 'dash',
					labels: {
						format: '{value:,.0f} €'
					}
				},
				
				plotOptions: {
					series: {
						label: {
							connectorAllowed: false
						},
					}
				},
				tooltip: {
					shared: true,
					crosshairs: true,
					backgroundColor: '#fff',
					borderWidth: 0,
					borderRadius: 4
				},
			},
			expanded: false,
			tva: 'tva_pleine_libre',
			pkg: 'prix_sp',
			filter: 'volume',
			filter2: 'typologie',
			suffixe: 'T',
			filter3: 'tva_pleine_libre_sans',
			filter4: 'global',
			filter_svo: 's',
			mode_aff: 'granulaire',
			mode_aff_histo: 'programme',
			compteur: 5,
			chartOptions_histo_vente: {
				accessibility: {
					enabled: false
				},
				chart: {
					type: 'column',
					height: 250
				},
				title: {
					text: '',
					align: 'left'
				},
				xAxis: {
					categories: ['']
				},
				yAxis: {
					min: 0,
					title: {
						text: ''
					},
					stackLabels: {
						enabled: true
					}
				},
				legend: {
					align: 'center',
					verticalAlign: 'top',
				},
				plotOptions: {
					column: {
						stacking: 'normal',
						dataLabels: {
							enabled: true
						}
					}
				},
				series: [{
					name: '',
					data: [0]
				}]
			},

			chartOptions_histo_offre: {
				accessibility: {
					enabled: false
				},
				chart: {
					type: 'column',
					height: 250
				},
				title: {
					text: '',
					align: 'left'
				},
				xAxis: {
					categories: ['']
				},
				yAxis: {
					min: 0,
					title: {
						text: ''
					},
					stackLabels: {
						enabled: false
					}
				},
				legend: {
					align: 'center',
					verticalAlign: 'top',
				},
				plotOptions: {
					column: {
						grouping: true,  // active le groupement des colonnes (c'est déjà la valeur par défaut)
						stacking: null,  // ou simplement omettre "stacking"
						dataLabels: {
							enabled: true
						}
					}
				},
				series: [{
					name: '',
					data: [0]
				}]
			},

			chartOptions_histo_offre_prev: {
				accessibility: {
					enabled: false
				},
				chart: {
					type: 'column',
					height: 250
				},
				title: {
					text: '',
					align: 'left'
				},
				xAxis: {
					categories: ['']
				},
				yAxis: {
					min: 0,
					title: {
						text: ''
					},
					stackLabels: {
						enabled: false
					}
				},
				legend: {
					align: 'center',
					verticalAlign: 'top',
				},
				plotOptions: {
					column: {
						grouping: true,  // active le groupement des colonnes (c'est déjà la valeur par défaut)
						stacking: null,  // ou simplement omettre "stacking"
						dataLabels: {
							enabled: true
						}
					}
				},
				series: [{
					name: '',
					data: [0]
				}]
			},

		}
	},

	computed: Object.assign(mapGetters([ 'app', 'expanded_program', 'selected_secteurs' ]), {
		DonneValeurPrixProg: function() {
			let val = 0;
			let num = 10;
			let fin = "/m²";
			if (this.activeTab_tva == 'tva_pleine_libre' && this.activeTab_pk == 'prix_ap' && this.activeTab_type == 's') val = this.expanded_program.Prix_Stock_TVAP_LIBRE_APK
			if (this.activeTab_tva == 'tva_pleine_libre' && this.activeTab_pk == 'prix_sp' && this.activeTab_type == 's') val = this.expanded_program.Prix_Stock_TVAP_LIBRE_SPK
			if (this.activeTab_tva == 'tva_pleine_aide' && this.activeTab_pk == 'prix_ap' && this.activeTab_type == 's') val = this.expanded_program.Prix_Stock_TVAP_AIDE_APK
			if (this.activeTab_tva == 'tva_pleine_aide' && this.activeTab_pk == 'prix_sp' && this.activeTab_type == 's') val = this.expanded_program.Prix_Stock_TVAP_AIDE_SPK
			if (this.activeTab_tva == 'tva_reduite' && this.activeTab_pk == 'prix_ap' && this.activeTab_type == 's') val = this.expanded_program.Prix_Stock_TVAR_APK
			if (this.activeTab_tva == 'tva_reduite' && this.activeTab_pk == 'prix_sp' && this.activeTab_type == 's') val = this.expanded_program.Prix_Stock_TVAR_SPK

			if (this.activeTab_tva == 'tva_pleine_libre' && this.activeTab_pk == 'prix_ap' && this.activeTab_type == 'v') val = this.expanded_program.Prix_Vente_TVAP_LIBRE_APK
			if (this.activeTab_tva == 'tva_pleine_libre' && this.activeTab_pk == 'prix_sp' && this.activeTab_type == 'v') val = this.expanded_program.Prix_Vente_TVAP_LIBRE_SPK
			if (this.activeTab_tva == 'tva_pleine_aide' && this.activeTab_pk == 'prix_ap' && this.activeTab_type == 'v') val = this.expanded_program.Prix_Vente_TVAP_AIDE_APK
			if (this.activeTab_tva == 'tva_pleine_aide' && this.activeTab_pk == 'prix_sp' && this.activeTab_type == 'v') val = this.expanded_program.Prix_Vente_TVAP_AIDE_SPK
			if (this.activeTab_tva == 'tva_reduite' && this.activeTab_pk == 'prix_ap' && this.activeTab_type == 'v') val = this.expanded_program.Prix_Vente_TVAR_APK
			if (this.activeTab_tva == 'tva_reduite' && this.activeTab_pk == 'prix_sp' && this.activeTab_type == 'v') val = this.expanded_program.Prix_Vente_TVAR_SPK
			
			if (this.activeTab_tva == 'tva_pleine_libre' && this.activeTab_pk == 'prix_ap' && this.activeTab_type == 'o') val = this.expanded_program.Prix_Offre_TVAP_LIBRE_APK
			if (this.activeTab_tva == 'tva_pleine_libre' && this.activeTab_pk == 'prix_sp' && this.activeTab_type == 'o') val = this.expanded_program.Prix_Offre_TVAP_LIBRE_SPK
			if (this.activeTab_tva == 'tva_pleine_aide' && this.activeTab_pk == 'prix_ap' && this.activeTab_type == 'o') val = this.expanded_program.Prix_Offre_TVAP_AIDE_APK
			if (this.activeTab_tva == 'tva_pleine_aide' && this.activeTab_pk == 'prix_sp' && this.activeTab_type == 'o') val = this.expanded_program.Prix_Offre_TVAP_AIDE_SPK
			if (this.activeTab_tva == 'tva_reduite' && this.activeTab_pk == 'prix_ap' && this.activeTab_type == 'o') val = this.expanded_program.Prix_Offre_TVAR_APK
			if (this.activeTab_tva == 'tva_reduite' && this.activeTab_pk == 'prix_sp' && this.activeTab_type == 'o') val = this.expanded_program.Prix_Offre_TVAR_SPK

			const euro = new Intl.NumberFormat('fr-FR', {
				style: 'currency',
				currency: 'EUR',
				minimumFractionDigits: 0
			});
			if (val==0 || val=='NaN') {
				return "-"
			} else {
				val = Math.round(val/num)*num
				if (val==0 || val=='NaN') {
					return "-"
				}
				let formatted = euro.format(val)+fin
				formatted = formatted.replace(/\u00A0/g, ' ');
				return formatted
			}
		},

		getInfoPrix: function() {
			if (this.activeTab_tva == 'tva_pleine_libre' && this.activeTab_pk == 'prix_ap' && this.activeTab_type == 's') return i18n.t('tous_les_logements_en_tva_pleine_libre_parking_inclus')
			if (this.activeTab_tva == 'tva_pleine_libre' && this.activeTab_pk == 'prix_sp' && this.activeTab_type == 's') return i18n.t('tous_les_logements_en_tva_pleine_libre_hors_parking')
			if (this.activeTab_tva == 'tva_pleine_aide' && this.activeTab_pk == 'prix_ap' && this.activeTab_type == 's') return i18n.t('tous_les_logements_en_tva_pleine_aidee_parking_inclus')
			if (this.activeTab_tva == 'tva_pleine_aide' && this.activeTab_pk == 'prix_sp' && this.activeTab_type == 's') return i18n.t('tous_les_logements_en_tva_pleine_aidee_hors_parking')
			if (this.activeTab_tva == 'tva_reduite' && this.activeTab_pk == 'prix_ap' && this.activeTab_type == 's') return i18n.t('tous_les_logements_en_tva_reduite_parking_inclus')
			if (this.activeTab_tva == 'tva_reduite' && this.activeTab_pk == 'prix_sp' && this.activeTab_type == 's') return i18n.t('tous_les_logements_en_tva_reduite_hors_parking')

			if (this.activeTab_tva == 'tva_pleine_libre' && this.activeTab_pk == 'prix_ap' && this.activeTab_type == 'v') return i18n.t('tous_les_logements_vendus_en_tva_pleine_libre_parking_inclus')
			if (this.activeTab_tva == 'tva_pleine_libre' && this.activeTab_pk == 'prix_sp' && this.activeTab_type == 'v') return i18n.t('tous_les_logements_vendus_en_tva_pleine_libre_hors_parking')
			if (this.activeTab_tva == 'tva_pleine_aide' && this.activeTab_pk == 'prix_ap' && this.activeTab_type == 'v') return i18n.t('tous_les_logements_vendus_en_tva_pleine_aidee_parking_inclus')
			if (this.activeTab_tva == 'tva_pleine_aide' && this.activeTab_pk == 'prix_sp' && this.activeTab_type == 'v') return i18n.t('tous_les_logements_vendus_en_tva_pleine_aidee_hors_parking')
			if (this.activeTab_tva == 'tva_reduite' && this.activeTab_pk == 'prix_ap' && this.activeTab_type == 'v') return i18n.t('tous_les_logements_vendus_en_tva_reduite_parking_inclus')
			if (this.activeTab_tva == 'tva_reduite' && this.activeTab_pk == 'prix_sp' && this.activeTab_type == 'v') return i18n.t('tous_les_logements_vendus_en_tva_reduite_hors_parking')
			
			if (this.activeTab_tva == 'tva_pleine_libre' && this.activeTab_pk == 'prix_ap' && this.activeTab_type == 'o') return i18n.t('tous_les_logements_a_loffre_en_tva_pleine_libre_parking_inclus')
			if (this.activeTab_tva == 'tva_pleine_libre' && this.activeTab_pk == 'prix_sp' && this.activeTab_type == 'o') return i18n.t('tous_les_logements_a_loffre_en_tva_pleine_libre_hors_parking')
			if (this.activeTab_tva == 'tva_pleine_aide' && this.activeTab_pk == 'prix_ap' && this.activeTab_type == 'o') return i18n.t('tous_les_logements_a_loffre_en_tva_pleine_aidee_parking_inclus')
			if (this.activeTab_tva == 'tva_pleine_aide' && this.activeTab_pk == 'prix_sp' && this.activeTab_type == 'o') return i18n.t('tous_les_logements_a_loffre_en_tva_pleine_aidee_hors_parking')
			if (this.activeTab_tva == 'tva_reduite' && this.activeTab_pk == 'prix_ap' && this.activeTab_type == 'o') return i18n.t('tous_les_logements_a_loffre_en_tva_reduite_parking_inclus')
			if (this.activeTab_tva == 'tva_reduite' && this.activeTab_pk == 'prix_sp' && this.activeTab_type == 'o') return i18n.t('tous_les_logements_a_loffre_en_tva_reduite_hors_parking')
		},
		tabs_pk_computed: function() {
			let r = []
			if (this.expanded_program.tva_pleine_libre_prix_m2_parking_avec+this.expanded_program.tva_pleine_aide_prix_m2_parking_avec+this.expanded_program.tva_reduite_prix_m2_parking_avec>0) {
				r.push({ label: i18n.t('parking_inclus'), name: 'prix_ap' })
			}
			if (this.expanded_program.tva_pleine_libre_prix_m2_parking_sans+this.expanded_program.tva_pleine_aide_prix_m2_parking_sans + this.expanded_program.tva_reduite_prix_m2_parking_sans>0) {
				r.push({ label: i18n.t('hors_parking'), name: 'prix_sp' })
			}
			return r;
		},

		tabs_tva_computed: function() {
			let r = []
			if (this.expanded_program.tva_pleine_libre_prix_m2_parking_avec+this.expanded_program.tva_pleine_libre_prix_m2_parking_sans>0 && this.app.efocus_type!='B') {
				r.push({ label: i18n.t('tva_pleine_libre'), name: 'tva_pleine_libre' })
			}
			if (this.expanded_program.tva_pleine_aide_prix_m2_parking_avec+this.expanded_program.tva_pleine_aide_prix_m2_parking_sans>0 && this.app.efocus_type!='B') {
				r.push({ label: i18n.t('tva_pleine_aidee'), name: 'tva_pleine_aide' })
			}
			if (this.expanded_program.tva_reduite_prix_m2_parking_avec+this.expanded_program.tva_reduite_prix_m2_parking_sans>0 && this.app.efocus_type!='B') {
				r.push({ label: '\u00A0\u00A0' + i18n.t('TVA Réduite') + '\u00A0\u00A0', name: 'tva_reduite' })
			}
			return r;
		},
		tabs_type_computed: function() {
			return [
					{ label: 'Tous les logements', name: 's' },
					{ label: 'Des ventes', name: 'v' },
					{ label: 'A l\'offre', name: 'o' },
				]
				/*
			if (this.expanded_program.offre > 0 ) {
				return [
					{ label: 'Tous les logements', name: 's' },
					{ label: 'Des ventes', name: 'v' },
					{ label: 'A l\'offre', name: 'o' },
				]
			} else {
				this.activeTab_type = 'v';
				return false
			}
				*/
		},

		tabs_histo_graph_computed: function() {
			if (this.expanded_program.offre > 0 ) {
				return [
					{ label: 'Dates', name: 'dates' },
					{ label: 'Ventes', name: 'ventes' },
					{ label: 'Offres', name: 'offres' },
					{ label: 'Prévision', name: 'prevision' }
				]
			} else {
				return [
					{ label: 'Dates', name: 'dates' },
					{ label: 'Ventes', name: 'ventes' },
					{ label: 'Offres', name: 'offres' }
				]
			}
		},
		events1: function() {
			let t = []
			if (this.expanded_program && this.expanded_program.date_liv) {

				let year = parseInt(this.expanded_program.date_liv.substring(0, 4));
				let month = parseInt(this.expanded_program.date_liv.substring(4, 6)) - 1; // Les mois en JavaScript sont de 0 à 11
				let day = parseInt(this.expanded_program.date_liv.substring(6, 8));
				let date_liv = new Date(year, month, day);
				
				let today = new Date();
				today.setHours(0, 0, 0, 0)

				t.push({status: 'Mise en vente', date: this.DonneTrimestre(this.expanded_program.date_com), icon: 'pi pi-check', color: '#22c55e'})
				if (this.expanded_program.date_fin != '0') {	
					if (this.expanded_program.date_fin < this.expanded_program.date_liv) {
						t.push({status: 'Terminé', date: this.DonneTrimestre(this.expanded_program.date_fin), icon: 'pi pi-check', color: '#22c55e'})
						if (date_liv < today) {
							t.push({status: 'Livré', date: this.DonneTrimestre(this.expanded_program.date_liv), icon: 'pi pi-check', color: '#22c55e'})
						} else {
							t.push({status: 'Livré', date: this.DonneTrimestre(this.expanded_program.date_liv), icon: 'pi pi-circle', color: false})
						}					
					} else {
						if (date_liv < today) {
							t.push({status: 'Livré', date: this.DonneTrimestre(this.expanded_program.date_liv), icon: 'pi pi-check', color: '#22c55e'})
						} else {
							t.push({status: 'Livré', date: this.DonneTrimestre(this.expanded_program.date_liv), icon: 'pi pi-circle', color: false})
						}					
						t.push({status: 'Terminé', date: this.DonneTrimestre(this.expanded_program.date_fin), icon: 'pi pi-check', color: '#22c55e'})
					}
				} else {
					if (date_liv < today) {
						t.push({status: 'Livré', date: this.DonneTrimestre(this.expanded_program.date_liv), icon: 'pi pi-check', color: '#22c55e'})
					} else {
						t.push({status: 'Livré', date: this.DonneTrimestre(this.expanded_program.date_liv), icon: 'pi pi-circle', color: false})
					}					
					t.push({status: 'Terminé', date: this.DonneTrimestre(this.expanded_program.date_fin), icon: 'pi pi-circle', color: false})
				}
			}
			return t
		},

		events2: function() {
			let t = []
			if (this.expanded_program.evt) {
				for (let index = 0; index < this.expanded_program.evt.length; index++) {
					let lib = ''
					let icon = ''
					switch(this.expanded_program.evt[index].libelle)
					{
						case 'permis autorisé':
							lib = 'Autorisé'
							icon = 'pi pi-thumbs-up'
							break;
						case 'permis déposé':
							lib = 'Déposé'
							icon = 'pi pi-inbox'
							break;
						case 'permis non déposé':
							lib = 'Non déposé'
							icon = 'pi pi-eye-slash'
							break;
						case 'permis en recours':
							lib = 'En recours'
							icon = 'pi pi-megaphone'
							break;
						case 'permis en instruction':
							lib = 'En instruction'
							icon = 'pi pi-clock'
							break;
						case 'permis en sursis à statuer':
							lib = 'En sursis à statuer'
							icon = 'pi pi-exclamation-triangle'
							break;
						case 'permis annulé':
							lib = 'Annulé'
							icon = 'pi pi-ban'
							break;
						default:
							lib = this.expanded_program.evt[index].libelle;
							icon = 'pi pi-check'
					}
					t.push({status: lib, date: this.expanded_program.evt[index].dates, icon: icon, color: '#22c55e'})
				}
				t.push({status: '', date: '', icon: '', color: ''})
				t.sort((a, b) => {
					if (a.date < b.date) {
						return -1;
					}
					if (a.date > b.date) {
						return 1;
					}
					return 0;
				});
				t.push({status: '', date: '', icon: '', color: ''})
			}
			return t
		},

		data1: function() {
			let d = []
			for (let i = 1; i <= this.compteur; i++) {
				let z = {"id": this.getTypoLib(i, this.activeTab_typo), "si": " - ", "vte": " - ", "off": " - ", "smin": " - ", "smoy": " - ", "smax": " - ", "si_xls": "", "vte_xls": "", "off_xls": "", "smin_xls": "", "smoy_xls": "", "smax_xls": "", "pmin": " - ", "pmoy": " - ", "pmax": " - ", "pmmoy": " - ", "pmin_xls": "", "pmoy_xls": "", "pmax_xls": "", "pmmoy_xls": ""}
				let w = this._getTypo_All(i, this.activeTab_typo);
				if (w.stock) {
					z.si = w.stock
					z.vte =w.vente
					z.off = w.offre
					z.si_xls = w.stock
					z.vte_xls =w.vente
					z.off_xls = w.offre
					if (this._getTypo(i, this.activeTab_typo)) {
						z.smin = Math.round(this._getTypo(i, this.activeTab_typo).s.n)
						z.smoy = Math.round(this._getTypo(i, this.activeTab_typo).s.y)
						z.smax = Math.round(this._getTypo(i, this.activeTab_typo).s.x)
						z.smin_xls = Math.round(this._getTypo(i, this.activeTab_typo).s.n)
						z.smoy_xls = Math.round(this._getTypo(i, this.activeTab_typo).s.y)
						z.smax_xls = Math.round(this._getTypo(i, this.activeTab_typo).s.x)
					}
					if (this._getTypo(i, this.activeTab_typo, this.activeTab_pk)) {
						z.pmin = this.DonneValeurPrix(this._getTypo(i, this.activeTab_typo, this.activeTab_pk).p.n, 1000, "")
						z.pmoy = this.DonneValeurPrix(this._getTypo(i, this.activeTab_typo, this.activeTab_pk).p.y, 1000, "")
						z.pmax = this.DonneValeurPrix(this._getTypo(i, this.activeTab_typo, this.activeTab_pk).p.x, 1000, "") 
						if (this._getTypo(i, this.activeTab_typo, this.activeTab_pk).p.s > 0) z.pmmoy = this.DonneValeurPrix(this._getTypo(i, this.activeTab_typo, this.activeTab_pk).p.s / this._getTypo(i, this.activeTab_typo, this.activeTab_pk).s.s,10,"")

						z.pmin_xls = this.DonneValeurPrix_xls(this._getTypo(i, this.activeTab_typo, this.activeTab_pk).p.n, 1000)
						z.pmoy_xls = this.DonneValeurPrix_xls(this._getTypo(i, this.activeTab_typo, this.activeTab_pk).p.y, 1000)
						z.pmax_xls = this.DonneValeurPrix_xls(this._getTypo(i, this.activeTab_typo, this.activeTab_pk).p.x, 1000) 
						if (this._getTypo(i, this.activeTab_typo, this.activeTab_pk).p.s > 0) z.pmmoy_xls = this.DonneValeurPrix_xls(this._getTypo(i, this.activeTab_typo, this.activeTab_pk).p.s / this._getTypo(i, this.activeTab_typo, this.activeTab_pk).s.s,10)
					}
				}
				d.push(z)
			}
			return d
		},
	}),

	watch: {
		'avec_pk': function(newValue) {
			if (newValue) {
				this.activeTab_pk = 'prix_ap'
			} else {
				this.activeTab_pk = 'prix_sp'
			}
		},
		'app.histo_program': function(newValue) {
			if (newValue) {
				this.maj_graph()
			}
		},

		'app.expanded_program': function(newValue) {
			if (newValue) {
				this.activeTab_typo = 'typologie'
				this.suffixe = "T";
				this.compteur=5; 

				this.activeTab_type = 's'

				this.filter_svo = "s"
				if (this.app.efocus_type=='B') {
					this.activeTab_tva = 'tva_reduite';
					this.filter3 = 'tva_reduite';
					this.tva = 'tva_reduite';
				} else {
					if (this.expanded_program.tva_reduite_prix_m2_parking_avec + this.expanded_program.tva_reduite_prix_m2_parking_sans > 0) {
						this.activeTab_tva = 'tva_reduite';
						this.filter3 = 'tva_reduite';
						this.tva = 'tva_reduite';
					} else {
						if (this.expanded_program.tva_pleine_libre_prix_m2_parking_avec + this.expanded_program.tva_pleine_libre_prix_m2_parking_sans > 0) {
							this.activeTab_tva = 'tva_pleine_libre';
							this.filter3 = 'tva_pleine_libre';
							this.tva = 'tva_pleine_libre';
						} else {
							this.activeTab_tva = 'tva_pleine_aide';
							this.filter3 = 'tva_pleine_aide';
							this.tva = 'tva_pleine_aide';
						}
					}
				}
				if (this.expanded_program.tva_pleine_libre_prix_m2_parking_avec + this.expanded_program.tva_pleine_aide_prix_m2_parking_avec + this.expanded_program.tva_reduite_prix_m2_parking_avec>0) {
					this.pkg = 'prix_ap'; 
					this.activeTab_pk = 'prix_ap';
					this.avec_pk = true;
				} else {
					this.pkg = 'prix_sp';
					this.activeTab_pk = 'prix_sp';
					this.avec_pk = false;
				}
				if (this.aff_histo==4 && !this.expanded_program.offre) {
					this.aff_histo = 1
				}
				this.maj_graph();
				this.maj_graph_histo();
			}
		},
		'app.active_segmentation': function(newValue) {
			if (newValue) {
				this.maj_graph_histo();
			}
		}
	},

	methods: {
		handleTabChange_typo: function(payload) {
			this.activeTab_typo = payload.tab.name;
			if (this.activeTab_typo=="echelle") {
				this.suffixe = "S";
				this.compteur=12; 
			}
			else {
				this.suffixe = "T";
				this.compteur=5; 
			}
		},
		handleTabChange_type: function(payload) {
			this.activeTab_type = payload.tab.name;
		},
		handleTabChange_tva: function(payload) {
			this.activeTab_tva = payload.tab.name;
		},
		handleTabChange_pk: function(payload) {
			this.activeTab_pk = payload.tab.name;
		},
		handleTabChange_te: function(payload) {
			this.activeTab_te = payload.tab.name;
			this.maj_graph_histo();
		},
		handleTabChange: function(payload) {
			this.activeTab_histo_graph = payload.tab.name;
		},
		convertirChaineEnDate: function(chaine) {
			const annee = parseInt(chaine.substring(0, 4), 10);
			const mois = parseInt(chaine.substring(5, 7), 10) - 1; // On soustrait 1 pour le décalage de l'indexation
			const jour = parseInt(chaine.substring(8, 10), 10);
			return new Date(annee, mois, jour);
		},
		getCurrentQuarter: function(d) {
			const year = d.getFullYear();
			const month = d.getMonth(); // janvier = 0, décembre = 11
			const quarter = Math.floor(month / 3) + 1;
			return `${year}_t${quarter}`;
		},
		ajouterMois: function(dateInitiale, nbMois) {
			const nouvelleDate = new Date(dateInitiale); // Crée une copie de la date
			nouvelleDate.setMonth(nouvelleDate.getMonth() + nbMois);
			return nouvelleDate;
		},
		parseTrimestre: function(chaine) {
			// Ex: "2025_t3" → { année: 2025, trimestre: 3 }
			const [anneeStr, trimestreStr] = chaine.split('_t');
			return { annee: parseInt(anneeStr, 10), trimestre: parseInt(trimestreStr, 10) };
		},

		trimestreToString: function(annee, trimestre) {
			return `${annee}_t${trimestre}`;
		},

		completerTrimestres: function(trimestresExistants, trimestreCible) {
			// On part du premier trimestre existant
			if (trimestresExistants.length === 0) {
				return [];
			}
			const resultat = [];
			let courant = this.parseTrimestre(trimestresExistants[0]);
			const cible = this.parseTrimestre(trimestreCible);
			
			// Tant que le trimestre courant est inférieur ou égal au trimestre cible
			while (
				courant.annee < cible.annee ||
				(courant.annee === cible.annee && courant.trimestre <= cible.trimestre)
			) {
				resultat.push(this.trimestreToString(courant.annee, courant.trimestre));
				// Incrémente le trimestre
				if (courant.trimestre === 4) {
				courant.annee++;
				courant.trimestre = 1;
				} else {
				courant.trimestre++;
				}
			}
			return resultat;
		},

		maj_graph_histo: function() {

			if (this.app.active_segmentation && this.app.active_segmentation[2]) {
				let { categories, series } = this.prepareChartData(this.app.active_segmentation[2])
				let trimliv = this.DonneTrimestre2(this.expanded_program.date_liv.replaceAll("-", ""))
				trimliv = String(trimliv).trim();
				let lastDate = this.convertirChaineEnDate(this.expanded_program.datemaj)
				let trimencours = String(this.getCurrentQuarter(lastDate)).trim();
				let indexliv = categories.indexOf(trimliv)
				let indextrimencours = categories.indexOf(trimencours)
				// Exemple : calculer une série area (ici, par exemple, le stock restant par catégorie)
				let si = this.expanded_program.stock_initial;
				let si_Typo = [0,0,0,0,0]
				let nMax = 0
				if (this.app.active_segmentation[0]._source.d_typo) {
					for (let i = 0; i < 5; i++) {
						const element = this.app.active_segmentation[0]._source.d_typo.ini.vt["t" + (i+1)];
						if (element) {
							si_Typo[i] = element.dt.lt.pn.n
							if (nMax < si_Typo[i]) nMax = si_Typo[i]
						}
					}
				}

				let offre_typo = [[], [], [], [], []];

				let series_si = [];
				for (let index = 0; index < series[0].data.length; index++) {
					if (index > indextrimencours) {
						for (let index1 = 0; index1 < series.length; index1++) {
							series[index1].data[index] = null;
						}
					}
				}
				for (let index = 0; index < series[0].data.length; index++) {
					if (index <= indextrimencours || indextrimencours==-1) {
						if (si == 0) {
							series_si.push(null)
						} else {
							for (let index1 = 0; index1 < series.length; index1++) {
								si = si - series[index1].data[index];
							}
							series_si.push(si)
						}
					} else {
						series_si.push(null)
					}
				}

				let m = null
				let series_si_tx = [];
				let categories_prev = JSON.parse(JSON.stringify(categories));
				if (indextrimencours) {
					switch (this.activeTab_te) {
						case 'global':
							m = parseFloat(this.expanded_program.rythme.total.duree)
							break;
						case '3':
							m = parseFloat(this.expanded_program.rythme.lancement.duree)
							break;
						case '90':
							m = parseFloat(this.expanded_program.rythme.sans_fin_prog.duree)
							break;
						case 'croisiere':
							m = parseFloat(this.expanded_program.rythme.croisiere.duree)
							break;
					}
					let FinCom = lastDate
					if (m) {
						FinCom = this.ajouterMois(lastDate, m)
					}
					let trimenfin = String(this.getCurrentQuarter(FinCom)).trim();
					let indexfin = categories_prev.indexOf(trimenfin)
					if (indexfin<0) {
						categories_prev = this.completerTrimestres(categories_prev, trimenfin);
						indexfin = categories_prev.indexOf(trimenfin)
					}
					let gap = si / (indexfin-indextrimencours)
					let s = si
					for (let index = 0; index < categories_prev.length; index++) {
						if (index == indextrimencours) {
							series_si_tx.push(si)
						}
						else {
							if (index > indextrimencours) {
								if (index == indexfin) {
									series_si_tx.push(0)
								} else {
									if (index > indexfin) {
										series_si_tx.push(null)
									} else {
										s = s - gap
										series_si_tx.push(Math.round(s))
									}
								}
							} else {
								series_si_tx.push(null)
							}
						}
					}
				}

				for (let index1 = 0; index1 < series.length; index1++) {
					let i = 0
					if (series[index1].name =="T1") i = 0
					if (series[index1].name =="T2") i = 1
					if (series[index1].name =="T3") i = 2
					if (series[index1].name =="T4") i = 3
					if (series[index1].name =="T5") i = 4
					for (let index = 0; index < series[index1].data.length; index++) {
						if (index <= indextrimencours || indextrimencours==-1) {
							if (series[index1].data[index]) {
								si_Typo[i] = si_Typo[i] - series[index1].data[index]
							}
							offre_typo[i].push(si_Typo[i])
						} else {
							offre_typo[i].push(null)
						}
					}
				}

				let Tcouleurs = ["#008FFB", "#00E396", "#FEB019", "#FF4560", "#7c74c4"];
				// Créer un objet pour la série area
				let areaSeries = {
					name: 'Stock restant',
					type: 'areaspline',
					marker: {
						symbol: 'circle'
					},
					data: series_si,
					// Vous pouvez ajouter ici des options spécifiques à cette série
					fillOpacity: 0.2,
					lineWidth: 2,
					linecap: 'round',
					color: '#544fbf',
				};
				series.push(areaSeries);

				let series_offre_prev = JSON.parse(JSON.stringify(series));
				if (this.expanded_program.offre > 0) {
					// Créer un objet pour la série area
					let areaSeries_prev = {
						name: 'Stock restant prévisionnel',
						type: 'areaspline',        // Utilise un graphique en ligne
						dashStyle: 'Dash',
						marker: {
							symbol: 'circle'
						},
						connectNulls: true,
						data: series_si_tx,
						// Vous pouvez ajouter ici des options spécifiques à cette série
						fillOpacity: 0.2,
						lineWidth: 2,
						linecap: 'round',
						color: '#fbc54e',
					};
					series_offre_prev.push(areaSeries_prev);
				}


				let series_offre = [];
				for (let i = 0; i < 5; i++) {
					series_offre.push(
						{
							name: 'T' + (i+1),
							type: 'column',
							data: offre_typo[i],
							// Vous pouvez ajouter ici des options spécifiques à cette série
							fillOpacity: 0.8,
							color: Tcouleurs[i],
						}
					)
				}
				series_offre.push(areaSeries);

				// Définition des options Highcharts
				this.chartOptions_histo_vente = {
					accessibility: {
						enabled: false
					},
					chart: {
						type: "column",
						height: "50%"
					},
					title: {
						text: null 
					},
					legend: {
						align: 'center',
						verticalAlign: 'top',
					},
					xAxis: {
						categories: categories.map(label => label.replace("_t", "-T")),
						labels: {
							/**/
							useHTML: true,
							formatter: function() {
							const parts = this.value.split('-');
							return `<div style="text-align: center;font-family: "Roboto", arial, sans-serif, sans-serif;font-size: 22px;">${parts[1]}<br/>${parts[0]}</div>`;
							}
							/**/
							/*
							formatter: function() {
								const parts = this.value.split('-');
								return parts[1]; // Affiche uniquement le trimestre (T2, T3, etc.)
							},
							// Configuration pour grouper les libellés par année
							groupedOptions: [{
								// Le niveau 1 regroupe les années
								className: 'year-label',
								style: {
									fontWeight: 'bold',
									fontSize: '12px'
								},
								formatter: function() {
									const parts = this.value.split('-');
									return parts[0]; // Affiche uniquement l'année
								}
							}],
							// Active le regroupement
							grouping: true,
							*/
						},
						title: {
							text: null
						},
						gridLineWidth: 0,
						plotBands: [{ // 🔹 Ajout d'une barre verticale fine
							color: 'rgba(255, 0, 0, 0.3)', // Rouge semi-transparent
							width: 3,
							value: indexliv,
							zIndex: 5,
							label: {
								text: "Livraison",
								align: "center",
								rotation: -90,
								x: 3,
								y: 100,
								style: {
									color: "red",
									fontWeight: "bold",
									fontFamily: 'Nunito',
									textOutline: '2px white'
								}
							}
						}]

					},
					yAxis: {
						min: 0,
						max : this.expanded_program.stock_initial,
						endOnTick: false,
						title: {
							text: null 
						},
						stackLabels: {
							enabled: false
						},
						labels: {
							enabled: true,
						},
						gridLineWidth: 1
					},
					tooltip: {
						enabled: true,
						headerFormat: "<b>{point.x}</b><br/>",
						pointFormat: "{series.name}: {point.y}<br/>Total: {point.stackTotal}"
					},
					plotOptions: {
						series: {
							dataLabels: {
								enabled: false // Désactive les dataLabels pour toutes les séries
							}
						},
						column: {
							dataLabels: {
								enabled: false // Désactive les dataLabels pour toutes les séries
							},
							stacking: "normal",
							pointPadding: 0.1,   // Espace entre colonnes d'une même catégorie
							groupPadding: 0.1,   // Espace entre groupes de colonnes (catégories)
							maxPointWidth: 30,   // Largeur max en pixels (ajustez selon vos goûts)
							borderWidth: 0,
						}
					},
					series: series,
					credits: {
						enabled: false
					}

				};


/**/
				// Définition des options Highcharts
				this.chartOptions_histo_offre = {
					accessibility: {
						enabled: false
					},
					chart: {
						type: "column",
						height: "50%"
					},
					title: {
						text: null 
					},
					legend: {
						align: 'center',
						verticalAlign: 'top',
					},
					xAxis: {
						categories: categories.map(label => label.replace("_t", "-T")),
						labels: {
							useHTML: true,
							formatter: function() {
							// Divise la chaîne à partir du tiret
							const parts = this.value.split('-');
							// Retourne l'année et le trimestre sur deux lignes
							return `<div style="text-align: center;font-family: "Roboto", arial, sans-serif, sans-serif;font-size: 22px;">${parts[1]}<br/>${parts[0]}</div>`;
							}
						},
						title: {
							text: null
						},
						gridLineWidth: 0,
						plotBands: [{ // 🔹 Ajout d'une barre verticale fine
							color: 'rgba(255, 0, 0, 0.3)', // Rouge semi-transparent
							width: 3,
							value: indexliv,
							zIndex: 5,
							label: {
								text: "Livraison",
								align: "center",
								rotation: -90,
								x: 3,
								y: 100,
								style: {
									color: "red",
									fontWeight: "bold",
									fontFamily: 'Nunito',
									textOutline: '2px white'
								}
							}
						}]

					},
					yAxis: {
						min: 0,
						max : this.expanded_program.stock_initial,
						endOnTick: false,
						title: {
							text: null 
						},
						labels: {
							enabled: true,
						},
						gridLineWidth: 1
					},
					tooltip: {
						enabled: true,
						headerFormat: "<b>{point.x}</b><br/>",
						pointFormat: "{series.name}: {point.y}"
					},
					plotOptions: {
						series: {
							dataLabels: {
								enabled: false // Désactive les dataLabels pour toutes les séries
							}
						},
						column: {
							grouping: true,  // active le groupement des colonnes (c'est déjà la valeur par défaut)
							stacking: null,  // ou simplement omettre "stacking"
							dataLabels: {
								enabled: false // Désactive les dataLabels pour toutes les séries
							},
							pointPadding: 0.1,   // Espace entre colonnes d'une même catégorie
							groupPadding: 0.1,   // Espace entre groupes de colonnes (catégories)
							maxPointWidth: 30,   // Largeur max en pixels (ajustez selon vos goûts)
							borderWidth: 0,
						}
					},
					series: series_offre,
					credits: {
						enabled: false
					}

				};


				// Définition des options Highcharts
				this.chartOptions_histo_offre_prev = {
					accessibility: {
						enabled: false
					},
					chart: {
						type: "column",
						height: "50%"
					},
					title: {
						text: null 
					},
					legend: {
						align: 'center',
						verticalAlign: 'top',
					},
					xAxis: {
						categories: categories_prev.map(label => label.replace("_t", "-T")),
						labels: {
							useHTML: true,
							formatter: function() {
							// Divise la chaîne à partir du tiret
							if (this.value) {
								let parts = ['', '']
								try {
									parts = this.value.split('-');
								} catch {
									//console.log(this.value)
								}
								// Retourne l'année et le trimestre sur deux lignes
								return `<div style="text-align: center;font-family: "Roboto", arial, sans-serif, sans-serif;font-size: 22px;">${parts[1]}<br/>${parts[0]}</div>`;
							} else {
								return ``;
							}
							}
						},
						title: {
							text: null
						},
						gridLineWidth: 0,
						plotBands: [{ // 🔹 Ajout d'une barre verticale fine
							color: 'rgba(255, 0, 0, 0.3)', // Rouge semi-transparent
							width: 3,
							value: indexliv,
							zIndex: 5,
							label: {
								text: "Livraison",
								align: "center",
								rotation: -90,
								x: 3,
								y: 100,
								style: {
									color: "red",
									fontWeight: "bold",
									fontFamily: 'Nunito',
									textOutline: '2px white'
								}
							}
						}]

					},
					yAxis: {
						min: 0,
						max : this.expanded_program.stock_initial,
						endOnTick: false,
						title: {
							text: null 
						},
						labels: {
							enabled: true,
						},
						gridLineWidth: 1
					},
					tooltip: {
						enabled: true,
						headerFormat: "<b>{point.x}</b><br/>",
						pointFormat: "{series.name}: {point.y}"
					},
					plotOptions: {
						series: {
							dataLabels: {
								enabled: false // Désactive les dataLabels pour toutes les séries
							}
						},
						column: {
							grouping: true,  // active le groupement des colonnes (c'est déjà la valeur par défaut)
							stacking: null,  // ou simplement omettre "stacking"
							dataLabels: {
								enabled: false // Désactive les dataLabels pour toutes les séries
							},
							pointPadding: 0.1,   // Espace entre colonnes d'une même catégorie
							groupPadding: 0.1,   // Espace entre groupes de colonnes (catégories)
							maxPointWidth: 30,   // Largeur max en pixels (ajustez selon vos goûts)
							borderWidth: 0,
						}
					},
					series: series_offre_prev,
					credits: {
						enabled: false
					}

				};				
/**/
			}
		},
		generateTrimesters: function(startYear, endYear, firstTrimester, maxTrimester) {
			let trimesters = [];
			for (let year = startYear; year <= endYear; year++) {
				for (let t = 1; t <= 4; t++) {
					let trimestre = `${year}_t${t}`;
					if (trimestre >= firstTrimester && trimestre <= maxTrimester) {
						trimesters.push(trimestre);
					}
				}
			}

			return trimesters;
		},

		sortTypologies: function(typologies) {
			return typologies.sort((a, b) => {
				let numA = a;
				let numB = b;
				return numA - numB;
			});
		},

		sortTrimesters: function (trimesters) {
			return trimesters.sort((a, b) => {
				let [yearA, quarterA] = a.split("_t").map(Number);
				let [yearB, quarterB] = b.split("_t").map(Number);
				return yearA === yearB ? quarterA - quarterB : yearA - yearB;
			});
		},

		prepareChartData: function(data) {
			// Extraire tous les trimestres et typologies existants
			let allTypologies = [...new Set(data.map(item => item.typ_id))];
			let sortedTypologies = this.sortTypologies(allTypologies);
			let years = [...new Set(data.map(item => parseInt(item.trimestre.split("_")[0])))];

			let firstTrimester = data.map(item => item.trimestre).sort()[0];
			let lastTrimester = data.map(item => item.trimestre).sort().reverse()[0];
			let additionalTrimester = this.DonneTrimestre2(this.expanded_program.date_com);
			if (additionalTrimester < firstTrimester) {
				firstTrimester = additionalTrimester
				years.push(parseInt(this.expanded_program.date_com.substring(0, 4)))
			}
			additionalTrimester = this.DonneTrimestre2(this.expanded_program.datemaj.replaceAll("-", ""));
			if (additionalTrimester > lastTrimester) {
				lastTrimester = additionalTrimester
				years.push(parseInt(this.expanded_program.datemaj.substring(0, 4)))
			}
			additionalTrimester = this.DonneTrimestre2(this.expanded_program.date_liv.replaceAll("-", ""));
			if (additionalTrimester > lastTrimester) {
				lastTrimester = additionalTrimester
				years.push(parseInt(this.expanded_program.date_liv.substring(0, 4)))
			}
			let allTrimesters = this.generateTrimesters(Math.min(...years), Math.max(...years), firstTrimester, lastTrimester);
			allTrimesters = this.sortTrimesters(allTrimesters);

			// Initialiser un objet pour stocker les ventes par trimestre et typologie
			let dataMap = {};
			allTrimesters.forEach(trimestre => {
				dataMap[trimestre] = {};
				sortedTypologies.forEach(typ => {
					dataMap[trimestre][typ] = 0; // Par défaut 0 si pas de ventes
				});
			});

			// Remplir avec les données réelles
			data.forEach(({ trimestre, typ_id, sum }) => {
				dataMap[trimestre][typ_id] = sum;
			});

			const colorMap = {
				"T1": "#008FFB",  // Rouge
				"T2": "#00E396",  // Vert
				"T3": "#FEB019",  // Bleu
				"T4": "#FF4560",  // Jaune
				"T5": "#7c74c4"   // Violet
			};
			// Transformer en format Highcharts
			let series = sortedTypologies.map(typ => ({
				name: `T${typ}`,
				data: allTrimesters.map(trimestre => dataMap[trimestre][typ] === 0 ? null : dataMap[trimestre][typ]),
				color: colorMap[`T${typ}`] || "#CCCCCC"
			}));

			return { categories: allTrimesters, series };
		},

		monthsDifference(a) {
			const targetDate = new Date(a);
			const today = new Date();

			const yearsDifference = today.getFullYear() - targetDate.getFullYear();
			const monthsDifference = today.getMonth() - targetDate.getMonth();

			return yearsDifference * 12 + monthsDifference;
		}, 
		adresseComplete() {
			if (this.expanded_program && this.expanded_program.location) return { lat: parseFloat(this.expanded_program.location.lat), lng: parseFloat(this.expanded_program.location.lon) }
			else return false			
		},
		handleImageError(event) {
			event.target.src = this.defaultImageUrl; // Remplacer par une image par défaut en cas d'erreur
		},

		vebacte: function() {
			let n = 0
			if (this.expanded_program.liste_veb && this.expanded_program.liste_veb.length) {
				for (let index = 0; index < this.expanded_program.liste_veb.length; index++) {
					if (this.expanded_program.liste_veb[index].tra_veb_date_acte) n += this.expanded_program.liste_veb[index].tra_stock_initial
				}
			}
			return n
		},

		vebresa: function() {
			let n = 0
			if (this.expanded_program.liste_veb && this.expanded_program.liste_veb.length) {
				for (let index = 0; index < this.expanded_program.liste_veb.length; index++) {
					if (this.expanded_program.liste_veb[index].tra_veb_date_reservation && !this.expanded_program.liste_veb[index].tra_veb_date_acte) n += this.expanded_program.liste_veb[index].tra_stock_initial
				}
			}
			return n
		},

		ExportTable() {
			let sTva = ''
			if (this.activeTab_tva == 'tva_pleine_libre') sTva = 'TVA pleine libre'
			if (this.activeTab_tva == 'tva_pleine_aide') sTva = 'TVA pleine aidé'
			if (this.activeTab_tva == 'tva_reduite') sTva = 'TVA réduite'
			let sPrk = ''
			if (this.pkg == 'prix_sp') sPrk = 'Hors parking'
			if (this.pkg == 'prix_ap') sPrk = 'Parking inclus'
			let sSvo = ''
			if (this.activeTab_type == 's') sSvo = 'de tous les logements'
			if (this.activeTab_type == 'v') sSvo = 'des ventes'
			if (this.activeTab_type == 'o') sSvo = 'de l\'offre'

			let s = ''
			s = s + '<?xml version="1.0"?>' + '\r\n'
			s = s + '<?mso-application progid="Excel.Sheet"?>' + '\r\n'
			s = s + '<Workbook xmlns="urn:schemas-microsoft-com:office:spreadsheet" xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns:ss="urn:schemas-microsoft-com:office:spreadsheet" xmlns:html="http://www.w3.org/TR/REC-html40">' + '\r\n'
			s = s + '<OfficeDocumentSettings xmlns="urn:schemas-microsoft-com:office:office"><AllowPNG/></OfficeDocumentSettings>' + '\r\n'
			s = s + '<ExcelWorkbook xmlns="urn:schemas-microsoft-com:office:excel"><ProtectStructure>False</ProtectStructure><ProtectWindows>False</ProtectWindows></ExcelWorkbook>' + '\r\n'
			s = s + '<Styles>' + '\r\n'
			s = s + '<Style ss:ID="Default" ss:Name="Normal"><Alignment ss:Vertical="Bottom"/><Borders/><Font ss:FontName="Calibri" x:Family="Swiss" ss:Size="12" ss:Color="#000000"/><Interior/><NumberFormat/><Protection/></Style>' + '\r\n'
			s = s + '<Style ss:ID="s18" ss:Name="Monétaire"><NumberFormat ss:Format="_-* #,##0.00\\ &quot;€&quot;_-;\\-* #,##0.00\\ &quot;€&quot;_-;_-* &quot;-&quot;??\\ &quot;€&quot;_-;_-@_-"/></Style>' + '\r\n'
			s = s + '<Style ss:ID="s62"><NumberFormat ss:Format="#,##0"/></Style>' + '\r\n'
			s = s + '<Style ss:ID="s67" ss:Parent="s18"> <Font ss:FontName="Calibri" x:Family="Swiss" ss:Size="12" ss:Color="#000000"/> <NumberFormat ss:Format="_-* #,##0\\ &quot;€&quot;_-;\\-* #,##0\\ &quot;€&quot;_-;_-* &quot;-&quot;??\\ &quot;€&quot;_-;_-@_-"/></Style>' + '\r\n'
			s = s + '</Styles>' + '\r\n'
			s = s + '<Worksheet ss:Name="tableau_typo"><Table>' + '\r\n'
			s = s + '<Row>' + '\r\n'
			s = s + '<Cell><Data ss:Type="String">Chiffre clé ' + sSvo + '</Data></Cell>' + '\r\n'
			s = s + '</Row>' + '\r\n'
			s = s + '<Row>' + '\r\n'
			s = s + '<Cell><Data ss:Type="String">' + sTva + '</Data></Cell>' + '\r\n'
			s = s + '</Row>' + '\r\n'
			s = s + '<Row>' + '\r\n'
			s = s + '<Cell><Data ss:Type="String">' + sPrk + '</Data></Cell>' + '\r\n'
			s = s + '</Row>' + '\r\n'
			s = s + '<Row>' + '\r\n'
			s = s + '<Cell ss:Index="2"><Data ss:Type="String">Stock initial</Data></Cell>' + '\r\n'
			s = s + '<Cell><Data ss:Type="String">Ventes</Data></Cell>' + '\r\n'
			s = s + '<Cell><Data ss:Type="String">Offre</Data></Cell>' + '\r\n'
			s = s + '<Cell><Data ss:Type="String">Surface min en m²</Data></Cell>' + '\r\n'
			s = s + '<Cell><Data ss:Type="String">Surface moy en m²</Data></Cell>' + '\r\n'
			s = s + '<Cell><Data ss:Type="String">Surface max en m²</Data></Cell>' + '\r\n'
			s = s + '<Cell><Data ss:Type="String">Prix unitaire min en €</Data></Cell>' + '\r\n'
			s = s + '<Cell><Data ss:Type="String">Prix unitaire moy en €</Data></Cell>' + '\r\n'
			s = s + '<Cell><Data ss:Type="String">Prix unitaire max en €</Data></Cell>' + '\r\n'
			s = s + '<Cell><Data ss:Type="String">Prix en €/m²</Data></Cell>' + '\r\n'
			s = s + '</Row>' + '\r\n'

			for (let index = 0; index < this.data1.length; index++) {
				const element = this.data1[index];
				s = s + '<Row>' + '\r\n'
				s = s + '<Cell><Data ss:Type="String">' + element.id +  '</Data></Cell>' + '\r\n'
				s = s + '<Cell ss:StyleID="s62"><Data ss:Type="Number">' + element.si_xls +  '</Data></Cell>' + '\r\n'
				s = s + '<Cell ss:StyleID="s62"><Data ss:Type="Number">' + element.vte_xls +  '</Data></Cell>' + '\r\n'
				s = s + '<Cell ss:StyleID="s62"><Data ss:Type="Number">' + element.off_xls +  '</Data></Cell>' + '\r\n'
				s = s + '<Cell ss:StyleID="s62"><Data ss:Type="Number">' + element.smin_xls +  '</Data></Cell>' + '\r\n'
				s = s + '<Cell ss:StyleID="s62"><Data ss:Type="Number">' + element.smoy_xls +  '</Data></Cell>' + '\r\n'
				s = s + '<Cell ss:StyleID="s62"><Data ss:Type="Number">' + element.smax_xls +  '</Data></Cell>' + '\r\n'
				s = s + '<Cell ss:StyleID="s67"><Data ss:Type="Number">' + element.pmin_xls +  '</Data></Cell>' + '\r\n'
				s = s + '<Cell ss:StyleID="s67"><Data ss:Type="Number">' + element.pmoy_xls +  '</Data></Cell>' + '\r\n'
				s = s + '<Cell ss:StyleID="s67"><Data ss:Type="Number">' + element.pmax_xls +  '</Data></Cell>' + '\r\n'
				s = s + '<Cell ss:StyleID="s67"><Data ss:Type="Number">' + element.pmmoy_xls +  '</Data></Cell>' + '\r\n'
				s = s + '</Row>' + '\r\n'
			}
			s = s + '</Table>' + '\r\n'
			s = s + '<WorksheetOptions xmlns="urn:schemas-microsoft-com:office:excel"><ProtectObjects>False</ProtectObjects><ProtectScenarios>False</ProtectScenarios></WorksheetOptions>' + '\r\n'
			s = s + '</Worksheet>' + '\r\n'
			s = s + '</Workbook>'

			// Créer un Blob à partir du texte
			const blob = new Blob([s], { type: 'text/plain' });

			// Créer un lien pour le téléchargement
			const link = document.createElement('a');
			link.href = URL.createObjectURL(blob);
			const accentsMap = {
			'à': 'a', 'á': 'a', 'â': 'a', 'ã': 'a', 'ä': 'a', 'å': 'a', 'æ': 'ae', 'ç': 'c',
			'è': 'e', 'é': 'e', 'ê': 'e', 'ë': 'e', 'ì': 'i', 'í': 'i', 'î': 'i', 'ï': 'i',
			'ð': 'd', 'ñ': 'n', 'ò': 'o', 'ó': 'o', 'ô': 'o', 'õ': 'o', 'ö': 'o', 'ø': 'o',
			'ù': 'u', 'ú': 'u', 'û': 'u', 'ü': 'u', 'ý': 'y', 'þ': 'th', 'ÿ': 'y',
			'À': 'A', 'Á': 'A', 'Â': 'A', 'Ã': 'A', 'Ä': 'A', 'Å': 'A', 'Æ': 'AE', 'Ç': 'C',
			'È': 'E', 'É': 'E', 'Ê': 'E', 'Ë': 'E', 'Ì': 'I', 'Í': 'I', 'Î': 'I', 'Ï': 'I',
			'Ð': 'D', 'Ñ': 'N', 'Ò': 'O', 'Ó': 'O', 'Ô': 'O', 'Õ': 'O', 'Ö': 'O', 'Ø': 'O',
			'Ù': 'U', 'Ú': 'U', 'Û': 'U', 'Ü': 'U', 'Ý': 'Y', 'Þ': 'Th', 'ß': 'ss',
			'Ÿ': 'Y'
			};

			let normalizedStr = this.expanded_program.nom.split('').map(char => accentsMap[char] || char).join('');

			// Supprimer tous les caractères spéciaux
			normalizedStr = normalizedStr.replace(/[^a-zA-Z0-9 ]/g, "");

			link.download = normalizedStr + '.xls'; // Nom du fichier à télécharger
			link.click();

			// Nettoyage de l'URL après le téléchargement
			URL.revokeObjectURL(link.href);

		},

		AfficheDate(dateString){
			if (dateString) {
				const parts = dateString.split('-');
				return `${parts[2]}/${parts[1]}/${parts[0]}`;
			} else return '';
		},

		rowClass(data) {
            return (data.si>0) ? 'row-accessories': 'row-accessories2';
        },

		// eslint-disable-next-line
		generateProgImage(id) {
			this.exportencours = true
			const elementToExport = document.getElementById('ProgEx');

			let that = this

			domtoimage.toPng(elementToExport, {
				height: elementToExport.scrollHeight,
				width: elementToExport.scrollWidth,
				style: {
					'transform': 'scale(1)',
					'transformOrigin': 'top left',
				},
				filter: (node) => {
					return (!(node.id === 'streetviewprog') && !(node.id === 'pan-titre-programme') && !(node.id === 'expt'));
				}				
			})
			.then((dataUrl) => {
				const link = document.createElement('a');
				link.href = dataUrl;
				link.download = 'ProgEx.png';
				link.click();
				that.exportencours = false
			})
			.catch((error) => {
				console.error('Erreur lors de la génération de l\'image', error);
			});
			
		},


/*
		maj_graph: function() {
			if (this.app.histo_program && this.app.histo_program.hits.hits.length) {
				
				let min = 0
				let max = 0
				let fn;
				fn = function setMinMax(Val) {
					if (Val > 0) {
						if (min==0 || min>Val) {
							min = Val
						}
						if (max==0 || max<Val) {
							max = Val
						}
					}
				}
				let t_Prog = []
				let t_Offre = []
				let t_Vente = []
				if (this.tva == 'tva_pleine_libre' && this.pkg == 'prix_ap') {
					t_Prog = this.app.histo_program.hits.hits[0]._source.prix_prog.tva_pleine_libre_prix_m2_parking_avec
					t_Offre = this.app.histo_program.hits.hits[0]._source.offre.tva_pleine_libre_prix_m2_parking_avec
					t_Vente = this.app.histo_program.hits.hits[0]._source.ventes.tva_pleine_libre_prix_m2_parking_avec
				}
				if (this.tva == 'tva_pleine_libre' && this.pkg == 'prix_sp') {
					t_Prog = this.app.histo_program.hits.hits[0]._source.prix_prog.tva_pleine_libre_prix_m2_parking_sans
					t_Offre = this.app.histo_program.hits.hits[0]._source.offre.tva_pleine_libre_prix_m2_parking_sans
					t_Vente = this.app.histo_program.hits.hits[0]._source.ventes.tva_pleine_libre_prix_m2_parking_sans
				}
				if (this.tva == 'tva_pleine_aide' && this.pkg == 'prix_ap') {
					t_Prog = this.app.histo_program.hits.hits[0]._source.prix_prog.tva_pleine_aide_prix_m2_parking_avec
					t_Offre = this.app.histo_program.hits.hits[0]._source.offre.tva_pleine_aide_prix_m2_parking_avec
					t_Vente = this.app.histo_program.hits.hits[0]._source.ventes.tva_pleine_aide_prix_m2_parking_avec
				}
				if (this.tva == 'tva_pleine_aide' && this.pkg == 'prix_sp') {
					t_Prog = this.app.histo_program.hits.hits[0]._source.prix_prog.tva_pleine_aide_prix_m2_parking_sans
					t_Offre = this.app.histo_program.hits.hits[0]._source.offre.tva_pleine_aide_prix_m2_parking_sans
					t_Vente = this.app.histo_program.hits.hits[0]._source.ventes.tva_pleine_aide_prix_m2_parking_sans
				}
				if (this.tva == 'tva_reduite' && this.pkg == 'prix_ap') {
					t_Prog = this.app.histo_program.hits.hits[0]._source.prix_prog.tva_reduite_prix_m2_parking_avec
					t_Offre = this.app.histo_program.hits.hits[0]._source.offre.tva_reduite_prix_m2_parking_avec
					t_Vente = this.app.histo_program.hits.hits[0]._source.ventes.tva_reduite_prix_m2_parking_avec
				}
				if (this.tva == 'tva_reduite' && this.pkg == 'prix_sp') {
					t_Prog = this.app.histo_program.hits.hits[0]._source.prix_prog.tva_reduite_prix_m2_parking_sans
					t_Offre = this.app.histo_program.hits.hits[0]._source.offre.tva_reduite_prix_m2_parking_sans
					t_Vente = this.app.histo_program.hits.hits[0]._source.ventes.tva_reduite_prix_m2_parking_sans
				}
				for (let index = 0; index < this.app.histo_program.hits.hits[0]._source.libelle.length; index++) {
					fn(t_Prog[index])
					fn(t_Offre[index])
					fn(t_Vente[index])
					if (t_Prog[index]==0) t_Prog[index] = null
					if (t_Offre[index]==0) t_Offre[index] = null
					if (t_Vente[index]==0) t_Vente[index] = null
				}
				
				this.chartOptions_trim.yAxis.min = min
				this.chartOptions_trim.yAxis.max = max
				
				this.chartOptions_trim.series[0].data = t_Prog
				this.chartOptions_trim.series[1].data = t_Offre
				this.chartOptions_trim.series[2].data = t_Vente
				this.chartOptions_trim.xAxis.categories= this.app.histo_program.hits.hits[0]._source.libelle
			}
		},
*/
		maj_graph: function() {
			if (this.app.histo_program && this.app.histo_program.hits.hits.length) {
				let min = 0, max = 0;

				const setMinMax = (val) => {
					if (val > 0) {
						min = min === 0 || min > val ? val : min;
						max = max === 0 || max < val ? val : max;
					}
				};

				const dataMapping = {
					'tva_pleine_libre_prix_ap': 'tva_pleine_libre_prix_m2_parking_avec',
					'tva_pleine_libre_prix_sp': 'tva_pleine_libre_prix_m2_parking_sans',
					'tva_pleine_aide_prix_ap': 'tva_pleine_aide_prix_m2_parking_avec',
					'tva_pleine_aide_prix_sp': 'tva_pleine_aide_prix_m2_parking_sans',
					'tva_reduite_prix_ap': 'tva_reduite_prix_m2_parking_avec',
					'tva_reduite_prix_sp': 'tva_reduite_prix_m2_parking_sans'
				};

				const key = `${this.activeTab_tva}_${this.pkg}`;
				const source = this.app.histo_program.hits.hits[0]._source;
				const t_Prog = source.prix_prog[dataMapping[key]] || [];
				const t_Offre = source.offre[dataMapping[key]] || [];
				const t_Vente = source.ventes[dataMapping[key]] || [];

				source.libelle.forEach((label, index) => {
					[t_Prog[index], t_Offre[index], t_Vente[index]].forEach(setMinMax);
					if (t_Prog[index] === 0) t_Prog[index] = null;
					if (t_Offre[index] === 0) t_Offre[index] = null;
					if (t_Vente[index] === 0) t_Vente[index] = null;
				});

				this.chartOptions_trim.yAxis.min = min;
				this.chartOptions_trim.yAxis.max = max;

				this.chartOptions_trim.series[0].data = t_Prog;
				this.chartOptions_trim.series[1].data = t_Offre;
				this.chartOptions_trim.series[2].data = t_Vente;
				this.chartOptions_trim.xAxis.categories = source.libelle;
			}
		},

		DonneTrimestre: function(val) {
			let DateTrim
			let t = String(val).substr(4,2)
			let a = String(val).substr(0,4)
			switch(parseInt(t))
			{
				case 1:
				case 2:
				case 3:
				DateTrim = "1T " + a; 
				break;
				case 4:
				case 5:
				case 6:
				DateTrim = "2T " + a; 
				break;
				case 7:
				case 8:
				case 9:
				DateTrim = "3T " + a; 
				break;
				case 10:
				case 11:
				case 12:
				DateTrim = "4T " + a; 
				break;
			}
			return DateTrim
		},

		DonneTrimestre2: function(val) {
			let DateTrim
			let t = String(val).substr(4,2)
			let a = String(val).substr(0,4)
			switch(parseInt(t))
			{
				case 1:
				case 2:
				case 3:
				DateTrim = a + "_t1 "; 
				break;
				case 4:
				case 5:
				case 6:
				DateTrim = a + "_t2"; 
				break;
				case 7:
				case 8:
				case 9:
				DateTrim = a + "_t3"; 
				break;
				case 10:
				case 11:
				case 12:
				DateTrim = a + "_t4"; 
				break;
			}
			return DateTrim
		},

		DonneValeurPrix: function(val, num, fin) {
			const euro = new Intl.NumberFormat('fr-FR', {
				style: 'currency',
				currency: 'EUR',
				minimumFractionDigits: 0
			});
			if (val==0 || val=='NaN') {
				return "-"
			} else {
				val = Math.round(val/num)*num
				if (val==0 || val=='NaN') {
					return "-"
				}
				let formatted = euro.format(val)+fin
				formatted = formatted.replace(/\u00A0/g, ' ');
				return formatted
			}
		},
		DonneValeurPrix_xls: function(val, num) {
			if (val==0 || val=='NaN') {
				return ""
			} else {
				val = Math.round(val/num)*num
				return val
			}
		},

		DonneValeurPrixArr: function(val, num) {
			const euro = new Intl.NumberFormat('fr-FR', {
				style: 'currency',
				currency: 'EUR',
				minimumFractionDigits: 0
			});
			if (val==0 || val=='NaN') {
				return "-"
			} else {
				val = Math.round(val/num)*num
				return euro.format(val)
			}
		},

		getTypoLib: function(t, a) {
			if (a!='typologie') {
				switch(parseInt(t))
				{
					case 1:
					return "< 34m²"
					case 2:
					return "34m²-39m²"
					case 3:
					return "39m²-44m²"
					case 4:
					return "44m²-47m²"
					case 5:
					return "47m²-50m²"
					case 6:
					return "50m²-56m²"
					case 7:
					return "56m²-62m²"
					case 8:
					return "62m²-72m²"
					case 9:
					return "72m²-78m²"
					case 10:
					return "78m²-84m²"
					case 11:
					return "84m²-89m²"
					case 12:
					return ">= 89m²"
				}
			} else {
				return "T" + t 
			}
		},

		_getTypo_All: function(t, a) {
			let v = false
			let w = false
			let stock = 0
			let vente = 0
			let offre = 0

			if (this.app.active_segmentation) {
				if (a=='echelle') {
					w = this.app.active_segmentation[1]._source.d_surf
				} else {
					w = this.app.active_segmentation[0]._source.d_typo
				}
				if (w) {
					v = w.ini.vt
					if (a=='echelle') {
						v = v['s' + t.toString()]
					} else {
						v = v['t' + t.toString()]
					}
					if (v && v.dt && v.dt.lt && v.dt.lt.pn)	stock = v.dt.lt.pn.n

					if (w.vte) {
						v = w.vte.vt
						if (v) {
							if (a=='echelle') {
								v = v['s' + t.toString()]
							} else {
								v = v['t' + t.toString()]
							}
						}
						if (v && v.dt && v.dt.lt && v.dt.lt.pn)	vente = v.dt.lt.pn.n
					}

					if (w.ofr) {
						v = w.ofr.vt
						if (v) {
							if (a=='echelle') {
								v = v['s' + t.toString()]
							} else {
								v = v['t' + t.toString()]
							}						
							if (v && v.dt && v.dt.lt && v.dt.lt.pn)	offre = v.dt.lt.pn.n
						}
					}
				}

			}
			return {stock: stock, vente: vente, offre: offre}
		},
		_getTypo: function(t, a, p) {
			let v = false
			if (this.app.active_segmentation) {
				if (a=='echelle') {
					v = this.app.active_segmentation[1]._source.d_surf
				} else {
					v = this.app.active_segmentation[0]._source.d_typo
				}
				switch(this.activeTab_type)
				{
					case "s":
					v = v.ini
					break;
					case "v":
					v = v.vte
					break
					case "o":
					v = v.ofr
					break
				}
				if (v) {
					switch(this.activeTab_tva)
					{
						case "tva_pleine_libre":
						v = v.vp
						break;
						case "tva_pleine_aide":
						v = v.vp
						break
						case "tva_reduite":
						v = v.vr
						break
					}
				}
				if (v) {
					if (a=='echelle') {
						v = v['s' + t.toString()]						
					} else {
						v = v['t' + t.toString()]
					}
				}
				if (v) {
					v = v.dt
					if (v) {
						switch(this.activeTab_tva)
						{
							case "tva_pleine_libre":
							v = v.ll
							break;
							case "tva_pleine_aide":
							v = v.la
							break
							case "tva_reduite":
							v = v.la
							break
						}
					}
					if (v) {
						let w = false
						if (p=="prix_ap") {
							w = v.po
							if (!w) {
								if (this.expanded_program.forme_urbaine.toUpperCase() == 'INDIVIDUEL') {
									w = v.pn
								}
							}
						} else {
							w = v.pn
						}
						if (w) {
							v = w
						} else {
							v = false
						}
					}
				}				
			}
			return v
		},

		getEchelle: function() {
			let v = false
			return v
		},
		
		expand_program: function() {
			this.$router.push({ name: 'neuf'}).catch(()=>{});
			if (this.expanded_program != this.program) {
				this.$store.dispatch('expand_program', { program: this.program })
			} else {
				this.$store.dispatch('expand_program', { program: false })
			}
		},

		select_program: function() {
			this.$store.dispatch('select_program', { program: this.program })
		},

		show_program_on_map: function() {
			this.$store.dispatch('center_map', { coords: { lat: this.expanded_program.location.lat, lng: this.expanded_program.location.lon }, zoom: 16 })
		},

		print: function() {
			this.$store.dispatch('print_program', { divname: "progencours"})
			this.$store.dispatch('SendEvent', { action: "program_download", opt_label: this.expanded_program.nom, opt_value: 0 })
			this.$store.dispatch('set_print', { print: 'expanded-program' })
		}
	},

	mounted: function() {
		let that = this
		that.maj_graph()
		that.maj_graph_histo()			
	},
	// eslint-disable-next-line
	components: { InputSwitch, ToolSource, AnimatedTabs, apexchart: VueApexCharts, highcharts: Chart, Icon, DataTable, Column, ColumnGroup, Row, Chip, Timeline, ImagePreview, SpeedDial, Dialog, Button, StreetView }
}
</script>

<style lang="stylus" scoped>
@import '../assets/css/app'

.pane-program-enter, .pane-program-leave-to
	transform translateX(100%)

.pan-titre-programme
	z-index 999
	position fixed
	float left
	with 100%
	transition 0.5s easeOutQuart
	.accordion-menu
		overflow hidden scroll
		height 100%
.fermer
	position absolute
	width 60px
	right 0px
	border-left 1px solid palette_gris_bordure
	text-align center
	line-height 60px
	cursor pointer
.aide
	width 60px
	left 60px
	position absolute
	border-right 1px solid palette_gris_bordure
	text-align center
	line-height 60px
	cursor pointer
.chatbox
	width 60px
	left 0px
	border-right 1px solid palette_gris_bordure
	position absolute
	text-align center
	line-height 60px
	cursor pointer
.print
	width 60px;
	position absolute
	right 60px
	border-left 1px solid palette_gris_bordure
	text-align center
	line-height 60px
	cursor pointer

.module_ret
	width 800px
	height 60px
	vertical-align middle
	background-color palette_gris
	border-bottom 1px solid palette_gris_bordure
	border-right 1px solid palette_gris_bordure

.pane-program
	position absolute
	right 0
	top headersize
	bottom 0
	width 800px
	background-color #fefefe
	transition 0.5s easeOutQuart
	z-index 599
	border-left 1px solid palette_gris_bordure

.program
	color #343a40
	width calc(100% + 20px)
	border-bottom 2px solid #eee
	&.inactif
		.program-main
			background #f5f5f5
			.program-content
				.name
					color #666
	&.expanded
		&:hover
			.program-main
				background lighten(#e3f2fd, 25%)
		.program-main
			background lighten(#e3f2fd, 50%)
			box-shadow inset -2px 2px 0 0 palette2, inset 2px 2px 0 0 palette2
			.program-content
				.name
					color palette2
		.program-detail
			box-shadow inset -2px -2px 0 0 palette2, inset 2px -2px 0 0 palette2
	&.	
		&:hover
			.program-main
				background lighten(orange, 85%)
		.program-main
			background lighten(orange, 90%)
	&.disabled, &.disabled:hover
		.program-main
			box-shadow none
			.image
				background #eee
				img
					display none
			.price
				color lighten(green, 10%)
			.program-content
				.name, .address, .city, .seller
					color #fff
					&:after
						content ''
						position absolute
						left 0
						top 4px
						bottom 4px
						width 75%
						background #eee
				.forme-urbaine
					color #fff
					&:after
						content ''
						position absolute
						left 0
						top 0
						bottom 0
						width 100%
						background #eee
						border-radius radius
		.program-detail
			display none
	.program-main
		background #fefefe
		padding 16px
		.image
			position absolute
			left 200px
			top 16px
			height 285px
			width 396px
			border-radius radius
			background #eee url(../assets/img/no-camera.png) center center no-repeat
			background-size 48px 48px
			img
				position absolute
				left 50%
				transform translateX(-50%)
				height 100%
				width auto
		.program-content
			margin-left 16px
			.name
				height 24px
				line-height 20px
				text-align center
			.city, .address
				color #999
				font-size 12px
			.seller
				margin 8px 0 0 0
				text-transform uppercase
				font-size 12px
				line-height 24px
			.forme-urbaine
				position absolute
				right 0
				bottom 0
				color #999
		.price
			position absolute
			left 44px
			top 24px
			padding 0 8px 0 16px
			font-family volte, sans-serif
			font-weight 600
			line-height 24px
			background lighten(green, 10%)
			color #fff
			&:after, &:before
				content ''
				position absolute
				right -16px
				width 0
				height 0
				border-style solid
			&:before
				top 0
				border-width 16px 16px 0 0
				border-color lighten(green, 10%) transparent transparent transparent
			&:after
				bottom 0
				border-width 16px 0 0 16px
				border-color transparent transparent transparent lighten(green, 10%)
			.angle
				position absolute
				left 0
				top -4px
				width 0
				height 0
				border-style solid
				border-width 0 0 4px 6px
				border-color transparent transparent darken(blue, 50%) transparent

	.program-detail
		overflow hidden
		height auto
		&.detail-enter, &.detail-leave-to
			height 430px
			background-color red
		.program-detail-content
			padding 16px
			.resume
				margin 8px 0 0 0
				&:first-child
					margin 0
				&:after
					content ''
					display block
					clear both
				.resume-data
					float left
					width 33.3333%
					margin 0 0 8px 0
					.label
						font-size 10px
						text-transform uppercase
						color #999
					.value
						font-weight 500
			.program-detail-info
				padding 8px 0
				.program-detail-info-filters
					text-align center
					margin 0 0 8px 0
				.program-detail-info-content
					padding 8px 0
					background #f5f5f5
					border-radius radius
					overflow hidden
					table
						width 100%
						border-collapse collapse
						tr
							&:last-child
								border none
							&:hover
								td
							td, th
								padding 4px 8px
							th
								font-size 12px
								font-weight 500
							td
								text-align center
								&:first-child
									text-align left
									font-size 12px
									font-weight bold
									padding-left 16px
			.actions
				margin 8px 0 0 0
				.action
					display inline-block
					margin 0 24px 0 0
					height 32px
					color #666
					font-size 12px
					font-weight 500
					line-height @height
					cursor pointer
					&:hover
						color #333
					.icon
						float left
						margin 4px 8px 0 0


.actionaide
	position absolute
	right 140px
	top 0px
	z-index 999
.actionfermer
	position absolute
	right 22px
	top 0px
	z-index 999
.action_print
	position absolute
	right 60px
	top 0px
	z-index 999
.action_help
	position absolute
	right 100px
	top 0px
	z-index 999


.indice_confiance
	position absolute
	right 121px
	width 560px !important
	height 60px
	top 0px
	z-index 999
	vertical-align middle
	line-height 59px
	text-align center
	left 120px
	font-size 14px
	&.bas
		background qualitedonneesbas
	&.milieu
		background qualitedonneesmilieu
	&.haut
		background qualitedonneeshaut
	&.pc
		background #ccc

.vb
	.vb-dragger
		z-index 5
		width 10px
		right 0

.back-button
	cursor pointer
	border-radius 100%
	transition 0.25s easeOutQuart
	width 32px
	height 32px
	padding 4px	
	&:hover
		color #fff
		background-color darken(green, 10%)

.program-maj
	text-align center
	font-size 12px
	font-weight 500
	padding-left 16px
	color palette1

.nom
	color primaryA100

.refreshprog
	height 185px
	width 750px

.infinite-loader
	position absolute
	right 307px
	top 28px
	.loader
		display inline-block
		background main_color
		border-radius 100%
		color #fff
		.icon
			display block
			animation rotate 1.5s infinite linear


.sp
	margin-right 10px !important
	padding-left 10px
	padding-right 10px
	font-family Montserrat, sans-serif
	font-style normal
	font-weight normal
	font-size 16px
	line-height 28px
	color rgba(0, 0, 0, 0.87)
	margin-right 2px
	height 28px
	right 0
	bottom 0
	background #FAFAFA
	border 1px solid rgba(0, 0, 0, 0.6)
	box-sizing border-box
	border-radius 14px
	width auto
	
	

.speeddial-tooltip-demo {
	right: 50px;
	top: -25px;
}

:deep(.speeddial-tooltip-demo) {
	.p-speeddial-direction-up {
		&.speeddial-left {
			left: 0;
			bottom: 0;
		}
		&.speeddial-right {
			right: 0;
			bottom: 0;
		}
	}
	.p-speeddial-button.p-button.p-button-icon-only {
		width: 3rem !important;
		height: 3rem !important;
	}

}	

:deep(.row-accessories2) {
	background-color: #eee !important;
}

:deep(p-datatable-tbody) {
	font-size: 10px;
}

.p-chip.custom-chip {
	background: #3B82F6;
	font-size:12px;
	color: var(--primary-color-text);
}
.p-chip.vebacte {
	background: #fd943a;
	font-size:12px;
	color: var(--primary-color-text);
}
.p-chip.vebresa {
	background: #fdc13a;
	font-size:12px;
	color: var(--primary-color-text);
}

.p-chip.qeb {
	background: #13A10E;
	font-size:12px;
	color: #FFFFFF;
}

.custom-marker {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	width: 2rem;
	height: 2rem;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	color: #fff;
	border-radius: 50%;
	z-index: 1;
}

.tab-content-panel {
	display: flex;
	justify-content: center;
	width: 100%;
}

.results {
	display: flex;
	//grid-template-columns: repeat(3, 1fr);
	gap: 20px;
	margin-bottom: 10px;
	justify-content: space-around;
	flex-direction: row;
	flex-wrap: wrap;
}

.result-card {
	background-color: #ffffff;
	border-radius: 12px;
	padding: 10px;
	//box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
	text-align: center;
	//border: 1px solid  #e2e8f0;
	//transition: all 0.2s;
	min-width: 180px;
}

.result-card:hover {
	//transform: translateY(-5px);
	//box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}

.result-icon {
	width: 60px;
	height: 60px;
	border-radius: 50%;
	background-color: #ede8ff;
	color: #6a3de8;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0 auto 15px;
}

.result-icon i {
	font-size: 24px;
}

.result-label {
	color: #718096;
	margin-bottom: 10px;
	font-size: 0.9rem;
}

.result-value {
	font-size: 1.4rem;
	font-weight: 700;
	color: #6a3de8;
}

:deep(.p-panel-content) {
	background: #f0f2f4 !important;	
}


.program-header {
    padding: 15px;
    border-bottom: 1px solid #f1f3f5;
}

/*
.program-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	background: linear-gradient(135deg, #fcfcfc 0%, #f5f5f5 100%);
	border-radius: 12px;
	padding: 10px 20px;
	//box-shadow: 0 6px 16px rgba(0, 0, 0, 0.05);
	box-shadow: 0 4px 15px rgba(0,0,0,0.05);
	border: 1px solid #e2e8f0;
	position: relative;
	overflow: hidden;
	font-family: 'Segoe UI', Arial, sans-serif;
	margin-bottom: 20px;
}

.program-header::before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 6px;
	height: 100%;
	background: #b388ff;
}
*/
.program-header-left, .program-header-right {
	display: flex;
	flex-direction: column;
}

.program-header-full {
	width: 100%;
}

.program-title {
    color: #6c5ce7;
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 3px;
}

/*
.program-title {
	color: #b388ff;
	font-size: 24px;
	font-weight: 700;
	margin: 0 0 8px 0;
	letter-spacing: 1px;
}
*/

.program-promoter, .program-units, .program-address {
	margin-bottom: 8px;
	font-size: 16px;
}

.label {
	font-weight: 600;
	color: #555;
	margin-right: 6px;
}

.value {
	color: #333;
	font-weight: 500;
}

.program-address {
	text-align: right;
	font-weight: 500;
}

.location-pin {
	margin-left: 6px;
	font-size: 18px;
}

.program-details {
	display: flex;
	justify-content: flex-end;
	gap: 16px;
	margin-top: 6px;
}

.program-type, .program-floors {
	background-color: #b388ff;
	color: white;
	padding: 8px 16px;
	border-radius: 20px;
	font-size: 10px;
	font-weight: 600;
	letter-spacing: 0.5px;
}
  
.program-qeb {
	background-color: #13A10E;
	color: white;
	padding: 8px 16px;
	border-radius: 20px;
	font-size: 10px;
	font-weight: 600;
	letter-spacing: 0.5px;
}

.program-vebacte {
	background-color: #fd943a;
	color: white;
	padding: 8px 16px;
	border-radius: 20px;
	font-size: 10px;
	font-weight: 600;
	letter-spacing: 0.5px;
}

.program-vebresa {
	background-color: #fdc13a;
	color: white;
	padding: 8px 16px;
	border-radius: 20px;
	font-size: 10px;
	font-weight: 600;
	letter-spacing: 0.5px;
}

.program-tvar {
	background-color: #3B82F6;
	color: white;
	padding: 8px 16px;
	border-radius: 20px;
	font-size: 10px;
	font-weight: 600;
	letter-spacing: 0.5px;
}






:root {
	--primary: #6c5ce7;
	--primary-light: #a29bfe;
	--primary-dark: #5341d6;
	--secondary: #f1f3f5;
	--secondary-dark: #e9ecef;
	--text-dark: #343a40;
	--text-medium: #6c757d;
	--text-light: #adb5bd;
	--success: #27ae60;
	--warning: #f39c12;
	--white: #ffffff;
	--border-radius: 10px;
	--shadow: 0 2px 10px rgba(0, 0, 0, 0.08);
}


body {
	background-color: #f8f9fa;
	color: #343a40;
	line-height: 1.6;
	font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.property-card {
	background-color: white;
	border-radius: 10px;
	overflow: hidden;
	//box-shadow: 0 2px 10px rgba(0, 0, 0, 0.08);
	margin-bottom: 10px;
}

.property-image {
	width: 100%;
	height: 200px;
	object-fit: cover;
}

.property-header {
	padding-top: 15px;
	padding-bottom: 15px;
	//border-bottom: 1px solid #f1f3f5;
}

.property-title {
	color: #6c5ce7;
	font-size: 22px;
	font-weight: 700;
	margin-bottom: 3px;
}

.property-details {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
	margin-top: 10px;
}

.badge-container {
	display: flex;
	flex-wrap: wrap;
	gap: 4px;
}

.badge {
	padding: 3px 8px;
	border-radius: 4px;
	font-size: 13px;
	font-weight: 500;
	display: inline-block;
}

.badge-green {
	background-color: #ebfbf0;
	color: #27ae60;
}

.badge-blue {
	background-color: #e7f3ff;
	color: #3498db;
}

.badge-orange {
	background-color: #fff3e0;
	color: #f39c12;
}

.badge-purple {
	background-color: #f0ebff;
	color: #6c5ce7;
}

.address {
	display: flex;
	align-items: center;
	color: #6c757d;
	margin-top: 5px;
	font-size: 15px;
}

.tabs {
	display: flex;
	border-bottom: 1px solid #f1f3f5;
	background-color: #f8f9fa;
}

.tab {
	padding: 8px 15px;
	cursor: pointer;
	font-size: 13px;
	transition: all 0.2s ease;
	border-bottom: 2px solid transparent;
}

.tab.active {
	border-bottom: 2px solid #6c5ce7;
	color: #6c5ce7;
	font-weight: 500;
}

.chart-container {
	padding: 10px 15px;
	position: relative;
	//border-bottom: 1px solid #f1f3f5;
}

.chart-placeholder {
	width: 100%;
	height: 170px;
	background-color: #f5f7fa;
	border-radius: 8px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #6c757d;
	font-size: 14px;
}

.update-info {
	text-align: center;
	font-size: 11px;
	color: #6c757d;
	margin-top: 8px;
}

.progress-container {
	padding: 10px 15px;
	border-bottom: 1px solid #f1f3f5;
	display: none;
}

.progress-steps {
	display: flex;
	justify-content: space-between;
	position: relative;
	margin-bottom: 10px;
}

.progress-line {
	position: absolute;
	top: 15px;
	left: 0;
	right: 0;
	height: 2px;
	background-color: #f1f3f5;
	z-index: 1;
}

.progress-step {
	position: relative;
	z-index: 2;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 33%;
}

.step-icon {
	width: 30px;
	height: 30px;
	border-radius: 50%;
	background-color: #f1f3f5;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #6c757d;
	margin-bottom: 5px;
	font-size: 12px;
}

.step-icon.completed {
	background-color: #27ae60;
	color: white;
}

.step-date {
	font-size: 12px;
	color: #6c757d;
}

.stats-filters {
	display: flex;
	justify-content: center;
	padding: 10px 0;
	border-bottom: 1px solid #f1f3f5;
	border-top: 1px solid #f1f3f5;
    padding-bottom: 0px;
	gap: 5px;
}

.stats-filter {
	padding: 6px 10px;
	font-size: 12px;
	border-radius: 20px;
	background-color: #f1f3f5;
	color: #6c757d;
	cursor: pointer;
	transition: all 0.2s ease;
}

.stats-filter.active {
	background-color: #6c5ce7;
	color: white;
}

.stats-container {
	display: flex;
	justify-content: space-between;
	padding: 10px 15px;
	border-bottom: 1px solid #f1f3f5;
}

.stat-box {
	text-align: center;
	position: relative;
}

.stat-label {
	font-size: 12px;
	color: #6c757d;
	margin-bottom: 2px;
	cursor: help;
}

.stat-value {
	font-size: 18px;
	font-weight: 600;
	color: #6c5ce7;
}

.stat-tooltip {
	position: absolute;
	bottom: 100%;
	left: 50%;
	transform: translateX(-50%);
	background-color: #343a40;
	color: white;
	padding: 8px 12px;
	border-radius: 6px;
	font-size: 11px;
	width: 200px;
	opacity: 0;
	visibility: hidden;
	transition: opacity 0.3s, visibility 0.3s;
	z-index: 10;
	pointer-events: none;
	text-align: left;
}

.stat-tooltip::after {
	content: "";
	position: absolute;
	top: 100%;
	left: 50%;
	margin-left: -5px;
	border-width: 5px;
	border-style: solid;
	border-color: #343a40 transparent transparent transparent;
}

.stat-label:hover + .stat-tooltip {
	opacity: 1;
	visibility: visible;
}

.view-tabs {
	display: flex;
	padding: 10px 15px;
	border-bottom: 1px solid #f1f3f5;
	background-color: #f8f9fa;
}

.table-controls {
	padding: 10px 15px;
	background-color: white;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid #f1f3f5;
}

.filter-controls {
	display: flex;
	gap: 15px;
	align-items: center;
}

.filter-section {
	display: flex;
	align-items: center;
	gap: 8px;
}

.filter-label {
	font-size: 13px;
	color: #6c757d;
}

.toggle-container {
	position: relative;
	display: inline-block;
	width: 40px;
	height: 20px;
}

.toggle-input {
	opacity: 0;
	width: 0;
	height: 0;
}

.toggle-slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #adb5bd;
	transition: .3s;
	border-radius: 20px;
}

.toggle-slider:before {
	position: absolute;
	content: "";
	height: 14px;
	width: 14px;
	left: 3px;
	bottom: 3px;
	background-color: white;
	transition: .3s;
	border-radius: 50%;
}

.toggle-input:checked + .toggle-slider {
	background-color: #6c5ce7;
}

.toggle-input:checked + .toggle-slider:before {
	transform: translateX(20px);
}

.download-btn {
	display: flex;
	align-items: center;
	background: none;
	border: none;
	font-size: 13px;
	color: #6c757d;
	cursor: pointer;
}

.download-btn svg {
	margin-right: 5px;
}

.price-boxes {
	display: flex;
	justify-content: space-between;
	padding: 10px 15px;
	border-bottom: 1px solid #f1f3f5;
}

.price-box {
	text-align: center;
	position: relative;
}

.price-label {
	font-size: 12px;
	color: #6c757d;
	margin-bottom: 2px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.price-value {
	font-size: 18px;
	font-weight: 600;
	color: #6c5ce7;
}

.price-unit {
	font-size: 12px;
	color: #6c757d;
}

.info-icon {
	font-size: 12px;
	color: #adb5bd;
	cursor: pointer;
	margin-left: 4px;
}

.info-tooltip {
	position: absolute;
	bottom: 100%;
	left: 50%;
	transform: translateX(-50%);
	background-color: #343a40;
	color: white;
	text-align: center;
	border-radius: 6px;
	padding: 6px;
	width: 180px;
	z-index: 1;
	opacity: 0;
	transition: opacity 0.3s;
	font-size: 11px;
	visibility: hidden;
}

.info-tooltip::after {
	content: "";
	position: absolute;
	top: 100%;
	left: 50%;
	margin-left: -5px;
	border-width: 5px;
	border-style: solid;
	border-color: #343a40 transparent transparent transparent;
}

.info-icon:hover + .info-tooltip {
	visibility: visible;
	opacity: 1;
}

.type-switch {
	display: flex;
	padding: 5px 15px;
	border-bottom: 1px solid #f1f3f5;
}

.switch-options {
	display: flex;
	background-color: #f1f3f5;
	border-radius: 4px;
	overflow: hidden;
}

.switch-option {
	padding: 4px 10px;
	font-size: 12px;
	cursor: pointer;
}

.switch-option.active {
	background-color: #6c5ce7;
	color: white;
}

table {
	width: 100%;
	border-collapse: collapse;
}

th {
	text-align: left;
	padding: 8px;
	font-size: 12px;
	font-weight: 500;
	color: #6c757d;
	background-color: #f1f3f5;
	border-bottom: 1px solid #e9ecef;
}

td {
	padding: 8px;
	font-size: 13px;
	border-bottom: 1px solid #f1f3f5;
}

tr:last-child td {
	border-bottom: none;
}

.filter-effect {
	animation: highlight 2s ease;
}

.sold-out-notice {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ebfbf0;
    color: #27ae60;
    font-weight: 600;
    font-size: 16px;
    padding: 10px;
    margin-bottom: 2px;
    border-radius: 5px;
	font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    width: 100%;
    margin-top: 10px;
}

.veille-notice {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fdf2f8;
    color: #d82f75;
    font-weight: 600;
    font-size: 16px;
    padding: 10px;
    margin-bottom: 2px;
    border-radius: 5px;
	font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    width: 100%;
    margin-top: 10px;
}

.info-panel {
	background-color: #f6f4ff;
	padding: 15px;
	font-size: 13px;
	line-height: 1.4;
	border-bottom: 1px solid #e0e0e0;
	border-left: 3px solid #7B35E9;
	margin-bottom: 15px;
	text-align: justify;
}

@media print
	.program
		.program-detail
			.program-detail-content
				.actions
					display none


</style>
