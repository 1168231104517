<template>
	<div class="tab-adresse" id='report-adresse'>
		<div class="tab-content" v-observe-visibility="onVisibilityChange">
			<Fieldset legend="Qualité de l'adresse" :toggleable="true" class="surface-ground">
				<div class="part">
					<div class="part-content">
						<div class="row" style="display: flex;flex-wrap: wrap;">
							<div v-if="app.modules_adresse.adresse_transport" class="col company-dashboard">
								<div class="business-dashboard company-header">
									<div style="height:auto !important">
										<div class="unmodule_detail_titre">
											<div class="icone_module"><span class="cs-tile-icon cs-icon-thematic icon-12-02 ng-star-inserted" style="color: rgb(250, 87, 50);"></span></div>
											<div class="thematique">{{ $t('thematique') }}</div>
											<div class="titre_sommaire"><b>{{ $t('acces_au_transport') }}</b></div>
											<div class="note" :class="app.cityscan.adresse.note_transports">{{app.cityscan.adresse.note_transports}}</div>
										</div>
										<div class="">
											<div class="programs-list-content">
												<div class="content" ref="scrollable_programs">
													<ul class="accordion-menu">
														<div class="single-accordion">
															<li class="unmodule_detail" v-if="app.modules_adresse.adresse_transport_bus">
																<div class="icone_module"><span class="cs-tile-icon cs-icon-thematic icon-1200-02 ng-star-inserted" style="color: rgb(204, 0, 0);"></span></div>
																<div class="titre_module">{{ $t('bus') }}</div>
																<div class="note" :class="this.app.cityscan.adresse.bus.note">{{this.app.cityscan.adresse.bus.note}}</div>
																<div class="soustitre_module">{{this.app.cityscan.adresse.bus.nb}} {{this.app.cityscan.adresse.bus.temps}}</div>
															</li>
															<div class="divider" v-if="app.modules_adresse.adresse_transport_metro" v-show="this.app.cityscan.adresse.metro.note!='ND'"></div>
															<li class="unmodule_detail " v-if="app.modules_adresse.adresse_transport_metro" v-show="this.app.cityscan.adresse.metro.note!='ND'">
																<div class="icone_module"><span class="cs-tile-icon cs-icon-thematic icon-1201-02 ng-star-inserted" style="color: rgb(255, 73, 24);"></span></div>
																<div class="titre_module">{{ $t('metro') }}</div>
																<div class="note" :class="this.app.cityscan.adresse.metro.note">{{this.app.cityscan.adresse.metro.note}}</div>
																<div class="soustitre_module">{{this.app.cityscan.adresse.metro.nb}} {{this.app.cityscan.adresse.metro.temps}}</div>
															</li>
															<div class="divider" v-if="app.modules_adresse.adresse_transport_tram"></div>
															<li class="unmodule_detail " v-if="app.modules_adresse.adresse_transport_tram" v-show="this.app.cityscan.adresse.tramway.note!='ND'">
																<div class="icone_module"><span class="cs-tile-icon cs-icon-thematic icon-1202-02 ng-star-inserted" style="color: rgb(255, 116, 13);"></span></div>
																<div class="titre_module">{{ $t('tramway') }}</div>
																<div class="note" :class="this.app.cityscan.adresse.tramway.note">{{this.app.cityscan.adresse.tramway.note}}</div>
																<div class="soustitre_module">{{this.app.cityscan.adresse.tramway.nb}} {{this.app.cityscan.adresse.tramway.temps}}</div>
															</li>
															<div class="divider" v-if="app.modules_adresse.adresse_transport_route_rapide"></div>
															<li class="unmodule_detail " v-if="app.modules_adresse.adresse_transport_route_rapide">
																<div class="icone_module"><span class="cs-tile-icon cs-icon-thematic icon-1210-02 ng-star-inserted" style="color: rgb(255, 111, 30);"></span></div>
																<div class="titre_module">{{ $t('acces_route_rapide') }}</div>
																<div class="note" :class="this.app.cityscan.adresse.route_rapide.note">{{this.app.cityscan.adresse.route_rapide.note}}</div>
																<div class="soustitre_module">{{this.app.cityscan.adresse.route_rapide.nb}} {{this.app.cityscan.adresse.route_rapide.temps}}</div>
															</li>
															<div class="divider" v-if="app.modules_adresse.adresse_transport_parking"></div>
															<li class="unmodule_detail " v-if="app.modules_adresse.adresse_transport_parking">
																<div class="icone_module"><span class="cs-tile-icon cs-icon-thematic icon-1230-02 ng-star-inserted" style="color: rgb(255, 188, 0);"></span></div>
																<div class="titre_module">{{ $t('parking') }}</div>
																<div class="note" :class="this.app.cityscan.adresse.parking.note">{{this.app.cityscan.adresse.parking.note}}</div>
																<div class="soustitre_module">{{this.app.cityscan.adresse.parking.nb}} {{this.app.cityscan.adresse.parking.temps}}</div>
															</li>
															<div class="divider" v-if="app.modules_adresse.adresse_transport_gare"></div>
															<li class="unmodule_detail " v-if="app.modules_adresse.adresse_transport_gare">
																<div class="icone_module"><span class="cs-tile-icon cs-icon-thematic icon-1220-02 ng-star-inserted" style="color: rgb(237, 96, 0);"></span></div>
																<div class="titre_module">{{ $t('gare') }}</div>
																<div class="note" :class="this.app.cityscan.adresse.gare.note">{{this.app.cityscan.adresse.gare.note}}</div>
																<div class="soustitre_module">{{this.app.cityscan.adresse.gare.nb}} {{this.app.cityscan.adresse.gare.temps}}</div>
															</li>
															<div class="divider" v-if="app.modules_adresse.adresse_transport_aeroport"></div>
															<li class="unmodule_detail " v-if="app.modules_adresse.adresse_transport_aeroport">
																<div class="icone_module"><span class="cs-tile-icon cs-icon-thematic icon-1221-02 ng-star-inserted" style="color: rgb(230, 52, 0);"></span></div>
																<div class="titre_module">{{ $t('aeroport') }}</div>
																<div class="note" :class="this.app.cityscan.adresse.aeroport.note">{{this.app.cityscan.adresse.aeroport.note}}</div>
																<div class="soustitre_module">{{this.app.cityscan.adresse.aeroport.nb}} {{this.app.cityscan.adresse.aeroport.temps}}</div>
															</li>
															<div class="divider" v-if="app.modules_adresse.adresse_transport_port"></div>
															<li class="unmodule_detail " v-if="app.modules_adresse.adresse_transport_port" v-show="this.app.cityscan.adresse.port.note!='ND'">
																<div class="icone_module"><span class="cs-tile-icon cs-icon-thematic icon-1222-02 ng-star-inserted" style="color: rgb(255, 167, 38);"></span></div>
																<div class="titre_module">{{ $t('port_de_voyageur') }}</div>
																<div class="note" :class="this.app.cityscan.adresse.port.note">{{this.app.cityscan.adresse.port.note}}</div>
																<div class="soustitre_module">{{this.app.cityscan.adresse.port.nb}} {{this.app.cityscan.adresse.port.temps}}</div>
															</li>
														<!--
															<div class="divider" v-if="app.modules_adresse.adresse_transport_velo"></div>
															<li class="unmodule_detail " v-if="app.modules_adresse.adresse_transport_velo">
																<div class="icone_module"><span class="cs-tile-icon cs-icon-thematic icon-2000-02 ng-star-inserted" style="color: rgb(65, 112, 52);"></span></div>
																<div class="titre_module">{{ $t('velo_partage') }}</div>
																<div class="note" :class="this.app.cityscan.adresse.velo.note">{{this.app.cityscan.adresse.velo.note}}</div>
																<div class="soustitre_module">{{this.app.cityscan.adresse.velo.nb}} {{this.app.cityscan.adresse.velo.temps}}</div>
															</li>
															<div class="divider" v-if="app.modules_adresse.adresse_transport_auto"></div>
															<li class="unmodule_detail " v-if="app.modules_adresse.adresse_transport_auto">
																<div class="icone_module"><span class="cs-tile-icon cs-icon-thematic icon-2001-02 ng-star-inserted" style="color: rgb(68, 87, 69);"></span></div>
																<div class="titre_module">{{ $t('auto_partage') }}</div>
																<div class="note" :class="this.app.cityscan.adresse.auto.note">{{this.app.cityscan.adresse.auto.note}}</div>
																<div class="soustitre_module">{{this.app.cityscan.adresse.auto.nb}} {{this.app.cityscan.adresse.auto.temps}}</div>
															</li>
															<div class="divider" v-if="app.modules_adresse.adresse_transport_borne"></div>
															<li class="unmodule_detail " v-if="app.modules_adresse.adresse_transport_borne">
																<div class="icone_module"><span class="cs-tile-icon cs-icon-thematic icon-2002-02 ng-star-inserted" style="color: rgb(94, 121, 92);"></span></div>
																<div class="titre_module">{{ $t('borne_de_recharge') }}</div>
																<div class="note" :class="this.app.cityscan.adresse.borne.note">{{this.app.cityscan.adresse.borne.note}}</div>
																<div class="soustitre_module">{{this.app.cityscan.adresse.borne.nb}} {{this.app.cityscan.adresse.borne.temps}}</div>
															</li>
														-->
														</div>
													</ul>
												</div>
											</div>
										</div>
									</div>					
								</div>
							</div>
							<div v-if="app.modules_adresse.adresse_education" class="col company-dashboard">
								<div class="business-dashboard company-header">
									<div style="height:auto !important">
										<div class="unmodule_detail_titre">
											<div class="icone_module"><span class="cs-tile-icon cs-icon-thematic icon-14-02 ng-star-inserted" style="color: rgb(29, 16, 117);"></span></div>
											<div class="thematique">{{ $t('thematique') }}</div>
											<div class="titre_sommaire"><b>{{ $t('acces_a_leducation') }}</b></div>
											<div class="note" :class="app.cityscan.adresse.note_education">{{app.cityscan.adresse.note_education}}</div>
										</div>
										<div class="">
											<div class="programs-list-content" v-bar>
												<div class="content" ref="scrollable_programs">
													<ul class="accordion-menu">
														<div class="single-accordion">
															<li class="unmodule_detail" v-if="app.modules_adresse.adresse_ecole_creche">
																<div class="icone_module"><span class="cs-tile-icon cs-icon-thematic icon-1400-02 ng-star-inserted" style="color: rgb(29, 16, 117);"></span></div>
																<div class="titre_module">{{ $t('creche') }}</div>
																<div class="note" :class="this.app.cityscan.adresse.creche.note">{{this.app.cityscan.adresse.creche.note}}</div>
																<div class="soustitre_module">{{this.app.cityscan.adresse.creche.nb}} {{this.app.cityscan.adresse.creche.temps}}</div>
															</li>
															<div class="divider" v-if="app.modules_adresse.adresse_ecole_creche"></div>
															<li class="unmodule_detail" v-if="app.modules_adresse.adresse_ecole_maternelle">
																<div class="icone_module"><span class="cs-tile-icon cs-icon-thematic icon-1410-02 ng-star-inserted" style="color: rgb(53, 26, 143);"></span></div>
																<div class="titre_module">{{ $t('maternelle') }}</div>
																<div class="note" :class="this.app.cityscan.adresse.maternelle.note">{{this.app.cityscan.adresse.maternelle.note}}</div>
																<div class="soustitre_module">{{this.app.cityscan.adresse.maternelle.nb}} {{this.app.cityscan.adresse.maternelle.temps}}</div>
															</li>
															<div class="divider" v-if="app.modules_adresse.adresse_ecole_maternelle"></div>
															<li class="unmodule_detail" v-if="app.modules_adresse.adresse_ecole_elementaire">
																<div class="icone_module"><span class="cs-tile-icon cs-icon-thematic icon-1420-02 ng-star-inserted" style="color: rgb(77, 36, 169);"></span></div>
																<div class="titre_module">{{ $t('elementaire') }}</div>
																<div class="note" :class="this.app.cityscan.adresse.elementaire.note">{{this.app.cityscan.adresse.elementaire.note}}</div>
																<div class="soustitre_module">{{this.app.cityscan.adresse.elementaire.nb}} {{this.app.cityscan.adresse.elementaire.temps}}</div>
															</li>
															<div class="divider" v-if="app.modules_adresse.adresse_ecole_elementaire"></div>
															<li class="unmodule_detail" v-if="app.modules_adresse.adresse_ecole_college">
																<div class="icone_module"><span class="cs-tile-icon cs-icon-thematic icon-1430-02 ng-star-inserted" style="color: rgb(100, 45, 195);"></span></div>
																<div class="titre_module">{{ $t('college') }}</div>
																<div class="note" :class="this.app.cityscan.adresse.college.note">{{this.app.cityscan.adresse.college.note}}</div>
																<div class="soustitre_module">{{this.app.cityscan.adresse.college.nb}} {{this.app.cityscan.adresse.college.temps}}</div>
															</li>
															<div class="divider" v-if="app.modules_adresse.adresse_ecole_college"></div>
															<li class="unmodule_detail" v-if="app.modules_adresse.adresse_ecole_lycee">
																<div class="icone_module"><span class="cs-tile-icon cs-icon-thematic icon-1440-02 ng-star-inserted" style="color: rgb(124, 55, 221);"></span></div>
																<div class="titre_module">{{ $t('lycee_general') }}</div>
																<div class="note" :class="this.app.cityscan.adresse.lycee.note">{{this.app.cityscan.adresse.lycee.note}}</div>
																<div class="soustitre_module">{{this.app.cityscan.adresse.lycee.nb}} {{this.app.cityscan.adresse.lycee.temps}}</div>
															</li>
															<div class="divider" v-if="app.modules_adresse.adresse_ecole_lycee"></div>
															<li class="unmodule_detail" v-if="app.modules_adresse.adresse_ecole_lycee_pro">
																<div class="icone_module"><span class="cs-tile-icon cs-icon-thematic icon-1441-02 ng-star-inserted" style="color: rgb(127, 68, 182);"></span></div>
																<div class="titre_module">{{ $t('lycee_professionnel') }}</div>
																<div class="note" :class="this.app.cityscan.adresse.lycee_pro.note">{{this.app.cityscan.adresse.lycee_pro.note}}</div>
																<div class="soustitre_module">{{this.app.cityscan.adresse.lycee_pro.nb}} {{this.app.cityscan.adresse.lycee_pro.temps}}</div>
															</li>
															<div class="divider" v-if="app.modules_adresse.adresse_ecole_lycee_pro"></div>
															<li class="unmodule_detail" v-if="app.modules_adresse.adresse_ecole_universite">
																<div class="icone_module"><span class="cs-tile-icon cs-icon-thematic icon-1450-02 ng-star-inserted" style="color: rgb(151, 92, 238);"></span></div>
																<div class="titre_module">{{ $t('universite') }}</div>
																<div class="note" :class="this.app.cityscan.adresse.universite.note">{{this.app.cityscan.adresse.universite.note}}</div>
																<div class="soustitre_module">{{this.app.cityscan.adresse.universite.nb}} {{this.app.cityscan.adresse.universite.temps}}</div>
															</li>
															<div class="divider" v-if="app.modules_adresse.adresse_ecole_universite"></div>
															<li class="unmodule_detail" v-if="app.modules_adresse.adresse_ecole_superieur">
																<div class="icone_module"><span class="cs-tile-icon cs-icon-thematic icon-1460-02 ng-star-inserted" style="color: rgb(177, 128, 255);"></span></div>
																<div class="titre_module">{{ $t('ecole_superieure') }}</div>
																<div class="note" :class="this.app.cityscan.adresse.superieures.note">{{this.app.cityscan.adresse.superieures.note}}</div>
																<div class="soustitre_module">{{this.app.cityscan.adresse.superieures.nb}} {{this.app.cityscan.adresse.superieures.temps}}</div>
															</li>
															<div class="divider" v-if="app.modules_adresse.adresse_ecole_superieur"></div>
															<li class="unmodule_detail" v-if="app.modules_adresse.adresse_ecole_formation">
																<div class="icone_module"><span class="cs-tile-icon cs-icon-thematic icon-1470-02 ng-star-inserted" style="color: rgb(77, 8, 109);"></span></div>
																<div class="titre_module">{{ $t('formation_professionnelle') }}</div>
																<div class="note" :class="this.app.cityscan.adresse.formation_pro.note">{{this.app.cityscan.adresse.formation_pro.note}}</div>
																<div class="soustitre_module">{{this.app.cityscan.adresse.formation_pro.nb}} {{this.app.cityscan.adresse.formation_pro.temps}}</div>
															</li>
														</div>
													</ul>
												</div>
											</div>
										</div>
									</div>
								</div>	
							</div>
							<div v-if="app.modules_adresse.adresse_commerces" class="col company-dashboard">
								<div class="business-dashboard company-header">
									<div style="height:auto !important">
										<div class="unmodule_detail_titre">
											<div class="icone_module"><span class="cs-tile-icon cs-icon-thematic icon-21-02 ng-star-inserted" style="color: rgb(124, 0, 41);"></span></div>
											<div class="thematique">{{ $t('thematique') }}</div>
											<div class="titre_sommaire"><b>{{ $t('acces_aux_commerces') }}</b></div>
											<div class="note" :class="app.cityscan.adresse.note_commerces">{{app.cityscan.adresse.note_commerces}}</div>
										</div>
										<div class="">
											<div class="programs-list-content" v-bar>
												<div class="content" ref="scrollable_programs">
													<ul class="accordion-menu">
														<div class="single-accordion">
															<li class="unmodule_detail" v-if="app.modules_adresse.adresse_commerce_alimentation">
																<div class="icone_module"><span class="cs-tile-icon cs-icon-thematic icon-1330-02 ng-star-inserted" style="color: rgb(124, 0, 41);"></span></div>
																<div class="titre_module">{{ $t('alimentation_generale') }}</div>
																<div class="note" :class="this.app.cityscan.adresse.alimentation.note">{{this.app.cityscan.adresse.alimentation.note}}</div>
																<div class="soustitre_module">{{this.app.cityscan.adresse.alimentation.nb}} {{this.app.cityscan.adresse.alimentation.temps}}</div>
															</li>
															<div class="divider" v-if="app.modules_adresse.adresse_commerce_alimentation"></div>
															<li class="unmodule_detail" v-if="app.modules_adresse.adresse_commerce_bouche">
																<div class="icone_module"><span class="cs-tile-icon cs-icon-thematic icon-1332-02 ng-star-inserted" style="color: rgb(110, 0, 55);"></span></div>
																<div class="titre_module">{{ $t('commerce_de_bouche') }}</div>
																<div class="note" :class="this.app.cityscan.adresse.commerces_bouche.note">{{this.app.cityscan.adresse.commerces_bouche.note}}</div>
																<div class="soustitre_module">{{this.app.cityscan.adresse.commerces_bouche.nb}} {{this.app.cityscan.adresse.commerces_bouche.temps}}</div>
															</li>
															<div class="divider" v-if="app.modules_adresse.adresse_commerce_bouche"></div>
															<li class="unmodule_detail" v-if="app.modules_adresse.adresse_commerce_utilitaire">
																<div class="icone_module"><span class="cs-tile-icon cs-icon-thematic icon-1333-02 ng-star-inserted" style="color: rgb(224, 52, 36);"></span></div>
																<div class="titre_module">{{ $t('commerce_utilitaire') }}</div>
																<div class="note" :class="this.app.cityscan.adresse.commerces_utilitaires.note">{{this.app.cityscan.adresse.commerces_utilitaires.note}}</div>
																<div class="soustitre_module">{{this.app.cityscan.adresse.commerces_utilitaires.nb}} {{this.app.cityscan.adresse.commerces_utilitaires.temps}}</div>
															</li>
															<div class="divider" v-if="app.modules_adresse.adresse_commerce_utilitaire"></div>
															<li class="unmodule_detail" v-if="app.modules_adresse.adresse_commerce_shopping">
																<div class="icone_module"><span class="cs-tile-icon cs-icon-thematic icon-1331-02 ng-star-inserted" style="color: rgb(208, 17, 66);"></span></div>
																<div class="titre_module">{{ $t('shopping') }}</div>
																<div class="note" :class="this.app.cityscan.adresse.shopping.note">{{this.app.cityscan.adresse.shopping.note}}</div>
																<div class="soustitre_module">{{this.app.cityscan.adresse.shopping.nb}} {{this.app.cityscan.adresse.shopping.temps}}</div>
															</li>
															<div class="divider" v-if="app.modules_adresse.adresse_commerce_shopping"></div>
															<li class="unmodule_detail" v-if="app.modules_adresse.adresse_commerce_soin">
																<div class="icone_module"><span class="cs-tile-icon cs-icon-thematic icon-1334-02 ng-star-inserted" style="color: rgb(216, 35, 51);"></span></div>
																<div class="titre_module">{{ $t('soins_et_beaute') }}</div>
																<div class="note" :class="this.app.cityscan.adresse.soins_beaute.note">{{this.app.cityscan.adresse.soins_beaute.note}}</div>
																<div class="soustitre_module">{{this.app.cityscan.adresse.soins_beaute.nb}} {{this.app.cityscan.adresse.soins_beaute.temps}}</div>
															</li>
															<div class="divider" v-if="app.modules_adresse.adresse_commerce_soin"></div>
															<li class="unmodule_detail" v-if="app.modules_adresse.adresse_commerce_logement">
																<div class="icone_module"><span class="cs-tile-icon cs-icon-thematic icon-1370-02 ng-star-inserted" style="color: rgb(231, 47, 52);"></span></div>
																<div class="titre_module">{{ $t('services_pour_le_logement') }}</div>
																<div class="note" :class="this.app.cityscan.adresse.services_logement.note">{{this.app.cityscan.adresse.services_logement.note}}</div>
																<div class="soustitre_module">{{this.app.cityscan.adresse.services_logement.nb}} {{this.app.cityscan.adresse.services_logement.temps}}</div>
															</li>
															<div class="divider" v-if="app.modules_adresse.adresse_commerce_logement"></div>
															<li class="unmodule_detail" v-if="app.modules_adresse.adresse_commerce_vehicule">
																<div class="icone_module"><span class="cs-tile-icon cs-icon-thematic icon-1231-02 ng-star-inserted" style="color: rgb(243, 124, 46);"></span></div>
																<div class="titre_module">{{ $t('services_pour_le_vehicule') }}</div>
																<div class="note" :class="this.app.cityscan.adresse.services_vehicule.note">{{this.app.cityscan.adresse.services_vehicule.note}}</div>
																<div class="soustitre_module">{{this.app.cityscan.adresse.services_vehicule.nb}} {{this.app.cityscan.adresse.services_vehicule.temps}}</div>
															</li>
														</div>
													</ul>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div v-if="app.modules_adresse.adresse_vie" class="col company-dashboard">
								<div class="business-dashboard company-header">
									<div style="height:auto !important">
										<div class="unmodule_detail_titre">
											<div class="icone_module"><span class="cs-tile-icon cs-icon-thematic icon-10-02 ng-star-inserted" style="color: rgb(224, 1, 1);"></span></div>
											<div class="thematique">{{ $t('thematique') }}</div>
											<div class="titre_sommaire"><b>{{ $t('qualite_de_vie') }}</b></div>
											<div class="note" :class="app.cityscan.adresse.note_qualite">{{app.cityscan.adresse.note_qualite}}</div>
										</div>
										<div class="">
											<div class="programs-list-content" v-bar>
												<div class="content" ref="scrollable_programs">
													<ul class="accordion-menu">
														<div class="single-accordion">
															<li class="unmodule_detail" v-if="app.modules_adresse.adresse_sonore">
																<div class="icone_module" :class="getimagenote(this.app.cityscan.adresse.sonore.note)" style="width:32px">&nbsp;</div>
																<div class="titre_module">{{ $t('nuisance_sonore') }}</div>
																<div class="note" :class="this.app.cityscan.adresse.sonore.note">{{this.app.cityscan.adresse.sonore.note}}</div>
																<div class="soustitre_module">{{getlibellenote(this.app.cityscan.adresse.sonore.note, 'sonore')}}</div>
															</li>
															<div class="divider" v-if="app.modules_adresse.adresse_sonore"></div>
															<li class="unmodule_detail" v-if="app.modules_adresse.adresse_olfactive">
																<div class="icone_module" :class="getimagenote(this.app.cityscan.adresse.olfactive.note)" style="width:32px">&nbsp;</div>
																<div class="titre_module">{{ $t('nuisance_olfactive') }}</div>
																<div class="note" :class="this.app.cityscan.adresse.olfactive.note">{{this.app.cityscan.adresse.olfactive.note}}</div>
																<div class="soustitre_module">{{getlibellenote(this.app.cityscan.adresse.olfactive.note, 'olfactive')}}</div>
															</li>
															<div class="divider" v-if="app.modules_adresse.adresse_olfactive"></div>
															<li class="unmodule_detail" v-if="app.modules_adresse.adresse_saturation_population">
																<div class="icone_module" :class="getimagenote(this.app.cityscan.adresse.saturation_population.note)" style="width:32px">&nbsp;</div>
																<div class="titre_module">{{ $t('saturation_population') }}</div>
																<div class="note" :class="this.app.cityscan.adresse.saturation_population.note">{{this.app.cityscan.adresse.saturation_population.note}}</div>
																<div class="soustitre_module">{{getlibellenote(this.app.cityscan.adresse.saturation_population.note, 'saturation_population')}}</div>
															</li>
															<div class="divider" v-if="app.modules_adresse.adresse_saturation_population"></div>
															<li class="unmodule_detail" v-if="app.modules_adresse.adresse_saturation_vehicules">
																<div class="icone_module" :class="getimagenote(this.app.cityscan.adresse.saturation_vehicules.note)" style="width:32px">&nbsp;</div>
																<div class="titre_module">{{ $t('saturation_vehicule') }}</div>
																<div class="note" :class="this.app.cityscan.adresse.saturation_vehicules.note">{{this.app.cityscan.adresse.saturation_vehicules.note}}</div>
																<div class="soustitre_module">{{getlibellenote(this.app.cityscan.adresse.saturation_vehicules.note, 'saturation_vehicules')}}</div>
															</li>
															<div class="divider" v-if="app.modules_adresse.adresse_saturation_vehicules"></div>
															<li class="unmodule_detail" v-if="app.modules_adresse.adresse_air">
																<div class="icone_module" :class="getimagenote(this.app.cityscan.adresse.air.note)" style="width:32px">&nbsp;</div>
																<div class="titre_module">{{ $t('pollution_de_lair') }}</div>
																<div class="note" :class="this.app.cityscan.adresse.air.note">{{this.app.cityscan.adresse.air.note}}</div>
																<div class="soustitre_module">{{getlibellenote(this.app.cityscan.adresse.air.note, 'air')}}</div>
															</li>
															<div class="divider" v-if="app.modules_adresse.adresse_air"></div>
															<li class="unmodule_detail" v-if="app.modules_adresse.adresse_electromagnetique">
																<div class="icone_module" :class="getimagenote(this.app.cityscan.adresse.electromagnetique.note)" style="width:32px">&nbsp;</div>
																<div class="titre_module">{{ $t('pollution_electromagnetique') }}</div>
																<div class="note" :class="this.app.cityscan.adresse.electromagnetique.note">{{this.app.cityscan.adresse.electromagnetique.note}}</div>
																<div class="soustitre_module">{{getlibellenote(this.app.cityscan.adresse.electromagnetique.note, 'electromagnetique')}}</div>
															</li>
															<div class="divider" v-if="app.modules_adresse.adresse_electromagnetique"></div>
															<li class="unmodule_detail" v-if="app.modules_adresse.adresse_radon">
																<div class="icone_module" :class="getimagenote(this.app.cityscan.adresse.radon.note)" style="width:32px">&nbsp;</div>
																<div class="titre_module">{{ $t('radon') }}</div>
																<div class="note" :class="this.app.cityscan.adresse.radon.note">{{this.app.cityscan.adresse.radon.note}}</div>
																<div class="soustitre_module">{{getlibellenote(this.app.cityscan.adresse.radon.note, 'radon')}}</div>
															</li>
														</div>
													</ul>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div v-if="app.modules_adresse.adresse_loisirs" class="col company-dashboard">
								<div class="business-dashboard company-header">
									<div style="height:auto !important">
										<div class="unmodule_detail_titre">
											<div class="icone_module"><span class="cs-tile-icon cs-icon-thematic icon-22-02 ng-star-inserted" style="color: rgb(0, 214, 242);"></span></div>
											<div class="thematique">{{ $t('thematique') }}</div>
											<div class="titre_sommaire"><b>{{ $t('acces_aux_loisirs_et_tourisme') }}</b></div>
											<div class="note" :class="app.cityscan.adresse.note_loisirs">{{app.cityscan.adresse.note_loisirs}}</div>
										</div>
										<div class="">
											<div class="programs-list-content" v-bar>
												<div class="content" ref="scrollable_programs">
													<ul class="accordion-menu">
														<div class="single-accordion">
															<li class="unmodule_detail" v-if="app.modules_adresse.adresse_loisir_bibliotheque">
																<div class="icone_module"><span class="cs-tile-icon cs-icon-thematic icon-1350-02 ng-star-inserted" style="color: rgb(0, 214, 242);"></span></div>
																<div class="titre_module">{{ $t('bibliotheque') }}</div>
																<div class="note" :class="this.app.cityscan.adresse.bibliotheques.note">{{this.app.cityscan.adresse.bibliotheques.note}}</div>
																<div class="soustitre_module">{{this.app.cityscan.adresse.bibliotheques.nb}} {{this.app.cityscan.adresse.bibliotheques.temps}}</div>
															</li>
															<div class="divider" v-if="app.modules_adresse.adresse_loisir_theatre"></div>
															<li class="unmodule_detail" v-if="app.modules_adresse.adresse_loisir_theatre">
																<div class="icone_module"><span class="cs-tile-icon cs-icon-thematic icon-1351-02 ng-star-inserted" style="color: rgb(0, 179, 211);"></span></div>
																<div class="titre_module">{{ $t('theâtre_&_opera') }}</div>
																<div class="note" :class="this.app.cityscan.adresse.theatre.note">{{this.app.cityscan.adresse.theatre.note}}</div>
																<div class="soustitre_module">{{this.app.cityscan.adresse.theatre.nb}} {{this.app.cityscan.adresse.theatre.temps}}</div>
															</li>
															<div class="divider" v-if="app.modules_adresse.adresse_loisir_musee"></div>
															<li class="unmodule_detail" v-if="app.modules_adresse.adresse_loisir_musee">
																<div class="icone_module"><span class="cs-tile-icon cs-icon-thematic icon-1352-02 ng-star-inserted" style="color: rgb(0, 143, 179);"></span></div>
																<div class="titre_module">{{ $t('musee') }}</div>
																<div class="note" :class="this.app.cityscan.adresse.musees.note">{{this.app.cityscan.adresse.musees.note}}</div>
																<div class="soustitre_module">{{this.app.cityscan.adresse.musees.nb}} {{this.app.cityscan.adresse.musees.temps}}</div>
															</li>
															<div class="divider" v-if="app.modules_adresse.adresse_loisir_cinema"></div>
															<li class="unmodule_detail" v-if="app.modules_adresse.adresse_loisir_cinema">
																<div class="icone_module"><span class="cs-tile-icon cs-icon-thematic icon-1353-02 ng-star-inserted" style="color: rgb(18, 4, 208);"></span></div>
																<div class="titre_module">{{ $t('cinema') }}</div>
																<div class="note" :class="this.app.cityscan.adresse.cinema.note">{{this.app.cityscan.adresse.cinema.note}}</div>
																<div class="soustitre_module">{{this.app.cityscan.adresse.cinema.nb}} {{this.app.cityscan.adresse.cinema.temps}}</div>
															</li>
															<div class="divider" v-if="app.modules_adresse.adresse_loisir_bar"></div>
															<li class="unmodule_detail" v-if="app.modules_adresse.adresse_loisir_bar">
																<div class="icone_module"><span class="cs-tile-icon cs-icon-thematic icon-1341-02 ng-star-inserted" style="color: rgb(14, 46, 215);"></span></div>
																<div class="titre_module">{{ $t('bar_et_cafe') }}</div>
																<div class="note" :class="this.app.cityscan.adresse.bar.note">{{this.app.cityscan.adresse.bar.note}}</div>
																<div class="soustitre_module">{{this.app.cityscan.adresse.bar.nb}} {{this.app.cityscan.adresse.bar.temps}}</div>
															</li>
															<div class="divider" v-if="app.modules_adresse.adresse_loisir_restaurant"></div>
															<li class="unmodule_detail" v-if="app.modules_adresse.adresse_loisir_restaurant">
																<div class="icone_module"><span class="cs-tile-icon cs-icon-thematic icon-1340-02 ng-star-inserted" style="color: rgb(13, 67, 218);"></span></div>
																<div class="titre_module">{{ $t('restaurant') }}</div>
																<div class="note" :class="this.app.cityscan.adresse.restaurant.note">{{this.app.cityscan.adresse.restaurant.note}}</div>
																<div class="soustitre_module">{{this.app.cityscan.adresse.restaurant.nb}} {{this.app.cityscan.adresse.restaurant.temps}}</div>
															</li>
															<div class="divider" v-if="app.modules_adresse.adresse_loisir_discotheque"></div>
															<li class="unmodule_detail" v-if="app.modules_adresse.adresse_loisir_discotheque">
																<div class="icone_module"><span class="cs-tile-icon cs-icon-thematic icon-1343-02 ng-star-inserted" style="color: rgb(11, 88, 222);"></span></div>
																<div class="titre_module">{{ $t('discotheque') }}</div>
																<div class="note" :class="this.app.cityscan.adresse.discotheque.note">{{this.app.cityscan.adresse.discotheque.note}}</div>
																<div class="soustitre_module">{{this.app.cityscan.adresse.discotheque.nb}} {{this.app.cityscan.adresse.discotheque.temps}}</div>
															</li>
															<div class="divider" v-if="app.modules_adresse.adresse_loisir_jardin"></div>
															<li class="unmodule_detail" v-if="app.modules_adresse.adresse_loisir_jardin">
																<div class="icone_module"><span class="cs-tile-icon cs-icon-thematic icon-1342-02 ng-star-inserted" style="color: rgb(7, 130, 228);"></span></div>
																<div class="titre_module">{{ $t('parc_et_jardin') }}</div>
																<div class="note" :class="this.app.cityscan.adresse.jardin.note">{{this.app.cityscan.adresse.jardin.note}}</div>
																<div class="soustitre_module">{{this.app.cityscan.adresse.jardin.nb}} {{this.app.cityscan.adresse.jardin.temps}}</div>
															</li>
															<div class="divider" v-if="app.modules_adresse.adresse_loisir_sport_collectif"></div>
															<li class="unmodule_detail" v-if="app.modules_adresse.adresse_loisir_sport_collectif">
																<div class="icone_module"><span class="cs-tile-icon cs-icon-thematic icon-1360-02 ng-star-inserted" style="color: rgb(0, 108, 148);"></span></div>
																<div class="titre_module">{{ $t('sport_collectif') }}</div>
																<div class="note" :class="this.app.cityscan.adresse.sport_collectif.note">{{this.app.cityscan.adresse.sport_collectif.note}}</div>
																<div class="soustitre_module">{{this.app.cityscan.adresse.sport_collectif.nb}} {{this.app.cityscan.adresse.sport_collectif.temps}}</div>
															</li>
															<div class="divider" v-if="app.modules_adresse.adresse_loisir_sport_individuel"></div>
															<li class="unmodule_detail" v-if="app.modules_adresse.adresse_loisir_sport_individuel">
																<div class="icone_module"><span class="cs-tile-icon cs-icon-thematic icon-1361-02 ng-star-inserted" style="color: rgb(0, 72, 117);"></span></div>
																<div class="titre_module">{{ $t('sport_individuel') }}</div>
																<div class="note" :class="this.app.cityscan.adresse.sport_individuel.note">{{this.app.cityscan.adresse.sport_individuel.note}}</div>
																<div class="soustitre_module">{{this.app.cityscan.adresse.sport_individuel.nb}} {{this.app.cityscan.adresse.sport_individuel.temps}}</div>
															</li>
															<div class="divider" v-if="app.modules_adresse.adresse_loisir_hotel"></div>
															<li class="unmodule_detail" v-if="app.modules_adresse.adresse_loisir_hotel">
																<div class="icone_module"><span class="cs-tile-icon cs-icon-thematic icon-1371-02 ng-star-inserted" style="color: rgb(67, 207, 239);"></span></div>
																<div class="titre_module">{{ $t('hôtel') }}</div>
																<div class="note" :class="this.app.cityscan.adresse.hotel.note">{{this.app.cityscan.adresse.hotel.note}}</div>
																<div class="soustitre_module">{{this.app.cityscan.adresse.hotel.nb}} {{this.app.cityscan.adresse.hotel.temps}}</div>
															</li>
															<div class="divider" v-if="app.modules_adresse.adresse_loisir_hebergement"></div>
															<li class="unmodule_detail" v-if="app.modules_adresse.adresse_loisir_hebergement">
																<div class="icone_module"><span class="cs-tile-icon cs-icon-thematic icon-1373-02 ng-star-inserted" style="color: rgb(63, 194, 221);"></span></div>
																<div class="titre_module">{{ $t('autres_hebergements') }}</div>
																<div class="note" :class="this.app.cityscan.adresse.hebergement.note">{{this.app.cityscan.adresse.hebergement.note}}</div>
																<div class="soustitre_module">{{this.app.cityscan.adresse.hebergement.nb}} {{this.app.cityscan.adresse.hebergement.temps}}</div>
															</li>
														</div>
													</ul>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div v-if="app.modules_adresse.adresse_numerique" class="col company-dashboard">
								<div class="business-dashboard company-header">
									<div style="height:auto !important">
										<div class="unmodule_detail_titre">
											<div class="icone_module"><span class="cs-tile-icon cs-icon-thematic icon-15-02 ng-star-inserted" style="color: rgb(100, 22, 65);"></span></div>
											<div class="thematique">{{ $t('thematique') }}</div>
											<div class="titre_sommaire"><b>{{ $t('qualite_numerique') }}</b></div>
											<div class="note" :class="app.cityscan.adresse.note_numerique">{{app.cityscan.adresse.note_numerique}}</div>
										</div>
										<div class="">
											<div class="programs-list-content" v-bar>
												<div class="content" ref="scrollable_programs">
													<ul class="accordion-menu">
														<div class="single-accordion">
															<li class="unmodule_detail" v-if="app.modules_adresse.adresse_haut_debit">
																<div class="icone_module" :class="getimagenote(this.app.cityscan.adresse.haut_debit.note)" style="width:32px">&nbsp;</div>
																<div class="titre_module">{{ $t('internet_haut_debit') }}</div>
																<div class="note" :class="this.app.cityscan.adresse.haut_debit.note">{{this.app.cityscan.adresse.haut_debit.note}}</div>
																<div class="soustitre_module">{{getlibellenote(this.app.cityscan.adresse.haut_debit.note, 'haut_debit')}}</div>
															</li>
															<div class="divider" v-if="app.modules_adresse.adresse_fibre"></div>
															<li class="unmodule_detail" v-if="app.modules_adresse.adresse_fibre">
																<div class="icone_module" :class="getimagenote(this.app.cityscan.adresse.fibre.note)" style="width:32px">&nbsp;</div>
																<div class="titre_module">{{ $t('fibre') }}</div>
																<div class="note" :class="this.app.cityscan.adresse.fibre.note">{{this.app.cityscan.adresse.fibre.note}}</div>
																<div class="soustitre_module">{{getlibellenote(this.app.cityscan.adresse.fibre.note, 'fibre')}}</div>
															</li>
															<div class="divider" v-if="app.modules_adresse.adresse_mobile_4G"></div>
															<li class="unmodule_detail" v-if="app.modules_adresse.adresse_mobile_4G">
																<div class="icone_module" :class="getimagenote(this.app.cityscan.adresse.mobile_4G.note)" style="width:32px">&nbsp;</div>
																<div class="titre_module">{{ $t('mobile_4g') }}</div>
																<div class="note" :class="this.app.cityscan.adresse.mobile_4G.note">{{this.app.cityscan.adresse.mobile_4G.note}}</div>
																<div class="soustitre_module">{{getlibellenote(this.app.cityscan.adresse.mobile_4G.note, 'mobile_4G')}}</div>
															</li>
															<div class="divider" v-if="app.modules_adresse.adresse_mobile_3G"></div>
															<li class="unmodule_detail" v-if="app.modules_adresse.adresse_mobile_3G">
																<div class="icone_module" :class="getimagenote(this.app.cityscan.adresse.mobile_3G.note)" style="width:32px">&nbsp;</div>
																<div class="titre_module">{{ $t('mobile_3g') }}</div>
																<div class="note" :class="this.app.cityscan.adresse.mobile_3G.note">{{this.app.cityscan.adresse.mobile_3G.note}}</div>
																<div class="soustitre_module">{{getlibellenote(this.app.cityscan.adresse.mobile_3G.note, 'mobile_3G')}}</div>
															</li>
														</div>
													</ul>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div v-if="app.modules_adresse.adresse_services" class="col company-dashboard">
								<div class="business-dashboard company-header">
									<div style="height:auto !important">
										<div class="unmodule_detail_titre">
											<div class="icone_module"><span class="cs-tile-icon cs-icon-thematic icon-23-02 ng-star-inserted" style="color: rgb(78, 161, 50);"></span></div>
											<div class="thematique">{{ $t('thematique') }}</div>
											<div class="titre_sommaire"><b>{{ $t('services_publics_&_sante') }}</b></div>
											<div class="note" :class="app.cityscan.adresse.note_services">{{app.cityscan.adresse.note_services}}</div>
										</div>
										<div class="">
											<div class="programs-list-content" v-bar>
												<div class="content" ref="scrollable_programs">
													<ul class="accordion-menu">
														<div class="single-accordion">
															<li class="unmodule_detail" v-if="app.modules_adresse.adresse_service_commissariat">
																<div class="icone_module"><span class="cs-tile-icon cs-icon-thematic icon-1300-02 ng-star-inserted" style="color: rgb(174, 31, 19);"></span></div>
																<div class="titre_module">{{ $t('commissariat') }}</div>
																<div class="note" :class="this.app.cityscan.adresse.commissariat.note">{{this.app.cityscan.adresse.commissariat.note}}</div>
																<div class="soustitre_module">{{this.app.cityscan.adresse.commissariat.nb}} {{this.app.cityscan.adresse.commissariat.temps}}</div>
															</li>
															<div class="divider" v-if="app.modules_adresse.adresse_service_gendarmerie"></div>
															<li class="unmodule_detail" v-if="app.modules_adresse.adresse_service_gendarmerie">
																<div class="icone_module"><span class="cs-tile-icon cs-icon-thematic icon-1301-02 ng-star-inserted" style="color: rgb(220, 43, 29);"></span></div>
																<div class="titre_module">{{ $t('gendarmerie') }}</div>
																<div class="note" :class="this.app.cityscan.adresse.gendarmerie.note">{{this.app.cityscan.adresse.gendarmerie.note}}</div>
																<div class="soustitre_module">{{this.app.cityscan.adresse.gendarmerie.nb}} {{this.app.cityscan.adresse.gendarmerie.temps}}</div>
															</li>
															<div class="divider" v-if="app.modules_adresse.adresse_service_pompier"></div>
															<li class="unmodule_detail" v-if="app.modules_adresse.adresse_service_pompier">
																<div class="icone_module"><span class="cs-tile-icon cs-icon-thematic icon-1310-02 ng-star-inserted" style="color: rgb(246, 107, 99);"></span></div>
																<div class="titre_module">{{ $t('pompiers') }}</div>
																<div class="note" :class="this.app.cityscan.adresse.pompiers.note">{{this.app.cityscan.adresse.pompiers.note}}</div>
																<div class="soustitre_module">{{this.app.cityscan.adresse.pompiers.nb}} {{this.app.cityscan.adresse.pompiers.temps}}</div>
															</li>
															<div class="divider" v-if="app.modules_adresse.adresse_service_hopital"></div>
															<li class="unmodule_detail" v-if="app.modules_adresse.adresse_service_hopital">
																<div class="icone_module"><span class="cs-tile-icon cs-icon-thematic icon-1311-02 ng-star-inserted" style="color: rgb(246, 47, 72);"></span></div>
																<div class="titre_module">{{ $t('hôpital') }}</div>
																<div class="note" :class="this.app.cityscan.adresse.urgences.note">{{this.app.cityscan.adresse.urgences.note}}</div>
																<div class="soustitre_module">{{this.app.cityscan.adresse.urgences.nb}} {{this.app.cityscan.adresse.urgences.temps}}</div>
															</li>
															<div class="divider" v-if="app.modules_adresse.adresse_service_laboratoire"></div>
															<li class="unmodule_detail" v-if="app.modules_adresse.adresse_service_laboratoire">
																<div class="icone_module"><span class="cs-tile-icon cs-icon-thematic icon-1322-02 ng-star-inserted" style="color: rgb(78, 161, 50);"></span></div>
																<div class="titre_module">{{ $t('laboratoire_medical') }}</div>
																<div class="note" :class="this.app.cityscan.adresse.laboratoires.note">{{this.app.cityscan.adresse.laboratoires.note}}</div>
																<div class="soustitre_module">{{this.app.cityscan.adresse.laboratoires.nb}} {{this.app.cityscan.adresse.laboratoires.temps}}</div>
															</li>
															<div class="divider" v-if="app.modules_adresse.adresse_service_agees"></div>
															<li class="unmodule_detail" v-if="app.modules_adresse.adresse_service_agees">
																<div class="icone_module"><span class="cs-tile-icon cs-icon-thematic icon-1372-02 ng-star-inserted" style="color: rgb(64, 140, 38);"></span></div>
																<div class="titre_module">{{ $t('services_aux_personnes_âgees') }}</div>
																<div class="note" :class="this.app.cityscan.adresse.personnes_agees.note">{{this.app.cityscan.adresse.personnes_agees.note}}</div>
																<div class="soustitre_module">{{this.app.cityscan.adresse.personnes_agees.nb}} {{this.app.cityscan.adresse.personnes_agees.temps}}</div>
															</li>
															<div class="divider" v-if="app.modules_adresse.adresse_service_pharmacie"></div>
															<li class="unmodule_detail" v-if="app.modules_adresse.adresse_service_pharmacie">
																<div class="icone_module"><span class="cs-tile-icon cs-icon-thematic icon-1321-02 ng-star-inserted" style="color: rgb(22, 76, 0);"></span></div>
																<div class="titre_module">{{ $t('pharmacie') }}</div>
																<div class="note" :class="this.app.cityscan.adresse.pharmacie.note">{{this.app.cityscan.adresse.pharmacie.note}}</div>
																<div class="soustitre_module">{{this.app.cityscan.adresse.pharmacie.nb}} {{this.app.cityscan.adresse.pharmacie.temps}}</div>
															</li>
															<div class="divider" v-if="app.modules_adresse.adresse_service_medecin"></div>
															<li class="unmodule_detail" v-if="app.modules_adresse.adresse_service_medecin">
																<div class="icone_module"><span class="cs-tile-icon cs-icon-thematic icon-1320-02 ng-star-inserted" style="color: rgb(36, 97, 13);"></span></div>
																<div class="titre_module">{{ $t('medecin_generaliste') }}</div>
																<div class="note" :class="this.app.cityscan.adresse.medecin.note">{{this.app.cityscan.adresse.medecin.note}}</div>
																<div class="soustitre_module">{{this.app.cityscan.adresse.medecin.nb}} {{this.app.cityscan.adresse.medecin.temps}}</div>
															</li>
															<div class="divider" v-if="app.modules_adresse.adresse_service_specialiste"></div>
															<li class="unmodule_detail" v-if="app.modules_adresse.adresse_service_specialiste">
																<div class="icone_module"><span class="cs-tile-icon cs-icon-thematic icon-1323-02 ng-star-inserted" style="color: rgb(22, 76, 0);"></span></div>
																<div class="titre_module">{{ $t('medecin_specialiste') }}</div>
																<div class="note" :class="this.app.cityscan.adresse.specialistes.note">{{this.app.cityscan.adresse.specialistes.note}}</div>
																<div class="soustitre_module">{{this.app.cityscan.adresse.specialistes.nb}} {{this.app.cityscan.adresse.specialistes.temps}}</div>
															</li>
															<div class="divider" v-if="app.modules_adresse.adresse_service_poste"></div>
															<li class="unmodule_detail" v-if="app.modules_adresse.adresse_service_poste">
																<div class="icone_module"><span class="cs-tile-icon cs-icon-thematic icon-1380-02 ng-star-inserted" style="color: rgb(255, 170, 34);"></span></div>
																<div class="titre_module">{{ $t('poste') }}</div>
																<div class="note" :class="this.app.cityscan.adresse.poste.note">{{this.app.cityscan.adresse.poste.note}}</div>
																<div class="soustitre_module">{{this.app.cityscan.adresse.poste.nb}} {{this.app.cityscan.adresse.poste.temps}}</div>
															</li>
															<div class="divider" v-if="app.modules_adresse.adresse_service_recyclage"></div>
															<li class="unmodule_detail" v-if="app.modules_adresse.adresse_service_recyclage">
																<div class="icone_module"><span class="cs-tile-icon cs-icon-thematic icon-2010-02 ng-star-inserted" style="color: rgb(120, 155, 114);"></span></div>
																<div class="titre_module">{{ $t('recyclage') }}</div>
																<div class="note" :class="this.app.cityscan.adresse.recyclage.note">{{this.app.cityscan.adresse.recyclage.note}}</div>
																<div class="soustitre_module">{{this.app.cityscan.adresse.recyclage.nb}} {{this.app.cityscan.adresse.recyclage.temps}}</div>
															</li>
															<div class="divider" v-if="app.modules_adresse.adresse_service_dechet"></div>
															<li class="unmodule_detail" v-if="app.modules_adresse.adresse_service_dechet">
																<div class="icone_module"><span class="cs-tile-icon cs-icon-thematic icon-2011-02 ng-star-inserted" style="color: rgb(42, 53, 47);"></span></div>
																<div class="titre_module">{{ $t('dechets_menagers') }}</div>
																<div class="note" :class="this.app.cityscan.adresse.dechets.note">{{this.app.cityscan.adresse.dechets.note}}</div>
																<div class="soustitre_module">{{this.app.cityscan.adresse.dechets.nb}} {{this.app.cityscan.adresse.dechets.temps}}</div>
															</li>
														</div>
													</ul>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Fieldset>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import { ObserveVisibility  } from 'vue-observe-visibility'
import Fieldset from 'primevue/fieldset';

export default {
	name: 'TabAdresse',
	data: function() {
		return {
			activeSection2 : '',
			tab: 'tvap',
			unan: 0,
			deuxans: 0,
			fu_visible: false,
			detail: '',
		}
	},

	computed: Object.assign(mapGetters([ 'app', 'report' ]), {
		
	}),

	directives: {
		'observe-visibility': ObserveVisibility
	},

	methods: {
		getlibellenote: function(note, theme) {
			switch (theme) {
				case 'fibre':
					switch (note) {
						case 'A': return 'Bâtiment fibré'
						case 'B': return 'Fibre en cours de déploiement'
						default: return 'Non équipé'
					}
				default:
					switch (note) {
						case 'A': return 'Très favorable'
						case 'B': return 'Favorable'
						case 'C': return 'Assez favorable'
						case 'D': return 'Potentiellement défavorable'
						case 'E': return 'Potentiellement très défavorable'
						default: return ''
					}
			}
		},

		getimagenote: function(note) {
			switch (note) {
				case 'E': return 'level_one'
				case 'D': return 'level_two'
				case 'C': return 'level_three'
				case 'B': return 'level_four'
				case 'A': return 'level_five'
				default: return ''
			}
		},

		onVisibilityChange(isVisible) {			
			if (isVisible) {
				this.activeSection2 = 'taboptim'
			} else {
				if (this.activeSection2 == 'taboptim') {
					this.activeSection2 = ''
				}
			}
		},
	},

	mounted: function() {
	},

	components: { Fieldset }
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/app'

.tab-content
	padding 24px 32px
	.part
		margin 0 0 40px 0
		.part-title
			margin 0 0 8px 0
			line-height 32px
			font-weight 600
			text-transform uppercase
			font-size 16px
.tabs2
	position fixed
	top 61px
	left 0px
	left 60px
	color secondary
	background white
	border-bottom 1px solid hsl(0deg 0% 86%)
	border-left 1px solid hsl(0deg 0% 86%)
	&:after
		content ''
		display block
		clear both
	.tab
		float left
		margin 0 0px 0 0
		height 60px
		width 180px
		display flex
		align-items center
		justify-content center
		flex-direction column
		opacity 1
		font-size 12px
		font-weight 400
		border-bottom 4px solid transparent
		text-align center
		text-transform uppercase
		cursor pointer
		color #a1a5b8
		&:hover
			color #666
		&.active
			opacity 2
			border-bottom-color var(--primary)
			color var(--primary)
		.icon
			display block
			margin 0 0 2px 0

.programs-list
	height 100%

.adrnote
	height 60px

.single-accordion
	width 100% !important
	min-width PaneLeftSize
.unmodule_detail
	cursor auto !important
	height 70px
	width 100% !important
	border none !important

.unmodule_detail_titre
	width 100% !important

.titre_sommaire
	width 240px !important
	text-overflow ellipsis
	overflow hidden
	white-space nowrap
.rol
	&.col
		margin-bottom 20px
.business-dashboard
	width 390px !important

	
.dashboard-tile {
	width: 20%;
	min-width: 180px;
	padding: 20px;
	background-color: var(--white);
	border-radius: 6px;
	border: 1px solid var(--fade-grey-dark-3);
	transition: all 0.3s;
	font-family: var(--font), sans-serif;
	margin-right: 10px;
}
.dashboard-tile2 {
	padding: 10px;
	background-color: var(--white);
	border-radius: 6px;
	border: 1px solid var(--fade-grey-dark-3);
	transition: all 0.3s;
	font-family: var(--font), sans-serif;
}
.dashboard-tile .tile-head {
	display: flex;
	align-items: center;
	justify-content: space-between;

}
.dashboard-tile .tile-body {
	font-size: 2rem;
	padding: 4px 0 8px;
	text-align: center
}
.dashboard-tile .tile-foot span:first-child {
	font-weight: 500;
}

.text-h-green {
	color: var(--green);
	font-size: 24px;
}

.text-h-red {
	color: var(--red);
	font-size: 24px;
}

.text-h-p {
	color: #6c21f9;
	font-size: 24px;
}

.dashboard-tile .tile-foot span:nth-child(2) {
	color: var(--light-text);
	font-size: 0.9rem;
}

.is-4 {
	flex: none;
	width: 25%;
	padding: 0.75rem;
}
.is-2 {
	width: 50%;
}

.le {
	color: var(--light-text);
	font-size: 0.9rem;
	text-align: right;
	position: absolute;
	right: 0px;
}


.columns {
	margin-left: -0.75rem;
	margin-right: -0.75rem;
	margin-top: -0.75rem;
}
.columns:last-child {
	margin-bottom: -0.75rem;
}
.columns:not(:last-child) {
	margin-bottom: calc(1.5rem - 0.75rem);
}
.columns.is-centered {
	justify-content: center;
}
.columns.is-gapless {
	margin-left: 0;
	margin-right: 0;
	margin-top: 0;
}
.columns.is-gapless > .column {
	margin: 0;
	padding: 0 !important;
}
.columns.is-gapless:not(:last-child) {
	margin-bottom: 1.5rem;
}
.columns.is-gapless:last-child {
	margin-bottom: 0;
}
.columns.is-mobile {
	display: flex;
}
.columns.is-multiline {
	flex-wrap: wrap;
}
.columns.is-vcentered {
	align-items: center;
}
.company-dashboard .company-header {
	display: flex;
	padding: 20px;
	background: var(--white);
	border: 1px solid var(--fade-grey-dark-3);
	border-radius: 4px;
	margin-bottom: 0.2rem;
}
.company-dashboard .company-header .header-item {
	width: 50%;
	border-right: 1px solid var(--fade-grey-dark-3);
}
.company-dashboard .company-header .header-item:last-child {
	border-right: none;
}
.company-dashboard .company-header .header-item .item-inner {
	text-align: center;
}
.company-dashboard .company-header .header-item .item-inner .lnil, .company-dashboard .company-header .header-item .item-inner .lnir {
	font-size: 1.8rem;
	margin-bottom: 6px;
	color: var(--primary);
}

.lnil {
	display: inline-block;
	font: normal normal normal 1em/1 'LineIconsPro Light';
	speak: none;
	text-transform: none;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
.company-dashboard .company-header .header-item .item-inner span {
	display: block;
	font-family: var(--font), sans-serif;
	font-weight: 600;
	font-size: 1.6rem;
	color: var(--dark-text);
}
.company-dashboard .company-header .header-item .item-inner p {
	font-family: var(--font-alt), sans-serif;
	font-size: 0.85rem;
}

.r {
	font-family: var(--font-alt), sans-serif !important;
	font-size: 0.95rem !important;
	font-family: var(--font), sans-serif !important;
	font-weight: 300 !important;
	color: var(--light-text) !important;
	display: initial !important;
}


.company-dashboard .dashboard-card.is-base-chart {
	padding: 0;
	display: flex;
	flex-direction: column;
}

.company-dashboard .dashboard-card {
	flex: 1;
	display: inline-block;
	width: 100%;
	padding: 20px;
	background-color: var(--white);
	border-radius: 6px;
	border: 1px solid var(--fade-grey-dark-3);
	transition: all 0.3s;
	height: 100%;
}
.company-dashboard .dashboard-card.is-base-chart .content-box {
	padding: 30px;
}
.company-dashboard .dashboard-card.is-base-chart .chart-container {
	margin-top: auto;
}
.company-dashboard .dashboard-card.is-base-chart .content-box .revenue-stats {
	display: flex;
	padding-bottom: 20px;
	border-bottom: 1px solid var(--fade-grey-dark-3);
}
.company-dashboard .dashboard-card.is-base-chart .content-box .revenue-stats .revenue-stat {
	margin-right: 30px;
	font-family: var(--font), sans-serif;
}
.company-dashboard .dashboard-card.is-base-chart .content-box .revenue-stats .revenue-stat span:first-child {
	text-transform: uppercase;
	font-family: var(--font-alt), sans-serif;
	font-size: 0.75rem;
	color: var(--light-text);
}

.company-dashboard .dashboard-card.is-base-chart .content-box .revenue-stats .revenue-stat span {
	display: block;
}
.company-dashboard .dashboard-card.is-base-chart .content-box .revenue-stats .revenue-stat span.current {
	color: var(--primary);
}

.company-dashboard .dashboard-card.is-base-chart .content-box .revenue-stats .revenue-stat span:nth-child(2) {
	color: var(--dark-text);
	font-size: 1.6rem;
	font-weight: 600;
}
.revenue-stat {
	width: 33%
}
.chart-container {
}
.dark-inverted2 {
	font-size: 1.4rem !important;
}			

.programs-list-content{
	background-color: white !important;
	width: PaneLeftSize !important;
}
	
</style>
