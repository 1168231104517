<template>
	<div class="stat-box">
		<div class="stat-box-header">
			<div class="flex align-items-center surface-card">
				<div style="width:48px;height:48px;border-radius:10px" class="bg-yellow-100 inline-flex align-items-center justify-content-center mr-3">
					<i class="pi pi-bolt text-yellow-500 text-3xl"></i>
				</div>
				<div>
					<span class="text-900 text-xl font-medium mb-2">{{ $t('rythme') }}</span>
					<p class="mt-1 mb-0 text-600 font-medium text-sm">{{ $t('de_vente') }} *
						<tool-source url='' :txt="'Adequation au ' + app.stats.prix_avec_stat.intitule_colonne[0]"/>
					</p>
				</div>
				<div class="ml-auto">
					<div class="speeddial-tooltip-demo">
						<SpeedDial className="speeddial-left" :model="items" direction="down" showIcon="pi pi-bars" hideIcon="pi pi-times" buttonClassName="p-button-outlined"/>
					</div>
				</div>
			</div>
			<TabPeriode/>
		</div>
		<div class="stat-box-content">
			<div class="table">
				<table id='datatable_rythme'>
					<tbody v-if="app.tab == 'trimestres'" class="table">
						<tr>
							<th id="ry_11">{{app.livedata.rythme_vente_2.titre}}</th>
							<th id="ry_12">{{app.livedata.rythme_vente_2.intitule_colonne[0]}}</th>
							<th id="ry_13">{{app.livedata.rythme_vente_2.intitule_colonne[1]}}</th>
							<th id="ry_14">{{app.livedata.rythme_vente_2.intitule_colonne[2]}}</th>
						</tr>
						<tr>
							<th id="ry_21">{{app.livedata.rythme_vente_2.te.libelle}}</th>
							<td>{{percent(app.livedata.rythme_vente_2.te.tableau[0])}}</td>
							<td>{{percent(app.livedata.rythme_vente_2.te.tableau[1])}}</td>
							<td>{{percent(app.livedata.rythme_vente_2.te.tableau[2])}}</td>
						</tr>
						<tr>
							<th id="ry_31">{{app.livedata.rythme_vente_2.duree_com.libelle}}</th>
							<td>{{enmois(app.livedata.rythme_vente_2.duree_com.tableau[0])}}</td>
							<td>{{enmois(app.livedata.rythme_vente_2.duree_com.tableau[1])}}</td>
							<td>{{enmois(app.livedata.rythme_vente_2.duree_com.tableau[2])}}</td>
						</tr>
					</tbody>
					<tbody v-if="app.tab == 'mois'" class="table">
						<tr>
							<th id="ry_41">{{app.livedata.rythme_vente.titre}}</th>
							<th id="ry_42">{{app.livedata.rythme_vente.intitule_colonne[0]}}</th>
							<th id="ry_43">{{app.livedata.rythme_vente.intitule_colonne[1]}}</th>
							<th id="ry_44">{{app.livedata.rythme_vente.intitule_colonne[2]}}</th>
							<th id="ry_45">{{app.livedata.rythme_vente.intitule_colonne[3]}}</th>
							<th id="ry_46">{{app.livedata.rythme_vente.intitule_colonne[4]}}</th>
							<th id="ry_47">{{app.livedata.rythme_vente.intitule_colonne[5]}}</th>
							<th id="ry_48">{{app.livedata.rythme_vente.intitule_colonne[6]}}</th>
							<th id="ry_49">{{app.livedata.rythme_vente.intitule_colonne[7]}}</th>
						</tr>
						<tr>
							<th id="ry_51">{{app.livedata.rythme_vente.te.libelle}}</th>
							<td>{{percent(app.livedata.rythme_vente.te.tableau[0])}}</td>
							<td>{{percent(app.livedata.rythme_vente.te.tableau[1])}}</td>
							<td>{{percent(app.livedata.rythme_vente.te.tableau[2])}}</td>
							<td>{{percent(app.livedata.rythme_vente.te.tableau[3])}}</td>
							<td>{{percent(app.livedata.rythme_vente.te.tableau[4])}}</td>
							<td>{{percent(app.livedata.rythme_vente.te.tableau[5])}}</td>
							<td>{{percent(app.livedata.rythme_vente.te.tableau[6])}}</td>
							<td>{{percent(app.livedata.rythme_vente.te.tableau[7])}}</td>
						</tr>
						<tr>
							<th id="ry_61">{{app.livedata.rythme_vente.duree_com.libelle}}</th>
							<td>{{enmois(app.livedata.rythme_vente.duree_com.tableau[0])}}</td>
							<td>{{enmois(app.livedata.rythme_vente.duree_com.tableau[1])}}</td>
							<td>{{enmois(app.livedata.rythme_vente.duree_com.tableau[2])}}</td>
							<td>{{enmois(app.livedata.rythme_vente.duree_com.tableau[3])}}</td>
							<td>{{enmois(app.livedata.rythme_vente.duree_com.tableau[4])}}</td>
							<td>{{enmois(app.livedata.rythme_vente.duree_com.tableau[5])}}</td>
							<td>{{enmois(app.livedata.rythme_vente.duree_com.tableau[6])}}</td>
							<td>{{enmois(app.livedata.rythme_vente.duree_com.tableau[7])}}</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import SpeedDial from 'primevue/speeddial';
import TabPeriode from '../TabPeriode.vue';
import ToolSource from '../../../../global/ToolSource';

export default {
	name: 'ArrayRythme',

	data: function() {
		return {
			tab: 'mois',
			items: [
				{label: 'Data', icon: 'pi pi-fw pi-download', command:() => {this.$store.dispatch('ExportToXls',{id: 'datatable_rythme', name: 'Rythme de vente'});} },
				{label: 'Image', icon: 'pi pi-fw pi-image', command:() => {this.$store.dispatch('ExportToPng',{id: 'datatable_rythme'});} },
			]
		}
	},

	computed: Object.assign(mapGetters([ 'app' ]), {
		
	}),

    methods: {
		ChangeTab: function(periodicite) {
			this.$store.dispatch('change_periodicite', { periodicite: periodicite })
		},

		percent: function(str) {
			if (str) {
				let n = parseFloat(str)
				if (n)
					return Math.round(n) + ' %'
			}
            return ""
        },

		enmois: function(str) {
			if (str) {
				let n = parseFloat(str)
				if (n)
					return Math.round(n) + ' mois'
			}
            return ""
        }
    },
	
	components: { ToolSource, SpeedDial, TabPeriode }

}
</script>

<style lang="stylus" scoped>
@import '../../../../../assets/css/app'




</style>
