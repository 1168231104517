<template>
	<div v-if="editor" class="container">
		
	<div class="tiptap">
		<div class="control-group">

      <div class="button-group" style="margin-bottom: 5px;">
        <Button class="b" icon="pi pi-undo"  @click="editor.chain().focus().undo().run()" :disabled="!editor.can().chain().focus().undo().run()"/>
        <Button class="b" icon="pi pi-refresh"  @click="editor.chain().focus().redo().run()" :disabled="!editor.can().chain().focus().redo().run()"/>
        <div style="border-right: 1px solid #CCCCCC;margin-right:2px">&nbsp;</div>
        <Button class="b" icon="pi" @click="editor.chain().focus().toggleBold().run()" :disabled="!editor.can().chain().focus().toggleBold().run()" :class="{ 'is-active': editor.isActive('bold') }"><strong>B</strong></Button>
        <Button class="b" icon="pi" @click="editor.chain().focus().toggleItalic().run()" :disabled="!editor.can().chain().focus().toggleItalic().run()" :class="{ 'is-active': editor.isActive('italic') }"><span style="font-style: italic;">I</span></Button>
        <Button class="b" icon="pi" @click="editor.chain().focus().toggleStrike().run()" :disabled="!editor.can().chain().focus().toggleStrike().run()" :class="{ 'is-active': editor.isActive('strike') }"><s>S</s></Button>
        <div style="border-right: 1px solid #CCCCCC;margin-right:2px">&nbsp;</div>
        <Button class="b" icon="pi" @click="editor.chain().focus().toggleHeading({ level: 1 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }">H1</Button>
        <Button class="b" icon="pi" @click="editor.chain().focus().toggleHeading({ level: 2 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }">H2</Button>
        <Button class="b" icon="pi" @click="editor.chain().focus().toggleHeading({ level: 3 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 3 }) }">H3</Button>
        <!--
        <Button class="b" icon="pi" @click="editor.chain().focus().toggleHeading({ level: 4 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 4 }) }">H4</Button>
        -->
        <div style="border-right: 1px solid #CCCCCC;margin-right:2px">&nbsp;</div>
        <Button class="b" icon="pi pi-list"  @click="editor.chain().focus().toggleBulletList().run()" :class="{ 'is-active': editor.isActive('bulletList') }"/>
        <!--
        <Button class="b" icon="pi pi-list-check"  @click="editor.chain().focus().toggleOrderedList().run()" :class="{ 'is-active': editor.isActive('orderedList') }" />
        -->
        <div style="border-right: 1px solid #CCCCCC;margin-right:2px">&nbsp;</div>
        <Button class="b" icon="pi pi-align-left" @click="editor.chain().focus().setTextAlign('left').run()" :class="{ 'is-active': editor.isActive({ textAlign: 'left' }) }" />
        <Button class="b" icon="pi pi-align-center" @click="editor.chain().focus().setTextAlign('center').run()" :class="{ 'is-active': editor.isActive({ textAlign: 'center' }) }" />
        <Button class="b" icon="pi pi-align-right" @click="editor.chain().focus().setTextAlign('right').run()" :class="{ 'is-active': editor.isActive({ textAlign: 'right' }) }" />
        <Button class="b" icon="pi pi-align-justify" @click="editor.chain().focus().setTextAlign('justify').run()" :class="{ 'is-active': editor.isActive({ textAlign: 'justify' }) }" />
        <!--
        <div style="border-right: 1px solid #CCCCCC;margin-right:2px">&nbsp;</div>
        <Button class="b" icon="pi pi-minus" @click="editor.chain().focus().setHorizontalRule().run()" />
        <Button class="b" icon="pi pi-reply" @click="editor.chain().focus().setHardBreak().run()" />
        <Button class="b" icon="pi pi-code" @click="editor.chain().focus().toggleCodeBlock().run()" :class="{ 'is-active': editor.isActive('codeBlock') }" />
        -->
        <div style="border-right: 1px solid #CCCCCC;margin-right:2px">&nbsp;</div>
        <input
          type="color"
          @input="editor.chain().focus().setColor($event.target.value).run()"
          :value="editor.getAttributes('textStyle').color"
        >

			</div>
		</div>
		<div class="p-editor-content" style="margin-bottom: 5px;">
			<editor-content id='comment_i' :editor="editor" style="max-height: 300px;min-height: 300px;overflow: hidden;"/>
		</div>
    <!--
    <input type="file" @change="uploadImage" />
    -->
  </div>
	</div>
</template>

<script>
	import { Editor, EditorContent } from '@tiptap/vue-2'
	import { Color } from '@tiptap/extension-color'
	import ListItem from '@tiptap/extension-list-item'
	import TextStyle from '@tiptap/extension-text-style'
	import StarterKit from '@tiptap/starter-kit'
	import Table from '@tiptap/extension-table'
	import TableCell from '@tiptap/extension-table-cell'
	import TableHeader from '@tiptap/extension-table-header'
	import TableRow from '@tiptap/extension-table-row'
	import TaskItem from '@tiptap/extension-task-item'
	import TaskList from '@tiptap/extension-task-list'
	import FontFamily from '@tiptap/extension-font-family'
	import Placeholder from '@tiptap/extension-placeholder'
	import TextAlign from '@tiptap/extension-text-align'
	import Image from "@tiptap/extension-image";
  import ImageResize from 'tiptap-extension-resize-image';
  import Button from 'primevue/button';

	export default {
	components: {
		EditorContent, Button,
	},

	data() {
		return {
			editor: null,
		}
	},
	methods: {
		uploadImage(event) {
		const file = event.target.files[0];
		if (file) {
			const reader = new FileReader();
			reader.onload = (e) => {
			const base64Image = e.target.result;
			this.editor.commands.insertContent({
				type: "image",
				attrs: {
				src: base64Image,
				},
			});
			};
			reader.readAsDataURL(file);
		}
		},
	},

	mounted() {
	this.editor = new Editor({
	extensions: [
		Color.configure({ types: [TextStyle.name, ListItem.name] }),
		TextStyle.configure({ types: [ListItem.name] }),
		FontFamily,
		StarterKit,
    ImageResize,
		Table.configure({
          resizable: true,
        }),
		TextAlign.configure({
          types: ['heading', 'paragraph'],
        }),
        TableRow,
        TableHeader,
        TableCell,
		TaskList,
        TaskItem.configure({
          nested: true,
        }),
		Image.configure({
          inline: true, // Permet d'insérer des images en ligne
          allowBase64: true, // Autorise les images en base64 (utile pour les tests)
          resizable: true, // Ajoute la capacité de redimensionner (voir étape suivante)
        }),
		Placeholder.configure({
          // Use a placeholder:
          placeholder: 'Write something …',
          // Use different placeholders depending on the node type:
          // placeholder: ({ node }) => {
          //   if (node.type.name === 'heading') {
          //     return 'What’s the title?'
          //   }

          //   return 'Can you add some further context?'
          // },
        }),
	],
	content: ``,
	})
},

beforeUnmount() {
	this.editor.destroy()
},

	}
</script>



<style lang="scss">

:root {
	--white: #FFF;
    --black: #2E2B29;
    --black-contrast: #110F0E;
    --gray-1: rgba(61, 37, 20, .05);
    --gray-2: rgba(61, 37, 20, .08);
    --gray-3: rgba(61, 37, 20, .12);
    --gray-4: rgba(53, 38, 28, .3);
    --gray-5: rgba(28, 25, 23, .6);
    --green: #22C55E;
    --purple: #6A00F5;
    --purple-contrast: #5800CC;
    --purple-light: rgba(88, 5, 255, .05);
    --yellow-contrast: #FACC15;
    --yellow: rgba(250, 204, 21, .4);
    --yellow-light: #FFFAE5;
    --red: #FF5C33;
    --red-light: #FFEBE5;
    --shadow: 0px 12px 33px 0px rgba(0, 0, 0, .06), 0p  x 3.618px 9.949px 0px rgba(0, 0, 0, .04);
}

.container {
	display: flex;
	flex-direction: column;
	height: 300px !important;
}

.tiptap {
	/* Basic editor styles */

	.control-group {
		align-items: flex-start;
		background-color: var(--white);
		display: flex;
    flex-direction: column;
		gap: 0.5rem;
		//padding: 1.5rem;
	}

	.button-group {
    background-color: #f1f5f9;
    border-radius: 5px;
    padding: 5px;
		display: flex;
		flex-wrap: wrap;
		gap: .25rem;
	}

:first-child {
    margin-top: 0;
  }

  /* List styles */
  ul,
  ol {
    padding: 0 1rem;
    margin: 1.25rem 1rem 1.25rem 0.4rem;

    li p {
      margin-top: 0.25em;
      margin-bottom: 0.25em;
    }
  }

  /* Heading styles */
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.1;
    margin-top: 2.5rem;
    text-wrap: pretty;
  }

  h1,
  h2 {
    margin-top: 3.5rem;
    margin-bottom: 1.5rem;
  }

  h1 {
    font-size: 1.4rem;
  }

  h2 {
    font-size: 1.2rem;
  }

  h3 {
    font-size: 1.1rem;
  }

  h4,
  h5,
  h6 {
    font-size: 1rem;
  }

  /* Code and preformatted text styles */
  code {
    background-color: var(--purple-light);
    border-radius: 0.4rem;
    color: var(--black);
    font-size: 0.85rem;
    padding: 0.25em 0.3em;
  }

  pre {
    background: var(--black);
    border-radius: 0.5rem;
    color: var(--white);
    font-family: 'JetBrainsMono', monospace;
    margin: 1.5rem 0;
    padding: 0.75rem 1rem;

    code {
      background: none;
      color: inherit;
      font-size: 0.8rem;
      padding: 0;
    }
  }

  blockquote {
    border-left: 3px solid var(--gray-3);
    margin: 1.5rem 0;
    padding-left: 1rem;
  }

  hr {
    border: none;
    border-top: 1px solid var(--gray-2);
    margin: 2rem 0;
  }

  button, input, select, textarea {
    background: var(--gray-2);
    border-radius: .5rem;
    border: none;
    color: var(--black);
    font-family: inherit;
    font-size: .875rem;
    font-weight: 500;
    line-height: 1.15;
    margin: none;
    padding: .375rem .625rem;
    transition: all .2s cubic-bezier(.65,.05,.36,1);
}
button.is-active, input.is-active, select.is-active, textarea.is-active {
    background: var(--purple);
    color: var(--white);
}
.b {
  width: 38px;
  height: 30px;
  vertical-align: middle;
  text-align: center;
  .icon:hover {
    color: white;
  }
}
Button .icon:hover {
  color: white;
}
.ProseMirror {
  height:300px;
  border: 1px solid #CCCCCC;
  border-radius: 5px;
  padding: 10px;
}
}
</style>