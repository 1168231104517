<template>
	<transition name="pane-program">
		<div class="pane pane-program" id="rplsencours">
			<div class="content vb-content" style="display: block; overflow: hidden scroll; height: 100%; width: 100%; box-sizing: content-box; padding-right: 20px;background-color: white;" id="LocatifEx">
				<div class="pan-titre-programme" id="pan-titre-rpls">
					<div class="module_ret">
						<div class="btns fermer" :title="$t('Fermer')"  @click="expand_offre();"><icon file="fermer" :size="16" /></div>
						<div class="btns print" :title="$t('imprimer')" @click="generateImage(expanded_annonce.id);"><icon file="download" :size="24" />&nbsp;</div>
<!--						<div class="btns chatbox"><Button style="height:60px;" class="p-button-text" icon="pi pi-comments" onclick="$('#zohohc-asap-web-launcherbox').click();" /></div>-->
						<div class="btns chatbox" onclick="$('#zohohc-asap-web-launcherbox').click();"><icon file="question" :size="24" />&nbsp;</div>
						<div class="indice_confiance" :class="{ pc : true }">
							<div v-if="expanded_annonce.creationDate">
								<div v-if="expanded_annonce.creationDate.substring(0, 4)!='1970'">
									<span>{{$t('Annonce du')}} {{expanded_annonce.creationDate.substring(8,10)}}/{{expanded_annonce.creationDate.substring(5,7)}}/{{expanded_annonce.creationDate.substring(0, 4)}}</span><span v-if="expanded_annonce.creationDate.substring(8,10)/expanded_annonce.creationDate.substring(5,7)/expanded_annonce.creationDate.substring(0, 4)!=expanded_annonce.updateDate.substring(8,10)/expanded_annonce.updateDate.substring(5,7)/expanded_annonce.updateDate.substring(0, 4)"> {{$t('mise à jour au')}} {{expanded_annonce.updateDate.substring(8,10)}}/{{expanded_annonce.updateDate.substring(5,7)}}/{{expanded_annonce.updateDate.substring(0, 4)}}</span>
								</div>
							</div>
							<div v-else>
								<div v-if="expanded_annonce.updateDate">
									<span>{{$t('Annonce du')}} {{expanded_annonce.updateDate.substring(8,10)}}/{{expanded_annonce.updateDate.substring(5,7)}}/{{expanded_annonce.updateDate.substring(0, 4)}}</span>
								</div>
							</div>
							
						</div>
					</div>
					<br/>
				</div>
				<div><br><br><br><br></div>
				<div>
					<div class="program" :class="{ inactif: false }" style="height: 350px">
						<div class="program-main" style="height:285px;">
							<div v-if="expanded_annonce.features && expanded_annonce.features.visual">
								<Carousel :value="expanded_annonce.features.visual.images" :numVisible="1" >
									<template #item="slotProps">
										<div class="image">
											<img alt="Annonce" :src="slotProps.data" style="width:100%;height:100%;" />
										</div>
									</template>
								</Carousel>
							</div>
						</div>
					</div>
					<div class="program-detail">
						<div class="program-detail-content" style="padding-top: 8px !important;">
							<div class="program-detail-info" v-if="expanded_annonce.features">
								<div class="program-maj" v-if="expanded_annonce.dateindex">
								</div>
								<ul class="list-none p-2 m-0">
									<li style="background-color: #f5f5f5;margin-top: 10px;margin-bottom:10px;">
										<ul class="list-none p-0 m-0 overflow-hidden">
											<li v-if="expanded_annonce.features && expanded_annonce.features.descriptive && expanded_annonce.features.descriptive.description">
												<a v-ripple class="flex align-items-center cursor-default p-3 hover:surface-100 border-round transition-colors transition-duration-150 p-ripple" style="font-size:1.2rem;justify-content: space-between">
													<div>
														<b>
															<span v-if="expanded_annonce.type!='APARTMENT'">{{$t('MAISON')}}</span><span v-else>{{$t('APPARTEMENT')}}</span><span v-if="expanded_annonce.features.descriptive && expanded_annonce.features.descriptive.equipments && expanded_annonce.features.descriptive.equipments.FURNITURE"> {{$t('Meublé')}}</span> - {{ expanded_annonce.features.geometry.roomCount }} {{$t('piéces de')}} {{ expanded_annonce.features.geometry.surface }} {{$t('m²')}}
														</b>
													</div>
													<div>
														<b>
															{{ getPrice(expanded_annonce.marketing.priceHC) }}
														</b>
													</div>
												</a>
											</li>
											<li v-if="expanded_annonce.features.geometry.surface">
												<a v-ripple class="flex align-items-center cursor-default pl-3 pb-3 hover:surface-100 border-round transition-colors transition-duration-150 p-ripple">
													<span class="font-medium text-700">{{ getPrice(expanded_annonce.marketing.priceHC/expanded_annonce.features.geometry.surface) }}{{$t('/m²')}}</span>
												</a>
											</li>
										</ul>
									</li>
									<li style="background-color: #f5f5f5;margin-top: 10px;margin-bottom: 10px;">
										<ul class="list-none p-0 m-0 overflow-hidden">
											<li v-if="expanded_annonce.features.descriptive.description">
												<a v-ripple class="flex align-items-center cursor-default p-1 hover:surface-100 border-round transition-colors transition-duration-150 p-ripple">
													<div class="desc" v-html="formattedText"></div>
												</a>
											</li>
										</ul>
									</li>
									<li>
										<ul class="list-none p-0 m-0 overflow-hidden flex-list">
											<li v-if="expanded_annonce.features.geometry.roomCount">
												<a v-ripple class="flex align-items-center cursor-default p-3 hover:surface-100 border-round transition-colors transition-duration-150 p-ripple">
													<i class="pi pi-box mr-2 text-700"></i>
													<span class="font-medium text-700">{{expanded_annonce.features.geometry.roomCount}} {{$t('piece')}}</span><span v-if="expanded_annonce.features.geometry.roomCount>1">s</span>
												</a>
											</li>
											<li v-if="expanded_annonce.features.geometry.surface">
												<a v-ripple class="flex align-items-center cursor-default p-3 hover:surface-100 border-round transition-colors transition-duration-150 p-ripple">
													<i class="pi pi-check-square mr-2 text-700"></i>
													<span class="font-medium text-700">{{expanded_annonce.features.geometry.surface}} {{$t('m²')}}</span>
												</a>
											</li>
											<li v-if="expanded_annonce.marketing.priceHC">
												<a v-ripple class="flex align-items-center cursor-default p-3 hover:surface-100 border-round transition-colors transition-duration-150 p-ripple">
													<i class="pi pi-euro mr-2 text-700"></i>
													<span class="font-medium text-700">{{ getPrice(expanded_annonce.marketing.priceHC) }}</span>
												</a>
											</li>
											<li v-if="expanded_annonce.features.geometry && expanded_annonce.features.geometry.floors && expanded_annonce.features.geometry.floors.length && expanded_annonce.features.geometry.floors[0].level">
												<a v-ripple class="flex align-items-center cursor-default p-3 hover:surface-100 border-round transition-colors transition-duration-150 p-ripple">
													<i class="pi pi-bars mr-2 text-700"></i>
													<span class="font-medium text-700">{{expanded_annonce.features.geometry.floors[0].level}} {{$t('étage')}}</span>
												</a>
											</li>
											<li v-if="expanded_annonce.features && expanded_annonce.features.visual && expanded_annonce.features.visual.orientations">
												<a v-ripple class="flex align-items-center cursor-default p-3 hover:surface-100 border-round transition-colors transition-duration-150 p-ripple">
													<i class="pi pi-sun mr-2 text-700"></i>
													<span class="font-medium text-700">{{$t('Exposé')}} {{getLibelle(expanded_annonce.features.visual.orientations[0])}}</span>
												</a>
											</li>
											<li v-if="expanded_annonce.features.energy.heatingMode">
												<a v-ripple class="flex align-items-center cursor-default p-3 hover:surface-100 border-round transition-colors transition-duration-150 p-ripple">
													<i class="pi pi-bolt mr-2 text-700"></i>
													<span class="font-medium text-700">{{ getLibelle(expanded_annonce.features.energy.heatingMode)}}</span>
												</a>
											</li>
											<li v-if="expanded_annonce.features.geometry.areacount && expanded_annonce.features.geometry.areacount.PARKING">
												<a v-ripple class="flex align-items-center cursor-default p-3 hover:surface-100 border-round transition-colors transition-duration-150 p-ripple">
													<i class="pi pi-car mr-2 text-700"></i>
													<span class="font-medium text-700">{{expanded_annonce.features.geometry.areacount.PARKING}} {{$t('stationnement')}}</span><span v-if="expanded_annonce.features.geometry.areacount.PARKING>1">s</span>
												</a>
											</li>
											<li v-if="expanded_annonce.features.additionalFeatures[0].features.descriptive.equipments.ELEVATOR">
												<a v-ripple class="flex align-items-center cursor-default p-3 hover:surface-100 border-round transition-colors transition-duration-150 p-ripple">
													<i class="pi pi-arrows-v mr-2 text-700"></i>
													<span class="font-medium text-700">{{$t('Ascenseur')}}</span>
												</a>
											</li>
											<li v-if="expanded_annonce.features.geometry.areacount && expanded_annonce.features.geometry.areacount.TERRACE>0">
												<a v-ripple class="flex align-items-center cursor-default p-3 hover:surface-100 border-round transition-colors transition-duration-150 p-ripple">
													<i class="pi pi-window-maximize mr-2 text-700"></i>
													<span class="font-medium text-700">{{$t('Terrasse')}}</span>
												</a>
											</li>
										</ul>
									</li>
								</ul>

								<div v-if="expanded_annonce.features.energy.greenhouseGasConsumptionLetter || expanded_annonce.features.energy.consumptionLetter" style="margin-bottom: 10px;display:flex;justify-content: space-around;" class="ng-star-inserted">
									<div v-if="expanded_annonce.features.energy.consumptionLetter && expanded_annonce.features.energy.consumptionLetter!='NS'" _ngcontent-laq-c321="" fxlayout="row" fxlayout.lt-md="column" style="flex-direction: row; box-sizing: border-box; display: flex;" class="ng-star-inserted">
										<div _ngcontent-laq-c321="" fxlayout="column" fxflex="50" style="flex-direction: column; box-sizing: border-box; display: flex; flex: 1 1 100%; max-width: 50%;" class="ng-star-inserted">
											<div class="app-property-energy-indicator" style="text-align: center;" _ngcontent-laq-c321="" _nghost-laq-c320="">
												<h3 _ngcontent-laq-c320="" class="ng-star-inserted">{{ $t('diagnostic_de_performance_energetique') }}</h3>
												<div _ngcontent-laq-c320="" class="value ng-star-inserted">
													<div _ngcontent-laq-c320="" class="tile ng-star-inserted" :class="{selected: expanded_annonce.features.energy.consumptionLetter=='A'}" style="background-color: rgb(50, 153, 50);">
														<p _ngcontent-laq-c320="">A</p>
														<div v-if="expanded_annonce.features.energy.consumptionLetter=='A'" _ngcontent-laq-c320="" fxlayout="row" fxlayoutgap="5px" class="label ng-star-inserted" style="flex-direction: row; box-sizing: border-box; display: flex;">
															<span _ngcontent-laq-c320="" style="margin-right: 5px;" class="ng-star-inserted">{{expanded_annonce.features.energy.consumption}}</span>
															<span _ngcontent-laq-c320="" class="ng-star-inserted spanlib"> {{ $t('kwhep/m².an') }}</span>
														</div>
													</div>
													<div _ngcontent-laq-c320="" class="tile ng-star-inserted" :class="{selected: expanded_annonce.features.energy.consumptionLetter=='B'}" style="background-color: rgb(51, 204, 51);">
														<p _ngcontent-laq-c320="">B</p>
														<div v-if="expanded_annonce.features.energy.consumptionLetter=='B'" _ngcontent-laq-c320="" fxlayout="row" fxlayoutgap="5px" class="label ng-star-inserted" style="flex-direction: row; box-sizing: border-box; display: flex;">
															<span _ngcontent-laq-c320="" style="margin-right: 5px;" class="ng-star-inserted">{{expanded_annonce.features.energy.consumption}}</span>
															<span _ngcontent-laq-c320="" class="ng-star-inserted spanlib"> {{ $t('kwhep/m².an') }}</span>
														</div>
													</div>
													<div _ngcontent-laq-c320="" class="tile ng-star-inserted" :class="{selected: expanded_annonce.features.energy.consumptionLetter=='C'}" style="background-color: rgb(181, 226, 47);">
														<p _ngcontent-laq-c320="">C</p>
														<div v-if="expanded_annonce.features.energy.consumptionLetter=='C'" _ngcontent-laq-c320="" fxlayout="row" fxlayoutgap="5px" class="label ng-star-inserted" style="flex-direction: row; box-sizing: border-box; display: flex;">
															<span _ngcontent-laq-c320="" style="margin-right: 5px;" class="ng-star-inserted">{{expanded_annonce.features.energy.consumption}}</span>
															<span _ngcontent-laq-c320="" class="ng-star-inserted spanlib"> {{ $t('kwhep/m².an') }}</span>
														</div>
													</div>
													<div _ngcontent-laq-c320="" class="tile ng-star-inserted" :class="{selected: expanded_annonce.features.energy.consumptionLetter=='D'}" style="background-color: rgb(224, 224, 0);">
														<p _ngcontent-laq-c320="">D</p>
														<div v-if="expanded_annonce.features.energy.consumptionLetter=='D'" _ngcontent-laq-c320="" fxlayout="row" fxlayoutgap="5px" class="label ng-star-inserted" style="flex-direction: row; box-sizing: border-box; display: flex;">
															<span _ngcontent-laq-c320="" style="margin-right: 5px;" class="ng-star-inserted">{{expanded_annonce.features.energy.consumption}}</span>
															<span _ngcontent-laq-c320="" class="ng-star-inserted spanlib"> {{ $t('kwhep/m².an') }}</span>
														</div>
													</div>
													<div _ngcontent-laq-c320="" class="tile ng-star-inserted" :class="{selected: expanded_annonce.features.energy.consumptionLetter=='E'}" style="background-color: rgb(255, 204, 0);">
														<p _ngcontent-laq-c320="">E</p>
														<div v-if="expanded_annonce.features.energy.consumptionLetter=='E'" _ngcontent-laq-c320="" fxlayout="row" fxlayoutgap="5px" class="label ng-star-inserted" style="flex-direction: row; box-sizing: border-box; display: flex;">
															<span _ngcontent-laq-c320="" style="margin-right: 5px;" class="ng-star-inserted">{{expanded_annonce.features.energy.consumption}}</span>
															<span _ngcontent-laq-c320="" class="ng-star-inserted spanlib"> {{ $t('kwhep/m².an') }}</span>
														</div>
													</div>
													<div _ngcontent-laq-c320="" class="tile ng-star-inserted" :class="{selected: expanded_annonce.features.energy.consumptionLetter=='F'}" style="background-color: rgb(255, 154, 51);">
														<p _ngcontent-laq-c320="">F</p>
														<div v-if="expanded_annonce.features.energy.consumptionLetter=='F'" _ngcontent-laq-c320="" fxlayout="row" fxlayoutgap="5px" class="label ng-star-inserted" style="flex-direction: row; box-sizing: border-box; display: flex;">
															<span _ngcontent-laq-c320="" style="margin-right: 5px;" class="ng-star-inserted">{{expanded_annonce.features.energy.consumption}}</span>
															<span _ngcontent-laq-c320="" class="ng-star-inserted spanlib"> {{ $t('kwhep/m².an') }}</span>
														</div>
													</div>
													<div _ngcontent-laq-c320="" class="tile ng-star-inserted" :class="{selected: expanded_annonce.features.energy.consumptionLetter=='G'}" style="background-color: rgb(255, 0, 0);">
														<p _ngcontent-laq-c320="">G</p>
														<div v-if="expanded_annonce.features.energy.consumptionLetter=='G'" _ngcontent-laq-c320="" fxlayout="row" fxlayoutgap="5px" class="label ng-star-inserted" style="flex-direction: row; box-sizing: border-box; display: flex;">
															<span _ngcontent-laq-c320="" style="margin-right: 5px;" class="ng-star-inserted">{{expanded_annonce.features.energy.consumption}}</span>
															<span _ngcontent-laq-c320="" class="ng-star-inserted spanlib"> {{ $t('kwhep/m².an') }}</span>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div v-if="expanded_annonce.features.energy.greenhouseGasConsumptionLetter && expanded_annonce.features.energy.greenhouseGasConsumptionLetter!='NS'" _ngcontent-laq-c321="" fxlayout="row" fxlayout.lt-md="column" style="flex-direction: row; box-sizing: border-box; display: flex;" class="ng-star-inserted">
										<div _ngcontent-laq-c321="" fxlayout="column" fxflex="50" style="flex-direction: column; box-sizing: border-box; display: flex; flex: 1 1 100%; max-width: 50%;" class="ng-star-inserted">
											<div class="app-property-energy-indicator" style="text-align: center;" _ngcontent-laq-c321="" _nghost-laq-c320="">
												<h3 _ngcontent-laq-c320="" class="ng-star-inserted">{{ $t('indice_demission_de_gaz_a_effet_de_serre') }}</h3>
												<div _ngcontent-laq-c320="" class="value ng-star-inserted">
													<div _ngcontent-laq-c320="" class="tile ng-star-inserted" :class="{selected: expanded_annonce.features.energy.greenhouseGasConsumptionLetter=='A'}" style="background-color: rgb(253, 234, 254);">
														<p _ngcontent-laq-c320="">A</p>
														<div v-if="expanded_annonce.features.energy.greenhouseGasConsumptionLetter=='A'" _ngcontent-laq-c320="" fxlayout="row" fxlayoutgap="5px" class="label ng-star-inserted" style="flex-direction: row; box-sizing: border-box; display: flex;">
															<span _ngcontent-laq-c320="" style="margin-right: 5px;" class="ng-star-inserted">{{expanded_annonce.features.energy.greenhouseGasConsumption}}</span>
															<span _ngcontent-laq-c320="" class="ng-star-inserted spanlib"> {{ $t('kgeqco2/m².an') }}</span>
														</div>
													</div>
													<div _ngcontent-laq-c320="" class="tile ng-star-inserted" :class="{selected: expanded_annonce.features.energy.greenhouseGasConsumptionLetter=='B'}" style="background-color: rgb(255, 222, 253);">
														<p _ngcontent-laq-c320="">B</p>
														<div v-if="expanded_annonce.features.energy.greenhouseGasConsumptionLetter=='B'" _ngcontent-laq-c320="" fxlayout="row" fxlayoutgap="5px" class="label ng-star-inserted" style="flex-direction: row; box-sizing: border-box; display: flex;">
															<span _ngcontent-laq-c320="" style="margin-right: 5px;" class="ng-star-inserted">{{expanded_annonce.features.energy.greenhouseGasConsumption}}</span>
															<span _ngcontent-laq-c320="" class="ng-star-inserted spanlib"> {{ $t('kgeqco2/m².an') }}</span>
														</div>
													</div>
													<div _ngcontent-laq-c320="" class="tile ng-star-inserted" :class="{selected: expanded_annonce.features.energy.greenhouseGasConsumptionLetter=='C'}" style="background-color: rgb(249, 190, 253);">
														<p _ngcontent-laq-c320="">C</p>
														<div v-if="expanded_annonce.features.energy.greenhouseGasConsumptionLetter=='C'" _ngcontent-laq-c320="" fxlayout="row" fxlayoutgap="5px" class="label ng-star-inserted" style="flex-direction: row; box-sizing: border-box; display: flex;">
															<span _ngcontent-laq-c320="" style="margin-right: 5px;" class="ng-star-inserted">{{expanded_annonce.features.energy.greenhouseGasConsumption}}</span>
															<span _ngcontent-laq-c320="" class="ng-star-inserted spanlib"> {{ $t('kgeqco2/m².an') }}</span>
														</div>
													</div>
													<div _ngcontent-laq-c320="" class="tile ng-star-inserted" :class="{selected: expanded_annonce.features.energy.greenhouseGasConsumptionLetter=='D'}" style="background-color: rgb(251, 126, 255);">
														<p _ngcontent-laq-c320="">D</p>
														<div v-if="expanded_annonce.features.energy.greenhouseGasConsumptionLetter=='D'" _ngcontent-laq-c320="" fxlayout="row" fxlayoutgap="5px" class="label ng-star-inserted" style="flex-direction: row; box-sizing: border-box; display: flex;">
															<span _ngcontent-laq-c320="" style="margin-right: 5px;" class="ng-star-inserted">{{expanded_annonce.features.energy.greenhouseGasConsumption}}</span>
															<span _ngcontent-laq-c320="" class="ng-star-inserted spanlib"> {{ $t('kgeqco2/m².an') }}</span>
														</div>
													</div>
													<div _ngcontent-laq-c320="" class="tile ng-star-inserted" :class="{selected: expanded_annonce.features.energy.greenhouseGasConsumptionLetter=='E'}" style="background-color: rgb(246, 82, 252);">
														<p _ngcontent-laq-c320="">E</p>
														<div v-if="expanded_annonce.features.energy.greenhouseGasConsumptionLetter=='E'" _ngcontent-laq-c320="" fxlayout="row" fxlayoutgap="5px" class="label ng-star-inserted" style="flex-direction: row; box-sizing: border-box; display: flex;">
															<span _ngcontent-laq-c320="" style="margin-right: 5px;" class="ng-star-inserted">{{expanded_annonce.features.energy.greenhouseGasConsumption}}</span>
															<span _ngcontent-laq-c320="" class="ng-star-inserted spanlib"> {{ $t('kgeqco2/m².an') }}</span>
														</div>
													</div>
													<div _ngcontent-laq-c320="" class="tile ng-star-inserted" :class="{selected: expanded_annonce.features.energy.greenhouseGasConsumptionLetter=='F'}" style="background-color: rgb(228, 40, 244);">
														<p _ngcontent-laq-c320="">F</p>
														<div v-if="expanded_annonce.features.energy.greenhouseGasConsumptionLetter=='F'" _ngcontent-laq-c320="" fxlayout="row" fxlayoutgap="5px" class="label ng-star-inserted" style="flex-direction: row; box-sizing: border-box; display: flex;">
															<span _ngcontent-laq-c320="" style="margin-right: 5px;" class="ng-star-inserted">{{expanded_annonce.features.energy.greenhouseGasConsumption}}</span>
															<span _ngcontent-laq-c320="" class="ng-star-inserted spanlib"> {{ $t('kgeqco2/m².an') }}</span>
														</div>
													</div>
													<div _ngcontent-laq-c320="" class="tile ng-star-inserted" :class="{selected: expanded_annonce.features.energy.greenhouseGasConsumptionLetter=='G'}" style="background-color: rgb(180, 21, 185);">
														<p _ngcontent-laq-c320="">G</p>
														<div v-if="expanded_annonce.features.energy.greenhouseGasConsumptionLetter=='G'" _ngcontent-laq-c320="" fxlayout="row" fxlayoutgap="5px" class="label ng-star-inserted" style="flex-direction: row; box-sizing: border-box; display: flex;">
															<span _ngcontent-laq-c320="" style="margin-right: 5px;" class="ng-star-inserted">{{expanded_annonce.features.energy.greenhouseGasConsumption}}</span>
															<span _ngcontent-laq-c320="" class="ng-star-inserted spanlib"> {{ $t('kgeqco2/m².an') }}</span>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>

							</div>							
						</div>
					</div>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
import { mapGetters } from 'vuex'
import Carousel from 'primevue/carousel';
// eslint-disable-next-line
import domtoimage from 'dom-to-image'
import Button from 'primevue/button';

export default {
	name: 'PaneOffre',

	data: function() {
		return {
			expanded: false,
			tva: 'tva_pleine_libre',
			pkg: 'prix_sp',
			filter: 'volume',
			filter2: 'base',
			suffixe: 'T',
			filter3: 'tva_pleine_libre_sans',
			filter4: 'global',
			filter_svo: 's',
			mode_aff: 'granulaire',
			mode_aff_histo: 'programme',
			compteur: 5,
			lib: [
				{ slug: "APARTMENT", name: "Appartement" },{ slug: "HOUSE", name: "Maison" },{ slug: "PREMISES", name: "Local Commercial" },
				{ slug: "LAND", name: "Terrain" },{ slug: "BUILDING", name: "Immeuble" },{ slug: "PARKING", name: "Parking" },
				{ slug: "PARKING_INCLUDED_IN_RENT", name: "Parking inclus dans le loyer" },{ slug: "CHALET", name: "Chalet" },{ slug: "MANSION", name: "Hôtel particulier" },
				{ slug: "CASTLE", name: "Château" },{ slug: "HOMESTEAD", name: "Propriété" },{ slug: "LOFT", name: "Loft" },{ slug: "DUPLEX", name: "Duplex" },
				{ slug: "TRIPLEX", name: "Triplex" },{ slug: "SHOP", name: "Magasin" },{ slug: "OFFICE", name: "Bureau" },{ slug: "FURNITURE", name: "Meublé" },
				{ slug: "FIREPLACE", name: "Cheminée" },{ slug: "AIR_CONDITIONING", name: "Climatisation" },{ slug: "ADSL", name: "Adsl" },
				{ slug: "CABLE", name: "Câble" },{ slug: "FIBER", name: "Fibre" },{ slug: "ELEVATOR", name: "Ascenseur" },{ slug: "DIGICODE", name: "Digicode" },
				{ slug: "INTERCOM", name: "Interphone" },{ slug: "VIDEOPHONE", name: "Vidéophone" },{ slug: "BIKE_AREA", name: "Local vélo" },
				{ slug: "TWO_WHEEL_PARK", name: "Emplacement 2 roues" },{ slug: "DISABLED_ACCESS", name: "Accès handicapé" },{ slug: "PARKING_FACILITIES", name: "Possibilité de stationnement" },
				{ slug: "BEDROOM", name: "Chambre" },{ slug: "BATHROOM", name: "Salle de bain" },{ slug: "SHOWER_ROOM", name: "Salle d'eau" },
				{ slug: "LUNCHROOM", name: "Salle à manger" },{ slug: "LIVING_ROOM", name: "Salon" },{ slug: "CELLAR", name: "Cave" },{ slug: "KITCHEN", name: "Cuisine" },
				{ slug: "TOILET", name: "WC" },{ slug: "BALCONY", name: "Balcon" },{ slug: "GARDEN", name: "Jardin" },{ slug: "SWIMMING_POOL", name: "Piscine" },
				{ slug: "TERRACE", name: "Terrasse" },{ slug: "CARETAKER", name: "Gardien" },{ slug: "CLEANING", name: "Société de nettoyage" },{ slug: "FULL", name: "Complet" },
				{ slug: "PARTIAL", name: "Partiel" },{ slug: "NEW", name: "Neuf" },{ slug: "VERY_GOOD", name: "Très bon" },{ slug: "GOOD", name: "Bon" },
				{ slug: "NORMAL", name: "Normal" },{ slug: "BAD", name: "À rafraîchir" },{ slug: "VERY_BAD", name: "Travaux à prévoir" },
				{ slug: "CHIP_NEW", name: "Etat neuf" },{ slug: "CHIP_VERY_GOOD", name: "Très bon état" },{ slug: "CHIP_GOOD", name: "Bon état" },
				{ slug: "CHIP_NORMAL", name: "Normal" },{ slug: "CHIP_BAD", name: "À rafraîchir" },{ slug: "CHIP_VERY_BAD", name: "Travaux à prévoir" },
				{ slug: "ELECTRIC", name: "Électrique" },{ slug: "GAS", name: "Au gaz" },{ slug: "FUEL", name: "Au fuel" },{ slug: "WOOD", name: "Au bois" },
				{ slug: "SOLAR", name: "Solaire" },{ slug: "HEAT_PUMP", name: "Par pompes thermiques" },{ slug: "UNDERFLOOR", name: "Géothermique" },
				{ slug: "INDIVIDUAL", name: "Individuel" },{ slug: "COLLECTIVE", name: "Collectif" },{ slug: "MIXED", name: "Mixe" },
				{ slug: "OPENED", name: "Cuisine américaine" },{ slug: "CLOSED", name: "Cuisine fermée" },{ slug: "KITCHENETTE", name: "Kitchenette" },
				{ slug: "FITTED", name: "Sur mesure" },{ slug: "EQUIPPED", name: "Équipée" },{ slug: "NONE", name: "Non équipée" },{ slug: "FREE", name: "Libre" },
				{ slug: "AGREED", name: "Conventionnée" },{ slug: "TAX_FREE", name: "Défiscalisée" },{ slug: "ACTIVE", name: "Actif" },
				{ slug: "JOINT_OWNERSHIP", name: "Indivision" },{ slug: "OK", name: "Non opposé" },{ slug: "BLOCKED", name: "Opposé" },
				{ slug: "UNCHECKED", name: "Vérification planifiée" },{ slug: "SURFACE_PRIVEE", name: "Surface Privée" },{ slug: "PRIVATE", name: "Particulier" },
				{ slug: "AGENCY", name: "Professionnel" },{ slug: "MANDATORY", name: "Professionnel" },{ slug: "REFUSAL", name: "Refus" },{ slug: "UNREACHABLE", name: "Non joignable" },
				{ slug: "MARKETING_DONE", name: "Commercialisation terminé" },{ slug: "IN_MANDATE", name: "En mandat" },{ slug: "QUALIFICATION", name: "Qualification" },
				{ slug: "NEVER", name: "Jamais" },{ slug: "CREATE", name: "Nouveau" },{ slug: "PRICE_DECREASE", name: "Prix en baisse" },{ slug: "EXPIRED", name: "Expiré" },
				{ slug: "TODAY", name: "Aujourd'hui" },{ slug: "THIS_WEEK", name: "Cette semaine" },{ slug: "OLDER", name: "Avant" },{ slug: "OFFER", name: "Offre d'achat" },
				{ slug: "COMPROMISE", name: "Sous compromis" },{ slug: "SIGNED", name: "Vendu" },{ slug: "MONTHS", name: "Mois" },{ slug: "QUARTERS", name: "Trimestre" },
				{ slug: "YEARS", name: "Année" },{ slug: "PER_DAYS", name: "par jour" },{ slug: "PER_MONTHS", name: "par mois" },{ slug: "PER_QUARTERS", name: "par trimestre" },
				{ slug: "PER_YEARS", name: "par année" },{ slug: "REAL_ESTATE", name: "Agences" },{ slug: "NOTARY", name: "Notaires" },{ slug: "MANDATARY", name: "Mandataires" },
				{ slug: "REALTY_DEVELOPER", name: "Promoteurs" },{ slug: "PROPERTY_MANAGER", name: "Gestionnaires de patrimoine" },{ slug: "TRANSACTION", name: "Transaction" },
				{ slug: "CONSTRUCTION", name: "Construction" },{ slug: "LOCATION", name: "Location" },{ slug: "ADMINISTRATION", name: "Administration" },
				{ slug: "DONE", name: "Terminée" },{ slug: "TYPE", name: "Type" },{ slug: "USER_ID", name: "Affectation" },{ slug: "construction_year", name: "Année de construction" },
				{ slug: "expenses", name: "Charges locatives" },{ slug: "floor_count", name: "Nombre d'étages" },{ slug: "has_parking", name: "Parking" },{ slug: "has_pool", name: "Piscine" },
				{ slug: "land_surface", name: "Jardin" },{ slug: "level", name: "Étage" },{ slug: "location", name: "Emplacement" },{ slug: "newly_built", name: "Construction neuve" },
				{ slug: "price", name: "Prix" },{ slug: "room_count", name: "Nombre de pièces" },{ slug: "surface", name: "Surface" },{ slug: "NEW_BUILD", name: "Construction neuve" },
				{ slug: "HABITABLE_SURFACE", name: "Surface habitable" },{ slug: "MEAN_SURFACE", name: "Surface moyenne" },{ slug: "LOT_COUNT", name: "Nombre de lots" },
				{ slug: "AREA_COUNT", name: "Nombre de pièces" },{ slug: "GENERAL_STATE", name: "État" },{ slug: "LAND_SURFACE", name: "Surface terrain" },
				{ slug: "NUMBER_OF_GARDENS", name: "Nombre de jardins" },{ slug: "NUMBER_OF_BATHROOMS", name: "Salle de bains" },{ slug: "NUMBER_OF_WC", name: "Nombre de WC" },
				{ slug: "NUMBER_OF_SHOWER_ROOMS", name: "Nombre de salle d'eau" },{ slug: "NUMBER_OF_LIVING_ROOMS", name: "Salons" },{ slug: "NUMBER_OF_BALCONIES", name: "Balcons" },
				{ slug: "NUMBER_OF_TERRACES", name: "Terrasses" },{ slug: "HEATING", name: "Chauffage" },{ slug: "CONDOMINIUM_LOT_COUNT", name: "Nombre de lot de la copropriété" },
				{ slug: "CONDOMINIUM_EXPENSES", name: "Charges" },{ slug: "CHARGES_INCLUDED", name: "Charges comprises" },{ slug: "CHARGES", name: "Charges" },
				{ slug: "VIEW", name: "Vue" },{ slug: "SEA", name: "Sur la mer" },{ slug: "MOUNTAIN", name: "Sur la montagne" },{ slug: "RIVER", name: "Sur la rivière" },
				{ slug: "LAKE", name: "Sur le lac" },{ slug: "EXPOSITION", name: "Exposition" },{ slug: "NORTH", name: "Nord" },{ slug: "EAST", name: "Est" },{ slug: "SOUTH", name: "Sud" },
				{ slug: "WEST", name: "Ouest" },{ slug: "LEGAL_PERSON", name: "Personne morale" },{ slug: "SINGLE_PROPERTY", name: "Mono propriété" },{ slug: "CONDOMINIUM", name: "Copropriété" },
				{ slug: "CONSTRUCTION_PERIOD", name: "Période de construction" },{ slug: "CONSTRUCTION_YEAR", name: "Année de construction" },{ slug: "FLOOR_COUNT", name: "Nombre d'étages" },
				{ slug: "LEVEL", name: "Étage" },{ slug: "BEDROOM_COUNT", name: "Chambres" },{ slug: "SURFACE", name: "Surface" },{ slug: "A", name: "A" },
				{ slug: "A_BIS", name: "A bis" },{ slug: "B1", name: "B1" },{ slug: "B2", name: "B2" },{ slug: "LEVEL_1", name: "Niveau 1" },{ slug: "LEVEL_2", name: "Niveau 2" },
				{ slug: "BUYER", name: "À la charge de l’acquéreur" },{ slug: "SELLER", name: "À la charge du vendeur" },
				{ slug: "CREATION", name: "Entrée" },{ slug: "DELETION", name: "Sortie" },{ slug: "PARKING_SPOT", name: "Place de parking" },
				{ slug: "BOX", name: "Box" },{ slug: "GARAGE", name: "Garage" },{ slug: "AUTHORISED", name: "Autorisé" },
				{ slug: "CANCELED", name: "Annulé" },{ slug: "STARTED", name: "Démarré" },{ slug: "FINISHED", name: "Terminé" },
				{ slug: "SINGLE", name: "Individuel" },{ slug: "DEMOLISHED", name: "Démolition" },{ slug: "SOCIAL", name: "Social" },
				{ slug: "SOCIAL_LANDLORD", name: "Bailleur social" },{ slug: "PROPERTY_DEVELOPER", name: "Promoteur" },{ slug: "PUBLIC_ADMINISTRATION", name: "Administration publique" },
				{ slug: "OTHER_COMPANY", name: "Autre société" },{ slug: "PURE_LOCAL", name: "Non déterminé" },{ slug: "EXISTING_BUILD", name: "Construction existante" },
				{ slug: "PROJECT_TYPE_RESIDENTIAL", name: "Résidentiel" },{ slug: "PROJECT_TYPE_NON_RESIDENTIAL", name: "Non résidentiel" },{ slug: "PROJECT_TYPE_MIXED", name: "Mixte" },
				{ slug: "ONLINE_ADS", name: "Annonces en ligne" },{ slug: "FIELD_PROSPECTING", name: "Prospection terrain" },{ slug: "UNPAID", name: "Non payé" },
				{ slug: "TRIALING", name: "Période d'essai" },{ slug: "UNKNOWN", name: "Inconnu" },{ slug: "MANUAL", name: "Manuel" },
				{ slug: "STRIPE", name: "Stripe" },{ slug: "constructionYear", name: "Construction" },{ slug: "energyConsumptionLetter", name: "DPE" },
				{ slug: "floorCount", name: "Nombre d'étages" },{ slug: "furnished", name: "Meublé" },{ slug: "generalState", name: "Etat du bien" },
				{ slug: "greenhouseGasConsumptionLetter", name: "GES" },{ slug: "balconyCount", name: "Balcon" },{ slug: "cellarCount", name: "Cave" },
				{ slug: "gardenCount", name: "Jardin" },{ slug: "parkingCount", name: "Parking" },{ slug: "swimmingPoolCount", name: "Piscine" },
				{ slug: "terraceCount", name: "Terrasse" },{ slug: "landSurface", name: "Surface du terrain" },{ slug: "floor", name: "Etage" },
				{ slug: "roomCount", name: "Nombre de pièces" },{ slug: "energyDiagnostic", name: "DPE / GES" },{ slug: "typology", name: "Typologie" }
			]
		}
	},

	computed: Object.assign(mapGetters([ 'app', 'expanded_annonce' ]), {
		formattedText() {
			return this.expanded_annonce.features.descriptive.description.replace(/\n/g, '<br>');
		},
		photos: function() {
			let v = []
			if (this.expanded_annonce && this.expanded_annonce.photos) {
				for (let index = 0; index < this.expanded_annonce.photos.length; index++) {
					v.push({image: this.expanded_annonce.photos[index].url_photo})
				}
			}
			return v
		},
	}),

	watch: {
		'app.expanded_annonce': function(newValue) {
			if (newValue) {
				this.filter_svo = "s"
			}
		},
	},
	
	methods: {
		getLibelle: function(etat) {
            for (let i in this.lib) if (this.lib[i].slug==etat) return this.lib[i].name
        },

		getLibelleBalcon: function(areas) {
			for (let index = 0; index < areas.length; index++) {
				const element = areas[index];
				if (element.type == 'BALCONY') {
					if (element.surface) return ' (' + element.surface + ' m²)'
				}
			}
			return ''
        },

		getLibelleTerrasse: function(areas) {
			for (let index = 0; index < areas.length; index++) {
				const element = areas[index];
				if (element.type == 'TERRACE') {
					if (element.surface) return ' (' + element.surface + ' m²)'
				}
			}
			return ''
        },

		getLibelleCuisine: function(areas) {
			for (let index = 0; index < areas.length; index++) {
				const element = areas[index];
				if (element.type == 'KITCHEN') {
					for (let i in this.lib) if (this.lib[i].slug==element.kitchenType) return ' (' + this.lib[i].name + ')'
				}
			}
			return ''
        },

        getLibelleDate: function(d) {
            let a = new Date(d)
            let mm = a.getMonth() + 1;
            let dd = a.getDate();
            return [(dd>9 ? '' : '0') + dd,(mm>9 ? '' : '0') + mm, a.getFullYear()].join('-');
        },
        
		getPrice: function(p) {
			let nPrice=p
			
			const euro = new Intl.NumberFormat('fr-FR', {
                style: 'currency',
                currency: 'EUR',
                minimumFractionDigits: 0
            });
			if (nPrice==0) {
				return "-"
			} else {
                return euro.format(Math.round(nPrice))
			}
		},

		generateImage(id) {
			const elementToExport = document.getElementById('LocatifEx');
			let options = {
				height: elementToExport.scrollHeight,
				width: elementToExport.scrollWidth,
				style: {
					'transform': 'scale(1)',
					'transformOrigin': 'top left',
				},
			};

			domtoimage.toPng(elementToExport, options)
			.then((dataUrl) => {
				const link = document.createElement('a');
				link.href = dataUrl;
				link.download = 'Locatif_' + id + '.png';
				link.click();
			})
			.catch((error) => {
				console.error('Erreur lors de la génération de l\'image', error);
			});
		},

		getTxtTaux: function(value) {
			switch (value) {
				case 'Faible':
					return '< 70 %'
				case 'Moyen':
					return '70 - 90 %'
				case 'Fort':
					return 'plus de 90 %'
				default:
					return value
			}
		},
		
		getTxtTauxClass: function(value) {
			if (value < 0.7) return 'Faible'
			else {
				if (value < 0.9) return 'Moyen'
				else return 'Fort'
			}
		},

		getTxtClass: function(value) {
			switch (value) {
				case '< 70%':
					return 'Faible'
				case '70 - 90%':
					return 'Moyen'
				case '> 90%':
					return 'Fort'
				default:
					return ''
			}
		},


		expand_offre: function() {
			this.$router.push({ name: 'annonce'}).catch(()=>{});
			if (this.expanded_annonce != this.rpls) {
				this.$store.dispatch('expand_annonce', { annonce: this.annonce })
			} else {
				this.$store.dispatch('expand_annonce', { annonce: false })
			}
		},

		print: function() {
			this.$store.dispatch('print_program', { divname: "rplsencours"})
			this.$store.dispatch('SendEvent', { action: "program_download", opt_label: this.expanded_annonce.COMPLGEO, opt_value: 0 })
			this.$store.dispatch('set_print', { print: 'expanded-rpls' })
		}
	},

	// eslint-disable-next-line
	components: { Carousel, domtoimage, Button }

}
</script>

<style lang="stylus" scoped>
@import '../assets/css/app'

.pane-program-enter, .pane-program-leave-to
	transform translateX(100%)

.pan-titre-programme
	z-index 999
	position fixed
	float left
	with 100%
	transition 0.5s easeOutQuart
	.accordion-menu
		overflow hidden scroll
		height 100%
.fermer
	position absolute
	width 60px
	right 0px
	border-left 1px solid palette_gris_bordure
	text-align center
	line-height 60px
	cursor pointer
.aide
	width 60px
	left 60px
	position absolute
	border-right 1px solid palette_gris_bordure
	text-align center
	line-height 60px
	cursor pointer
.chatbox
	width 60px
	left 0px
	border-right 1px solid palette_gris_bordure
	position absolute
	text-align center
	line-height 60px
	cursor pointer
.print
	width 60px;
	position absolute
	right 60px
	border-left 1px solid palette_gris_bordure
	text-align center
	line-height 60px
	cursor pointer

.module_ret
	width 800px
	height 60px
	vertical-align middle
	background-color palette_gris
	border-bottom 1px solid palette_gris_bordure
	border-right 1px solid palette_gris_bordure

.pane-program
	position absolute
	right 0
	top headersize
	bottom 0
	height calc(100% - 120px)
	width 800px
	background-color #fefefe
	transition 0.5s easeOutQuart
	z-index 599
	border-left 1px solid palette_gris_bordure

.program
	width 100%
	border-bottom 2px solid #eee
	&.inactif
		.program-main
			background #f5f5f5
			.program-content
				.name
					color #666
	&.expanded
		&:hover
			.program-main
				background lighten(#e3f2fd, 25%)
		.program-main
			background lighten(#e3f2fd, 50%)
			box-shadow inset -2px 2px 0 0 palette2, inset 2px 2px 0 0 palette2
			.program-content
				.name
					color palette2
		.program-detail
			box-shadow inset -2px -2px 0 0 palette2, inset 2px -2px 0 0 palette2
	&.	
		&:hover
			.program-main
				background lighten(orange, 85%)
		.program-main
			background lighten(orange, 90%)
	&.disabled, &.disabled:hover
		.program-main
			box-shadow none
			.image
				background #eee
				img
					display none
			.price
				color lighten(green, 10%)
			.program-content
				.name, .address, .city, .seller
					color #fff
					&:after
						content ''
						position absolute
						left 0
						top 4px
						bottom 4px
						width 75%
						background #eee
				.forme-urbaine
					color #fff
					&:after
						content ''
						position absolute
						left 0
						top 0
						bottom 0
						width 100%
						background #eee
						border-radius radius
		.program-detail
			display none
	.program-main
		background #fefefe
		padding 16px
		.image
			position absolute
			left 130px
			height 285px
			width 396px
			border-radius radius
			background #eee url(../assets/img/no-camera.png) center center no-repeat
			background-size 48px 48px
			img
				position absolute
				left 50%
				transform translateX(-50%)
				height 100%
				width auto
		.program-content
			margin-left 16px
			.name
				height 24px
				line-height 20px
				text-align center
			.city, .address
				color #999
				font-size 12px
			.seller
				margin 8px 0 0 0
				text-transform uppercase
				font-size 12px
				line-height 24px
			.forme-urbaine
				position absolute
				right 0
				bottom 0
				color #999
		.price
			position absolute
			left 44px
			top 24px
			padding 0 8px 0 16px
			font-family volte, sans-serif
			font-weight 600
			line-height 24px
			background lighten(green, 10%)
			color #fff
			&:after, &:before
				content ''
				position absolute
				right -16px
				width 0
				height 0
				border-style solid
			&:before
				top 0
				border-width 16px 16px 0 0
				border-color lighten(green, 10%) transparent transparent transparent
			&:after
				bottom 0
				border-width 16px 0 0 16px
				border-color transparent transparent transparent lighten(green, 10%)
			.angle
				position absolute
				left 0
				top -4px
				width 0
				height 0
				border-style solid
				border-width 0 0 4px 6px
				border-color transparent transparent darken(blue, 50%) transparent

.program-detail
	overflow hidden
	height auto
	&.detail-enter, &.detail-leave-to
		height 430px
		background-color red
	.program-detail-content
		padding 16px
		.resume
			margin 8px 0 0 0
			&:first-child
				margin 0
			&:after
				content ''
				display block
				clear both
			.resume-data
				float left
				width 33.3333%
				margin 0 0 8px 0
				.label
					font-size 10px
					text-transform uppercase
					color #999
				.value
					font-weight 500
		.actions
			margin 8px 0 0 0
			.action
				display inline-block
				margin 0 24px 0 0
				height 32px
				color #666
				font-size 12px
				font-weight 500
				line-height @height
				cursor pointer
				&:hover
					color #333
				.icon
					float left
					margin 4px 8px 0 0
		.program-detail-info
			padding 8px 0
			.program-detail-info-filters
				text-align center
				margin 0 0 8px 0
			.program-detail-info-content
				padding 8px 0
				background #f5f5f5
				border-radius radius
				overflow hidden
				table
					width 100%
					border-collapse collapse
					tr
						&:last-child
							border none
						&:hover
							td
						td, th
							padding 4px 8px
						th
							font-size 12px
							font-weight 500
						td
							text-align center
							&:first-child
								text-align left
								font-size 12px
								font-weight bold
								padding-left 16px

.actionaide
	position absolute
	right 140px
	top 0px
	z-index 999
.actionfermer
	position absolute
	right 22px
	top 0px
	z-index 999
.action_print
	position absolute
	right 60px
	top 0px
	z-index 999
.action_help
	position absolute
	right 100px
	top 0px
	z-index 999


.indice_confiance
	position absolute
	right 121px
	width 620px !important
	height 60px
	top 0px
	z-index 999
	vertical-align middle
	line-height 59px
	text-align center
	font-size 14px
	&.bas
		background qualitedonneesbas
	&.milieu
		background qualitedonneesmilieu
	&.haut
		background qualitedonneeshaut
	&.pc
		background #ccc

.vb
	.vb-dragger
		z-index 5
		width 10px
		right 0

.back-button
	cursor pointer
	border-radius 100%
	transition 0.25s easeOutQuart
	width 32px
	height 32px
	padding 4px	
	&:hover
		color #fff
		background-color darken(green, 10%)

.program-maj
	text-align center
	font-size 12px
	font-weight 500
	padding-left 16px
	color palette1

.nom
	color primaryA100

.cible
	color #000

.refreshprog
	height 185px
	width 750px

.infinite-loader
	position absolute
	right 307px
	top 28px
	.loader
		display inline-block
		background main_color
		border-radius 100%
		color #fff
		.icon
			display block
			animation rotate 1.5s infinite linear

.Faible
	color #f2131f
	font-weight 600
.Moyen
	color #f2aa1f
	font-weight 600
.Fort
	color #1eaa1f
	font-weight 600
.recent
	color #1eaa1f
	font-weight 600

.center
	text-align center !important

.value
	height 60px
	p
.tile
	display inline-block
	vertical-align top
	width 43px
	height 20px
	line-height 20px
	font-weight 700
	text-align center

.tile.selected
	position relative
	width 43px
	height 43px
	margin-top -11px
	margin-left -5px
	margin-right -5px
	line-height 40px
	border 2px solid white
	border-radius 50%
	z-index 999
.spanlib
	padding 12px 0 0
	line-height 18px
	font-weight 400
	font-size x-small

.app-property-energy-indicator
	width 380px
	h3
		font-weight 400
		font-size 13px

.desc
	white-space pre-line
	position relative
	text-align justify
	font-size 15px
	color #495960
	margin-top 20px
	padding 0 20px 20px
	overflow hidden
	line-height 22px


:deep(.p-carousel-items-content){
	height: 285px !important;
}
	
.grid-list {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 16px;
}

.grid-list li {
	list-style: none;
}	

.flex-list {
	display: flex;
	flex-wrap: wrap;
	gap: 16px;
}

.flex-list li {
	list-style: none;
	flex: 1 1 auto;
	box-sizing: border-box;
}

.flex-list li a {
	display: flex;
	align-items: center;
	white-space: nowrap;
}
@media print
	.program
		.program-detail
			.program-detail-content
				.actions
					display none



</style>
