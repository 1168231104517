<template>
	<table class="grad-table" v-if="GradTab.reduce((total, current) => total + current.absolue, 0)==0">
		<tr>
			<td></td>
			<td class="grad-header">Nombre</td>
			<td class="grad-header">Part</td>
		</tr>
		<tr v-for="(c, index) in GradTab" :key="index">
			<!-- Première colonne : label avec couleur -->
			<td style="">
				<div class="grad-label" :style="{ backgroundColor: c.color }">
					{{ c.l }}
				</div>
			</td>
			<!-- Deuxième colonne : barre avec valeur absolue et pourcentage -->
			<td colspan="2" :rowspan="GradTab.length" v-if="index==0" :style="{border: '1px solid #CCCCCC'}">
				<div style="text-align: center; vertical-align: middle;height:100%;width:100%">
					données non disponibles
				</div>
			</td>
		</tr>
	</table>
	<table class="grad-table" v-else>
		<tr>
			<td></td>
			<td class="grad-header"><span v-if="absolute">Nombre</span></td>
			<td class="grad-header"><span v-if="percent">Part</span></td>
		</tr>
		<tr v-for="(c, index) in GradTab" :key="index">
			<!-- Première colonne : label avec couleur -->
			<td style="">
			<div class="grad-label" :style="{ backgroundColor: c.color }">
				{{ c.l }}
			</div>
			</td>
			<!-- Deuxième colonne : barre avec valeur absolue et pourcentage -->
			<td colspan="2" class="grad-bar-cell">
			<div class="grad-bar" :style="{ width: c.percentdisplay + '%', backgroundColor: c.color }"></div>
			<div class="grad-absolute">
				<span v-if="absolute">{{ c.absolue }}</span>
			</div>
			<div class="grad-percent">
				<span v-if="percent">{{ c.percent }} %</span>
			</div>
			</td>
		</tr>
	</table>
</template>

<script>
export default {
	name: "Grad",
	props: {
		absolute: {
			type: Boolean,
			required: false,
			default: true
		},
		percent: {
			type: Boolean,
			required: false,
			default: true
		},
		GradTab: {
			type: Array,
			required: true
		}
	},
	methods: {
		rien: function() {
			//console.log(this.GradTab)
			for (let index = 0; index < this.GradTab.length; index++) {
				const element = this.GradTab[index];
				if (element.absolue) return false;
			}
			return true;
		}
	}
};
</script>

<!-- Global Styles for Variables -->
<style>
:root {
  --height: 32px;
  --border-radius: calc(var(--height) / 2);
}
</style>

<style scoped>

.grad-table {
	width: 100%;
	border-spacing: 4px;
}

.grad-header {
	text-align: center;
	width: 50%;
	font-weight: 500;
	color: black;
}

.grad-label {
	width: 100%;
	border-top-left-radius: var(--border-radius);
	border-bottom-left-radius: var(--border-radius);
	height: var(--height);
	text-align: center;
	padding: 0 10px;
	white-space: nowrap;
	color: black;
	font-weight: 500;
	line-height: var(--height);
}

.grad-bar-cell {
	position: relative;
}

.grad-bar {
	height: var(--height);
	border-top-right-radius: var(--border-radius);
	border-bottom-right-radius: var(--border-radius);
	line-height: var(--height);
}

.grad-absolute,
.grad-percent {
	position: absolute;
	top: 0;
	height: 100%;
	line-height: var(--height);
	width: 50%;
	text-align: center;
	color: black;
}

.grad-absolute {
	left: 0;
}

.grad-percent {
	right: 0;
}
</style>
