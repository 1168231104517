<template>
	<transition name="pane-ancien">
		<div class="paneleft pan-ancien">
			<div class="module_ret">
				<!--
				<div class="neuf" @click.stop="toggle_filter()" title="Filtrer ma recherche" :class="{ idf: !app.module_actif }">
					<icon file="filter_list" :size="36" class="filters" />&nbsp;
				</div>
				-->
				<div class="unmodule_sous2" style="text-align:center;width:100%;left:0" v-if="this.app.locatif_iris && app.locatif_rayon_loading==0">
					<b><p>{{ LibelleSecteur }}</p></b>
				</div>
			</div>

			<div class="_filtre">
				<div class="programs-list" >
					<div class="programs-list-content" v-bar>
					</div>
				</div>
			</div>
			<div class="panecontent wrapper-scroll" style="height:calc(100% - 64px);">
				<div style="height:auto !important;padding-bottom:10px;">
					<div class="programs-list">
						<div class="programs-list-content" v-bar>
							<br>
							<animated-tabs 
							:tabs="tabs_forme" 
							:initial-active-index="0"
							@tab-change="handleTabChange_forme"
							></animated-tabs>
							<br>
							<animated-tabs 
							:tabs="tabs_geo" 
							:initial-active-index="0"
							@tab-change="handleTabChange_geo"
							></animated-tabs>
							<div class="slider-container" style="margin-top:15px;padding: 15px;" v-if="activeTab_geo=='rayon' && app.locatif_rayon_loading<1">
								<div class="form-group">
									<Slider v-model="distance" :step="50" :min="100" :max="2000"></Slider>
									<div class="slider-container" style="margin-top:15px">
										<div class="slider-value" id="price-factor-value">{{distance}} m</div>
									</div>
									<button class="refresh-btn" @click="Appliquer_Rayon()">
										<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" class="refresh-icon">
											<path d="M13.6567 2.34333C12.1567 0.843333 10.1567 0 8.00004 0C3.58337 0 0.00837085 3.575 0.00837085 8C0.00837085 12.425 3.58337 16 8.00004 16C11.7234 16 14.8317 13.525 15.7317 10H13.6567C12.8317 12.325 10.6067 14 8.00004 14C4.69171 14 2.00837 11.3167 2.00837 8C2.00837 4.68333 4.69171 2 8.00004 2C9.58337 2 11.0067 2.61667 12.075 3.6925L9.00004 6.75H16.0084V0L13.6567 2.34333Z" fill="white"/>
										</svg>
										Appliquer
									</button>
								</div>
							</div>
							<div class="content" ref="scrollable_programs" style="height:100%">

								<div style="height:100%;width:100%;display: flex;align-content: center;justify-content: center;margin-top:100px" v-if="app.locatif_rayon_loading>0">
									<div class="infinite-loader" style="position: relative;display: flex;flex-direction: column;align-items: center;flex-wrap: nowrap;top: auto;right: auto;left: auto;justify-content: center;vertical-align: middle;">
										<div class="text" style="text-align: center;color:#999;font-size:20px">
												<p>{{$t('Recalcul des statistiques')}}</p>
												<p>{{ $t('en_cours') }} ...</p>
												<p>&nbsp;</p>
										</div>
										<div class="loader"><icon file="loader" :size="64" style="color:#CCC" /></div>
									</div>
								</div>

								<div v-else>
									<div class="results" style="margin-top:10px" v-if="dataPrix && dataPrix.total">
										<div class="result-card" style="transform: translateY(0px);" id="img_estimmo_nb">
											<div class="result-label">Prix au m² med<tool-source v-if="false" url='' :txt='getInfoPrix'/></div>
											<div class="result-value"> {{ DonneValeurPrix(Math.round(dataPrix.total.prix_m2_median)) }}/m²</div>
										</div>
									</div>
									
									<div class="results" style="margin-top:10px" v-if="dataPrix && dataPrix.total">
										<div class="result-card" style="transform: translateY(0px);" id="img_estimmo_nb">
											<div class="result-label">Prix au m² min<tool-source v-if="false" url='' :txt='getInfoPrix'/></div>
											<div class="result-value"> {{ DonneValeurPrix(Math.round(dataPrix.total.prix_m2_min)) }}/m² </div>
										</div>
										<div class="result-card" style="transform: translateY(0px);" id="img_estimmo_nb">
											<div class="result-label">Prix au m² max<tool-source v-if="false" url='' :txt='getInfoPrix'/></div>
											<div class="result-value"> {{ DonneValeurPrix(Math.round(dataPrix.total.prix_m2_max)) }}/m² </div>
										</div>
									</div>
									<div class="tabs" style="margin-bottom:15px;justify-content: space-between;">
										<div style="display: flex;">
											<div class="tab" @click="activeTab_typo='typ'" 	:class="{active: activeTab_typo=='typ'}">Typologie</div>
											<div class="tab" @click="activeTab_typo='dpe'" 	:class="{active: activeTab_typo=='dpe'}">DPE</div>
										</div>
									</div>
									
									<div class="p-datatable-sm text-sm p-datatable p-component p-datatable-responsive-stack p-datatable-gridlines" v-if="dataPrix && dataPrix.total">
										<div class="tab-content-panel" style="padding-left:15px;padding-right:15px;">
											<table role="table" class="p-datatable-table" v-if="activeTab_typo=='typ'">
												<thead role="rowgroup" class="p-datatable-thead">
													<tr role="row">
														<th role="cell" class=""><div class="p-column-header-content"><span class="p-column-title">Typo.</span></div></th>
														<th role="cell" class=""><!----><div class="p-column-header-content"><!----><span class="p-column-title">Nombre</span><!----><!----><!----><!----></div></th>
														<th role="cell" class=""><!----><div class="p-column-header-content"><!----><span class="p-column-title">Surf. med.</span><!----><!----><!----><!----></div></th>
														<th role="cell" class=""><!----><div class="p-column-header-content"><!----><span class="p-column-title">Prix m² med.</span><!----><!----><!----><!----></div></th>
													</tr>
												</thead>
												<tbody role="rowgroup" class="p-datatable-tbody">
													<tr role="row" class="" v-for="typ in dataTyp" v-bind:key="typ.libelle">
														<td role="cell" class="">{{ typ.libelle}}</td>
														<td role="cell" class="">{{ typ.nombre_annonces}}</td>
														<td role="cell" class="">{{ typ.surface_mediane}}</td>
														<td role="cell" class="">{{ typ.prix_m2_median}}</td>
													</tr>
												</tbody>
											</table>
											<table role="table" class="p-datatable-table" v-if="activeTab_typo=='dpe'">
												<thead role="rowgroup" class="p-datatable-thead">
													<tr role="row">
														<th role="cell" class=""><div class="p-column-header-content"><span class="p-column-title">Typo.</span></div></th>
														<th role="cell" class=""><!----><div class="p-column-header-content"><!----><span class="p-column-title">Nombre</span><!----><!----><!----><!----></div></th>
														<th role="cell" class=""><!----><div class="p-column-header-content"><!----><span class="p-column-title">Prix m² med.</span><!----><!----><!----><!----></div></th>
													</tr>
												</thead>
												<tbody role="rowgroup" class="p-datatable-tbody">
													<tr role="row" class="" v-for="dpe in dataDpe" v-bind:key="dpe.libelle">
														<td role="cell" class="">{{ dpe.libelle}}</td>
														<td role="cell" class="">{{ dpe.nombre_annonces}}</td>
														<td role="cell" class="">{{ dpe.prix_m2_median}}</td>
													</tr>
												</tbody>
											</table>
										</div>
									</div>
									
									
									
									<div class="dropdown-item" style="text-align: center;">
<!--										<Button style="min-width:270px" :label="$t('Consulter les biens à louer')" class="p-button-rounded" @click="setBiensComparables(true); $store.dispatch('set_view', { view: 'ancien-locatif' })"  />-->
										<Button style="min-width:270px" :label="$t('Consulter les biens à louer')" class="p-button-rounded" @click="$store.dispatch('set_view', { view: 'locatif-offre' })" />
									</div>
									<div class="dropdown-item" style="text-align: center;">
										<Button style="min-width:270px" :label="$t('Afficher les résidences sociales')" class="p-button-rounded" @click="$store.dispatch('set_view', { view: 'rpls' })"  />
									</div>
									
									<div class="grid">
										<div v-if="activeTab_geo=='rayon'	&& activeTab_typo=='typ'" class="col-12"><highcharts :options="chartOptionsPrice_typo_rayon"></highcharts></div>
										<div v-if="activeTab_geo=='iris'	&& activeTab_typo=='typ'" class="col-12"><highcharts :options="chartOptionsPrice_typo_iris"></highcharts></div>
										<div v-if="activeTab_geo=='ville'	&& activeTab_typo=='typ'" class="col-12"><highcharts :options="chartOptionsPrice_typo_ville"></highcharts></div>
										<div v-if="activeTab_geo=='rayon'	&& activeTab_typo=='dpe'" class="col-12"><highcharts :options="chartOptionsPrice_dpe_rayon"></highcharts></div>
										<div v-if="activeTab_geo=='iris'	&& activeTab_typo=='dpe'" class="col-12"><highcharts :options="chartOptionsPrice_dpe_iris"></highcharts></div>
										<div v-if="activeTab_geo=='ville'	&& activeTab_typo=='dpe'" class="col-12"><highcharts :options="chartOptionsPrice_dpe_ville"></highcharts></div>
									</div>
									<div class="grid">
										<div v-if="activeTab_geo=='rayon'	&& activeTab_typo=='typ'" class="col-12"><highcharts :options="chartOptionsPriceM2_typo_rayon"></highcharts></div>
										<div v-if="activeTab_geo=='iris'	&& activeTab_typo=='typ'" class="col-12"><highcharts :options="chartOptionsPriceM2_typo_iris"></highcharts></div>
										<div v-if="activeTab_geo=='ville'	&& activeTab_typo=='typ'" class="col-12"><highcharts :options="chartOptionsPriceM2_typo_ville"></highcharts></div>
										<div v-if="activeTab_geo=='rayon'	&& activeTab_typo=='dpe'" class="col-12"><highcharts :options="chartOptionsPriceM2_dpe_rayon"></highcharts></div>
										<div v-if="activeTab_geo=='iris'	&& activeTab_typo=='dpe'" class="col-12"><highcharts :options="chartOptionsPriceM2_dpe_iris"></highcharts></div>
										<div v-if="activeTab_geo=='ville'	&& activeTab_typo=='dpe'" class="col-12"><highcharts :options="chartOptionsPriceM2_dpe_ville"></highcharts></div>
									</div>
									<div class="grid">
										<div v-if="activeTab_geo=='rayon'	&& activeTab_typo=='typ'" class="col-12"><highcharts :options="chartOptionsVolume_typo_rayon"></highcharts></div>
										<div v-if="activeTab_geo=='iris'	&& activeTab_typo=='typ'" class="col-12"><highcharts :options="chartOptionsVolume_typo_iris"></highcharts></div>
										<div v-if="activeTab_geo=='ville'	&& activeTab_typo=='typ'" class="col-12"><highcharts :options="chartOptionsVolume_typo_ville"></highcharts></div>
										<div v-if="activeTab_geo=='rayon'	&& activeTab_typo=='dpe'" class="col-12"><highcharts :options="chartOptionsVolume_dpe_rayon"></highcharts></div>
										<div v-if="activeTab_geo=='iris'	&& activeTab_typo=='dpe'" class="col-12"><highcharts :options="chartOptionsVolume_dpe_iris"></highcharts></div>
										<div v-if="activeTab_geo=='ville'	&& activeTab_typo=='dpe'" class="col-12"><highcharts :options="chartOptionsVolume_dpe_ville"></highcharts></div>
									</div>									
									<div style="text-align: center;font-familiy: Helvetica, Arial, sans-serif;font-size: 1.4em;color: rgb(51, 51, 51);font-weight: bold;fill: rgb(51, 51, 51);">Distribution des prix</div>									
									<div class="grid">
										<div v-if="activeTab_geo=='rayon'	&& activeTab_typo=='typ'" class="col-12"><apexchart type="heatmap" height="250px" width="100%" :options="chartOptionsDistribution_typo_rayon.chartOptions" :series="chartOptionsDistribution_typo_rayon.series"></apexchart></div>
										<div v-if="activeTab_geo=='iris'	&& activeTab_typo=='typ'" class="col-12"><apexchart type="heatmap" height="250px" width="100%" :options="chartOptionsDistribution_typo_iris.chartOptions" :series="chartOptionsDistribution_typo_iris.series"></apexchart></div>
										<div v-if="activeTab_geo=='ville'	&& activeTab_typo=='typ'" class="col-12"><apexchart type="heatmap" height="250px" width="100%" :options="chartOptionsDistribution_typo_ville.chartOptions" :series="chartOptionsDistribution_typo_ville.series"></apexchart></div>
										<div v-if="activeTab_geo=='rayon'	&& activeTab_typo=='dpe'" class="col-12"><apexchart type="heatmap" height="250px" width="100%" :options="chartOptionsDistribution_dpe_rayon.chartOptions" :series="chartOptionsDistribution_dpe_rayon.series"></apexchart></div>
										<div v-if="activeTab_geo=='iris'	&& activeTab_typo=='dpe'" class="col-12"><apexchart type="heatmap" height="250px" width="100%" :options="chartOptionsDistribution_dpe_iris.chartOptions" :series="chartOptionsDistribution_dpe_iris.series"></apexchart></div>
										<div v-if="activeTab_geo=='ville'	&& activeTab_typo=='dpe'" class="col-12"><apexchart type="heatmap" height="250px" width="100%" :options="chartOptionsDistribution_dpe_ville.chartOptions" :series="chartOptionsDistribution_dpe_ville.series"></apexchart></div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
import { mapGetters } from 'vuex'
// eslint-disable-next-line
import { i18n } from '../../main';
import Button from 'primevue/button';
import ToolSource from '../global/ToolSource';
import AnimatedTabs from '../global/AnimatedTabs.vue';
import { Chart } from 'highcharts-vue'
import Slider from 'primevue/slider';

export default {
	name: 'PaneAncien',
	data: function() {
		return {
			tabs_geo: [
			{ label: 'IRIS', name: 'iris' },
			{ label: 'Ville', name: 'ville' },
			{ label: 'Rayon', name: 'rayon' },
			],
			tabs_forme: [
			{ label: 'Tout', name: 'total' },
			{ label: 'Appartement', name: 'flat' },
			{ label: 'Maison', name: 'house' },
			],
			activeTab_geo: 'iris',
			activeTab_forme: 'total',
			activeTab_typo: 'typ',
			distance: 500,
			chartOptionsPrice_typo_rayon: {
				accessibility: {
					enabled: false
				},
				credits: {
					enabled: false
				},
				chart: { zoomType: 'xy' },
				title: { text: 'Historique des prix unitaires' },
				xAxis: { categories: [] },
				yAxis: {
					title: {
						text: null 
					},
					labels: {
						formatter: function() {
							const euro = new Intl.NumberFormat('fr-FR', {
								style: 'currency',
								currency: 'EUR',
								minimumFractionDigits: 0
							});
							let formatted = euro.format(this.value)
							formatted = formatted.replace(/\u00A0/g, ' ');
							return formatted
						}
					},
				}, // Pas de titre pour l'axe des ordonnées
				tooltip: { shared: true },
				series: []
			},
			chartOptionsPriceM2_typo_rayon: {
				accessibility: {
					enabled: false
				},
				credits: {
					enabled: false
				},
				chart: { zoomType: 'xy' },
				title: { text: 'Historique des prix au m²' },
				xAxis: { categories: [] },
				yAxis: {
					title: {
						text: null 
					},
					labels: {
						formatter: function() {
							const euro = new Intl.NumberFormat('fr-FR', {
								style: 'currency',
								currency: 'EUR',
								minimumFractionDigits: 0
							});
							let formatted = euro.format(this.value)
							formatted = formatted.replace(/\u00A0/g, ' ');
							return formatted
						}
					},
				}, // Pas de titre pour l'axe des ordonnées
				tooltip: { shared: true },
				series: []
			},
			chartOptionsVolume_typo_rayon: {
				accessibility: {
					enabled: false
				},
				credits: {
					enabled: false
				},
				chart: { zoomType: 'xy' },
				title: { text: 'Historique des volumes' },
				xAxis: { categories: [] },
				yAxis: {
					title: {
						text: null 
					},
					labels: {
						enabled: true,
					},
				}, // Pas de titre pour l'axe des ordonnées
				tooltip: { shared: true },
				series: []
			},


			chartOptionsPrice_typo_iris: {
				accessibility: {
					enabled: false
				},
				credits: {
					enabled: false
				},
				chart: { zoomType: 'xy' },
				title: { text: 'Historique des prix unitaires' },
				xAxis: { categories: [] },
				yAxis: {
					title: {
						text: null 
					},
					labels: {
						formatter: function() {
							const euro = new Intl.NumberFormat('fr-FR', {
								style: 'currency',
								currency: 'EUR',
								minimumFractionDigits: 0
							});
							let formatted = euro.format(this.value)
							formatted = formatted.replace(/\u00A0/g, ' ');
							return formatted
						}
					},
				}, // Pas de titre pour l'axe des ordonnées
				tooltip: { shared: true },
				series: []
			},
			chartOptionsPriceM2_typo_iris: {
				accessibility: {
					enabled: false
				},
				credits: {
					enabled: false
				},
				chart: { zoomType: 'xy' },
				title: { text: 'Historique des prix au m²' },
				xAxis: { categories: [] },
				yAxis: {
					title: {
						text: null 
					},
					labels: {
						formatter: function() {
							const euro = new Intl.NumberFormat('fr-FR', {
								style: 'currency',
								currency: 'EUR',
								minimumFractionDigits: 0
							});
							let formatted = euro.format(this.value)
							formatted = formatted.replace(/\u00A0/g, ' ');
							return formatted
						}
					},
				}, // Pas de titre pour l'axe des ordonnées
				tooltip: { shared: true },
				series: []
			},
			chartOptionsVolume_typo_iris: {
				accessibility: {
					enabled: false
				},
				credits: {
					enabled: false
				},
				chart: { zoomType: 'xy' },
				title: { text: 'Historique des volumes' },
				xAxis: { categories: [] },
				yAxis: {
					title: {
						text: null 
					},
					labels: {
						enabled: true,
					},
				}, // Pas de titre pour l'axe des ordonnées
				tooltip: { shared: true },
				series: []
			},


			chartOptionsPrice_typo_ville: {
				accessibility: {
					enabled: false
				},
				credits: {
					enabled: false
				},
				chart: { zoomType: 'xy' },
				title: { text: 'Historique des prix unitaires' },
				xAxis: { categories: [] },
				yAxis: {
					title: {
						text: null 
					},
					labels: {
						formatter: function() {
							const euro = new Intl.NumberFormat('fr-FR', {
								style: 'currency',
								currency: 'EUR',
								minimumFractionDigits: 0
							});
							let formatted = euro.format(this.value)
							formatted = formatted.replace(/\u00A0/g, ' ');
							return formatted
						}
					},
				}, // Pas de titre pour l'axe des ordonnées
				tooltip: { shared: true },
				series: []
			},
			chartOptionsPriceM2_typo_ville: {
				accessibility: {
					enabled: false
				},
				credits: {
					enabled: false
				},
				chart: { zoomType: 'xy' },
				title: { text: 'Historique des prix au m²' },
				xAxis: { categories: [] },
				yAxis: {
					title: {
						text: null 
					},
					labels: {
						formatter: function() {
							const euro = new Intl.NumberFormat('fr-FR', {
								style: 'currency',
								currency: 'EUR',
								minimumFractionDigits: 0
							});
							let formatted = euro.format(this.value)
							formatted = formatted.replace(/\u00A0/g, ' ');
							return formatted
						}
					},
				}, // Pas de titre pour l'axe des ordonnées
				tooltip: { shared: true },
				series: []
			},
			chartOptionsVolume_typo_ville: {
				accessibility: {
					enabled: false
				},
				credits: {
					enabled: false
				},
				chart: { zoomType: 'xy' },
				title: { text: 'Historique des volumes' },
				xAxis: { categories: [] },
				yAxis: {
					title: {
						text: null 
					},
					labels: {
						enabled: true,
					},
				}, // Pas de titre pour l'axe des ordonnées
				tooltip: { shared: true },
				series: []
			},

			chartOptionsPrice_dpe_rayon: {
				accessibility: {
					enabled: false
				},
				credits: {
					enabled: false
				},
				chart: { zoomType: 'xy' },
				title: { text: 'Historique des prix unitaires' },
				xAxis: { categories: [] },
				yAxis: {
					title: {
						text: null 
					},
					labels: {
						formatter: function() {
							const euro = new Intl.NumberFormat('fr-FR', {
								style: 'currency',
								currency: 'EUR',
								minimumFractionDigits: 0
							});
							let formatted = euro.format(this.value)
							formatted = formatted.replace(/\u00A0/g, ' ');
							return formatted
						}
					},
				}, // Pas de titre pour l'axe des ordonnées
				tooltip: { shared: true },
				series: []
			},
			chartOptionsPriceM2_dpe_rayon: {
				accessibility: {
					enabled: false
				},
				credits: {
					enabled: false
				},
				chart: { zoomType: 'xy' },
				title: { text: 'Historique des prix au m²' },
				xAxis: { categories: [] },
				yAxis: {
					title: {
						text: null 
					},
					labels: {
						formatter: function() {
							const euro = new Intl.NumberFormat('fr-FR', {
								style: 'currency',
								currency: 'EUR',
								minimumFractionDigits: 0
							});
							let formatted = euro.format(this.value)
							formatted = formatted.replace(/\u00A0/g, ' ');
							return formatted
						}
					},
				}, // Pas de titre pour l'axe des ordonnées
				tooltip: { shared: true },
				series: []
			},
			chartOptionsVolume_dpe_rayon: {
				accessibility: {
					enabled: false
				},
				credits: {
					enabled: false
				},
				chart: { zoomType: 'xy' },
				title: { text: 'Historique des volumes' },
				xAxis: { categories: [] },
				yAxis: {
					title: {
						text: null 
					},
					labels: {
						enabled: true,
					},
				}, // Pas de titre pour l'axe des ordonnées
				tooltip: { shared: true },
				series: []
			},


			chartOptionsPrice_dpe_iris: {
				accessibility: {
					enabled: false
				},
				credits: {
					enabled: false
				},
				chart: { zoomType: 'xy' },
				title: { text: 'Historique des prix unitaires' },
				xAxis: { categories: [] },
				yAxis: {
					title: {
						text: null 
					},
					labels: {
						formatter: function() {
							const euro = new Intl.NumberFormat('fr-FR', {
								style: 'currency',
								currency: 'EUR',
								minimumFractionDigits: 0
							});
							let formatted = euro.format(this.value)
							formatted = formatted.replace(/\u00A0/g, ' ');
							return formatted
						}
					},
				}, // Pas de titre pour l'axe des ordonnées
				tooltip: { shared: true },
				series: []
			},
			chartOptionsPriceM2_dpe_iris: {
				accessibility: {
					enabled: false
				},
				credits: {
					enabled: false
				},
				chart: { zoomType: 'xy' },
				title: { text: 'Historique des prix au m²' },
				xAxis: { categories: [] },
				yAxis: {
					title: {
						text: null 
					},
					labels: {
						formatter: function() {
							const euro = new Intl.NumberFormat('fr-FR', {
								style: 'currency',
								currency: 'EUR',
								minimumFractionDigits: 0
							});
							let formatted = euro.format(this.value)
							formatted = formatted.replace(/\u00A0/g, ' ');
							return formatted
						}
					},
				}, // Pas de titre pour l'axe des ordonnées
				tooltip: { shared: true },
				series: []
			},
			chartOptionsVolume_dpe_iris: {
				accessibility: {
					enabled: false
				},
				credits: {
					enabled: false
				},
				chart: { zoomType: 'xy' },
				title: { text: 'Historique des volumes' },
				xAxis: { categories: [] },
				yAxis: {
					title: {
						text: null 
					},
					labels: {
						enabled: true,
					},
				}, // Pas de titre pour l'axe des ordonnées
				tooltip: { shared: true },
				series: []
			},


			chartOptionsPrice_dpe_ville: {
				accessibility: {
					enabled: false
				},
				credits: {
					enabled: false
				},
				chart: { zoomType: 'xy' },
				title: { text: 'Historique des prix unitaires' },
				xAxis: { categories: [] },
				yAxis: {
					title: {
						text: null 
					},
					labels: {
						formatter: function() {
							const euro = new Intl.NumberFormat('fr-FR', {
								style: 'currency',
								currency: 'EUR',
								minimumFractionDigits: 0
							});
							let formatted = euro.format(this.value)
							formatted = formatted.replace(/\u00A0/g, ' ');
							return formatted
						}
					},
				}, // Pas de titre pour l'axe des ordonnées
				tooltip: { shared: true },
				series: []
			},
			chartOptionsPriceM2_dpe_ville: {
				accessibility: {
					enabled: false
				},
				credits: {
					enabled: false
				},
				chart: { zoomType: 'xy' },
				title: { text: 'Historique des prix au m²' },
				xAxis: { categories: [] },
				yAxis: {
					title: {
						text: null 
					},
					labels: {
						formatter: function() {
							const euro = new Intl.NumberFormat('fr-FR', {
								style: 'currency',
								currency: 'EUR',
								minimumFractionDigits: 0
							});
							let formatted = euro.format(this.value)
							formatted = formatted.replace(/\u00A0/g, ' ');
							return formatted
						}
					},
				}, // Pas de titre pour l'axe des ordonnées
				tooltip: { shared: true },
				series: []
			},
			chartOptionsVolume_dpe_ville: {
				accessibility: {
					enabled: false
				},
				credits: {
					enabled: false
				},
				chart: { zoomType: 'xy' },
				title: { text: 'Historique des volumes' },
				xAxis: { categories: [] },
				yAxis: {
					title: {
						text: null 
					},
					labels: {
						enabled: true,
					},
				}, // Pas de titre pour l'axe des ordonnées
				tooltip: { shared: true },
				series: []
			},


			chartOptionsDistribution_typo_rayon: {
				series: [],
				chartOptions: {
					chart: {
						type: 'heatmap',
						height: 400,
					},
					dataLabels: {
						enabled: false
					},
					colors: ["#008FFB"],
					xaxis: {
						type: 'category',
						categories: []
					},
				},

			},
			chartOptionsDistribution_typo_iris: {
				series: [],
				chartOptions: {
					chart: {
						type: 'heatmap',
						height: 400,
					},
					dataLabels: {
						enabled: false
					},
					colors: ["#008FFB"],
					xaxis: {
						type: 'category',
						categories: []
					},
				},

			},
			chartOptionsDistribution_typo_ville: {
				series: [],
				chartOptions: {
					chart: {
						type: 'heatmap',
						height: 400,
					},
					dataLabels: {
						enabled: false
					},
					colors: ["#008FFB"],
					xaxis: {
						type: 'category',
						categories: []
					},
				},

			},

			chartOptionsDistribution_dpe_rayon: {
				series: [],
				chartOptions: {
					chart: {
						type: 'heatmap',
						height: 400,
					},
					dataLabels: {
						enabled: false
					},
					colors: ["#008FFB"],
					xaxis: {
						type: 'category',
						categories: []
					},
				},

			},
			chartOptionsDistribution_dpe_iris: {
				series: [],
				chartOptions: {
					chart: {
						type: 'heatmap',
						height: 400,
					},
					dataLabels: {
						enabled: false
					},
					colors: ["#008FFB"],
					xaxis: {
						type: 'category',
						categories: []
					},
				},

			},
			chartOptionsDistribution_dpe_ville: {
				series: [],
				chartOptions: {
					chart: {
						type: 'heatmap',
						height: 400,
					},
					dataLabels: {
						enabled: false
					},
					colors: ["#008FFB"],
					xaxis: {
						type: 'category',
						categories: []
					},
				},

			},
			
			detail: '',
		}
	},

	computed: Object.assign(mapGetters([ 'app', 'map' ]), {
		LibelleSecteur: function() {
			let r = ''
			try {
				r = `${this.app.adr_init.iris_label} (${this.app.adr_init.iris_code})`
				switch (this.activeTab_geo) {
					case 'ville':
						r = `${this.app.adr_init.city} (${this.app.adr_init.insee})`
						break;
					case 'rayon':
						r = `à ${this.app.locatif_rayon_distance.city} m`
						break;
				}
			} catch (error) {
				r = 'Module locatif'
			}
			return r;
		},
		dataPrix: function() {
			let o = this.app.locatif_iris
			switch (this.activeTab_geo) {
				case 'ville':
					o = this.app.locatif_ville
					if (this.activeTab_forme != 'total') {
						o = this.app['locatif_ville_' + this.activeTab_forme]
					}
					break;
				case 'rayon':
					o = this.app.locatif_rayon
					if (this.activeTab_forme != 'total') {
						o = this.app['locatif_rayon_' + this.activeTab_forme]
					}
					break;
				default:
					o = this.app.locatif_iris
					if (this.activeTab_forme != 'total') {
						o = this.app['locatif_iris_' + this.activeTab_forme]
					}
					break;
			}
			return o
		},
		dataTyp: function() {
			let d = []
			let o = this.dataPrix
			for (let i = 1; i < 6; i++) {
				let z = {libelle: 'T' + i, nombre_annonces: 0, surface_mediane: '-', prix_m2_median: '-'}
				if (o.typo && o.typo['t' + i]) {
					z.libelle = 'T' + i
					z.nombre_annonces = o.typo['t' + i].nombre_annonces
					z.surface_mediane = Math.round(o.typo['t' + i].surface_mediane,2) + ' m²'
					z.prix_m2_median = this.DonneValeurPrix(Math.round(o.typo['t' + i].prix_m2_median)) + "/m²"
				}
				d.push(z)
			}
			return d
		},		
		dataDpe: function() {
			let d = []
			let dpe = ['A', 'B', 'C', 'D', 'E', 'F', 'G']
			let o = this.dataPrix
			for (let i = 0; i < dpe.length; i++) {
				if (o.energie[dpe[i]]) {
					let z = {}
					z.libelle = dpe[i]
					z.nombre_annonces = o.energie[dpe[i]].nombre_annonces
					z.prix_m2_median = this.DonneValeurPrix(Math.round(o.energie[dpe[i]].prix_m2_median)) + "/m²"
					d.push(z)
				}
			}
			return d
		},		
	}),

	methods: {
		setBiensComparables: function(p) {
			this.$store.dispatch('set_bienslocatifs', { value: p })
		},
		Appliquer_Rayon: function() {
			this.$store.commit('SET_VALUE', {c: 'locatif_rayon_distance', v: this.distance});
			this.activeTab_geo = ''
			this.$store.dispatch('load_locatif_rayon', { c: 3 })
			this.activeTab_geo = 'rayon'
		},
		handleTabChange_geo: function(payload) {
			this.activeTab_geo = payload.tab.name;
			this.$store.dispatch('set_contour_prix_locatif', { contour: this.activeTab_geo + "_" + this.activeTab_forme })
		},
		handleTabChange_forme: function(payload) {
			this.activeTab_forme = payload.tab.name;
			this.$store.dispatch('set_contour_prix_locatif', { contour: this.activeTab_geo + "_" + this.activeTab_forme })
			this.maj_graph_histo()
		},
		toggle_filter: function() {
			this.$store.dispatch('set_filters_ancien');
		},

		DonneValeurPrix: function(val) {
			const euro = new Intl.NumberFormat('fr-FR', {
				style: 'currency',
				currency: 'EUR',
				minimumFractionDigits: 0
			});
			if (val==0) {
				return "-"
			} else {
				return euro.format(val)
			}
		},

		maj_graph_histo: function() {
			const colorMap = {
				"T1": "#008FFB",  // Rouge
				"T2": "#00E396",  // Vert
				"T3": "#FEB019",  // Bleu
				"T4": "#FF4560",  // Jaune
				"T5": "#7c74c4"   // Violet
			};
			// iris
			let locatif_rayon 	= 'locatif_rayon'
			let locatif_iris 	= 'locatif_iris'
			let locatif_ville 	= 'locatif_ville'
			switch (this.activeTab_forme) {
				case 'flat':
					locatif_rayon 	= locatif_rayon 	+ "_flat"
					locatif_iris 	= locatif_iris 		+ "_flat"
					locatif_ville 	= locatif_ville 	+ "_flat"
					break;
			
				case 'house':
					locatif_rayon 	= locatif_rayon 	+ "_house"
					locatif_iris 	= locatif_iris 		+ "_house"
					locatif_ville 	= locatif_ville 	+ "_house"
					break;
		
				default:
					break;
			}
			if ((this.app.locatif_rayon && (this.activeTab_forme=='total')) || (this.app.locatif_rayon_flat && (this.activeTab_forme=='flat')) || (this.app.locatif_rayon_house && (this.activeTab_forme=='house'))) {

				let rawData_rayon = this.app[locatif_rayon].historique
				// iris
				let rawData_iris = this.app[locatif_iris].historique
				// iris
				let rawData_ville = this.app[locatif_ville].historique
				// Extraction des labels de période pour l'axe x
				const periodLabels = rawData_iris.map(item => item.periode.label);

				const euro = new Intl.NumberFormat('fr-FR', {
					style: 'currency',
					currency: 'EUR',
					minimumFractionDigits: 0
				});

				// Extraction des labels de période pour l'axe x
				let rangeLabels_rayon = [];
				let rangeLabels_iris = [];
				let rangeLabels_ville = [];
				for (let index = 0; index < this.app[locatif_rayon].distribution_range.length; index++) {
					const element = this.app[locatif_rayon].distribution_range[index];
					switch (index) {
						case 0:
							rangeLabels_rayon.push('< ' + euro.format(element.to).replace(/\u00A0/g, ' '))
							break;
					
						case (this.app[locatif_rayon].distribution_range.length-1):
							rangeLabels_rayon.push('> ' + euro.format(element.from).replace(/\u00A0/g, ' '))
							break;
					
						default:
							rangeLabels_rayon.push('< ' + euro.format(element.to).replace(/\u00A0/g, ' '))
							break;
					} 
				}
				for (let index = 0; index < this.app[locatif_iris].distribution_range.length; index++) {
					const element = this.app[locatif_iris].distribution_range[index];
					switch (index) {
						case 0:
							rangeLabels_iris.push('< ' + euro.format(element.to).replace(/\u00A0/g, ' '))
							break;
					
						case (this.app[locatif_iris].distribution_range.length-1):
							rangeLabels_iris.push('> ' + euro.format(element.from).replace(/\u00A0/g, ' '))
							break;
					
						default:
							rangeLabels_iris.push('< ' + euro.format(element.to).replace(/\u00A0/g, ' '))
							break;
					} 
				}
				for (let index = 0; index < this.app[locatif_ville].distribution_range.length; index++) {
					const element = this.app[locatif_ville].distribution_range[index];
					switch (index) {
						case 0:
							rangeLabels_ville.push('< ' + euro.format(element.to).replace(/\u00A0/g, ' '))
							break;
					
						case (this.app[locatif_ville].distribution_range.length-1):
							rangeLabels_ville.push('> ' + euro.format(element.from).replace(/\u00A0/g, ' '))
							break;
					
						default:
							rangeLabels_ville.push('< ' + euro.format(element.to).replace(/\u00A0/g, ' '))
							break;
					} 
				}
				

				// Liste des typologies à traiter
				const typologies = ['t1', 't2', 't3', 't4', 't5'];
				// Liste des dpe à traiter
				const dpe = ['A', 'B', 'C', 'D', 'E', 'F', 'G'];

				const priceSeriesDistribution_rayon = [];
				for (let index = 0; index < typologies.length; index++) {
					let d = []
					for (let index2 = 0; index2 < this.app[locatif_rayon].distribution_typo[typologies[index]].length; index2++) {
						d.push(this.app[locatif_rayon].distribution_typo[typologies[index]][index2])
					}
					priceSeriesDistribution_rayon.push({name: typologies[index].toUpperCase(), data: d})
				}

				const priceSeriesDistribution_iris = [];
				for (let index = 0; index < typologies.length; index++) {
					let d = []
					for (let index2 = 0; index2 < this.app[locatif_iris].distribution_typo[typologies[index]].length; index2++) {
						d.push(this.app[locatif_iris].distribution_typo[typologies[index]][index2])
					}
					priceSeriesDistribution_iris.push({name: typologies[index].toUpperCase(), data: d})
				}

				const priceSeriesDistribution_ville = [];
				for (let index = 0; index < typologies.length; index++) {
					let d = []
					for (let index2 = 0; index2 < this.app[locatif_ville].distribution_typo[typologies[index]].length; index2++) {
						d.push(this.app[locatif_ville].distribution_typo[typologies[index]][index2])
					}
					priceSeriesDistribution_ville.push({name: typologies[index].toUpperCase(), data: d})
				}

				const priceSeriesDistribution_Dpe_rayon = [];
				for (let index = 0; index < dpe.length; index++) {
					let d = []
					if (this.app[locatif_rayon].distribution_dpe[dpe[index]]) {
						for (let index2 = 0; index2 < this.app[locatif_rayon].distribution_dpe[dpe[index]].length; index2++) {
							d.push(this.app[locatif_rayon].distribution_dpe[dpe[index]][index2])
						}
						priceSeriesDistribution_Dpe_rayon.push({name: dpe[index].toUpperCase(), data: d})
					}
				}

				const priceSeriesDistribution_Dpe_iris = [];
				for (let index = 0; index < dpe.length; index++) {
					let d = []
					if (this.app[locatif_iris].distribution_dpe[dpe[index]]) {
						for (let index2 = 0; index2 < this.app[locatif_iris].distribution_dpe[dpe[index]].length; index2++) {
							d.push(this.app[locatif_iris].distribution_dpe[dpe[index]][index2])
						}
						priceSeriesDistribution_Dpe_iris.push({name: dpe[index].toUpperCase(), data: d})
					}
				}

				const priceSeriesDistribution_Dpe_ville = [];
				for (let index = 0; index < dpe.length; index++) {
					let d = []
					if (this.app[locatif_ville].distribution_dpe[dpe[index]]) {
						for (let index2 = 0; index2 < this.app[locatif_ville].distribution_dpe[dpe[index]].length; index2++) {
							d.push(this.app[locatif_ville].distribution_dpe[dpe[index]][index2])
						}
						priceSeriesDistribution_Dpe_ville.push({name: dpe[index].toUpperCase(), data: d})
					}
				}

				let chartOptions_rayon = {
					chart: {
						type: 'heatmap',
						toolbar: {
							show: false
						}
					},
					dataLabels: {
						enabled: false
					},
					colors: ["#008FFB"],
					xaxis: {
						type: 'category',
						categories: rangeLabels_rayon
					},
				}
				let chartOptions_iris = {
					chart: {
						type: 'heatmap',
						toolbar: {
							show: false
						}
					},
					dataLabels: {
						enabled: false
					},
					colors: ["#008FFB"],
					xaxis: {
						type: 'category',
						categories: rangeLabels_iris
					},
				}
				let chartOptions_ville = {
					chart: {
						type: 'heatmap',
						toolbar: {
							show: false
						}
					},
					dataLabels: {
						enabled: false
					},
					colors: ["#008FFB"],
					xaxis: {
						type: 'category',
						categories: rangeLabels_ville
					},
				}
				this.chartOptionsDistribution_typo_rayon.chartOptions = chartOptions_rayon
				this.chartOptionsDistribution_typo_rayon.series = priceSeriesDistribution_rayon;

				this.chartOptionsDistribution_typo_iris.chartOptions = chartOptions_iris
				this.chartOptionsDistribution_typo_iris.series = priceSeriesDistribution_iris;

				this.chartOptionsDistribution_typo_ville.chartOptions = chartOptions_ville
				this.chartOptionsDistribution_typo_ville.series = priceSeriesDistribution_ville;

				this.chartOptionsDistribution_dpe_rayon.chartOptions = chartOptions_rayon
				this.chartOptionsDistribution_dpe_rayon.series = priceSeriesDistribution_Dpe_rayon;

				this.chartOptionsDistribution_dpe_iris.chartOptions = chartOptions_iris
				this.chartOptionsDistribution_dpe_iris.series = priceSeriesDistribution_Dpe_iris;

				this.chartOptionsDistribution_dpe_ville.chartOptions = chartOptions_ville
				this.chartOptionsDistribution_dpe_ville.series = priceSeriesDistribution_Dpe_ville;

				// Préparation des séries pour chaque graphique
				const priceSeries_rayon = [];
				const priceM2Series_rayon = [];
				const volumeSeries_rayon = [];

				const priceSeries_iris = [];
				const priceM2Series_iris = [];
				const volumeSeries_iris = [];

				const priceSeries_ville = [];
				const priceM2Series_ville = [];
				const volumeSeries_ville = [];

				// Préparation des séries pour chaque graphique
				const dpe_priceSeries_rayon = [];
				const dpe_priceM2Series_rayon = [];
				const dpe_volumeSeries_rayon = [];

				const dpe_priceSeries_iris = [];
				const dpe_priceM2Series_iris = [];
				const dpe_volumeSeries_iris = [];

				const dpe_priceSeries_ville = [];
				const dpe_priceM2Series_ville = [];
				const dpe_volumeSeries_ville = [];

				typologies.forEach(typ => {
					const upperTyp = typ.toUpperCase();
					priceSeries_rayon.push({
					name: upperTyp,
					data: rawData_rayon.map(item => item.typo && item.typo[typ] ? item.typo[typ].prix_median : null),
					color: colorMap[upperTyp]
					});
					priceM2Series_rayon.push({
					name: upperTyp,
					data: rawData_rayon.map(item => item.typo && item.typo[typ] ? item.typo[typ].prix_m2_median : null),
					color: colorMap[upperTyp]
					});
					volumeSeries_rayon.push({
					name: upperTyp,
					data: rawData_rayon.map(item => item.typo && item.typo[typ] ? item.typo[typ].nombre_annonces : null),
					color: colorMap[upperTyp]
					});
				});
				priceM2Series_rayon.push({
					name: 'total',
					data: rawData_rayon.map(item => item.total ? item.total.prix_m2_median : null),
					color: "#000000"
				});

				typologies.forEach(typ => {
					const upperTyp = typ.toUpperCase();
					priceSeries_iris.push({
					name: upperTyp,
					data: rawData_iris.map(item => item.typo && item.typo[typ] ? item.typo[typ].prix_median : null),
					color: colorMap[upperTyp]
					});
					priceM2Series_iris.push({
					name: upperTyp,
					data: rawData_iris.map(item => item.typo && item.typo[typ] ? item.typo[typ].prix_m2_median : null),
					color: colorMap[upperTyp]
					});
					volumeSeries_iris.push({
					name: upperTyp,
					data: rawData_iris.map(item => item.typo && item.typo[typ] ? item.typo[typ].nombre_annonces : null),
					color: colorMap[upperTyp]
					});
				});
				
				priceM2Series_iris.push({
					name: 'total',
					data: rawData_iris.map(item => item.total ? item.total.prix_m2_median : null),
					color: "#000000"
				});

				typologies.forEach(typ => {
					const upperTyp = typ.toUpperCase();
					priceSeries_ville.push({
					name: upperTyp,
					data: rawData_ville.map(item => item.typo && item.typo[typ] ? item.typo[typ].prix_median : null),
					color: colorMap[upperTyp]
					});
					priceM2Series_ville.push({
					name: upperTyp,
					data: rawData_ville.map(item => item.typo && item.typo[typ] ? item.typo[typ].prix_m2_median : null),
					color: colorMap[upperTyp]
					});
					volumeSeries_ville.push({
					name: upperTyp,
					data: rawData_ville.map(item => item.typo && item.typo[typ] ? item.typo[typ].nombre_annonces : null),
					color: colorMap[upperTyp]
					});
				});
				
				priceM2Series_ville.push({
					name: 'total',
					data: rawData_ville.map(item => item.total ? item.total.prix_m2_median : null),
					color: "#000000"
				});






				dpe.forEach(typ => {
					const upperTyp = typ.toUpperCase();
					dpe_priceSeries_rayon.push({
					name: upperTyp,
					data: rawData_rayon.map(item => item.energie && item.energie[typ] ? item.energie[typ].prix_median : null),
					color: colorMap[upperTyp]
					});
					dpe_priceM2Series_rayon.push({
					name: upperTyp,
					data: rawData_rayon.map(item => item.energie && item.energie[typ] ? item.energie[typ].prix_m2_median : null),
					color: colorMap[upperTyp]
					});
					dpe_volumeSeries_rayon.push({
					name: upperTyp,
					data: rawData_rayon.map(item => item.energie && item.energie[typ] ? item.energie[typ].nombre_annonces : null),
					color: colorMap[upperTyp]
					});
				});
				
				dpe_priceM2Series_rayon.push({
					name: 'total',
					data: rawData_rayon.map(item => item.total ? item.total.prix_m2_median : null),
					color: "#000000"
				});

				dpe.forEach(typ => {
					const upperTyp = typ.toUpperCase();
					dpe_priceSeries_iris.push({
					name: upperTyp,
					data: rawData_iris.map(item => item.energie && item.energie[typ] ? item.energie[typ].prix_median : null),
					color: colorMap[upperTyp]
					});
					dpe_priceM2Series_iris.push({
					name: upperTyp,
					data: rawData_iris.map(item => item.energie && item.energie[typ] ? item.energie[typ].prix_m2_median : null),
					color: colorMap[upperTyp]
					});
					dpe_volumeSeries_iris.push({
					name: upperTyp,
					data: rawData_iris.map(item => item.energie && item.energie[typ] ? item.energie[typ].nombre_annonces : null),
					color: colorMap[upperTyp]
					});
				});
				
				dpe_priceM2Series_iris.push({
					name: 'total',
					data: rawData_iris.map(item => item.total ? item.total.prix_m2_median : null),
					color: "#000000"
				});

				dpe.forEach(typ => {
					const upperTyp = typ.toUpperCase();
					dpe_priceSeries_ville.push({
					name: upperTyp,
					data: rawData_ville.map(item => item.energie && item.energie[typ] ? item.energie[typ].prix_median : null),
					color: colorMap[upperTyp]
					});
					dpe_priceM2Series_ville.push({
					name: upperTyp,
					data: rawData_ville.map(item => item.energie && item.energie[typ] ? item.energie[typ].prix_m2_median : null),
					color: colorMap[upperTyp]
					});
					dpe_volumeSeries_ville.push({
					name: upperTyp,
					data: rawData_ville.map(item => item.energie && item.energie[typ] ? item.energie[typ].nombre_annonces : null),
					color: colorMap[upperTyp]
					});
				});
				
				dpe_priceM2Series_ville.push({
					name: 'total',
					data: rawData_ville.map(item => item.total ? item.total.prix_m2_median : null),
					color: "#000000"
				});

				// Mise à jour des options pour chaque graphique
				this.chartOptionsPrice_typo_iris.xAxis.categories = periodLabels;
				this.chartOptionsPrice_typo_iris.series = priceSeries_iris;

				this.chartOptionsPriceM2_typo_iris.xAxis.categories = periodLabels;
				this.chartOptionsPriceM2_typo_iris.series = priceM2Series_iris;

				this.chartOptionsVolume_typo_iris.xAxis.categories = periodLabels;
				this.chartOptionsVolume_typo_iris.series = volumeSeries_iris;

				// Mise à jour des options pour chaque graphique
				this.chartOptionsPrice_dpe_iris.xAxis.categories = periodLabels;
				this.chartOptionsPrice_dpe_iris.series = dpe_priceSeries_iris;

				this.chartOptionsPriceM2_dpe_iris.xAxis.categories = periodLabels;
				this.chartOptionsPriceM2_dpe_iris.series = dpe_priceM2Series_iris;

				this.chartOptionsVolume_dpe_iris.xAxis.categories = periodLabels;
				this.chartOptionsVolume_dpe_iris.series = dpe_volumeSeries_iris;




				// Mise à jour des options pour chaque graphique
				this.chartOptionsPrice_typo_rayon.xAxis.categories = periodLabels;
				this.chartOptionsPrice_typo_rayon.series = priceSeries_rayon;

				this.chartOptionsPriceM2_typo_rayon.xAxis.categories = periodLabels;
				this.chartOptionsPriceM2_typo_rayon.series = priceM2Series_rayon;

				this.chartOptionsVolume_typo_rayon.xAxis.categories = periodLabels;
				this.chartOptionsVolume_typo_rayon.series = volumeSeries_rayon;

				// Mise à jour des options pour chaque graphique
				this.chartOptionsPrice_dpe_rayon.xAxis.categories = periodLabels;
				this.chartOptionsPrice_dpe_rayon.series = dpe_priceSeries_rayon;

				this.chartOptionsPriceM2_dpe_rayon.xAxis.categories = periodLabels;
				this.chartOptionsPriceM2_dpe_rayon.series = dpe_priceM2Series_rayon;

				this.chartOptionsVolume_dpe_rayon.xAxis.categories = periodLabels;
				this.chartOptionsVolume_dpe_rayon.series = dpe_volumeSeries_rayon;




				// Mise à jour des options pour chaque graphique
				this.chartOptionsPrice_typo_ville.xAxis.categories = periodLabels;
				this.chartOptionsPrice_typo_ville.series = priceSeries_ville;

				this.chartOptionsPriceM2_typo_ville.xAxis.categories = periodLabels;
				this.chartOptionsPriceM2_typo_ville.series = priceM2Series_ville;

				this.chartOptionsVolume_typo_ville.xAxis.categories = periodLabels;
				this.chartOptionsVolume_typo_ville.series = volumeSeries_ville;

				// Mise à jour des options pour chaque graphique
				this.chartOptionsPrice_dpe_ville.xAxis.categories = periodLabels;
				this.chartOptionsPrice_dpe_ville.series = dpe_priceSeries_ville;

				this.chartOptionsPriceM2_dpe_ville.xAxis.categories = periodLabels;
				this.chartOptionsPriceM2_dpe_ville.series = dpe_priceM2Series_ville;

				this.chartOptionsVolume_dpe_ville.xAxis.categories = periodLabels;
				this.chartOptionsVolume_dpe_ville.series = dpe_volumeSeries_ville;

			}

		},

	},
	
	watch: {
		'app.locatif_rayon_loading': function(v) {
			if (v==0) {
				this.maj_graph_histo()
			}
		},

		'app.locatif_rayon': function() {
			this.maj_graph_histo()
		},

		'app.locatif_iris': function() {
			this.maj_graph_histo()
		},

		'app.locatif_ville': function() {
			this.maj_graph_histo()
		},

		'app.view': function(newValue) {
			if (newValue=='locatif-adeq') {
				this.$store.dispatch('set_contour_prix_locatif', { contour: this.activeTab_geo + "_" + this.activeTab_forme })
			}
			else {
				this.$store.dispatch('set_contour_prix_locatif', { contour: false })
			}
		},
	},

	mounted: function() {
		//this.handleTabChange_geo({ tab: {name: 'iris'}})
		this.maj_graph_histo()
	},
	
	components: { Slider, highcharts: Chart, AnimatedTabs, ToolSource, Button }
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/app'
@import '../../assets/css/grid'



.tab_.active {
    color: white;
    background-color: #6c5ce7 !important;
}

.panecontent
	top 0px

.programs-list
	height 100%
	background-color #ffffff !important
	.programs-list-content
		background-color #ffffff !important

.module_ret
	width PaneLeftSize
	height 60px
	vertical-align middle
	background-color palette_gris
	border-bottom 1px solid palette_gris_bordure
	border-right 1px solid palette_gris_bordure

.pane-ancien-enter
	transform translateX(100%)
.pane-ancien-leave-to
	transform translateX(-100%)

.bar
	height 16px
	margin 0 0 16px 0
	border-radius radius
	
.pan-ancien
	position absolute
	top headersize
	float left
	transition 0.5s easeOutQuart
	background-color #ffffff

.dropdown.is-spaced .dropdown-item.is-media
	display flex
	align-items center

.dropdown .dropdown-menu .dropdown-item
	color hsl(232deg, 14%, 68%)
	font-family "Roboto", sans-serif
.dropdown-item
	padding 0.5rem 1rem
	font-size 0.85rem
	color hsl(232deg, 14%, 68%)
	transition all 0.3s
a.dropdown-item, button.dropdown-item
	padding-right 3rem
	text-align inherit
	white-space nowrap
	width 100%

.dropdown-item
	color hsl(0deg, 0%, 29%)
	display block
	font-size 0.875rem
	line-height 1.5
	padding 0.375rem 1rem
	position relative
	
.dropdown-divider
	background-color #ccc
	border none
	display block
	height 1px
	margin 0.5rem 0

.unmodule_sous2
	vertical-align middle
	padding 0px
	text-align center
	width PaneLeftSize
	width calc(100% - 240px)
	left 120px
	line-height 60px
	p
		font-size 18px	
		color #333 !important
		font-weight 600 !important

:deep(.p-button-label) {
	font-weight: 400 !important;
}	
.results {
	display: flex;
	//grid-template-columns: repeat(3, 1fr);
	gap: 20px;
	margin-bottom: 10px;
	justify-content: space-around;
	flex-direction: row;
	flex-wrap: wrap;
}

.result-card {
	background-color: #ffffff;
	border-radius: 12px;
	padding: 10px;
	//box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
	text-align: center;
	//border: 1px solid  #e2e8f0;
	//transition: all 0.2s;
	//min-width: 180px;
}

.result-card:hover {
	//transform: translateY(-5px);
	//box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}

.result-icon {
	width: 60px;
	height: 60px;
	border-radius: 50%;
	background-color: #ede8ff;
	color: #6a3de8;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0 auto 15px;
}

.result-icon i {
	font-size: 24px;
}

.result-label {
	color: #718096;
	margin-bottom: 10px;
	font-size: 0.9rem;
}

.result-value {
	font-size: 1.4rem;
	font-weight: 700;
	color: #6a3de8;
}

.tabs {
	display: flex;
	border-bottom: 1px solid #f1f3f5;
	background-color: #f8f9fa;
}

.tab {
	padding: 8px 15px;
	cursor: pointer;
	font-size: 13px;
	transition: all 0.2s ease;
	border-bottom: 2px solid transparent;
}

.tab.active {
	border-bottom: 2px solid #6c5ce7;
	color: #6c5ce7;
	font-weight: 500;
}

body {
	background-color: #f8f9fa;
	color: #343a40;
	line-height: 1.6;
	font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

thead {
    display: table-header-group;
    vertical-align: middle;
    unicode-bidi: isolate;
    border-color: inherit;
}
.p-datatable .p-datatable-thead>tr>th {
    text-align: left;
    padding: 1rem 1rem;
    border: 1px solid #dee2e6;
    border-width: 0 0 1px 0;
    font-weight: 700;
    color: #343a40;
    background: #f8f9fa;
    transition: box-shadow .2s;
}
.slider-container {
	width: 100%;
	display: flex;
	flex-direction: column;
}

.slider {
	-webkit-appearance: none;
	width: 100%;
	height: 6px;
	border-radius: 3px;
	background: #d3d3d3;
	outline: none;
	margin-bottom: 10px;
}

.slider::-webkit-slider-thumb {
	-webkit-appearance: none;
	appearance: none;
	width: 20px;
	height: 20px;
	border-radius: 50%;
	background: #7B35E9;
	cursor: pointer;
}

.slider::-moz-range-thumb {
	width: 20px;
	height: 20px;
	border-radius: 50%;
	background: #7B35E9;
	cursor: pointer;
}

.slider-value {
	text-align: center;
	font-size: 16px;
	color: #7B35E9;
	font-weight: bold;
}

.slider-labels {
	display: flex;
	justify-content: space-between;
	margin-top: 5px;
	font-size: 12px;
	color: #777;
}
.refresh-btn {
	background-color: #7B35E9;
	color: white;
	border: none;
	padding: 10px;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 10px;
	margin: 20px auto;
	font-size: 14px;
	width: 100%;
	max-width: 250px;
	border-radius: 4px;
}

.refresh-btn:hover {
	background-color: #6929c4;
}

.refresh-icon {
	margin-right: 5px;
}

.grid {
	margin-bottom: 15px;
	border-bottom: 1px solid #ccc;
}

.infinite-loader
	position absolute
	left 16px
	top calc(50% - 64px)
	.loader
		display inline-block
		background main_color
		border none
		border-radius 100%
		color #fff
		.icon
			display block
			animation rotate 1.5s infinite linear

</style>
