<template>
		<div class="pane" :class="app.viewlayer" v-if="app.module_estimmo">
			<div class="content">
				<div id="estimo" style="min-height:200px;">
					<div v-if="app.loading_estimmo" class="infinite-loader" style="width: 100%;display: flex;justify-content: center;padding-left: 30px;">
						<div class="loader"><icon file="loader" :size="64" style="color:#333" /></div>
					</div>
					<div style="display: flex;flex-wrap: wrap;justify-content: space-evenly;" v-else>


							<div class="estimo_block">
								<div class="nav-item">
									<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="nav-icon">
										<path d="M12 2L2 12H5V20H19V12H22L12 2Z" fill="#333333"/>
									</svg>
									Estimation du prix de marché
									<button class="info-toggle-btn" @click="info_estimo = !info_estimo" v-if="app.view!='report'">
										<svg width="12" height="12" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="white" stroke-width="2"/>
											<path d="M12 16V12" stroke="white" stroke-width="2" stroke-linecap="round"/>
											<circle cx="12" cy="8" r="1" fill="white"/>
										</svg>
										Info
									</button>
								</div>
								<div class="info-panel" v-if="info_estimo || app.view=='report'">
									<p>Estimmo analyse 8 variables clés pour estimer le prix de vente des logements neufs : l'adresse, la typologie, la surface habitable, la taille du programme, le prix de l'ancien à proximité, le profil de marché local, le revenu médian des ménages et l'année de vente.</p>
									<p>Ajustez le nombre de lots et les surfaces pour affiner votre estimation.</p>
								</div>
								
								<div class="content">
									<div class="address-display">
										<div class="address-label">Adresse analysée</div>
										<div class="address-value">{{app.adr_init.name}}, {{app.adr_init.postcode}} {{app.adr_init.city}}</div>
									</div>
									
									<div class="content1" v-if="app.view!='report'">
										<div class="section-header">
											<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" class="settings-icon">
												<path d="M10 13C11.6569 13 13 11.6569 13 10C13 8.34315 11.6569 7 10 7C8.34315 7 7 8.34315 7 10C7 11.6569 8.34315 13 10 13Z" fill="#7B35E9"/>
												<path d="M17.7929 12.2071L16.3284 10.7426C16.4439 10.1669 16.4439 9.58307 16.3284 9.00739L17.7929 7.54289C17.9804 7.35539 18.0355 7.06864 17.9354 6.82201C17.4673 5.70409 16.7879 4.68626 15.9354 3.82201C15.7379 3.62456 15.4511 3.56951 15.2045 3.6696L13.3284 4.3696C12.8545 4.0562 12.3396 3.80227 11.8045 3.6196L11.3992 1.67184C11.3442 1.41234 11.1405 1.20863 10.881 1.15363C9.71009 0.946388 8.52534 0.946388 7.35442 1.15363C7.09493 1.20863 6.89122 1.41234 6.83622 1.67184L6.43092 3.6196C5.89555 3.80295 5.38059 4.05689 4.90667 4.3696L3.03067 3.6696C2.78405 3.56951 2.49718 3.62456 2.29968 3.82201C1.44718 4.68626 0.767761 5.70409 0.299686 6.82201C0.199524 7.06864 0.254574 7.35539 0.442074 7.54289L1.90659 9.00739C1.79109 9.58307 1.79109 10.1669 1.90659 10.7426L0.442074 12.2071C0.254574 12.3946 0.199524 12.6814 0.299686 12.928C0.767761 14.0459 1.44718 15.0638 2.29968 15.928C2.49718 16.1255 2.78393 16.1805 3.03055 16.0804L4.90667 15.3804C5.38055 15.6938 5.89555 15.9478 6.43067 16.1304L6.83609 18.0782C6.89109 18.3377 7.0948 18.5414 7.3543 18.5964C8.52534 18.8037 9.71009 18.8037 10.881 18.5964C11.1405 18.5414 11.3442 18.3377 11.3992 18.0782L11.8045 16.1304C12.3399 15.9471 12.8549 15.6931 13.3288 15.3804L15.2048 16.0804C15.4515 16.1805 15.7383 16.1255 15.9358 15.928C16.7883 15.0638 17.4677 14.0459 17.9358 12.928C18.036 12.6814 17.9809 12.3946 17.7934 12.2071H17.7929Z" fill="#7B35E9"/>
											</svg>
											Paramètres du programme
										</div>
										
										<div class="form-group">
											<label for="program-size">Taille du programme</label>

											<div class="slider-container" style="margin-top:15px">
												<div class="form-group">
													<Slider v-model="programme_size" :step="1" :min="5" :max="150"></Slider>
													<div class="slider-container" style="margin-top:15px">
														<div class="slider-value" id="price-factor-value">{{programme_size}} logement<span v-if="programme_size>1">s</span></div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
									
							<div class="content estimo_block">
								<div class="content1">
									<table class="price-table">
										<thead>
											<tr>
												<th style="width: 15%;">Typo</th>
												<th style="width: 25%;">Surface (m²)</th>
												<th>Prix m²</th>
												<th>Prix unitaire</th>
												<!--
												<th>&nbsp;&nbsp;Nombre&nbsp;&nbsp;</th>
												<th>Sous-total</th>
												-->
											</tr>
										</thead>
										<tbody>
											<tr v-for="i in 5" v-bind:key="i">
												<td>
													<div class="typo-cell">
														<div class="typo-icon">T{{i}}</div>
													</div>
												</td>
												<td class="editable-cell"><input v-model.number="typo[i-1].surface" type="number"></td>
												<td class="unit-price tar">{{ formatSurface(typo[i-1].prix) }}</td>
												<td class="subtotal tar">{{ formatCurrency((Number(typo[i-1].prix) || 0) * typo[i-1].surface) }}</td>
												<!--<td><input v-model.number="typo[i-1].nb" type="number"></td>
												<td class="subtotal tar">{{ formatCurrency((Number(typo[i-1].prix) || 0) * typo[i-1].surface * (Number(typo[i-1].nb) || 0)) }}</td>-->
											</tr>
										<!--
											<tr class="total-row">
												<td colspan="4">TOTAL</td>
												<td id="total-count" class="tar">{{ get_estimmoTotalNb }}</td>
												<td id="total-revenue" class="tar"> {{ formatCurrency(get_estimmoTotalCA) }}</td>
											</tr>
										-->
										</tbody>


									</table>
								</div>
							</div>
							<!--
								<div class="interpretation">
									<strong>Analyse des résultats :</strong> Pour cette adresse à Lyon (3ème), les prix au m² varient selon la typologie. Les T1 présentent un prix au m² plus élevé (7 910 €/m²) que les T3 (6 750 €/m²), reflétant la forte demande pour les petites surfaces dans ce secteur. Modifiez les surfaces pour affiner cette estimation.
								</div>
								-->
							<div class="content estimo_block">
								<div class="content1" v-if="app.view=='report'">
									<div class="section-header">
										<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" class="settings-icon">
											<path d="M10 13C11.6569 13 13 11.6569 13 10C13 8.34315 11.6569 7 10 7C8.34315 7 7 8.34315 7 10C7 11.6569 8.34315 13 10 13Z" fill="#7B35E9"/>
											<path d="M17.7929 12.2071L16.3284 10.7426C16.4439 10.1669 16.4439 9.58307 16.3284 9.00739L17.7929 7.54289C17.9804 7.35539 18.0355 7.06864 17.9354 6.82201C17.4673 5.70409 16.7879 4.68626 15.9354 3.82201C15.7379 3.62456 15.4511 3.56951 15.2045 3.6696L13.3284 4.3696C12.8545 4.0562 12.3396 3.80227 11.8045 3.6196L11.3992 1.67184C11.3442 1.41234 11.1405 1.20863 10.881 1.15363C9.71009 0.946388 8.52534 0.946388 7.35442 1.15363C7.09493 1.20863 6.89122 1.41234 6.83622 1.67184L6.43092 3.6196C5.89555 3.80295 5.38059 4.05689 4.90667 4.3696L3.03067 3.6696C2.78405 3.56951 2.49718 3.62456 2.29968 3.82201C1.44718 4.68626 0.767761 5.70409 0.299686 6.82201C0.199524 7.06864 0.254574 7.35539 0.442074 7.54289L1.90659 9.00739C1.79109 9.58307 1.79109 10.1669 1.90659 10.7426L0.442074 12.2071C0.254574 12.3946 0.199524 12.6814 0.299686 12.928C0.767761 14.0459 1.44718 15.0638 2.29968 15.928C2.49718 16.1255 2.78393 16.1805 3.03055 16.0804L4.90667 15.3804C5.38055 15.6938 5.89555 15.9478 6.43067 16.1304L6.83609 18.0782C6.89109 18.3377 7.0948 18.5414 7.3543 18.5964C8.52534 18.8037 9.71009 18.8037 10.881 18.5964C11.1405 18.5414 11.3442 18.3377 11.3992 18.0782L11.8045 16.1304C12.3399 15.9471 12.8549 15.6931 13.3288 15.3804L15.2048 16.0804C15.4515 16.1805 15.7383 16.1255 15.9358 15.928C16.7883 15.0638 17.4677 14.0459 17.9358 12.928C18.036 12.6814 17.9809 12.3946 17.7934 12.2071H17.7929Z" fill="#7B35E9"/>
										</svg>
										Paramètres du programme
									</div>
									
									<div class="form-group" style="margin-bottom: 0px;">
										<label for="program-size">Taille du programme</label>

										<div class="slider-container" style="margin-top:15px">
											<div class="form-group">
												<Slider v-model="programme_size" :step="1" :min="5" :max="150"></Slider>
												<div class="slider-container" style="margin-top:15px">
													<div class="slider-value" id="price-factor-value">{{programme_size}} logement<span v-if="programme_size>1">s</span></div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="content1">
									
									<div class="info-panel" v-if="!torefresh">
										<p>{{ legende() }}</p>
									</div>
									<div class="stats-cards">
										<div class="stat-card" v-if="!torefresh">
										<!--
											<div class="stat-icon-container">
												<svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="stat-icon">
													<path d="M21 21H3V8L12 1L21 8V21Z" stroke="#7B35E9" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
													<path d="M9 21V12H15V21" stroke="#7B35E9" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
												</svg>
											</div>
										-->
										<div class="stat-label">Nombre de logement</div>
											<div class="stat-value">{{programme_size}}</div>
										</div>
										<div class="stat-card" v-if="!torefresh">
											<!----
											<div class="stat-icon-container">
												<svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="stat-icon">
													<path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20Z" fill="#7B35E9"/>
													<path d="M12.5 7H11V13L16.2 16.2L17 14.9L12.5 12.2V7Z" fill="#7B35E9"/>
												</svg>
											</div>
											-->
											<div class="stat-label" :title="legende()">Prix moyen au m²</div>
											<div class="stat-value" v-if="get_estimmoTotalSrf">{{ formatSurface(Math.round(get_estimmoTotalCA / get_estimmoTotalSrf)) }}</div>

										</div>
									</div>

									<button class="refresh-btn" @click="maj_estimo()">
										<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" class="refresh-icon">
											<path d="M13.6567 2.34333C12.1567 0.843333 10.1567 0 8.00004 0C3.58337 0 0.00837085 3.575 0.00837085 8C0.00837085 12.425 3.58337 16 8.00004 16C11.7234 16 14.8317 13.525 15.7317 10H13.6567C12.8317 12.325 10.6067 14 8.00004 14C4.69171 14 2.00837 11.3167 2.00837 8C2.00837 4.68333 4.69171 2 8.00004 2C9.58337 2 11.0067 2.61667 12.075 3.6925L9.00004 6.75H16.0084V0L13.6567 2.34333Z" fill="white"/>
										</svg>
										Rafraîchir l'estimation
									</button>

<!--
									<div class="sources-section">
										<div class="sources-title">Sources des données</div>
										<ul class="sources-list">
											<li>350 000 ventes de logements neufs depuis 2020</li>
											<li>Prix dans l'ancien (rayon de 500m)</li>
											<li>Données socio-économiques locales</li>
											<li>Profil de marché de la commune</li>
										</ul>
									</div>
-->									
								</div>
							</div>
































						<!--
						<div style="margin-bottom: 20px; display: flex;align-items: center; width:100%;padding-right:10px;">
							<div for="programme_size" class="font-bold block mb-2" style="top: 5px;white-space: nowrap;margin-right: 10px;"> Taille du programme </div>
							<InputNumber style="width: 60px;" v-model="programme_size" inputId="programme_size" showButtons buttonLayout="horizontal" :step="1">
								<template #incrementbuttonicon>
									<span class="pi pi-plus" />
								</template>
								<template #decrementbuttonicon>
									<span class="pi pi-minus" />
								</template>
							</InputNumber>
						</div>
						-->

						<div>
							<br><br><br>
						<!--
							<div class="header">
								<div class="logo">
									<i class="material-icons">real_estate_agent</i>
								</div>
								<h1>Estimation du prix de marché</h1>
							</div>
						-->
							<!--
							<div class="content">
								<div class="controls">
									<div class="card">
										<h2><i class="material-icons">settings</i> Paramètres du programme</h2>
										<div class="form-group">
											<label for="program-size">Taille du programme</label>
											<input disabled="true" type="number" id="program-size" v-model.number="get_estimmoTotalNb">
										</div>
									</div>
								</div>
								
								<div class="actions">
									<button class="btn btn-primary" id="refresh-btn" @click="maj_estimo()">
										<i class="material-icons">refresh</i>
										{{ $t('Rafraichir l\'estimation') }}
									</button>
								</div>
								<div class="results">
									<div class="result-card" style="transform: translateY(0px);" id="img_estimmo_nb">
										<div class="result-icon">
											<i class="material-icons">domain_add</i>
										</div>
										<div class="result-label">Nombre de logement</div>
										<div class="result-value" id="total-surface">{{ get_estimmoTotalNb }}</div>
									</div>
									<div class="result-card" style="transform: translateY(0px);" id="img_estimmo_px">
										<div class="result-icon">
											<i class="material-icons">euro</i>
										</div>
										<div class="result-label">Prix moyen au m²</div>
										<div class="result-value" id="avg-price" v-if="get_estimmoTotalSrf">{{ formatSurface(Math.round(get_estimmoTotalCA / get_estimmoTotalSrf)) }}</div>
										<div class="result-value" id="avg-price" v-else> - </div>
									</div>
									<div class="result-card" style="transform: translateY(0px);" id="img_estimmo_sr">
										<div class="result-icon">
											<i class="material-icons">straighten</i>
										</div>
										<div class="result-label">Surface totale</div>
										<div class="result-value" id="total-surface">{{ formatSurfacem(get_estimmoTotalSrf) }}</div>
									</div>
									<div class="result-card" style="transform: translateY(0px);" id="img_estimmo_ca">
										<div class="result-icon">
											<i class="material-icons">account_balance</i>
										</div>
										<div class="result-label">Chiffre d'affaires estimé</div>
										<div class="result-value" id="total-revenue-display">{{ formatCurrency(get_estimmoTotalCA) }}</div>
									</div>
								</div>
							</div>
							-->
						</div>
				</div>
			</div>
			
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import { ObserveVisibility  } from 'vue-observe-visibility'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import Tree from 'primevue/tree';
import ScrollPanel from 'primevue/scrollpanel';
import SwitchButton from './global/SwitchButton'
// eslint-disable-next-line
import Chart from 'primevue/chart';
import Chip from 'primevue/chip';
import Knob from 'primevue/knob';
import domtoimage from 'dom-to-image'
import ToolSource from './global/ToolSource';
import Button from 'primevue/button';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import InputText from 'primevue/inputtext';
import InputNumber from 'primevue/inputnumber';
import Slider from 'primevue/slider';


export default {
	name: 'PaneLayer',

	data () {
		return {
			torefresh: false,
			info_estimo: false,
			programme_size: 30,
            typo: [
				{typology: "T1", surface: 29, nb: 0.6, prix: null},
				{typology: "T2", surface: 44, nb: 3.5, prix: null},
				{typology: "T3", surface: 65, nb: 3.7, prix: null},
				{typology: "T4", surface: 87, nb: 1.8, prix: null},
				{typology: "T5", surface: 114, nb: 0.4, prix: null},
			],
            columns: [
                { field: 'typology'	, header: 'Typo' },
                { field: 'surface'	, header: 'Surface' },
                { field: 'prix'		, header: 'Prix' },
            ],
			
			estimo_loading: false,
		}
	},

	watch: {
		/*
		// Watcher sur la propriété calculée "surfaces"
		surfaces(newSurfaces, oldSurfaces) {
			newSurfaces.forEach((surface, index) => {
				if (surface !== oldSurfaces[index]) {
					this.torefresh = true
				}
			});
		},
		'programme_size': function() {
			this.torefresh = true
		},
		*/
		'loading_estimmo': function(n, o) {
			if (!n && n!=o) {
				this.torefresh = false
			}
		},
		'app.estimmo': function(v) {
			if (v) {
				for (let index = 0; index < v.superficies.length; index++) {
					this.typo[index].prix = v.superficies[index].prix
					this.typo[index].surface = v.superficies[index].surface
					this.typo[index].typology = v.superficies[index].typology
				}
			}
		},

		'app.viewlayer': function() {
			this.handleResize();
		},

	},

	methods: {
		legende() {
			return 'Prix moyen au m² à cette adresse pour un programme de ' + this.programme_size + ' lots avec répartition des typologies suivante : 7% de T1, 39% de T2, 38% de T3, 14% de T4 et 3% de T5'
		},

		formatSurfacem(value) {
			try {
				return value.toLocaleString('fr-FR', {style: 'currency', currency: 'EUR', minimumFractionDigits: 0, maximumFractionDigits: 0}) + "m²";
			} catch (error) {
				return ""				
			}
		},
		formatSurface(value) {
			try {
				return value.toLocaleString('fr-FR', {style: 'currency', currency: 'EUR', minimumFractionDigits: 0, maximumFractionDigits: 0}) + "/m²";
			} catch (error) {
				return ""				
			}
		},
        formatCurrency(value) {
			try {
				return value.toLocaleString('fr-FR', {style: 'currency', currency: 'EUR', minimumFractionDigits: 0, maximumFractionDigits: 0});
			} catch (error) {
				return ""				
			}
        },
		onCellEditComplete(event) {
            let { data, newValue, field } = event;

            switch (field) {
                case 'quantity':
                case 'price':
                    if (this.isPositiveInteger(newValue)) data[field] = newValue;
                    else event.preventDefault();
                    break;

                default:
                    if (newValue.toString().trim().length > 0) data[field] = newValue;
                    else event.preventDefault();
                    break;
            }
        },

		maj_estimo() {
			console.log('maj_estimo')
			let estimmo = {
				//programme_size: this.typo[0].nb + this.typo[1].nb + this.typo[2].nb + this.typo[3].nb + this.typo[4].nb,
				programme_size: this.programme_size,
				superficies: [
					{
						typology: this.typo[0].typology,
						surface: this.typo[0].surface,
						nb: this.typo[0].nb
					},
					{
						typology: this.typo[1].typology,
						surface: this.typo[1].surface,
						nb: this.typo[1].nb
					},
					{
						typology: this.typo[2].typology,
						surface: this.typo[2].surface,
						nb: this.typo[2].nb
					},
					{
						typology: this.typo[3].typology,
						surface: this.typo[3].surface,
						nb: this.typo[3].nb
					},
					{
						typology: this.typo[4].typology,
						surface: this.typo[4].surface,
						nb: this.typo[4].nb
					}
				],
			}
			this.torefresh = false
			this.$store.dispatch('load_estimmo', {latlng :{ lat : this.app.mrk.lat, lng : this.app.mrk.lng}, p: estimmo})
		},

		generateProgImage(id) {

			const elementToExport = document.getElementById(id);
			let options = {
				height: elementToExport.scrollHeight,
				width: elementToExport.scrollWidth,
				//style: {
				//	'transform': 'scale(1)',
				//	'transformOrigin': 'top left',
				//},
			};
		
			domtoimage.toPng(elementToExport, options)
			.then((dataUrl) => {
				const link = document.createElement('a');
				link.href = dataUrl;
				link.download = id + '.png';
				link.click();
			})
			.catch((error) => {
				console.error('Erreur lors de la génération de l\'image', error);
			});
		},

		
		set_view: function() {
			this.$store.dispatch('set_view_layer', { view: '' })
		},

		handleResize() {
			this.setElementMaxHeight();
		},

		setElementMaxHeight() {
			// Nothing
		},

		UpdateLayer: function() {

		}

	},

	directives: {
		'observe-visibility': ObserveVisibility
	},

	mounted() {
		if (this.programme_size != this.app.estimmo.programme_size) {
			this.programme_size = this.app.estimmo.programme_size
		}
		for (let index = 0; index < this.app.estimmo.superficies.length; index++) {
			if (this.typo[index].prix != this.app.estimmo.superficies[index].prix) {
				this.typo[index].prix = this.app.estimmo.superficies[index].prix
			}
			if (this.typo[index].nb != this.app.estimmo.superficies[index].nb) {
				this.typo[index].nb = this.app.estimmo.superficies[index].nb
			}
			if (this.typo[index].surface != this.app.estimmo.superficies[index].surface) {
				this.typo[index].surface = this.app.estimmo.superficies[index].surface
			}
			if (this.typo[index].surface != this.app.estimmo.superficies[index].surface) {
				this.typo[index].surface = this.app.estimmo.superficies[index].surface
			}
			if (this.typo[index].typology != this.app.estimmo.superficies[index].typology) {
				this.typo[index].typology = this.app.estimmo.superficies[index].typology
			}
		}

		// Création du watcher via $watch
		this.surfacesWatcher = this.$watch('surfaces', (newSurfaces, oldSurfaces) => {
			newSurfaces.forEach((surface, index) => {
				if (surface !== oldSurfaces[index]) {
					this.torefresh = true
				}
			});
		});

		// Création du watcher via $watch
		this.nombreWatcher = this.$watch('programme_size', () => {
			this.torefresh = true
		});


	},

	beforeDestroy() {
		// Nothing
	},
	
	computed: Object.assign(mapGetters([ 'app', 'report', 'selected_programs' ]), {
		surfaces() {
			return this.typo.map(item => item.surface);
		},
		loading_estimmo: function() {
			return this.app.loading_estimmo
		},
		get_estimmoTotalNb: function () {
			let n = 0;
			for (let index = 0; index < this.typo.length; index++) {
				n += this.typo[index].nb;
			}
			return n;
		},
		get_estimmoTotalCA: function () {
			let n = 0;
			for (let index = 0; index < this.typo.length; index++) {
				// n += this.typo[index].nb * (this.typo[index].surface) * (this.typo[index].prix);
				n += this.typo[index].nb * this.programme_size * (this.typo[index].surface) * (this.typo[index].prix);
			}
			return Math.round(n);
		},
		get_estimmoTotalSrf: function () {
			let n = 0;
			for (let index = 0; index < this.typo.length; index++) {
//				n += this.typo[index].nb * this.typo[index].surface;
				n += this.typo[index].nb * this.programme_size * this.typo[index].surface;
			}
			return Math.round(n);
		},
	}),

	// eslint-disable-next-line
	components: { ToolSource, Slider, InputText, InputNumber, Column, DataTable, Button, ToolSource, Tree, ScrollPanel, SwitchButton, Chart, Chip, Knob, domtoimage }
}
</script>

<style lang="stylus" scoped>
@import '../assets/css/app'

#programme_size
	width 50px

.loading-icon
	animation rotate 2s infinite linear
	margin 0 auto
.infinite-loader
	.loader
		display inline-block
		border none
		border-radius 100%
		.icon
			display block
			animation rotate 1.5s infinite linear

.vue-treeselect
	line-height 32px !important

.vue-treeselect__control
	line-height 32px !important

.vue-treeselect__value-container
	line-height 32px !important

.group_layer
	padding-bottom 20px
	border-bottom 1px solid #c0c0c0

.pane-layer
	left 100%
	bottom 0
	height calc(100% - 155px)
	width 400px
	background-color #333
	color #ccc
	transition 0.5s easeOutQuart
	border-left 1px solid darken(light, 10%)
	z-index 500
	&.layer
		transform translateX(-100%)
	&.isochrone
		transform translateX(-100%)
	&.legende
		transform translateX(-100%)
	&.insee
		transform translateX(-100%)
	&.estimo
		transform translateX(-100%)
	&.slide-enter, &.slide-leave-to
		transform translateX(0)
	.header
		background secondary
		height 60px
		.back-button
			position absolute
			right 10px
			top 6px
			padding 8px
			color #fff
			cursor pointer
			border-radius 100%
			transition 0.25s easeOutQuart
			&:hover
				background palette2
		.print-button
			position absolute
			right 68px
			top 6px
			padding 8px
			color #fff
			cursor pointer
			border-radius 100%
			transition 0.25s easeOutQuart
			&:hover
				background palette2
		.texte
			top 22px
			left 10px
			height 48px
			width 250px
			color white
		.tabs
			padding 0 32px
			color #fff
			&:after
				content ''
				display block
				clear both
			.tab
				float left
				margin 0 32px 0 0
				height 96px
				width 96px
				display flex
				align-items center
				justify-content center
				flex-direction column
				opacity 0.5
				font-size 12px
				font-weight 500
				border-bottom 4px solid transparent
				text-align center
				text-transform uppercase
				cursor pointer
				&:hover
					opacity 0.75
				&.active
					opacity 1
					border-color light
				.icon
					display block
					margin 0 0 4px 0
				
	.right-bar
		padding-left 10px
		height 100%
		background white
		width 100%
		color #333
		.right-bar-content
			height 100%
			.content
				height 100%

		.scroll-dots
			position absolute
			left -40px
			top 24px
			width 32px
			background alpha(green, 50%)
			border-radius radius
			z-index 2
			transition 0.25s easeOutQuart
			&:hover
				background alpha(green, 75%)
			.dot
				display block
				width 8px
				height 8px
				margin 12px
				background #fff
				opacity 0.25
				border-radius 100%
				transition 0.25s easeOutQuart
				cursor pointer
				&:hover, &.active
					opacity 1

.checkbox
	position absolute
	left 0px
	top 4px
	.text
		padding-left 20px
		display block
		line-height 24px
		white-space nowrap
	&.checked
		background-color transparent !important
		.chk .icon
			color palette0
			display block
	&:hover
		border-color palette2
		.chk
			background-color #e5e5e5
	.chk
		float left
		width 24px
		height 24px
		border-radius (radius / 2)
		.icon
			position absolute
			left 2px
			top 2px
			display none
.choix
	padding-bottom 20px

.mrk
	left 33%
	width 24px
	height 24px
	color #f0f2f4
	font-weight 600
	border-radius 100%
	text-align center
	line-height 24px
	cursor pointer
	-webkit-user-select none
	-moz-user-select none
	-ms-user-select none
	user-select none
	&.projet
		background #ccc
	&.actif
		background #29b6f6
	&.termine
		background #0086c3
	&.entree
		background #fcc434
	&.milieu
		background #ee8d3e
	&.haut
		background #d74a22
	&.col
		background #ff4081
	&.ind
		background #f8bbd0

.zon
	border-radius 5px
	padding-right 5px
	padding-left 5px
	line-height 24px
	margin-right 5px
.z1b
	background #d92c21
	color #fff
.z1
	background #efae1f
	color #fff
.z2
	background #7ab2d5
	color #fff
.z3
	background #efefef

.zabis
	background #d92c21
	color #fff
.za	
	background #f5aa21
	color #fff
.zb1
	background #649770
	color #fff
.zb2
	background #aacde1
	color #fff
.zc
	background #efefef
.red
	color red
.green
	color #32CD32
.kpi_label
	height 1.5rem
	padding-top 7px


:deep(.p-datatable-tbody tr) {
	height: 80px;
}

.vue-treeselect--open-above .vue-treeselect__menu {
}

:deep(.p-inputtext) {
	width: 100px !important;
}

:deep(.p-tooltip) {
	max-width: 18rem !important;
}

:deep(.p-tooltip-text) {
	font-size:10pt !important;
}

.p-tooltip-text {
	font-size:10pt !important;
}
.p-tooltip {
	max-width: 18rem !important;
}

        
.app-container {
	width: 375px;
	margin: 0 auto;
	background: white;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.header {
	background: #7B35E9;
	color: white;
	padding: 10px 15px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-radius: 4px 4px 0 0;
}

.header h1 {
	margin: 0;
	font-size: 18px;
	font-weight: 500;
}

.close-btn {
	background: none;
	border: none;
	color: white;
	font-size: 24px;
	cursor: pointer;
}

.nav-item {
	display: flex;
	align-items: center;
	padding: 15px;
	border-bottom: 1px solid #e0e0e0;
	font-size: 14px;
	position: relative;
}

.nav-icon {
	margin-right: 10px;
}

.info-toggle-btn {
	margin-left: auto;
	background-color: #7B35E9;
	color: white;
	font-size: 12px;
	padding: 4px 10px;
	border-radius: 20px;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	border: none;
	transition: background-color 0.2s;
}

.info-toggle-btn:hover {
	background-color: #6929c4;
}

.info-toggle-btn svg {
	margin-right: 4px;
}

.info-panel {
	background-color: #f6f4ff;
	padding: 15px;
	font-size: 13px;
	line-height: 1.4;
	border-bottom: 1px solid #e0e0e0;
	border-left: 3px solid #7B35E9;
	margin-bottom: 15px;
	text-align: justify;
}

.info-panel.show {
	display: block;
}

.content1 {
	padding: 15px;
}

.address-display {
	background: #f9f9f9;
	padding: 10px;
	margin-bottom: 15px;
	border-radius: 4px;
	font-size: 13px;
}

.address-label {
	color: #666;
	margin-bottom: 5px;
	font-size: 12px;
}

.address-value {
	font-weight: bold;
	color: #333;
}

.section-header {
	color: #7B35E9;
	display: flex;
	align-items: center;
	font-size: 15px;
	margin-bottom: 15px;
}

.settings-icon {
	margin-right: 10px;
	color: #7B35E9;
}

.form-group {
	margin-bottom: 15px;
}

.form-group label {
	display: block;
	font-size: 14px;
	color: #333;
	margin-bottom: 8px;
}

.slider-container {
	width: 100%;
	display: flex;
	flex-direction: column;
}

.slider {
	-webkit-appearance: none;
	width: 100%;
	height: 6px;
	border-radius: 3px;
	background: #d3d3d3;
	outline: none;
	margin-bottom: 10px;
}

.slider::-webkit-slider-thumb {
	-webkit-appearance: none;
	appearance: none;
	width: 20px;
	height: 20px;
	border-radius: 50%;
	background: #7B35E9;
	cursor: pointer;
}

.slider::-moz-range-thumb {
	width: 20px;
	height: 20px;
	border-radius: 50%;
	background: #7B35E9;
	cursor: pointer;
}

.slider-value {
	text-align: center;
	font-size: 16px;
	color: #7B35E9;
	font-weight: bold;
}

.slider-labels {
	display: flex;
	justify-content: space-between;
	margin-top: 5px;
	font-size: 12px;
	color: #777;
}

.price-table {
	width: 100%;
	border-collapse: collapse;
	margin-top: 15px;
	border-radius: 4px;
	overflow: hidden;
}

.price-table th {
	background: #7B35E9;
	color: white;
	font-weight: normal;
	text-align: center;
	padding: 8px 5px;
	font-size: 14px;
}

.price-table td {
	text-align: center;
	padding: 8px 5px;
	border-bottom: 1px solid #eee;
	font-size: 14px;
	background-color: #fff;
}

.price-table tr:last-child td {
	//background-color: #f5f5f5;
	//font-weight: bold;
}

.editable-cell input {
	width: 80%;
	padding: 4px;
	border: 1px solid #ddd;
	border-radius: 3px;
	text-align: center;
	background-color: #f8f8f8;
}

.editable-cell input:focus {
	border-color: #7B35E9;
	outline: none;
	background-color: #fff;
}

.interpretation {
	font-size: 13px;
	color: #555;
	margin: 15px 0;
	padding: 10px;
	background-color: #f9f9f9;
	border-left: 3px solid #7B35E9;
	border-radius: 0 4px 4px 0;
	text-align: justify;
}

.refresh-btn {
	background-color: #7B35E9;
	color: white;
	border: none;
	padding: 10px;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 10px;
	margin: 20px auto;
	font-size: 14px;
	width: 100%;
	max-width: 250px;
	border-radius: 4px;
}

.refresh-btn:hover {
	background-color: #6929c4;
}

.refresh-icon {
	margin-right: 5px;
}

.stats-cards {
	display: flex;
	gap: 15px;
	//margin-top: 15px;
}

.stat-card {
	flex: 1;
	background: #f9f9f9;
	padding: 6px 8px;
	text-align: center;
	border: 1px solid #f0f0f0;
	border-radius: 4px;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.stat-icon-container {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 2px;
}

.stat-icon {
	width: 18px;
	height: 18px;
}

.stat-label {
	color: #666;
	font-size: 11px;
	margin-bottom: 2px;
}

.stat-value {
	color: #7B35E9;
	font-size: 15px;
	font-weight: bold;
}

.sources-section {
	margin-top: 20px;
	padding: 10px;
	background-color: #f9f9f9;
	border-radius: 4px;
	font-size: 13px;
}

.sources-title {
	font-weight: bold;
	margin-bottom: 5px;
	color: #333;
}

.sources-list {
	margin: 0;
	padding-left: 20px;
	color: #555;
}

.estimo_block {
	width: 100%;
	max-width: 500px;
}
</style>
