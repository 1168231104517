<template>
	<transition name="pane-adresse-transport">
		<div class="paneleft pan-adresse-transport">
			<pane-adresse-retour accueil="" retour="adresse" cityscan="true" aide="2" v-show="false"/>
			<div style="height: 100%; display: block; overflow: hidden scroll; width: 100%; box-sizing: content-box; padding-right: 20px;">
					<div class="unmodule_detail_titre">
						<div class="titre_sommaire2">
							<span class="fila" @click="$store.dispatch('set_contour_iso', { contour: false });$store.dispatch('set_bienslocatifs', { value: false }); $store.dispatch('set_view', { view: 'adresse' })">{{$t('Général')}}</span>
							<span> > </span>
							<span><b>{{ $t('Transports') }}</b></span>
						</div>
						<div class="noteTitre" :class="app.cityscan.adresse.note_transports">{{app.cityscan.adresse.note_transports}}</div>
					</div>
					
					<div class="programs-list">
						<div class="programs-list-content wrapper-scroll">
							<div class="content" ref="scrollable_programs">
								<ul class="accordion-menu">
									<div class="single-accordion">
										<details class="accordion-item" open>
											<summary class="accordion-header" tabindex="0">{{ $t('transports_de_proximites') }}</summary>
											<div class="accordion-content">
												<li class="unmodule_detail " style="cursor:pointer">
													<div @click="$store.dispatch('set_view', { view: 'transport-bus' }); select_adresse_iso(app.cityscan.adresse.bus)">
														<div class="icone_module"><span class="cs-tile-icon cs-icon-thematic icon-1200-02 ng-star-inserted" style="color: rgb(204, 0, 0);"></span></div>
														<div class="titre_module">{{ $t('bus') }}</div>
														<div class="note" :class="this.app.cityscan.adresse.bus.note">{{this.app.cityscan.adresse.bus.note}}</div>
														<div class="soustitre_module">{{this.app.cityscan.adresse.bus.nb}} {{this.app.cityscan.adresse.bus.temps}}</div>
													</div>
													<div class="cz" style="top:18px !important;">
														<label for="adresse_transport_bus" class="checkbox" :class="{ checked: adresse_transport_bus }" >
															<input type="checkbox" :checked="adresse_transport_bus" id="adresse_transport_bus" @click=" select_adresse('adresse_transport_bus', app.cityscan.adresse.bus)">
															<span class="chk"><icon file="check" :size="16" /></span>
															<span class="text"></span>
														</label>
													</div>
												</li>
												<div class="divider" v-show="this.app.cityscan.adresse.metro.note!='ND'"></div>
												<li class="unmodule_detail " style="cursor:pointer" v-show="this.app.cityscan.adresse.metro.note!='ND'">
													<div @click="$store.dispatch('set_view', { view: 'transport-metro' }); select_adresse_iso(app.cityscan.adresse.metro)">
														<div class="icone_module"><span class="cs-tile-icon cs-icon-thematic icon-1201-02 ng-star-inserted" style="color: rgb(255, 73, 24);"></span></div>
														<div class="titre_module">{{ $t('metro') }}</div>
														<div class="note" :class="this.app.cityscan.adresse.metro.note">{{this.app.cityscan.adresse.metro.note}}</div>
														<div class="soustitre_module">{{this.app.cityscan.adresse.metro.nb}} {{this.app.cityscan.adresse.metro.temps}}</div>
													</div>
													<div class="cz" style="top:18px !important;">
														<label for="adresse_transport_metro" class="checkbox" :class="{ checked: adresse_transport_metro }">
															<input type="checkbox" :checked="adresse_transport_metro"  id="adresse_transport_metro" @click=" select_adresse('adresse_transport_metro', app.cityscan.adresse.metro)">
															<span class="chk"><icon file="check" :size="16" /></span>
															<span class="text"></span>
														</label>
													</div>
												</li>
												<div class="divider"></div>
												<li class="unmodule_detail " style="cursor:pointer" v-show="this.app.cityscan.adresse.tramway.note!='ND'">
													<div @click="$store.dispatch('set_view', { view: 'transport-tram' }); select_adresse_iso(app.cityscan.adresse.tramway)">
														<div class="icone_module"><span class="cs-tile-icon cs-icon-thematic icon-1202-02 ng-star-inserted" style="color: rgb(255, 116, 13);"></span></div>
														<div class="titre_module">{{ $t('tramway') }}</div>
														<div class="note" :class="this.app.cityscan.adresse.tramway.note">{{this.app.cityscan.adresse.tramway.note}}</div>
														<div class="soustitre_module">{{this.app.cityscan.adresse.tramway.nb}} {{this.app.cityscan.adresse.tramway.temps}}</div>
													</div>
													<div class="cz" style="top:18px !important;">
														<label for="adresse_transport_tram" class="checkbox" :class="{ checked: adresse_transport_tram }" >
															<input type="checkbox" :checked="adresse_transport_tram" id="adresse_transport_tram" @click=" select_adresse('adresse_transport_tram', app.cityscan.adresse.tramway)">
															<span class="chk"><icon file="check" :size="16" /></span>
															<span class="text"></span>
														</label>
													</div>
												</li>
											</div>
										</details>
										<details class="accordion-item" open>
											<summary class="accordion-header" tabindex="0">{{ $t('transports_par_route') }}</summary>
											<div class="accordion-content">
												<li class="unmodule_detail " style="cursor:pointer">
													<div @click="$store.dispatch('set_view', { view: 'transport-route-rapide' }); select_adresse_iso(app.cityscan.adresse.route_rapide)">
														<div class="icone_module"><span class="cs-tile-icon cs-icon-thematic icon-1210-02 ng-star-inserted" style="color: rgb(255, 111, 30);"></span></div>
														<div class="titre_module">{{ $t('acces_route_rapide') }}</div>
														<div class="note" :class="this.app.cityscan.adresse.route_rapide.note">{{this.app.cityscan.adresse.route_rapide.note}}</div>
														<div class="soustitre_module">{{this.app.cityscan.adresse.route_rapide.nb}} {{this.app.cityscan.adresse.route_rapide.temps}}</div>
													</div>
													<div class="cz" style="top:18px !important;">
														<label for="adresse_transport_route_rapide" class="checkbox" :class="{ checked: adresse_transport_route_rapide }">
															<input type="checkbox" :checked="adresse_transport_route_rapide"  id="adresse_transport_route_rapide" @click=" select_adresse('adresse_transport_route_rapide', app.cityscan.adresse.route_rapide)">
															<span class="chk"><icon file="check" :size="16" /></span>
															<span class="text"></span>
														</label>
													</div>
												</li>
												<div class="divider"></div>
												<li class="unmodule_detail " style="cursor:pointer">
													<div @click="$store.dispatch('set_view', { view: 'transport-parking' }); select_adresse_iso(app.cityscan.adresse.parking)">
														<div class="icone_module"><span class="cs-tile-icon cs-icon-thematic icon-1230-02 ng-star-inserted" style="color: rgb(255, 188, 0);"></span></div>
														<div class="titre_module">{{ $t('parking') }}</div>
														<div class="note" :class="this.app.cityscan.adresse.parking.note">{{this.app.cityscan.adresse.parking.note}}</div>
														<div class="soustitre_module">{{this.app.cityscan.adresse.parking.nb}} {{this.app.cityscan.adresse.parking.temps}}</div>
													</div>
													<div class="cz" style="top:18px !important;">
														<label for="adresse_transport_parking" class="checkbox" :class="{ checked: adresse_transport_parking }" >
															<input type="checkbox" :checked="adresse_transport_parking" id="adresse_transport_parking" @click=" select_adresse('adresse_transport_parking', app.cityscan.adresse.parking)">
															<span class="chk"><icon file="check" :size="16" /></span>
															<span class="text"></span>
														</label>
													</div>
												</li>
											</div>
										</details>
										<details class="accordion-item" open>
											<summary class="accordion-header" tabindex="0">{{ $t('transports_longue_distance') }}</summary>
											<div class="accordion-content">
												<li class="unmodule_detail " style="cursor:pointer">
													<div @click="$store.dispatch('set_view', { view: 'transport-gare' }); select_adresse_iso(app.cityscan.adresse.gare)">
														<div class="icone_module"><span class="cs-tile-icon cs-icon-thematic icon-1220-02 ng-star-inserted" style="color: rgb(237, 96, 0);"></span></div>
														<div class="titre_module">{{ $t('gare') }}</div>
														<div class="note" :class="this.app.cityscan.adresse.gare.note">{{this.app.cityscan.adresse.gare.note}}</div>
														<div class="soustitre_module">{{this.app.cityscan.adresse.gare.nb}} {{this.app.cityscan.adresse.gare.temps}}</div>
													</div>
													<div class="cz" style="top:18px !important;">
														<label for="adresse_transport_gare" class="checkbox" :class="{ checked: adresse_transport_gare }" >
															<input type="checkbox" :checked="adresse_transport_gare" id="adresse_transport_gare" @click=" select_adresse('adresse_transport_gare', app.cityscan.adresse.gare)">
															<span class="chk"><icon file="check" :size="16" /></span>
															<span class="text"></span>
														</label>
													</div>
												</li>
												<div class="divider"></div>
												<li class="unmodule_detail " style="cursor:pointer">
													<div @click="$store.dispatch('set_view', { view: 'transport-aeroport' }); select_adresse_iso(app.cityscan.adresse.aeroport)">
														<div class="icone_module"><span class="cs-tile-icon cs-icon-thematic icon-1221-02 ng-star-inserted" style="color: rgb(230, 52, 0);"></span></div>
														<div class="titre_module">{{ $t('aeroport') }}</div>
														<div class="note" :class="this.app.cityscan.adresse.aeroport.note">{{this.app.cityscan.adresse.aeroport.note}}</div>
														<div class="soustitre_module">{{this.app.cityscan.adresse.aeroport.nb}} {{this.app.cityscan.adresse.aeroport.temps}}</div>
													</div>
													<div class="cz" style="top:18px !important;">
														<label for="adresse_transport_aeroport" class="checkbox" :class="{ checked: adresse_transport_aeroport }">
															<input type="checkbox" :checked="adresse_transport_aeroport"  id="adresse_transport_aeroport" @click=" select_adresse('adresse_transport_aeroport', app.cityscan.adresse.aeroport)">
															<span class="chk"><icon file="check" :size="16" /></span>
															<span class="text"></span>
														</label>
													</div>
												</li>
												<div class="divider"></div>
												<li class="unmodule_detail " style="cursor:pointer" v-show="this.app.cityscan.adresse.port.note!='ND'">
													<div @click="$store.dispatch('set_view', { view: 'transport-port' }); select_adresse_iso(app.cityscan.adresse.port)">
														<div class="icone_module"><span class="cs-tile-icon cs-icon-thematic icon-1222-02 ng-star-inserted" style="color: rgb(255, 167, 38);"></span></div>
														<div class="titre_module">{{ $t('port_de_voyageur') }}</div>
														<div class="note" :class="this.app.cityscan.adresse.port.note">{{this.app.cityscan.adresse.port.note}}</div>
														<div class="soustitre_module">{{this.app.cityscan.adresse.port.nb}} {{this.app.cityscan.adresse.port.temps}}</div>
													</div>
													<div class="cz" style="top:18px !important;">
														<label for="adresse_transport_port" class="checkbox" :class="{ checked: adresse_transport_port }" >
															<input type="checkbox" :checked="adresse_transport_port" id="adresse_transport_port" @click=" select_adresse('adresse_transport_port', app.cityscan.adresse.port)">
															<span class="chk"><icon file="check" :size="16" /></span>
															<span class="text"></span>
														</label>
													</div>
												</li>
											</div>
										</details>
										<!--
										<details class="accordion-item" open>
											<summary class="accordion-header" tabindex="0">{{ $t('transports_durables') }}</summary>
											<div class="accordion-content">
												<li class="unmodule_detail " style="cursor:pointer">
													<div @click="$store.dispatch('set_view', { view: 'transport-velo' }); select_adresse_iso(app.cityscan.adresse.velo)">
														<div class="icone_module"><span class="cs-tile-icon cs-icon-thematic icon-2000-02 ng-star-inserted" style="color: rgb(65, 112, 52);"></span></div>
														<div class="titre_module">{{ $t('velo_partage') }}</div>
														<div class="note" :class="this.app.cityscan.adresse.velo.note">{{this.app.cityscan.adresse.velo.note}}</div>
														<div class="soustitre_module">{{this.app.cityscan.adresse.velo.nb}} {{this.app.cityscan.adresse.velo.temps}}</div>
													</div>
													<div class="cz" style="top:18px !important;">
														<label for="adresse_transport_velo" class="checkbox" :class="{ checked: adresse_transport_velo }" >
															<input type="checkbox" :checked="adresse_transport_velo" id="adresse_transport_velo" @click=" select_adresse('adresse_transport_velo', app.cityscan.adresse.velo)">
															<span class="chk"><icon file="check" :size="16" /></span>
															<span class="text"></span>
														</label>
													</div>
												</li>
												<div class="divider"></div>
												<li class="unmodule_detail " style="cursor:pointer">
													<div @click="$store.dispatch('set_view', { view: 'transport-auto' }); select_adresse_iso(app.cityscan.adresse.auto)">
														<div class="icone_module"><span class="cs-tile-icon cs-icon-thematic icon-2001-02 ng-star-inserted" style="color: rgb(68, 87, 69);"></span></div>
														<div class="titre_module">{{ $t('auto_partage') }}</div>
														<div class="note" :class="this.app.cityscan.adresse.auto.note">{{this.app.cityscan.adresse.auto.note}}</div>
														<div class="soustitre_module">{{this.app.cityscan.adresse.auto.nb}} {{this.app.cityscan.adresse.auto.temps}}</div>
													</div>
													<div class="cz" style="top:18px !important;">
														<label for="adresse_transport_auto" class="checkbox" :class="{ checked: adresse_transport_auto }" >
															<input type="checkbox" :checked="adresse_transport_auto" id="adresse_transport_auto" @click=" select_adresse('adresse_transport_auto', app.cityscan.adresse.auto)">
															<span class="chk"><icon file="check" :size="16" /></span>
															<span class="text"></span>
														</label>
													</div>
												</li>
												<div class="divider"></div>
												<li class="unmodule_detail " style="cursor:pointer">
													<div @click="$store.dispatch('set_view', { view: 'transport-borne' }); select_adresse_iso(app.cityscan.adresse.borne)">
														<div class="icone_module"><span class="cs-tile-icon cs-icon-thematic icon-2002-02 ng-star-inserted" style="color: rgb(94, 121, 92);"></span></div>
														<div class="titre_module">{{ $t('borne_de_recharge') }}</div>
														<div class="note" :class="this.app.cityscan.adresse.borne.note">{{this.app.cityscan.adresse.borne.note}}</div>
														<div class="soustitre_module">{{this.app.cityscan.adresse.borne.nb}} {{this.app.cityscan.adresse.borne.temps}}</div>
													</div>
													<div class="cz" style="top:18px !important;">
														<label for="adresse_transport_borne" class="checkbox" :class="{ checked: adresse_transport_borne }" >
															<input type="checkbox" :checked="adresse_transport_borne" id="adresse_transport_borne" @click=" select_adresse('adresse_transport_borne', app.cityscan.adresse.borne)">
															<span class="chk"><icon file="check" :size="16" /></span>
															<span class="text"></span>
														</label>
													</div>
												</li>
											</div>
										</details>
										-->
									</div>
								</ul>
							</div>
						</div>
					</div>
			</div>
		</div>
	</transition>
</template>

<script>
import { mapGetters } from 'vuex'
import PaneAdresseRetour from './PaneAdresseRetour.vue'

export default {
	name: 'PaneAdresseTransport',
	data: function() {
		return {
			adresse_transport_borne: true,
			adresse_transport_auto: true,
			adresse_transport_velo: true,
			adresse_transport_port: true,
			adresse_transport_aeroport: true,
			adresse_transport_gare: true,
			adresse_transport_parking: true,
			adresse_transport_route_rapide: true,
			adresse_transport_tram: true,
			adresse_transport_metro: true,
			adresse_transport_bus: true,
			detail: '',
		}
	},

	mounted: function() {
		// Gestion préférences
		//if (this.app.preferences.adresse_niv1.length) {
		//	this.adresse_transport_borne = (this.app.preferences.adresse_niv2.indexOf('adresse_transport_borne')!==-1)
		//	this.adresse_transport_auto = (this.app.preferences.adresse_niv2.indexOf('adresse_transport_auto')!==-1)
		//	this.adresse_transport_velo = (this.app.preferences.adresse_niv2.indexOf('adresse_transport_velo')!==-1)
		//	this.adresse_transport_port = (this.app.preferences.adresse_niv2.indexOf('adresse_transport_port')!==-1)
		//	this.adresse_transport_aeroport = (this.app.preferences.adresse_niv2.indexOf('adresse_transport_aeroport')!==-1)
		//	this.adresse_transport_gare = (this.app.preferences.adresse_niv2.indexOf('adresse_transport_gare')!==-1)
		//	this.adresse_transport_parking = (this.app.preferences.adresse_niv2.indexOf('adresse_transport_parking')!==-1)
		//	this.adresse_transport_route_rapide = (this.app.preferences.adresse_niv2.indexOf('adresse_transport_route_rapide')!==-1)
		//	this.adresse_transport_tram = (this.app.preferences.adresse_niv2.indexOf('adresse_transport_tram')!==-1)
		//	this.adresse_transport_metro = (this.app.preferences.adresse_niv2.indexOf('adresse_transport_metro')!==-1)
		//	this.adresse_transport_bus = (this.app.preferences.adresse_niv2.indexOf('adresse_transport_bus')!==-1)
		//}
	},

	computed: Object.assign(mapGetters([ 'app', 'map' ]), {
		
	}),

	methods: {
		select_adresse: function(e) {
			this[e] = !this[e];
			// Gestion préférences
			// this.$store.dispatch('set_preferences_adresses_niv2', {niv2: e, select: this[e]})
		},
		select_adresse_iso: function(propObj) {
			this.$store.dispatch('set_contour_iso_param', {duration: propObj.duration, transportType: propObj.transportType})
			this.$store.dispatch('set_contour_iso', { contour: true })
		},
		details: function(d) {
			if (this.detail==d) {
				this.detail=''
			}
			else {
				this.detail=d
			}
		}
	},

	components: { PaneAdresseRetour }
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/app'

.pane-adresse-transport-enter
	transform translateX(100%)
.pane-adresse-transport-leave-to
	transform translateX(-100%)

.pan-adresse-transport
	position absolute
	top headersize
	float left
	transition 0.5s easeOutQuart
.cz
	position absolute
	right 4px !important
	top 12px !important

.note
	right 40px !important
	top calc(50% - 8px) !important

	
.checkbox {
	width: 20px !important;
	height: 20px !important;
}

@keyframes rotate
	from
		transform rotate(0deg)
	to
		transform rotate(359deg)

</style>
