<template>
	<div class="tab-ancien" id='report-ancien'>
		<div class="tab-content" v-observe-visibility="onVisibilityChange">
			<Fieldset :legend="$t('Marché du locatif')" :toggleable="true" class="surface-ground">
				<div class="part">
					<div class="part-title">{{ $t('Estimation du marché locatif') }} </div>
					<div class="part-content">
						<div data-v-b47bf8ec="" class="surface-card shadow-2 p-3 border-round" style="height: 100%;margin-bottom:15px">
						<animated-tabs 
							:tabs="tabs_forme" 
							:initial-active-index="0"
							@tab-change="handleTabChange_forme"
							></animated-tabs>
						</div>
						<div style="display: flex;">
							<div class="part" style="width:50%;padding-right:20px;margin-bottom:5px !important;" v-if="dataPrix_Iris && dataPrix_Iris.total">

								<div class="surface-card shadow-2 p-3 border-round" ref="scrollable_programs" style="height:100%">
									IRIS {{ this.app.adr_init.iris_label }} ({{app.adr_init.iris_code}})
									<div>
										<div class="results" style="margin-top:10px">
											<div class="result-card" style="transform: translateY(0px);" id="img_estimmo_nb">
												<div class="result-label">loyers au m² med<tool-source v-if="false" url='' :txt='getInfoPrix'/></div>
												<div class="result-value"> {{ DonneValeurPrix(Math.round(dataPrix_Iris.total.prix_m2_median)) }}/m²</div>
											</div>
										</div>
										
										<div class="results" style="margin-top:10px">
											<div class="result-card" style="transform: translateY(0px);" id="img_estimmo_nb">
												<div class="result-label">loyers au m² min<tool-source v-if="false" url='' :txt='getInfoPrix'/></div>
												<div class="result-value"> {{ DonneValeurPrix(Math.round(dataPrix_Iris.total.prix_m2_min)) }}/m² </div>
											</div>
											<div class="result-card" style="transform: translateY(0px);" id="img_estimmo_nb">
												<div class="result-label">loyers au m² max<tool-source v-if="false" url='' :txt='getInfoPrix'/></div>
												<div class="result-value"> {{ DonneValeurPrix(Math.round(dataPrix_Iris.total.prix_m2_max)) }}/m² </div>
											</div>
										</div>
										
										<div class="p-datatable-sm text-sm p-datatable p-component p-datatable-responsive-stack p-datatable-gridlines">
											<div class="tab-content-panel" style="padding-left:15px;padding-right:15px;">
												<table role="table" class="p-datatable-table">
													<thead role="rowgroup" class="p-datatable-thead">
														<tr role="row">
															<th role="cell" class=""><div class="p-column-header-content"><span class="p-column-title">Typo.</span></div></th>
															<th role="cell" class=""><!----><div class="p-column-header-content"><!----><span class="p-column-title">Nombre</span><!----><!----><!----><!----></div></th>
															<th role="cell" class=""><!----><div class="p-column-header-content"><!----><span class="p-column-title">Surf. med.</span><!----><!----><!----><!----></div></th>
															<th role="cell" class=""><!----><div class="p-column-header-content"><!----><span class="p-column-title">Prix m² med.</span><!----><!----><!----><!----></div></th>
														</tr>
													</thead>
													<tbody role="rowgroup" class="p-datatable-tbody">
														<tr role="row" class="" v-for="typ in dataTyp_Iris" v-bind:key="typ.libelle">
															<td role="cell" class="">{{ typ.libelle}}</td>
															<td role="cell" class="">{{ typ.nombre_annonces}}</td>
															<td role="cell" class="">{{ typ.surface_mediane}}</td>
															<td role="cell" class="">{{ typ.prix_m2_median}}</td>
														</tr>
													</tbody>
												</table>
												<table role="table" class="p-datatable-table">
													<thead role="rowgroup" class="p-datatable-thead">
														<tr role="row">
															<th role="cell" class=""><div class="p-column-header-content"><span class="p-column-title">Typo.</span></div></th>
															<th role="cell" class=""><!----><div class="p-column-header-content"><!----><span class="p-column-title">Nombre</span><!----><!----><!----><!----></div></th>
															<th role="cell" class=""><!----><div class="p-column-header-content"><!----><span class="p-column-title">Prix m² med.</span><!----><!----><!----><!----></div></th>
														</tr>
													</thead>
													<tbody role="rowgroup" class="p-datatable-tbody">
														<tr role="row" class="" v-for="dpe in dataDpe_Iris" v-bind:key="dpe.libelle">
															<td role="cell" class="">{{ dpe.libelle}}</td>
															<td role="cell" class="">{{ dpe.nombre_annonces}}</td>
															<td role="cell" class="">{{ dpe.prix_m2_median}}</td>
														</tr>
													</tbody>
												</table>
											</div>
										</div>
										<div class="grid">
											<div class="col-12"><highcharts :options="chartOptionsPrice_typo_iris"></highcharts></div>
											<div class="col-12"><highcharts :options="chartOptionsPrice_dpe_iris"></highcharts></div>
										</div>
										<div class="grid">
											<div class="col-12"><highcharts :options="chartOptionsPriceM2_typo_iris"></highcharts></div>
											<div class="col-12"><highcharts :options="chartOptionsPriceM2_dpe_iris"></highcharts></div>
										</div>
										<div class="grid">
											<div class="col-12"><highcharts :options="chartOptionsVolume_typo_iris"></highcharts></div>
											<div class="col-12"><highcharts :options="chartOptionsVolume_dpe_iris"></highcharts></div>
										</div>
										<div class="grid">
											<div style="width:100%;text-align: center;font-familiy: Helvetica, Arial, sans-serif;font-size: 1.4em;color: rgb(51, 51, 51);font-weight: bold;fill: rgb(51, 51, 51);">Distribution des prix par typologie</div>									
											<div class="col-12"><apexchart type="heatmap" height="250px" width="100%" :options="chartOptionsDistribution_typo_iris.chartOptions" :series="chartOptionsDistribution_typo_iris.series"></apexchart></div>
											<div style="width:100%;text-align: center;font-familiy: Helvetica, Arial, sans-serif;font-size: 1.4em;color: rgb(51, 51, 51);font-weight: bold;fill: rgb(51, 51, 51);">Distribution des prix par DPE</div>									
											<div class="col-12"><apexchart type="heatmap" height="250px" width="100%" :options="chartOptionsDistribution_dpe_iris.chartOptions" :series="chartOptionsDistribution_dpe_iris.series"></apexchart></div>
										</div>
									</div>
								</div>
								
							</div>
							<div class="part" style="width:50%;margin-bottom:5px !important;">

								<div class="surface-card shadow-2 p-3 border-round" ref="scrollable_programs" style="height:100%">
									VILLE {{ this.app.adr_init.city }} ({{app.adr_init.insee}})
									<div>
										<div class="results" style="margin-top:10px" v-if="dataPrix_Ville && dataPrix_Ville.total">
											<div class="result-card" style="transform: translateY(0px);" id="img_estimmo_nb">
												<div class="result-label">loyers au m² med<tool-source v-if="false" url='' :txt='getInfoPrix'/></div>
												<div class="result-value"> {{ DonneValeurPrix(Math.round(dataPrix_Ville.total.prix_m2_median)) }}/m²</div>
											</div>
										</div>
										
										<div class="results" style="margin-top:10px" v-if="dataPrix_Ville && dataPrix_Ville.total">
											<div class="result-card" style="transform: translateY(0px);" id="img_estimmo_nb">
												<div class="result-label">loyers au m² min<tool-source v-if="false" url='' :txt='getInfoPrix'/></div>
												<div class="result-value"> {{ DonneValeurPrix(Math.round(dataPrix_Ville.total.prix_m2_min)) }}/m² </div>
											</div>
											<div class="result-card" style="transform: translateY(0px);" id="img_estimmo_nb">
												<div class="result-label">loyers au m² max<tool-source v-if="false" url='' :txt='getInfoPrix'/></div>
												<div class="result-value"> {{ DonneValeurPrix(Math.round(dataPrix_Ville.total.prix_m2_max)) }}/m² </div>
											</div>
										</div>
										
										<div class="p-datatable-sm text-sm p-datatable p-component p-datatable-responsive-stack p-datatable-gridlines" v-if="dataPrix_Ville && dataPrix_Ville.total">
											<div class="tab-content-panel" style="padding-left:15px;padding-right:15px;">
												<table role="table" class="p-datatable-table">
													<thead role="rowgroup" class="p-datatable-thead">
														<tr role="row">
															<th role="cell" class=""><div class="p-column-header-content"><span class="p-column-title">Typo.</span></div></th>
															<th role="cell" class=""><!----><div class="p-column-header-content"><!----><span class="p-column-title">Nombre</span><!----><!----><!----><!----></div></th>
															<th role="cell" class=""><!----><div class="p-column-header-content"><!----><span class="p-column-title">Surf. med.</span><!----><!----><!----><!----></div></th>
															<th role="cell" class=""><!----><div class="p-column-header-content"><!----><span class="p-column-title">Prix m² med.</span><!----><!----><!----><!----></div></th>
														</tr>
													</thead>
													<tbody role="rowgroup" class="p-datatable-tbody">
														<tr role="row" class="" v-for="typ in dataTyp_Ville" v-bind:key="typ.libelle">
															<td role="cell" class="">{{ typ.libelle}}</td>
															<td role="cell" class="">{{ typ.nombre_annonces}}</td>
															<td role="cell" class="">{{ typ.surface_mediane}}</td>
															<td role="cell" class="">{{ typ.prix_m2_median}}</td>
														</tr>
													</tbody>
												</table>
												<table role="table" class="p-datatable-table">
													<thead role="rowgroup" class="p-datatable-thead">
														<tr role="row">
															<th role="cell" class=""><div class="p-column-header-content"><span class="p-column-title">Typo.</span></div></th>
															<th role="cell" class=""><!----><div class="p-column-header-content"><!----><span class="p-column-title">Nombre</span><!----><!----><!----><!----></div></th>
															<th role="cell" class=""><!----><div class="p-column-header-content"><!----><span class="p-column-title">Prix m² med.</span><!----><!----><!----><!----></div></th>
														</tr>
													</thead>
													<tbody role="rowgroup" class="p-datatable-tbody">
														<tr role="row" class="" v-for="dpe in dataDpe_Ville" v-bind:key="dpe.libelle">
															<td role="cell" class="">{{ dpe.libelle}}</td>
															<td role="cell" class="">{{ dpe.nombre_annonces}}</td>
															<td role="cell" class="">{{ dpe.prix_m2_median}}</td>
														</tr>
													</tbody>
												</table>
											</div>
										</div>
										<div class="grid">
											<div class="col-12"><highcharts :options="chartOptionsPrice_typo_ville"></highcharts></div>
											<div class="col-12"><highcharts :options="chartOptionsPrice_dpe_ville"></highcharts></div>
										</div>
										<div class="grid">
											<div class="col-12"><highcharts :options="chartOptionsPriceM2_typo_ville"></highcharts></div>
											<div class="col-12"><highcharts :options="chartOptionsPriceM2_dpe_ville"></highcharts></div>
										</div>
										<div class="grid">
											<div class="col-12"><highcharts :options="chartOptionsVolume_typo_ville"></highcharts></div>
											<div class="col-12"><highcharts :options="chartOptionsVolume_dpe_ville"></highcharts></div>
										</div>
										<div class="grid">
											<div style="width:100%;text-align: center;font-familiy: Helvetica, Arial, sans-serif;font-size: 1.4em;color: rgb(51, 51, 51);font-weight: bold;fill: rgb(51, 51, 51);">Distribution des prix par typologie</div>									
											<div class="col-12"><apexchart type="heatmap" height="250px" width="100%" :options="chartOptionsDistribution_typo_ville.chartOptions" :series="chartOptionsDistribution_typo_ville.series"></apexchart></div>
											<div style="width:100%;text-align: center;font-familiy: Helvetica, Arial, sans-serif;font-size: 1.4em;color: rgb(51, 51, 51);font-weight: bold;fill: rgb(51, 51, 51);">Distribution des prix par dpe</div>									
											<div class="col-12"><apexchart type="heatmap" height="250px" width="100%" :options="chartOptionsDistribution_dpe_ville.chartOptions" :series="chartOptionsDistribution_dpe_ville.series"></apexchart></div>
										</div>
									</div>
								</div>

								</div>
							</div>
					</div>
				</div>
			</Fieldset>
		</div>
	</div>
</template>
<script>
import { mapGetters } from 'vuex'
import { ObserveVisibility  } from 'vue-observe-visibility'
import { Chart } from 'highcharts-vue'
import Fieldset from 'primevue/fieldset';
import AnimatedTabs from '../global/AnimatedTabs.vue';

export default {
	name: 'TabAncien',
	data: function() {
		return {
			activeSection2 : '',
			tab: 'tvap',
			unan: 0,
			deuxans: 0,
			fu_visible: false,
			detail: '',
			tabs_forme: [
			{ label: 'Tout', name: 'total' },
			{ label: 'Appartement', name: 'flat' },
			{ label: 'Maison', name: 'house' },
			],
			activeTab_forme: 'total',
			chartOptionsPrice_typo_rayon: {
				accessibility: {
					enabled: false
				},
				credits: {
					enabled: false
				},
				chart: { zoomType: 'xy' },
				title: { text: 'Historique des loyers par typologie' },
				xAxis: { categories: [] },
				yAxis: {
					title: {
						text: null 
					},
					labels: {
						formatter: function() {
							const euro = new Intl.NumberFormat('fr-FR', {
								style: 'currency',
								currency: 'EUR',
								minimumFractionDigits: 0
							});
							let formatted = euro.format(this.value)
							formatted = formatted.replace(/\u00A0/g, ' ');
							return formatted
						}
					},
				}, // Pas de titre pour l'axe des ordonnées
				tooltip: { shared: true },
				series: []
			},
			chartOptionsPriceM2_typo_rayon: {
				accessibility: {
					enabled: false
				},
				credits: {
					enabled: false
				},
				chart: { zoomType: 'xy' },
				title: { text: 'Historique des loyers au m² par typologie' },
				xAxis: { categories: [] },
				yAxis: {
					title: {
						text: null 
					},
					labels: {
						formatter: function() {
							const euro = new Intl.NumberFormat('fr-FR', {
								style: 'currency',
								currency: 'EUR',
								minimumFractionDigits: 0
							});
							let formatted = euro.format(this.value)
							formatted = formatted.replace(/\u00A0/g, ' ');
							return formatted
						}
					},
				}, // Pas de titre pour l'axe des ordonnées
				tooltip: { shared: true },
				series: []
			},
			chartOptionsVolume_typo_rayon: {
				accessibility: {
					enabled: false
				},
				credits: {
					enabled: false
				},
				chart: { zoomType: 'xy' },
				title: { text: 'Historique des volumes par typologie' },
				xAxis: { categories: [] },
				yAxis: {
					title: {
						text: null 
					},
					labels: {
						enabled: true,
					},
				}, // Pas de titre pour l'axe des ordonnées
				tooltip: { shared: true },
				series: []
			},
			chartOptionsPrice_typo_iris: {
				accessibility: {
					enabled: false
				},
				credits: {
					enabled: false
				},
				chart: { zoomType: 'xy' },
				title: { text: 'Historique des loyers par typologie' },
				xAxis: { categories: [] },
				yAxis: {
					title: {
						text: null 
					},
					labels: {
						formatter: function() {
							const euro = new Intl.NumberFormat('fr-FR', {
								style: 'currency',
								currency: 'EUR',
								minimumFractionDigits: 0
							});
							let formatted = euro.format(this.value)
							formatted = formatted.replace(/\u00A0/g, ' ');
							return formatted
						}
					},
				}, // Pas de titre pour l'axe des ordonnées
				tooltip: { shared: true },
				series: []
			},
			chartOptionsPriceM2_typo_iris: {
				accessibility: {
					enabled: false
				},
				credits: {
					enabled: false
				},
				chart: { zoomType: 'xy' },
				title: { text: 'Historique des loyers au m² par typologie' },
				xAxis: { categories: [] },
				yAxis: {
					title: {
						text: null 
					},
					labels: {
						formatter: function() {
							const euro = new Intl.NumberFormat('fr-FR', {
								style: 'currency',
								currency: 'EUR',
								minimumFractionDigits: 0
							});
							let formatted = euro.format(this.value)
							formatted = formatted.replace(/\u00A0/g, ' ');
							return formatted
						}
					},
				}, // Pas de titre pour l'axe des ordonnées
				tooltip: { shared: true },
				series: []
			},
			chartOptionsVolume_typo_iris: {
				accessibility: {
					enabled: false
				},
				credits: {
					enabled: false
				},
				chart: { zoomType: 'xy' },
				title: { text: 'Historique des volumes par typologie' },
				xAxis: { categories: [] },
				yAxis: {
					title: {
						text: null 
					},
					labels: {
						enabled: true,
					},
				}, // Pas de titre pour l'axe des ordonnées
				tooltip: { shared: true },
				series: []
			},
			chartOptionsPrice_typo_ville: {
				accessibility: {
					enabled: false
				},
				credits: {
					enabled: false
				},
				chart: { zoomType: 'xy' },
				title: { text: 'Historique des loyers par typologie' },
				xAxis: { categories: [] },
				yAxis: {
					title: {
						text: null 
					},
					labels: {
						formatter: function() {
							const euro = new Intl.NumberFormat('fr-FR', {
								style: 'currency',
								currency: 'EUR',
								minimumFractionDigits: 0
							});
							let formatted = euro.format(this.value)
							formatted = formatted.replace(/\u00A0/g, ' ');
							return formatted
						}
					},
				}, // Pas de titre pour l'axe des ordonnées
				tooltip: { shared: true },
				series: []
			},
			chartOptionsPriceM2_typo_ville: {
				accessibility: {
					enabled: false
				},
				credits: {
					enabled: false
				},
				chart: { zoomType: 'xy' },
				title: { text: 'Historique des loyers au m² par typologie' },
				xAxis: { categories: [] },
				yAxis: {
					title: {
						text: null 
					},
					labels: {
						formatter: function() {
							const euro = new Intl.NumberFormat('fr-FR', {
								style: 'currency',
								currency: 'EUR',
								minimumFractionDigits: 0
							});
							let formatted = euro.format(this.value)
							formatted = formatted.replace(/\u00A0/g, ' ');
							return formatted
						}
					},
				}, // Pas de titre pour l'axe des ordonnées
				tooltip: { shared: true },
				series: []
			},
			chartOptionsVolume_typo_ville: {
				accessibility: {
					enabled: false
				},
				credits: {
					enabled: false
				},
				chart: { zoomType: 'xy' },
				title: { text: 'Historique des volumes par typologie' },
				xAxis: { categories: [] },
				yAxis: {
					title: {
						text: null 
					},
					labels: {
						enabled: true,
					},
				}, // Pas de titre pour l'axe des ordonnées
				tooltip: { shared: true },
				series: []
			},
			chartOptionsPrice_dpe_rayon: {
				accessibility: {
					enabled: false
				},
				credits: {
					enabled: false
				},
				chart: { zoomType: 'xy' },
				title: { text: 'Historique des loyers par DPE' },
				xAxis: { categories: [] },
				yAxis: {
					title: {
						text: null 
					},
					labels: {
						formatter: function() {
							const euro = new Intl.NumberFormat('fr-FR', {
								style: 'currency',
								currency: 'EUR',
								minimumFractionDigits: 0
							});
							let formatted = euro.format(this.value)
							formatted = formatted.replace(/\u00A0/g, ' ');
							return formatted
						}
					},
				}, // Pas de titre pour l'axe des ordonnées
				tooltip: { shared: true },
				series: []
			},
			chartOptionsPriceM2_dpe_rayon: {
				accessibility: {
					enabled: false
				},
				credits: {
					enabled: false
				},
				chart: { zoomType: 'xy' },
				title: { text: 'Historique des loyers au m² par DPE' },
				xAxis: { categories: [] },
				yAxis: {
					title: {
						text: null 
					},
					labels: {
						formatter: function() {
							const euro = new Intl.NumberFormat('fr-FR', {
								style: 'currency',
								currency: 'EUR',
								minimumFractionDigits: 0
							});
							let formatted = euro.format(this.value)
							formatted = formatted.replace(/\u00A0/g, ' ');
							return formatted
						}
					},
				}, // Pas de titre pour l'axe des ordonnées
				tooltip: { shared: true },
				series: []
			},
			chartOptionsVolume_dpe_rayon: {
				accessibility: {
					enabled: false
				},
				credits: {
					enabled: false
				},
				chart: { zoomType: 'xy' },
				title: { text: 'Historique des volumes par DPE' },
				xAxis: { categories: [] },
				yAxis: {
					title: {
						text: null 
					},
					labels: {
						enabled: true,
					},
				}, // Pas de titre pour l'axe des ordonnées
				tooltip: { shared: true },
				series: []
			},
			chartOptionsPrice_dpe_iris: {
				accessibility: {
					enabled: false
				},
				credits: {
					enabled: false
				},
				chart: { zoomType: 'xy' },
				title: { text: 'Historique des loyers par DPE' },
				xAxis: { categories: [] },
				yAxis: {
					title: {
						text: null 
					},
					labels: {
						formatter: function() {
							const euro = new Intl.NumberFormat('fr-FR', {
								style: 'currency',
								currency: 'EUR',
								minimumFractionDigits: 0
							});
							let formatted = euro.format(this.value)
							formatted = formatted.replace(/\u00A0/g, ' ');
							return formatted
						}
					},
				}, // Pas de titre pour l'axe des ordonnées
				tooltip: { shared: true },
				series: []
			},
			chartOptionsPriceM2_dpe_iris: {
				accessibility: {
					enabled: false
				},
				credits: {
					enabled: false
				},
				chart: { zoomType: 'xy' },
				title: { text: 'Historique des loyers au m² par DPE' },
				xAxis: { categories: [] },
				yAxis: {
					title: {
						text: null 
					},
					labels: {
						formatter: function() {
							const euro = new Intl.NumberFormat('fr-FR', {
								style: 'currency',
								currency: 'EUR',
								minimumFractionDigits: 0
							});
							let formatted = euro.format(this.value)
							formatted = formatted.replace(/\u00A0/g, ' ');
							return formatted
						}
					},
				}, // Pas de titre pour l'axe des ordonnées
				tooltip: { shared: true },
				series: []
			},
			chartOptionsVolume_dpe_iris: {
				accessibility: {
					enabled: false
				},
				credits: {
					enabled: false
				},
				chart: { zoomType: 'xy' },
				title: { text: 'Historique des volumes par DPE' },
				xAxis: { categories: [] },
				yAxis: {
					title: {
						text: null 
					},
					labels: {
						enabled: true,
					},
				}, // Pas de titre pour l'axe des ordonnées
				tooltip: { shared: true },
				series: []
			},
			chartOptionsPrice_dpe_ville: {
				accessibility: {
					enabled: false
				},
				credits: {
					enabled: false
				},
				chart: { zoomType: 'xy' },
				title: { text: 'Historique des loyers par DPE' },
				xAxis: { categories: [] },
				yAxis: {
					title: {
						text: null 
					},
					labels: {
						formatter: function() {
							const euro = new Intl.NumberFormat('fr-FR', {
								style: 'currency',
								currency: 'EUR',
								minimumFractionDigits: 0
							});
							let formatted = euro.format(this.value)
							formatted = formatted.replace(/\u00A0/g, ' ');
							return formatted
						}
					},
				}, // Pas de titre pour l'axe des ordonnées
				tooltip: { shared: true },
				series: []
			},
			chartOptionsPriceM2_dpe_ville: {
				accessibility: {
					enabled: false
				},
				credits: {
					enabled: false
				},
				chart: { zoomType: 'xy' },
				title: { text: 'Historique des loyers au m² par DPE' },
				xAxis: { categories: [] },
				yAxis: {
					title: {
						text: null 
					},
					labels: {
						formatter: function() {
							const euro = new Intl.NumberFormat('fr-FR', {
								style: 'currency',
								currency: 'EUR',
								minimumFractionDigits: 0
							});
							let formatted = euro.format(this.value)
							formatted = formatted.replace(/\u00A0/g, ' ');
							return formatted
						}
					},
				}, // Pas de titre pour l'axe des ordonnées
				tooltip: { shared: true },
				series: []
			},
			chartOptionsVolume_dpe_ville: {
				accessibility: {
					enabled: false
				},
				credits: {
					enabled: false
				},
				chart: { zoomType: 'xy' },
				title: { text: 'Historique des volumes par DPE' },
				xAxis: { categories: [] },
				yAxis: {
					title: {
						text: null 
					},
					labels: {
						enabled: true,
					},
				}, // Pas de titre pour l'axe des ordonnées
				tooltip: { shared: true },
				series: []
			},
			chartOptionsDistribution_typo_rayon: {
				series: [],
				chartOptions: {
					chart: {
						type: 'heatmap',
						height: 400,
					},
					dataLabels: {
						enabled: false
					},
					colors: ["#008FFB"],
					xaxis: {
						type: 'category',
						categories: []
					},
				},

			},
			chartOptionsDistribution_typo_iris: {
				series: [],
				chartOptions: {
					chart: {
						type: 'heatmap',
						height: 400,
					},
					dataLabels: {
						enabled: false
					},
					colors: ["#008FFB"],
					xaxis: {
						type: 'category',
						categories: []
					},
				},

			},
			chartOptionsDistribution_typo_ville: {
				series: [],
				chartOptions: {
					chart: {
						type: 'heatmap',
						height: 400,
					},
					dataLabels: {
						enabled: false
					},
					colors: ["#008FFB"],
					xaxis: {
						type: 'category',
						categories: []
					},
				},

			},
			chartOptionsDistribution_dpe_rayon: {
				series: [],
				chartOptions: {
					chart: {
						type: 'heatmap',
						height: 400,
					},
					dataLabels: {
						enabled: false
					},
					colors: ["#008FFB"],
					xaxis: {
						type: 'category',
						categories: []
					},
				},

			},
			chartOptionsDistribution_dpe_iris: {
				series: [],
				chartOptions: {
					chart: {
						type: 'heatmap',
						height: 400,
					},
					dataLabels: {
						enabled: false
					},
					colors: ["#008FFB"],
					xaxis: {
						type: 'category',
						categories: []
					},
				},

			},
			chartOptionsDistribution_dpe_ville: {
				series: [],
				chartOptions: {
					chart: {
						type: 'heatmap',
						height: 400,
					},
					dataLabels: {
						enabled: false
					},
					colors: ["#008FFB"],
					xaxis: {
						type: 'category',
						categories: []
					},
				},

			},
		}
	},

	computed: Object.assign(mapGetters([ 'app', 'report' ]), {
		LibelleSecteur: function() {
			let r = ''
			try {
				r = `${this.app.adr_init.iris_label} (${this.app.adr_init.iris_code})`
				switch (this.activeTab_geo) {
					case 'ville':
						r = `${this.app.adr_init.city} (${this.app.adr_init.insee})`
						break;
					case 'rayon':
						r = `à ${this.app.locatif_rayon_distance.city} m`
						break;
				}
			} catch (error) {
				r = 'Module Locatif'
			}
			return r;
		},
		dataPrix_Rayon: function() {
			let o = this.app.locatif_rayon
			if (this.activeTab_forme != 'total') {
				o = this.app['locatif_rayon_' + this.activeTab_forme]
			}
			return o
		},
		dataPrix_Iris: function() {
			let o = this.app.locatif_iris
			if (this.activeTab_forme != 'total') {
				o = this.app['locatif_iris_' + this.activeTab_forme]
			}
			return o
		},
		dataPrix_Ville: function() {
			let o = this.app.locatif_ville
			if (this.activeTab_forme != 'total') {
				o = this.app['locatif_ville_' + this.activeTab_forme]
			}
			return o
		},
		dataTyp_Rayon: function() {
			let d = []
			let o = this.dataPrix_Rayon
			for (let i = 1; i < 6; i++) {
				let z = {libelle: 'T' + i, nombre_annonces: 0, surface_mediane: '-', prix_m2_median: '-'}
				if (o.typo && o.typo['t' + i]) {
					z.libelle = 'T' + i
					z.nombre_annonces = o.typo['t' + i].nombre_annonces
					z.surface_mediane = Math.round(o.typo['t' + i].surface_mediane,2) + ' m²'
					z.prix_m2_median = this.DonneValeurPrix(Math.round(o.typo['t' + i].prix_m2_median)) + "/m²"
				}
				d.push(z)
			}
			return d
		},		
		dataTyp_Iris: function() {
			let d = []
			let o = this.dataPrix_Iris
			for (let i = 1; i < 6; i++) {
				let z = {libelle: 'T' + i, nombre_annonces: 0, surface_mediane: '-', prix_m2_median: '-'}
				if (o.typo && o.typo['t' + i]) {
					z.libelle = 'T' + i
					z.nombre_annonces = o.typo['t' + i].nombre_annonces
					z.surface_mediane = Math.round(o.typo['t' + i].surface_mediane,2) + ' m²'
					z.prix_m2_median = this.DonneValeurPrix(Math.round(o.typo['t' + i].prix_m2_median)) + "/m²"
				}
				d.push(z)
			}
			return d
		},		
		dataTyp_Ville: function() {
			let d = []
			let o = this.dataPrix_Ville
			for (let i = 1; i < 6; i++) {
				let z = {libelle: 'T' + i, nombre_annonces: 0, surface_mediane: '-', prix_m2_median: '-'}
				if (o.typo && o.typo['t' + i]) {
					z.libelle = 'T' + i
					z.nombre_annonces = o.typo['t' + i].nombre_annonces
					z.surface_mediane = Math.round(o.typo['t' + i].surface_mediane,2) + ' m²'
					z.prix_m2_median = this.DonneValeurPrix(Math.round(o.typo['t' + i].prix_m2_median)) + "/m²"
				}
				d.push(z)
			}
			return d
		},		
		dataDpe_Rayon: function() {
			let d = []
			let dpe = ['A', 'B', 'C', 'D', 'E', 'F', 'G']
			let o = this.dataPrix_Rayon
			for (let i = 0; i < dpe.length; i++) {
				if (o.energie[dpe[i]]) {
					let z = {}
					z.libelle = dpe[i]
					z.nombre_annonces = o.energie[dpe[i]].nombre_annonces
					z.prix_m2_median = this.DonneValeurPrix(Math.round(o.energie[dpe[i]].prix_m2_median)) + "/m²"
					d.push(z)
				}
			}
			return d
		},				
		dataDpe_Iris: function() {
			let d = []
			let dpe = ['A', 'B', 'C', 'D', 'E', 'F', 'G']
			let o = this.dataPrix_Iris
			for (let i = 0; i < dpe.length; i++) {
				if (o.energie[dpe[i]]) {
					let z = {}
					z.libelle = dpe[i]
					z.nombre_annonces = o.energie[dpe[i]].nombre_annonces
					z.prix_m2_median = this.DonneValeurPrix(Math.round(o.energie[dpe[i]].prix_m2_median)) + "/m²"
					d.push(z)
				}
			}
			return d
		},				
		dataDpe_Ville: function() {
			let d = []
			let dpe = ['A', 'B', 'C', 'D', 'E', 'F', 'G']
			let o = this.dataPrix_Ville
			for (let i = 0; i < dpe.length; i++) {
				if (o.energie[dpe[i]]) {
					let z = {}
					z.libelle = dpe[i]
					z.nombre_annonces = o.energie[dpe[i]].nombre_annonces
					z.prix_m2_median = this.DonneValeurPrix(Math.round(o.energie[dpe[i]].prix_m2_median)) + "/m²"
					d.push(z)
				}
			}
			return d
		},				
	}),
	
	directives: {
		'observe-visibility': ObserveVisibility
	},

	methods: {
		onVisibilityChange(isVisible) {			
			if (isVisible) {
				this.activeSection2 = 'taboptim'
			} else {
				if (this.activeSection2 == 'taboptim') {
					this.activeSection2 = ''
				}
			}
		},

		maj_graph_histo: function() {
			const colorMap = {
				"T1": "#008FFB",  // Rouge
				"T2": "#00E396",  // Vert
				"T3": "#FEB019",  // Bleu
				"T4": "#FF4560",  // Jaune
				"T5": "#7c74c4"   // Violet
			};
			// iris
			let locatif_rayon = 'locatif_rayon'
			let locatif_iris = 'locatif_iris'
			let locatif_ville = 'locatif_ville'
			switch (this.activeTab_forme) {
				case 'flat':
					locatif_rayon 	= locatif_rayon 	+ "_flat"
					locatif_iris 	= locatif_iris 	+ "_flat"
					locatif_ville 	= locatif_ville 	+ "_flat"
					break;
			
				case 'house':
					locatif_rayon 	= locatif_rayon 	+ "_house"
					locatif_iris 	= locatif_iris 	+ "_house"
					locatif_ville 	= locatif_ville 	+ "_house"
					break;
		
				default:
					break;
			}
			if ((this.app.locatif_rayon && (this.activeTab_forme=='total')) || (this.app.locatif_rayon_flat && (this.activeTab_forme=='flat')) || (this.app.locatif_rayon_house && (this.activeTab_forme=='house'))) {

				let rawData_rayon = this.app[locatif_rayon].historique
				// iris
				let rawData_iris = this.app[locatif_iris].historique
				// iris
				let rawData_ville = this.app[locatif_ville].historique

				// Extraction des labels de période pour l'axe x
				const periodLabels = rawData_iris.map(item => item.periode.label);

				const euro = new Intl.NumberFormat('fr-FR', {
					style: 'currency',
					currency: 'EUR',
					minimumFractionDigits: 0
				});

				// Extraction des labels de période pour l'axe x
				let rangeLabels_rayon = [];
				let rangeLabels_iris = [];
				let rangeLabels_ville = [];
				for (let index = 0; index < this.app[locatif_rayon].distribution_range.length; index++) {
					const element = this.app[locatif_rayon].distribution_range[index];
					switch (index) {
						case 0:
							rangeLabels_rayon.push('< ' + euro.format(element.to).replace(/\u00A0/g, ' '))
							break;
					
						case (this.app[locatif_rayon].distribution_range.length-1):
							rangeLabels_rayon.push('> ' + euro.format(element.from).replace(/\u00A0/g, ' '))
							break;
					
						default:
							rangeLabels_rayon.push('< ' + euro.format(element.to).replace(/\u00A0/g, ' '))
							break;
					} 
				}
				for (let index = 0; index < this.app[locatif_iris].distribution_range.length; index++) {
					const element = this.app[locatif_iris].distribution_range[index];
					switch (index) {
						case 0:
							rangeLabels_iris.push('< ' + euro.format(element.to).replace(/\u00A0/g, ' '))
							break;
					
						case (this.app[locatif_iris].distribution_range.length-1):
							rangeLabels_iris.push('> ' + euro.format(element.from).replace(/\u00A0/g, ' '))
							break;
					
						default:
							rangeLabels_iris.push('< ' + euro.format(element.to).replace(/\u00A0/g, ' '))
							break;
					} 
				}
				for (let index = 0; index < this.app[locatif_ville].distribution_range.length; index++) {
					const element = this.app[locatif_ville].distribution_range[index];
					switch (index) {
						case 0:
							rangeLabels_ville.push('< ' + euro.format(element.to).replace(/\u00A0/g, ' '))
							break;
					
						case (this.app[locatif_ville].distribution_range.length-1):
							rangeLabels_ville.push('> ' + euro.format(element.from).replace(/\u00A0/g, ' '))
							break;
					
						default:
							rangeLabels_ville.push('< ' + euro.format(element.to).replace(/\u00A0/g, ' '))
							break;
					} 
				}
				

				// Liste des typologies à traiter
				const typologies = ['t1', 't2', 't3', 't4', 't5'];
				// Liste des dpe à traiter
				const dpe = ['A', 'B', 'C', 'D', 'E', 'F', 'G'];

				const priceSeriesDistribution_rayon = [];
				for (let index = 0; index < typologies.length; index++) {
					let d = []
					for (let index2 = 0; index2 < this.app[locatif_rayon].distribution_typo[typologies[index]].length; index2++) {
						d.push(this.app[locatif_rayon].distribution_typo[typologies[index]][index2])
					}
					priceSeriesDistribution_rayon.push({name: typologies[index].toUpperCase(), data: d})
				}

				const priceSeriesDistribution_iris = [];
				for (let index = 0; index < typologies.length; index++) {
					let d = []
					for (let index2 = 0; index2 < this.app[locatif_iris].distribution_typo[typologies[index]].length; index2++) {
						d.push(this.app[locatif_iris].distribution_typo[typologies[index]][index2])
					}
					priceSeriesDistribution_iris.push({name: typologies[index].toUpperCase(), data: d})
				}

				const priceSeriesDistribution_ville = [];
				for (let index = 0; index < typologies.length; index++) {
					let d = []
					for (let index2 = 0; index2 < this.app[locatif_ville].distribution_typo[typologies[index]].length; index2++) {
						d.push(this.app[locatif_ville].distribution_typo[typologies[index]][index2])
					}
					priceSeriesDistribution_ville.push({name: typologies[index].toUpperCase(), data: d})
				}

				const priceSeriesDistribution_Dpe_rayon = [];
				for (let index = 0; index < dpe.length; index++) {
					let d = []
					if (this.app[locatif_rayon].distribution_dpe[dpe[index]]) {
						for (let index2 = 0; index2 < this.app[locatif_rayon].distribution_dpe[dpe[index]].length; index2++) {
							d.push(this.app[locatif_rayon].distribution_dpe[dpe[index]][index2])
						}
						priceSeriesDistribution_Dpe_rayon.push({name: dpe[index].toUpperCase(), data: d})
					}
				}

				const priceSeriesDistribution_Dpe_iris = [];
				for (let index = 0; index < dpe.length; index++) {
					let d = []
					if (this.app[locatif_iris].distribution_dpe[dpe[index]]) {
						for (let index2 = 0; index2 < this.app[locatif_iris].distribution_dpe[dpe[index]].length; index2++) {
							d.push(this.app[locatif_iris].distribution_dpe[dpe[index]][index2])
						}
						priceSeriesDistribution_Dpe_iris.push({name: dpe[index].toUpperCase(), data: d})
					}
				}

				const priceSeriesDistribution_Dpe_ville = [];
				for (let index = 0; index < dpe.length; index++) {
					let d = []
					if (this.app[locatif_ville].distribution_dpe[dpe[index]]) {
						for (let index2 = 0; index2 < this.app[locatif_ville].distribution_dpe[dpe[index]].length; index2++) {
							d.push(this.app[locatif_ville].distribution_dpe[dpe[index]][index2])
						}
						priceSeriesDistribution_Dpe_ville.push({name: dpe[index].toUpperCase(), data: d})
					}
				}

				let chartOptions_rayon = {
					chart: {
						type: 'heatmap',
						toolbar: {
							show: false
						}
					},
					dataLabels: {
						enabled: false
					},
					colors: ["#008FFB"],
					xaxis: {
						type: 'category',
						categories: rangeLabels_rayon
					},
				}
				let chartOptions_iris = {
					chart: {
						type: 'heatmap',
						toolbar: {
							show: false
						}
					},
					dataLabels: {
						enabled: false
					},
					colors: ["#008FFB"],
					xaxis: {
						type: 'category',
						categories: rangeLabels_iris
					},
				}
				let chartOptions_ville = {
					chart: {
						type: 'heatmap',
						toolbar: {
							show: false
						}
					},
					dataLabels: {
						enabled: false
					},
					colors: ["#008FFB"],
					xaxis: {
						type: 'category',
						categories: rangeLabels_ville
					},
				}
				this.chartOptionsDistribution_typo_rayon.chartOptions = chartOptions_rayon
				this.chartOptionsDistribution_typo_rayon.series = priceSeriesDistribution_rayon;

				this.chartOptionsDistribution_typo_iris.chartOptions = chartOptions_iris
				this.chartOptionsDistribution_typo_iris.series = priceSeriesDistribution_iris;

				this.chartOptionsDistribution_typo_ville.chartOptions = chartOptions_ville
				this.chartOptionsDistribution_typo_ville.series = priceSeriesDistribution_ville;

				this.chartOptionsDistribution_dpe_rayon.chartOptions = chartOptions_rayon
				this.chartOptionsDistribution_dpe_rayon.series = priceSeriesDistribution_Dpe_rayon;

				this.chartOptionsDistribution_dpe_iris.chartOptions = chartOptions_iris
				this.chartOptionsDistribution_dpe_iris.series = priceSeriesDistribution_Dpe_iris;

				this.chartOptionsDistribution_dpe_ville.chartOptions = chartOptions_ville
				this.chartOptionsDistribution_dpe_ville.series = priceSeriesDistribution_Dpe_ville;

				// Préparation des séries pour chaque graphique
				const priceSeries_rayon = [];
				const priceM2Series_rayon = [];
				const volumeSeries_rayon = [];

				const priceSeries_iris = [];
				const priceM2Series_iris = [];
				const volumeSeries_iris = [];

				const priceSeries_ville = [];
				const priceM2Series_ville = [];
				const volumeSeries_ville = [];

				// Préparation des séries pour chaque graphique
				const dpe_priceSeries_rayon = [];
				const dpe_priceM2Series_rayon = [];
				const dpe_volumeSeries_rayon = [];

				const dpe_priceSeries_iris = [];
				const dpe_priceM2Series_iris = [];
				const dpe_volumeSeries_iris = [];

				const dpe_priceSeries_ville = [];
				const dpe_priceM2Series_ville = [];
				const dpe_volumeSeries_ville = [];

				typologies.forEach(typ => {
					const upperTyp = typ.toUpperCase();
					priceSeries_rayon.push({
					name: upperTyp,
					data: rawData_rayon.map(item => item.typo && item.typo[typ] ? Math.round(item.typo[typ].prix_median) : null),
					color: colorMap[upperTyp]
					});
					priceM2Series_rayon.push({
					name: upperTyp,
					data: rawData_rayon.map(item => item.typo && item.typo[typ] ? Math.round(item.typo[typ].prix_m2_median) : null),
					color: colorMap[upperTyp]
					});
					volumeSeries_rayon.push({
					name: upperTyp,
					data: rawData_rayon.map(item => item.typo && item.typo[typ] ? item.typo[typ].nombre_annonces : null),
					color: colorMap[upperTyp]
					});
				});
				priceM2Series_rayon.push({
					name: 'total',
					data: rawData_rayon.map(item => item.total ? Math.round(item.total.prix_m2_median) : null),
					color: "#000000"
				});

				typologies.forEach(typ => {
					const upperTyp = typ.toUpperCase();
					priceSeries_iris.push({
					name: upperTyp,
					data: rawData_iris.map(item => item.typo && item.typo[typ] ? Math.round(item.typo[typ].prix_median) : null),
					color: colorMap[upperTyp]
					});
					priceM2Series_iris.push({
					name: upperTyp,
					data: rawData_iris.map(item => item.typo && item.typo[typ] ? Math.round(item.typo[typ].prix_m2_median) : null),
					color: colorMap[upperTyp]
					});
					volumeSeries_iris.push({
					name: upperTyp,
					data: rawData_iris.map(item => item.typo && item.typo[typ] ? item.typo[typ].nombre_annonces : null),
					color: colorMap[upperTyp]
					});
				});
				
				priceM2Series_iris.push({
					name: 'total',
					data: rawData_iris.map(item => item.total ? Math.round(item.total.prix_m2_median) : null),
					color: "#000000"
				});

				typologies.forEach(typ => {
					const upperTyp = typ.toUpperCase();
					priceSeries_ville.push({
					name: upperTyp,
					data: rawData_ville.map(item => item.typo && item.typo[typ] ? Math.round(item.typo[typ].prix_median) : null),
					color: colorMap[upperTyp]
					});
					priceM2Series_ville.push({
					name: upperTyp,
					data: rawData_ville.map(item => item.typo && item.typo[typ] ? Math.round(item.typo[typ].prix_m2_median) : null),
					color: colorMap[upperTyp]
					});
					volumeSeries_ville.push({
					name: upperTyp,
					data: rawData_ville.map(item => item.typo && item.typo[typ] ? item.typo[typ].nombre_annonces : null),
					color: colorMap[upperTyp]
					});
				});
				
				priceM2Series_ville.push({
					name: 'total',
					data: rawData_ville.map(item => item.total ? Math.round(item.total.prix_m2_median) : null),
					color: "#000000"
				});






				dpe.forEach(typ => {
					const upperTyp = typ.toUpperCase();
					dpe_priceSeries_rayon.push({
					name: upperTyp,
					data: rawData_rayon.map(item => item.energie && item.energie[typ] ? Math.round(item.energie[typ].prix_median) : null),
					color: colorMap[upperTyp]
					});
					dpe_priceM2Series_rayon.push({
					name: upperTyp,
					data: rawData_rayon.map(item => item.energie && item.energie[typ] ? Math.round(item.energie[typ].prix_m2_median) : null),
					color: colorMap[upperTyp]
					});
					dpe_volumeSeries_rayon.push({
					name: upperTyp,
					data: rawData_rayon.map(item => item.energie && item.energie[typ] ? item.energie[typ].nombre_annonces : null),
					color: colorMap[upperTyp]
					});
				});
				
				dpe_priceM2Series_rayon.push({
					name: 'total',
					data: rawData_rayon.map(item => item.total ? Math.round(item.total.prix_m2_median) : null),
					color: "#000000"
				});

				dpe.forEach(typ => {
					const upperTyp = typ.toUpperCase();
					dpe_priceSeries_iris.push({
					name: upperTyp,
					data: rawData_iris.map(item => item.energie && item.energie[typ] ? Math.round(item.energie[typ].prix_median) : null),
					color: colorMap[upperTyp]
					});
					dpe_priceM2Series_iris.push({
					name: upperTyp,
					data: rawData_iris.map(item => item.energie && item.energie[typ] ? Math.round(item.energie[typ].prix_m2_median) : null),
					color: colorMap[upperTyp]
					});
					dpe_volumeSeries_iris.push({
					name: upperTyp,
					data: rawData_iris.map(item => item.energie && item.energie[typ] ? item.energie[typ].nombre_annonces : null),
					color: colorMap[upperTyp]
					});
				});
				
				dpe_priceM2Series_iris.push({
					name: 'total',
					data: rawData_iris.map(item => item.total ? Math.round(item.total.prix_m2_median) : null),
					color: "#000000"
				});

				dpe.forEach(typ => {
					const upperTyp = typ.toUpperCase();
					dpe_priceSeries_ville.push({
					name: upperTyp,
					data: rawData_ville.map(item => item.energie && item.energie[typ] ? Math.round(item.energie[typ].prix_median) : null),
					color: colorMap[upperTyp]
					});
					dpe_priceM2Series_ville.push({
					name: upperTyp,
					data: rawData_ville.map(item => item.energie && item.energie[typ] ? Math.round(item.energie[typ].prix_m2_median) : null),
					color: colorMap[upperTyp]
					});
					dpe_volumeSeries_ville.push({
					name: upperTyp,
					data: rawData_ville.map(item => item.energie && item.energie[typ] ? item.energie[typ].nombre_annonces : null),
					color: colorMap[upperTyp]
					});
				});
				
				dpe_priceM2Series_ville.push({
					name: 'total',
					data: rawData_ville.map(item => item.total ? Math.round(item.total.prix_m2_median) : null),
					color: "#000000"
				});

				// Mise à jour des options pour chaque graphique
				this.chartOptionsPrice_typo_iris.xAxis.categories = periodLabels;
				this.chartOptionsPrice_typo_iris.series = priceSeries_iris;

				this.chartOptionsPriceM2_typo_iris.xAxis.categories = periodLabels;
				this.chartOptionsPriceM2_typo_iris.series = priceM2Series_iris;

				this.chartOptionsVolume_typo_iris.xAxis.categories = periodLabels;
				this.chartOptionsVolume_typo_iris.series = volumeSeries_iris;

				// Mise à jour des options pour chaque graphique
				this.chartOptionsPrice_dpe_iris.xAxis.categories = periodLabels;
				this.chartOptionsPrice_dpe_iris.series = dpe_priceSeries_iris;

				this.chartOptionsPriceM2_dpe_iris.xAxis.categories = periodLabels;
				this.chartOptionsPriceM2_dpe_iris.series = dpe_priceM2Series_iris;

				this.chartOptionsVolume_dpe_iris.xAxis.categories = periodLabels;
				this.chartOptionsVolume_dpe_iris.series = dpe_volumeSeries_iris;




				// Mise à jour des options pour chaque graphique
				this.chartOptionsPrice_typo_rayon.xAxis.categories = periodLabels;
				this.chartOptionsPrice_typo_rayon.series = priceSeries_rayon;

				this.chartOptionsPriceM2_typo_rayon.xAxis.categories = periodLabels;
				this.chartOptionsPriceM2_typo_rayon.series = priceM2Series_rayon;

				this.chartOptionsVolume_typo_rayon.xAxis.categories = periodLabels;
				this.chartOptionsVolume_typo_rayon.series = volumeSeries_rayon;

				// Mise à jour des options pour chaque graphique
				this.chartOptionsPrice_dpe_rayon.xAxis.categories = periodLabels;
				this.chartOptionsPrice_dpe_rayon.series = dpe_priceSeries_rayon;

				this.chartOptionsPriceM2_dpe_rayon.xAxis.categories = periodLabels;
				this.chartOptionsPriceM2_dpe_rayon.series = dpe_priceM2Series_rayon;

				this.chartOptionsVolume_dpe_rayon.xAxis.categories = periodLabels;
				this.chartOptionsVolume_dpe_rayon.series = dpe_volumeSeries_rayon;




				// Mise à jour des options pour chaque graphique
				this.chartOptionsPrice_typo_ville.xAxis.categories = periodLabels;
				this.chartOptionsPrice_typo_ville.series = priceSeries_ville;

				this.chartOptionsPriceM2_typo_ville.xAxis.categories = periodLabels;
				this.chartOptionsPriceM2_typo_ville.series = priceM2Series_ville;

				this.chartOptionsVolume_typo_ville.xAxis.categories = periodLabels;
				this.chartOptionsVolume_typo_ville.series = volumeSeries_ville;

				// Mise à jour des options pour chaque graphique
				this.chartOptionsPrice_dpe_ville.xAxis.categories = periodLabels;
				this.chartOptionsPrice_dpe_ville.series = dpe_priceSeries_ville;

				this.chartOptionsPriceM2_dpe_ville.xAxis.categories = periodLabels;
				this.chartOptionsPriceM2_dpe_ville.series = dpe_priceM2Series_ville;

				this.chartOptionsVolume_dpe_ville.xAxis.categories = periodLabels;
				this.chartOptionsVolume_dpe_ville.series = dpe_volumeSeries_ville;

			}

		},

		handleTabChange_forme: function(payload) {
			this.activeTab_forme = payload.tab.name;
			this.maj_graph_histo()
		},

		DonneValeurPrix: function(val) {
			const euro = new Intl.NumberFormat('fr-FR', {
				style: 'currency',
				currency: 'EUR',
				minimumFractionDigits: 0
			});
			if (val==0) {
				return "-"
			} else {
				return euro.format(val)
			}
		},

	},

	mounted: function() {
		//let that = this
		this.maj_graph_histo()
	},
	
	components: {  AnimatedTabs, highcharts: Chart , Fieldset }
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/app'

.tab-content
	padding 24px 32px
	.part
		margin 0 0 40px 0
		.part-title
			margin 0 0 8px 0
			line-height 32px
			font-weight 600
			text-transform uppercase
			font-size 16px
.tabs2
	position fixed
	top 61px
	left 0px
	left 60px
	color secondary
	background white
	border-bottom 1px solid hsl(0deg 0% 86%)
	border-left 1px solid hsl(0deg 0% 86%)
	&:after
		content ''
		display block
		clear both
	.tab
		float left
		margin 0 0px 0 0
		height 60px
		width 180px
		display flex
		align-items center
		justify-content center
		flex-direction column
		opacity 1
		font-size 12px
		font-weight 400
		border-bottom 4px solid transparent
		text-align center
		text-transform uppercase
		cursor pointer
		color #a1a5b8
		&:hover
			color #666
		&.active
			opacity 2
			border-bottom-color var(--primary)
			color var(--primary)
		.icon
			display block
			margin 0 0 2px 0

.snacks 
	display flex
	flex-wrap wrap  
	.snack 
		margin 0 4px 8px
	.snack
		display inline-block
		background var(--fade-grey-light-2)
		height 30px
		width auto
		line-height 30px
		padding-right 10px
		padding-left 10px
		border-radius 500px
		border 1px solid var(--fade-grey-dark-3)
		transition all 0.3s
		&:hover 
			box-shadow var(--light-box-shadow)
		&.is-white
			background var(--white)
		&.is-small
			height 30px
.snack-media
	height 32px
	width 32px
	margin-right 4px
	&.is-icon
		height 30px
		width 30px
		svg
			height 15px
			width 15px
		img
			height 30px
			width 30px
.snack-text
	font-size 0.9rem
.snack-action
	top -9px
	margin 0 10px 0 6px
.snack-media
	position relative
	top -1px
	height 40px
	width 40px
	display inline-block
	margin-right 6px
	.snack-icon 
		position absolute
		top 50%
		left 50%
		transform translate(-50%, -50%)
		svg 
			height 18px
			width 18px
			stroke-width 1.6px
			color var(--light-text)
			vertical-align 0 !important
			transform rotate(0) translate(-50%, -50%) !important
		img 
			display inline-block
			height 38px
			width 38px
			border-radius var(--radius-rounded)
	.snack-text 
		display inline-block
		position relative
		color var(--dark-text)
	.snack-action 
		position relative
		top -14px
		display inline-block
		margin 0 16px 0 10px
		cursor pointer
		svg 
			height 14px
			width 14px
			color var(--light-text)
.snack.is-small .snack-media.is-icon .fas, .snack.is-small .snack-media.is-icon .far, .snack.is-small .snack-media.is-icon .fad, .snack.is-small .snack-media.is-icon .fal, .snack.is-small .snack-media.is-icon .fab 
	font-size 13px
.snack.is-small .snack-media.is-icon .lnil, .snack.is-small .snack-media.is-icon .lnir 
	font-size 16px
.snack .snack-media.is-icon.is-solid .fas, .snack .snack-media.is-icon.is-solid .far, .snack .snack-media.is-icon.is-solid .fad, .snack .snack-media.is-icon.is-solid .fal, .snack .snack-media.is-icon.is-solid .fab, .snack .snack-media.is-icon.is-solid .lnil, .snack .snack-media.is-icon.is-solid .lnir 
	color var(--white) !important
.snack .snack-media.is-icon.is-primary .fas, .snack .snack-media.is-icon.is-primary .far, .snack .snack-media.is-icon.is-primary .fad, .snack .snack-media.is-icon.is-primary .fal, .snack .snack-media.is-icon.is-primary .fab, .snack .snack-media.is-icon.is-primary .lnil, .snack .snack-media.is-icon.is-primary .lnir 
	color var(--primary)
.snack .snack-media.is-icon.is-success .fas, .snack .snack-media.is-icon.is-success .far, .snack .snack-media.is-icon.is-success .fad, .snack .snack-media.is-icon.is-success .fal, .snack .snack-media.is-icon.is-success .fab, .snack .snack-media.is-icon.is-success .lnil, .snack .snack-media.is-icon.is-success .lnir 
	color var(--success)
.snack .snack-media.is-icon.is-info .fas, .snack .snack-media.is-icon.is-info .far, .snack .snack-media.is-icon.is-info .fad, .snack .snack-media.is-icon.is-info .fal, .snack .snack-media.is-icon.is-info .fab, .snack .snack-media.is-icon.is-info .lnil, .snack .snack-media.is-icon.is-info .lnir 
	color var(--info)
.snack .snack-media.is-icon.is-warning .fas, .snack .snack-media.is-icon.is-warning .far, .snack .snack-media.is-icon.is-warning .fad, .snack .snack-media.is-icon.is-warning .fal, .snack .snack-media.is-icon.is-warning .fab, .snack .snack-media.is-icon.is-warning .lnil, .snack .snack-media.is-icon.is-warning .lnir 
	color var(--warning)
.snack .snack-media.is-icon.is-danger .fas, .snack .snack-media.is-icon.is-danger .far, .snack .snack-media.is-icon.is-danger .fad, .snack .snack-media.is-icon.is-danger .fal, .snack .snack-media.is-icon.is-danger .fab, .snack .snack-media.is-icon.is-danger .lnil, .snack .snack-media.is-icon.is-danger .lnir 
	color var(--danger)
.snack .snack-media.is-icon .fas, .snack .snack-media.is-icon .far, .snack .snack-media.is-icon .fad, .snack .snack-media.is-icon .fal, .snack .snack-media.is-icon .fab 
	font-size 15px
	color var(--light-text)
.snack .snack-media.is-icon .lnil, .snack .snack-media.is-icon .lnir 
	font-size 18px
	color var(--light-text)
.is-dark 
.snack 
	background var(--dark-sidebar-light-2)
	border-color var(--dark-sidebar-light-4)
.snack-media 
	&.is-icon 
		&:not(.is-solid) 
			background var(--dark-sidebar-light-4)
	&.is-primary 
	&:not(.is-solid) 
		border-color var(--primary)
		svg 
			color var(--primary)
	&.is-solid 
		background var(--primary)
		border-color var(--primary)
.snack-text 
	color var(--dark-dark-text)
.is-dark .snack .snack-media.is-icon.is-primary:not(.is-solid) .fas, .is-dark .snack .snack-media.is-icon.is-primary:not(.is-solid) .far, .is-dark .snack .snack-media.is-icon.is-primary:not(.is-solid) .fad, .is-dark .snack .snack-media.is-icon.is-primary:not(.is-solid) .fab, .is-dark .snack .snack-media.is-icon.is-primary:not(.is-solid) .fal, .is-dark .snack .snack-media.is-icon.is-primary:not(.is-solid) .lnil, .is-dark .snack .snack-media.is-icon.is-primary:not(.is-solid) .lnir 
	color var(--primary)

	
.dashboard-tile {
	width: 20%;
	min-width: 180px;
	padding: 20px;
	background-color: var(--white);
	border-radius: 6px;
	border: 1px solid var(--fade-grey-dark-3);
	transition: all 0.3s;
	font-family: var(--font), sans-serif;
	margin-right: 10px;
}
.dashboard-tile2 {
	padding: 10px;
	background-color: var(--white);
	border-radius: 6px;
	border: 1px solid var(--fade-grey-dark-3);
	transition: all 0.3s;
	font-family: var(--font), sans-serif;
}
.dashboard-tile .tile-head {
	display: flex;
	align-items: center;
	justify-content: space-between;

}
.dashboard-tile .tile-body {
	font-size: 2rem;
	padding: 4px 0 8px;
	text-align: center
}
.dashboard-tile .tile-foot span:first-child {
	font-weight: 500;
}

.text-h-green {
	color: var(--green);
	font-size: 30px !important;	
}

.text-h-red {
	color: var(--red);
	font-size: 30px !important;
}

.text-h-p {
	color: #6c21f9;
	font-size: 24px;
}

.dashboard-tile .tile-foot span:nth-child(2) {
	color: var(--light-text);
	font-size: 0.9rem;
}

.is-4 {
	flex: none;
	width: 25%;
	padding: 0.75rem;
}
.is-2 {
	width: 50%;
}

.le {
	color: var(--light-text);
	font-size: 0.9rem;
	text-align: right;
	position: absolute;
	right: 0px;
}


.columns {
	margin-left: -0.75rem;
	margin-right: -0.75rem;
	margin-top: -0.75rem;
}
.columns:last-child {
	margin-bottom: -0.75rem;
}
.columns:not(:last-child) {
	margin-bottom: calc(1.5rem - 0.75rem);
}
.columns.is-centered {
	justify-content: center;
}
.columns.is-gapless {
	margin-left: 0;
	margin-right: 0;
	margin-top: 0;
}
.columns.is-gapless > .column {
	margin: 0;
	padding: 0 !important;
}
.columns.is-gapless:not(:last-child) {
	margin-bottom: 1.5rem;
}
.columns.is-gapless:last-child {
	margin-bottom: 0;
}
.columns.is-mobile {
	display: flex;
}
.columns.is-multiline {
	flex-wrap: wrap;
}
.columns.is-vcentered {
	align-items: center;
}
.company-dashboard .company-header {
	display: flex;
	padding: 20px;
	background: var(--white);
	border: 1px solid var(--fade-grey-dark-3);
	border-radius: 4px;
	margin-bottom: 0.2rem;
}
.company-dashboard .company-header .header-item {
	width: 50%;
	border-right: 1px solid var(--fade-grey-dark-3);
}
.company-dashboard .company-header .header-item:last-child {
	border-right: none;
}
.company-dashboard .company-header .header-item .item-inner {
	text-align: center;
}
.company-dashboard .company-header .header-item .item-inner .lnil, .company-dashboard .company-header .header-item .item-inner .lnir {
	font-size: 1.8rem;
	margin-bottom: 6px;
	color: var(--primary);
}

.lnil {
	display: inline-block;
	font: normal normal normal 1em/1 'LineIconsPro Light';
	speak: none;
	text-transform: none;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
.company-dashboard .company-header .header-item .item-inner span {
	display: block;
	font-family: var(--font), sans-serif;
	font-weight: 600;
	font-size: 1.6rem;
	color: var(--dark-text);
}
.company-dashboard .company-header .header-item .item-inner p {
	font-family: var(--font-alt), sans-serif;
	font-size: 0.85rem;
}

.r {
	font-family: var(--font-alt), sans-serif !important;
	font-size: 0.95rem !important;
	font-family: var(--font), sans-serif !important;
	font-weight: 300 !important;
	color: var(--light-text) !important;
	display: initial !important;
}


.company-dashboard .dashboard-card.is-base-chart {
	padding: 0;
	display: flex;
	flex-direction: column;
}

.company-dashboard .dashboard-card {
	flex: 1;
	display: inline-block;
	width: 100%;
	padding: 20px;
	background-color: var(--white);
	border-radius: 6px;
	border: 1px solid var(--fade-grey-dark-3);
	transition: all 0.3s;
	height: 100%;
}
.company-dashboard .dashboard-card.is-base-chart .content-box {
	padding: 30px;
}
.company-dashboard .dashboard-card.is-base-chart .chart-container {
	margin-top: auto;
}
.company-dashboard .dashboard-card.is-base-chart .content-box .revenue-stats {
	display: flex;
	padding-bottom: 20px;
	border-bottom: 1px solid var(--fade-grey-dark-3);
}
.company-dashboard .dashboard-card.is-base-chart .content-box .revenue-stats .revenue-stat {
	margin-right: 30px;
	font-family: var(--font), sans-serif;
}
.company-dashboard .dashboard-card.is-base-chart .content-box .revenue-stats .revenue-stat span:first-child {
	text-transform: uppercase;
	font-family: var(--font-alt), sans-serif;
	font-size: 0.75rem;
	color: var(--light-text);
}

.company-dashboard .dashboard-card.is-base-chart .content-box .revenue-stats .revenue-stat span {
	display: block;
}
.company-dashboard .dashboard-card.is-base-chart .content-box .revenue-stats .revenue-stat span.current {
	color: var(--primary);
}

.company-dashboard .dashboard-card.is-base-chart .content-box .revenue-stats .revenue-stat span:nth-child(2) {
	color: var(--dark-text);
	font-size: 1.6rem;
	font-weight: 600;
}
.revenue-stat {
	width: 33%
}
.chart-container {
}
.dark-inverted2 {
	font-size: 1.4rem !important;
}

.results {
	display: flex;
	//grid-template-columns: repeat(3, 1fr);
	gap: 20px;
	margin-bottom: 10px;
	justify-content: space-around;
	flex-direction: row;
	flex-wrap: wrap;
}

.result-card {
	background-color: #ffffff;
	border-radius: 12px;
	padding: 10px;
	//box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
	text-align: center;
	//border: 1px solid  #e2e8f0;
	//transition: all 0.2s;
	//min-width: 180px;
}

.result-card:hover {
	//transform: translateY(-5px);
	//box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}

.result-icon {
	width: 60px;
	height: 60px;
	border-radius: 50%;
	background-color: #ede8ff;
	color: #6a3de8;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0 auto 15px;
}

.result-icon i {
	font-size: 24px;
}

.result-label {
	color: #718096;
	margin-bottom: 10px;
	font-size: 0.9rem;
}

.result-value {
	font-size: 1.4rem;
	font-weight: 700;
	color: #6a3de8;
}

.tabs {
	display: flex;
	border-bottom: 1px solid #f1f3f5;
	background-color: #f8f9fa;
}

.tab {
	padding: 8px 15px;
	cursor: pointer;
	font-size: 13px;
	transition: all 0.2s ease;
	border-bottom: 2px solid transparent;
}

.tab.active {
	border-bottom: 2px solid #6c5ce7;
	color: #6c5ce7;
	font-weight: 500;
}

body {
	background-color: #f8f9fa;
	color: #343a40;
	line-height: 1.6;
	font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

thead {
    display: table-header-group;
    vertical-align: middle;
    unicode-bidi: isolate;
    border-color: inherit;
}
.p-datatable {
	margin-bottom: 15px;
}

.p-datatable .p-datatable-thead>tr>th {
    text-align: left;
    padding: 1rem 1rem;
    border: 1px solid #dee2e6;
    border-width: 0 0 1px 0;
    font-weight: 700;
    color: #343a40;
    background: #f8f9fa;
    transition: box-shadow .2s;
}
.slider-container {
	width: 100%;
	display: flex;
	flex-direction: column;
}

.slider {
	-webkit-appearance: none;
	width: 100%;
	height: 6px;
	border-radius: 3px;
	background: #d3d3d3;
	outline: none;
	margin-bottom: 10px;
}

.slider::-webkit-slider-thumb {
	-webkit-appearance: none;
	appearance: none;
	width: 20px;
	height: 20px;
	border-radius: 50%;
	background: #7B35E9;
	cursor: pointer;
}

.slider::-moz-range-thumb {
	width: 20px;
	height: 20px;
	border-radius: 50%;
	background: #7B35E9;
	cursor: pointer;
}

.slider-value {
	text-align: center;
	font-size: 16px;
	color: #7B35E9;
	font-weight: bold;
}

.slider-labels {
	display: flex;
	justify-content: space-between;
	margin-top: 5px;
	font-size: 12px;
	color: #777;
}
.refresh-btn {
	background-color: #7B35E9;
	color: white;
	border: none;
	padding: 10px;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 10px;
	margin: 20px auto;
	font-size: 14px;
	width: 100%;
	max-width: 250px;
	border-radius: 4px;
}

.refresh-btn:hover {
	background-color: #6929c4;
}

.refresh-icon {
	margin-right: 5px;
}

.grid {
	margin-bottom: 15px;
	border-bottom: 1px solid #ccc;
}

</style>
