<template>
	<div class="stat-box" id="rs_repa_tr">
		<div class="stat-box-header">
			<p class="title">{{ $t('analyse_des_migrations_residentielles') }} {{ app.adr_init.territoire }}</p>
			<span class="tabs">
				<a id="export09" class="download_table" @click="$store.dispatch('ExportToPng',{id: 'rs_repa_tr', exportid: 'export09'});"><span><icon file="image" /></span></a>
			</span>
		</div>
		<div class="stat-box-content">
			<div class="row">
				<div class="col-one">
					<div class="graph">
						{{ $t('migrations') }}
						<highcharts :options="chartOptions_men_tranche"></highcharts>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import { Chart } from 'highcharts-vue'

export default {
	name: 'GraphInseeMigration',

	data: function() {
		return {
			chartOptions_men_tranche: {
				accessibility: {
					enabled: false
				},
				credits: {
					enabled: false
				},
				title: '',
				chart: {
					height: 280,
					type: 'column',
					style: {
						fontFamily: 'Nunito',
					}
				},
				series: [
					{
						color: '#2196F3',
						name: "Offre commerciale",
						data: [0,0,0],
						fillColor: {
							linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
							stops: [
								[0, 'rgba(33,150,243,0.3)'],
								[1, 'transparent']
							]
						},
						marker: {
							symbol: 'circle',
							enabled: false
						}
					},
				],
				xAxis: {
					type : 'category',
					categories : ['', '', '', '', '', '', '', ''],
					title: {
						text: ''
					},
					allowDecimals: false,
					lineWidth: 1,
					lineColor: '#ddd',
				},
				yAxis: {
					title: {
						text: ''
					},
					lineWidth: 0,
					gridLineColor: '#ddd',
					gridLineDashStyle: 'dash'
				},
				
				plotOptions: {
					series: {
						label: {
							connectorAllowed: false
						},
					}
				},
				tooltip: {
					shared: true,
					crosshairs: true,
					backgroundColor: '#fff',
					borderWidth: 0,
					borderRadius: 4
				},
			},
			chartOptions_men_tranche_gain: {
				credits: {
					enabled: false
				},
				title: '',
				chart: {
					height: 280,
					type: 'column',
					style: {
						fontFamily: 'Nunito',
					}
				},
				series: [
				{
						color: '#2196F3',
						name: "Mises en vente",
						data: [0,0,0],
						fillColor: {
							linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
							stops: [
								[0, 'rgba(33,150,243,0.3)'],
								[1, 'transparent']
							]
						},
						marker: {
							symbol: 'circle',
							enabled: false
						}
					},
				],
				xAxis: {
					type : 'category',
					categories : ['', '', '', '', '', '', '', ''],
					title: {
						text: ''
					},
					allowDecimals: false,
					lineWidth: 1,
					lineColor: '#ddd',
				},
				yAxis: {
					title: {
						text: ''
					},
					lineWidth: 0,
					gridLineColor: '#ddd',
					gridLineDashStyle: 'dash'
				},
				
				plotOptions: {
					series: {
						label: {
							connectorAllowed: false
						},
					}
				},
				tooltip: {
					shared: true,
					crosshairs: true,
					backgroundColor: '#fff',
					borderWidth: 0,
					borderRadius: 4
				},
			},
		}
	},

	computed: Object.assign(mapGetters([ 'app' ]), {
		
	}),

	methods: {

		maj_age: function() {
			if (this.app.livedata.insee && this.app.livedata.insee.length) {
			this.chartOptions_men_tranche.series[0].name = this.app.livedata.insee[0].data[this.app.livedata.insee[0].data.length-1].year;
			this.chartOptions_men_tranche.series[0].data = [
				this.app.livedata.insee[0].data[this.app.livedata.insee[0].data.length-1].household.last_year_rotation.same_lodgment, 
				this.app.livedata.insee[0].data[this.app.livedata.insee[0].data.length-1].household.last_year_rotation.change_lodgment_same_city, 
				this.app.livedata.insee[0].data[this.app.livedata.insee[0].data.length-1].household.last_year_rotation.change_city_same_agglomeration, 
				this.app.livedata.insee[0].data[this.app.livedata.insee[0].data.length-1].household.last_year_rotation.change_city_same_department, 
				this.app.livedata.insee[0].data[this.app.livedata.insee[0].data.length-1].household.last_year_rotation.change_departement_same_region, 
				this.app.livedata.insee[0].data[this.app.livedata.insee[0].data.length-1].household.last_year_rotation.change_region_mainland, 
				this.app.livedata.insee[0].data[this.app.livedata.insee[0].data.length-1].household.last_year_rotation.change_no_mainland, 
			];
			this.chartOptions_men_tranche.xAxis.categories= ['Même logement', 'Déménagement dans la même ville', 'Déménagement dans la même agglomération', 
			'Déménagement dans le même département', 'Déménagement dans la même région', 'Déménagement dans le même pays', 'Déménagement dans un autre pays']
			}
		},

		maj_gain: function() {	
			// Nothing		
		}
	},
	mounted: function() {
		this.maj_age()
		this.maj_gain()
	},

	watch: {
		'app.livedata': function() {
			if (this.app.view == 'report' || this.app.view == 'report_B') {
				this.maj_age()
				this.maj_gain()
			}
		},
	},
	
	components: { highcharts: Chart }
}
</script>

<style lang="stylus" scoped>
@import '../../../../../assets/css/app'
@import '../../../../../assets/css/grid'

.stat-box
	.stat-box-content
		padding 0 16px
		text-align center

.graph
	min-height 200px
	text-align center
	line-height @height


</style>
