<template>
	<div class="tab-estimmo" id='report-estimmo'>
		<div class="tab-content" v-observe-visibility="onVisibilityChange">
			<Fieldset legend="Estimmo" :toggleable="true" class="surface-ground">
				<div class="part">
					<div class="p-3 border-round shadow-2 align-items-center surface-card">
						<div class="row">
							<div class="col full">
								<pane-estimmo/>
							</div>
						</div>
					</div>
				</div>
				<div class="part">
					<div class="part-content">
						<div class="row">
							<div class="col full">
								<div class="program-detail-info">
									<div class="program-detail-info-content">
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Fieldset>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import { ObserveVisibility  } from 'vue-observe-visibility'
import Fieldset from 'primevue/fieldset';
import PaneEstimmo from '../PaneEstimmo'

export default {
	name: 'TabEstimmo',

	data: function() {
		return {
			activeSection2 : '',
		}
	},

	directives: {
		'observe-visibility': ObserveVisibility
	},

	methods: {
		onVisibilityChange(isVisible) {			
			if (isVisible) {
				this.activeSection2 = 'taboptim'
			} else {
				if (this.activeSection2 == 'taboptim') {
					this.activeSection2 = ''
				}
			}
		},
	},

	computed: Object.assign(mapGetters([ 'app', 'report' ]), {
		
	}),

	components: { PaneEstimmo, Fieldset }
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/app'
@import '../../assets/css/grid'

.tab-content
	padding 24px 32px
	.part
		margin 0 0 40px 0
		.part-title
			margin 0 0 8px 0
			line-height 32px
			font-weight 600
			text-transform uppercase
			font-size 16px

.tabs2
	position fixed
	top 61px
	left 0px
	left 60px
	color secondary
	background white
	border-bottom 1px solid hsl(0deg 0% 86%)
	border-left 1px solid hsl(0deg 0% 86%)
	&:after
		content ''
		display block
		clear both
	.tab
		float left
		margin 0 0px 0 0
		height 60px
		width 180px
		display flex
		align-items center
		justify-content center
		flex-direction column
		opacity 1
		font-size 12px
		font-weight 400
		border-bottom 4px solid transparent
		text-align center
		text-transform uppercase
		cursor pointer
		color #a1a5b8
		&:hover
			color #666
		&.active
			opacity 2
			border-bottom-color var(--primary)
			color var(--primary)
		.icon
			display block
			margin 0 0 2px 0
		
</style>
