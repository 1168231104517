<template>
	<transition name="pane-ancien">
		<div class="paneleft pan-ancien">
			<div class="module_ret">
				<div class="neuf" @click.stop="toggle_filter()" title="Filtrer ma recherche" :class="{ idf: !app.module_actif }">
					<icon file="filter_list" :size="36" class="filters" />&nbsp;
				</div>
				<div class="unmodule_sous2" style="text-align:center;">
					<b><p>{{ $t('estimation_de_ladresse') }}</p></b>
				</div>
			</div>

			<div class="_filtre">
				<div class="programs-list" >
					<div class="programs-list-content" v-bar>
					</div>
				</div>
			</div>
			<div class="panecontent wrapper-scroll" style="height:calc(100% - 64px);">
				<div style="height:auto !important;padding-bottom:10px;">
					<div class="programs-list">
						<div class="programs-list-content" v-bar>
							<div class="content" ref="scrollable_programs">
									<br/>
									<div class="px-3 py-1" style="padding-right:0px !important">
										<div class="grid">
											<div class="col-12">
												<div class="surface-card shadow-2 p-3 border-round">
													<div class="align-items-center">
														<Chip :label="DonneLibelleReference('typologie')" icon="pi pi-th-large" class="mr-2 custom-chip" />
														<Chip :label="DonneLibelleReference('etat')" icon="pi pi-tag" class="mr-2 custom-chip" />
														<Chip :label="DonneLibelleReference('surface')" icon="pi pi-stop" class="mr-2 custom-chip" />
														<Chip :label="DonneLibelleReference('parking')" icon="pi pi-car" class="mr-2 custom-chip" />
														<Chip :label="DonneLibelleReference('forme')" icon="pi pi-building" class="mr-2 custom-chip" />
													</div>
												</div>
											</div>
										</div>
									</div>

									<div class="px-3 py-1" style="padding-right:0px !important">
										<div class="surface-card shadow-2 p-3 border-round">
											<div class="row">
												<span class="pl-3 block text-500 font-medium mb-3">{{ $t('Estimations au m²') }}</span>
											</div>
											<div class="flex">
												<div style="border-right: 1px solid #AAA; width:33.33%;text-align:center;">
													<div class="justify-content-between mb-3">
														<div>
															<span class="block text-500 font-medium mb-3">{{ $t('Basse') }}</span>
															<div class="text-900 font-medium" style="font-size: 1.1rem !important;">{{ DonneValeurPrix((app.cityscan.adresse.priceLow/parseFloat(app.cityscan.realty.area)).toFixed(0)) }}</div>
														</div>
													</div>
													<p class="text-500 font-medium"><span :title="$t('prix_unitaire')"><b>{{ DonneValeurPrix(app.cityscan.adresse.priceLow.toFixed(0)) }}</b></span></p>
												</div>

												<div style="border-right: 1px solid #AAA; width:33.33%;text-align:center;">
													<div class="justify-content-between mb-3">
														<div>
															<span class="block text-500 font-medium mb-3">{{ $t('Moyenne') }}</span>
															<div class="text-900 font-medium" style="font-size: 1.1rem !important;">{{ DonneValeurPrix((app.cityscan.adresse.pm2).toFixed(0)) }}</div>
														</div>
													</div>
													<p class="text-500 font-medium"><span :title="$t('prix_unitaire')"><b>{{ DonneValeurPrix((app.cityscan.adresse.pm2 * parseFloat(app.cityscan.realty.area)).toFixed(0)) }}</b></span></p>
												</div>

												<div style="width:33.33%;text-align:center;">
													<div class="justify-content-between mb-3">
														<div>
															<span class="block text-500 font-medium mb-3">{{ $t('Haute') }}</span>
															<div class="text-900 font-medium" style="font-size: 1.1rem !important;">{{ DonneValeurPrix((app.cityscan.adresse.priceHigh/parseFloat(app.cityscan.realty.area)).toFixed(0)) }}</div>
														</div>
													</div>
													<p class="text-500 font-medium"><span :title="$t('prix_unitaire')"><b>{{ DonneValeurPrix(app.cityscan.adresse.priceHigh.toFixed(0)) }}</b></span></p>
												</div>
											</div>
										</div>
										<div class="half">
											
										</div>
									</div>

									<div class="dropdown-item" style="text-align: center;">
										<Button style="min-width:270px" :label="$t('Consulter les transactions')" class="p-button-rounded" @click="$store.dispatch('set_view', { view: 'ancien-mutations' })" />
									</div>

									<div class="dropdown-item" style="text-align: center;" v-if="app.user.adequation">
										<Button style="min-width:270px" :label="$t('Consulter les biens en vente')" class="p-button-rounded" @click="$store.dispatch('set_view', { view: 'ancien-offre' })" />
									</div>

									<div id="evolution_prix_ancien">
										<div class="px-3 py-1" style="padding-right:0px !important">
											<div class="grid">
											<!--
												<div class="col-12">
													<div class="surface-card shadow-2 p-3 border-round" style="padding-left: 0 !important;padding-bottom: 0 !important;padding-right: 0 !important;">
														<div class="content-box" style="padding-left: 0.5rem !important;padding-bottom: 0 !important;padding-right: 0.5rem !important;">
															{{ $t('Evolution des prix de transaction (DVF)') }}
															<tool-source url='' txt='DVF 2024'/>
															
															<span style="position: absolute;	right: 5px; top: 5px;">
																<a id="export02" class="download_table" @click="$store.dispatch('ExportToPng',{id: 'evolution_prix_ancien', name: 'AncienEvolutionPrix', exportid: 'export02'});"><span><icon file="image" /></span></a></span>
														</div>
														<div class="chart-container" style="margin-top:40px">
															<apexchart																		
															:height="spark1.chart.height"
															:type="spark1.chart.type"
															:series="spark1.series"
															:options="spark1"
															>
															</apexchart>
														</div>
													</div>
												</div>
											-->
											<div class="col-12">
												<div class="surface-card shadow-2 p-3 border-round">
													<div class="flex p-3 flex-column md:flex-row">
														<div class="border-bottom-1 md:border-right-1 md:border-bottom-none surface-border flex-auto p-3">
															<div class="flex align-items-center mb-3">
																<span class="text-500 font-medium">{{ $t('depuis_1_an') }}</span>
															</div>
															<div class="flex align-items-center">
																<i class="pi pi-arrow-up text-green-500 text-xl mr-2" v-if="unan>0"></i>
																<i class="pi pi-arrow-down text-pink-500 text-xl mr-2" v-if="unan<0"></i>
																<span class="text-green-500 font-medium " v-if="unan>0">+{{unan.toFixed(2)}} %</span>
																<span class="text-pink-500 font-medium " v-if="unan<0">{{unan.toFixed(2)}} %</span>
															</div>
														</div>
														<div class="border-bottom-1 md:border-bottom-none surface-border flex-auto p-3">
															<div class="flex align-items-center mb-3">
																<span class="text-500 font-medium">{{ $t('depuis_2_ans') }}</span>
															</div>
															<div class="flex align-items-center">
																<i class="pi pi-arrow-up text-green-500 text-xl mr-2" v-if="deuxans>0"></i>
																<i class="pi pi-arrow-down text-pink-500 text-xl mr-2" v-if="deuxans<0"></i>
																<span class="text-green-500 font-medium " v-if="deuxans>0">+{{deuxans.toFixed(2)}} %</span>
																<span class="text-pink-500 font-medium " v-if="deuxans<0">{{deuxans.toFixed(2)}} %</span>
															</div>
														</div>											
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
import { mapGetters } from 'vuex'
import Chip from 'primevue/chip';
import { i18n } from '../../main';
import Button from 'primevue/button';
// eslint-disable-next-line
import ToolSource from '../global/ToolSource';

export default {
	name: 'PaneAncien',
	data: function() {
		return {
			reference : {name: "Estimation de prix", typeId: 3, area: 60, areaOther: null, rooms: 3, bedrooms: null,
				amenityProximity: null,  areaBalcony: null, areaCellar: null, areaGardenPrivate: null,
				areaGardenShared: null, areaLoggia: null, areaTerrace: null, bathrooms: null,					
				buildingConstruction: null, buildingDpe: null, buildingFloors: null,
				chargesMaintenance: null, chargesPropertyTax: null, condition: 3, condominiumCondition: null, 
				condominiumFacade: null, condominiumLots: null, condominiumStanding: null, detaxation: null,
				dualAspect: null, easement: null, elevator: null, floor: null, garden: null, light: null,
				noise: null, occupation: null, orientation: null, parkingBoxed: 0, parkingOpen: 0, pool: null,
				vista: null, walls: null
			},
			unan: 0,
			deuxans: 0,
			spark1 : {
				chart: {
					id: 'sparkline1',
					group: 'sparklines',
					type: 'area',
					height: 130,
					sparkline: {
						enabled: true,
					},
				},
				colors: ["#0bb985"],
				stroke: {
					width: [2],
					curve: 'straight',
				},
				fill: {
					opacity: 1,
				},
				series: [
				{
					name: "Avis de valeur",
					data: [0,0,0],
				},
				],
				labels: ['2020', '2021', '2022'],
				yaxis: {
					min: 0,
					labels: {
						minWidth: 100,
					},
				},
				title: {
					offsetX: 5,
					style: {
						fontSize: '14px',
						fontFamily: 'Nunito',
						color: '#a2a5b9',
					},
				},
				subtitle: {
					text: undefined,
					offsetX: 5,
					style: {
						fontSize: '24px',
						fontWeight: '600',
						cssClass: 'apexcharts-yaxis-title',
					},
				},
			},
			
			chartOptions_trim: {
				title: '',
				chart: {
					height: 280,
					type: 'areaspline',
					style: {
						fontFamily: 'Nunito',
					}
				},
				series: [
				{
					color: "#ffc436",
					name: "Prix de l'ancien",
					data: [0,0,0,0],
					fillColor: {
						linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
						stops: [
						[0, 'rgba(40,8.2,97.6,0.3)'],
						[1, 'transparent']
						]
					},
					marker: {
						symbol: 'circle',
						enabled: false
					}
				},
				],
				xAxis: {
					type : 'category',
					categories : ['', '', ''],
					title: {
						text: ''
					},
					allowDecimals: false,
					lineWidth: 1,
					lineColor: '#ddd',
				},
				yAxis: {
					title: {
						text: ''
					},
					allowDecimals: true,
					lineWidth: 0,
					gridLineColor: '#ddd',
					gridLineDashStyle: 'dash',
					labels: {
						format: '{value:,.0f} €'
					}
				},
				
				plotOptions: {
					series: {
						label: {
							connectorAllowed: false
						},
					}
				},
				tooltip: {
					shared: true,
					crosshairs: true,
					backgroundColor: '#fff',
					borderWidth: 0,
					borderRadius: 4
				},
			},
			fu_visible: false,
			detail: '',
		}
	},

	computed: Object.assign(mapGetters([ 'app', 'map' ]), {
		
	}),

	methods: {
		toggle_filter: function() {
			this.$store.dispatch('set_filters_ancien');
		},

		changefu: function() {
			this.fu_visible = !this.fu_visible;
			if (this.fu_visible) {
				gtag('event', 'filtre_ancien', {'event_category': 'filtre','event_label': 'filtre_ancien'});
				this.$store.dispatch('send_event_backend', {module: 'ancien', action: 'filtre', option: 'ouverture'});
			}
		},

		isNumber: function(evt) {
			evt = (evt) ? evt : window.event;
			let charCode = (evt.which) ? evt.which : evt.keyCode;
			if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
				evt.preventDefault();
			} else {
				return true;
			}
		},
		
		setContour: function(el) {
			this.$store.dispatch('set_contour_prix', { contour: el })
		},
		
		DonneLibelleReference: function(filter) {
			switch (filter) {
				case 'typologie':
				return 'Logement T' + this.app.cityscan.realty.rooms
				case 'forme':
				if (this.app.cityscan.realty.typeId==1) {
					return 'Maison'
				} else {
					return 'Appartement'
				}
				case 'etat':
				switch (this.app.cityscan.realty.condition) {
					case 1:
					return 'Refait à neuf'
					case 2:
					return 'Rafraîchi'
					case 3:
					return 'Standard'
					case 4:
					return 'A rafraîchir'
					default:
					return 'A refaire'
				}
				case 'parking':
				if (this.app.cityscan.realty.parkingOpen==1) {
					return 'Parking inclus'
				} else {
					return 'Sans parking'
				}
				case 'surface':
				return this.app.cityscan.realty.area + "m²"
				default:
				break;
			}
		},
		
		DonneValeurPrix: function(val) {
			const euro = new Intl.NumberFormat('fr-FR', {
				style: 'currency',
				currency: 'EUR',
				minimumFractionDigits: 0
			});
			if (val==0) {
				return "-"
			} else {
				return euro.format(val)
			}
		},

		toggle_switch: function(filter, value) {
			switch (filter) {
				case 'typologie':
				gtag('event', 'filtre_ancien_typologie', {'event_category': 'filtre','event_label': 'filtre_ancien_typologie'});
				this.$store.dispatch('send_event_backend', {module: 'ancien', action: 'filtre', option: 'typologie'});
				this.reference.rooms = value
				break;
				case 'forme':
				gtag('event', 'filtre_ancien_fu', {'event_category': 'filtre','event_label': 'filtre_ancien_fu'});
				this.$store.dispatch('send_event_backend', {module: 'ancien', action: 'filtre', option: 'forme urbaine'});
				this.reference.typeId = value
				break;
				case 'etat':
				gtag('event', 'filtre_ancien_etat', {'event_category': 'filtre','event_label': 'filtre_ancien_etat'});
				this.$store.dispatch('send_event_backend', {module: 'ancien', action: 'filtre', option: 'etat'});
				this.reference.condition = value
				break;
				case 'parking':
				gtag('event', 'filtre_ancien_parking', {'event_category': 'filtre','event_label': 'filtre_ancien_parking'});
				this.$store.dispatch('send_event_backend', {module: 'ancien', action: 'filtre', option: 'parking'});
				this.reference.parkingOpen = value
				break;
				default:
				break;
			}
		},

		valide_surface: function(v) {
			this.reference.area = parseFloat(v)
		},

		valide_estimation: function() {
			let that = this
			this.$store.dispatch('set_cityscan_realty', { realty: this.reference })
			this.$store.dispatch('app_cityscan_realty', {callback: function() {
				that.maj_graph()
			}})
		},
		
		details: function(d) {
			if (this.detail==d) {
				this.detail=''
			}
			else {
				this.detail=d
			}
		},

		maj_graph: function() {
			let t = []
			let l = []
			let min = 0
			let max = 0
			for (let index = this.app.cityscan.adresse.priceTrends.length-1; index >= 0; index--) {
				if (min==0) min = this.app.cityscan.adresse.priceTrends[index].price
				if (max==0) max = this.app.cityscan.adresse.priceTrends[index].price
				if (this.app.cityscan.adresse.priceTrends[index].price < min) min = this.app.cityscan.adresse.priceTrends[index].price
				if (this.app.cityscan.adresse.priceTrends[index].price > max) max = this.app.cityscan.adresse.priceTrends[index].price
				t.push(this.app.cityscan.adresse.priceTrends[index].price)
				l.push(this.app.cityscan.adresse.priceTrends[index].date)
			}
			this.chartOptions_trim.series[0].data = t
			this.spark1 = {
				chart: {
					id: 'sparkline1',
					group: 'sparklines',
					type: 'area',
					height: 130,
					sparkline: {
						enabled: true,
					},
				},
				colors: ["#0bb985"],
				stroke: {
					width: [2],
					curve: 'straight',
				},
				fill: {
					opacity: 1,
				},
				series: [
				{
					name: i18n.t('prix_au_m²'),
					data: t
				},
				],
				labels: l,
				yaxis: {
					min: min,
					max: max,
					show: false,
					labels: {
						show: false,
						minWidth: 100,
						style: {
							colors: [],
							fontSize: '12px',
							fontFamily: 'Nunito',
							fontWeight: 400,
							cssClass: 'apexcharts-yaxis-label',
						},
						offsetX: 0,
						offsetY: 0,
					},
					axisTicks: {
						show: false,
					},
					title: {
						text: undefined,
					}
				},
				title: {
					offsetX: 5,
					style: {
						fontSize: '14px',
						fontFamily: 'Nunito',
						color: '#a2a5b9',
					},
				},
				subtitle: {
					offsetX: 5,
					style: {
						fontSize: '24px',
						fontWeight: '600',
						cssClass: 'apexcharts-yaxis-title',
					},
				},
			}
			this.unan = ((this.app.cityscan.adresse.pm2_0-this.app.cityscan.adresse.pm2_12)/this.app.cityscan.adresse.pm2_12)*100
			this.deuxans = ((this.app.cityscan.adresse.pm2_0-this.app.cityscan.adresse.pm2_24)/this.app.cityscan.adresse.pm2_24)*100
			this.chartOptions_trim.xAxis.categories= ['N-2', 'N-1', 'N']
		},
		
	},
	
	watch: {
		'app.cityscan.realty': function() {
			this.maj_graph()
		},

		'app.cityscanprixtime': function() {
			this.maj_graph()
		},

		'app.view': function(newValue) {
			if (newValue=='ancien') {
				this.$store.dispatch('set_contour_prix', { contour: true })
			}
			else {
				this.$store.dispatch('set_contour_prix', { contour: false })
			}
		},
	},

	mounted: function() {
		this.maj_graph()
	},
	
	// eslint-disable-next-line
	components: { ToolSource, Chip, Button }
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/app'
@import '../../assets/css/grid'
	
.p-chip.custom-chip {
	background: #dee2e6;
	font-size:12px;
	color: #495057;
	margin-bottom:5px;
}
	
.panecontent
	top 0px

.programs-list
	height 100%
.module_ret
	width PaneLeftSize
	height 60px
	vertical-align middle
	background-color palette_gris
	border-bottom 1px solid palette_gris_bordure
	border-right 1px solid palette_gris_bordure

.aide
	width 60px
	left 0px
	position absolute
	border-right 1px solid palette_gris_bordure
	text-align center
	line-height 60px
	cursor pointer

.pane-ancien-enter
	transform translateX(100%)
.pane-ancien-leave-to
	transform translateX(-100%)
	
.conjoncture
	padding-top 10px
	padding-left 10px
	
.value
	display inline-block
	text-align right
	border none
	width 100%
	padding 0 12px
	height 32px
	background-color #f5f5f5
	border-radius 2px
	font-size 1.4rem
	line-height 32px
	outline none
	
.round
	padding 4px 8px
	height 24px
	left 0
	bottom 0
	background lighten(primaryA100, 90%)
	border 1px solid primaryA100
	box-sizing border-box
	border-radius 20px
	font-family Montserrat, sans-serif
	font-style normal
	font-weight normal
	font-size 12px
	line-height 16px
	letter-spacing 0.4px
	color primaryA400
	text-align center
	
.sous-titre
	font-weight 500
	color #000
	padding-top 10px
	padding-bottom 10px
	
.lien_module
	left 330px
	font-size 0.8rem
	color red
	padding-bottom 5px
	cursor pointer
.bar
	height 16px
	margin 0 0 16px 0
	border-radius radius
.progress
	position absolute
	left 0
	top 0
	height 100%
	&:first-child
		border-top-left-radius radius
		border-bottom-left-radius radius
	&:last-child
		border-top-right-radius radius
		border-bottom-right-radius radius
.percent
	position absolute
	left 50%
	transform translateX(-50%)
	font-size 0.7rem
	font-weight 500
	line-height 18px
	color #000
	
.titre
	width PaneLeftSize
	height 40px
	padding-left 10px
	padding-top 10px
	vertical-align middle
	background-color #fafafa
	border-bottom 1px solid #cccccc
	font-size 10pt
	font-weight bold
	text-align center
	
.module
	width PaneLeftSize
	padding-left 10px !important
	padding-right 10px !important
	padding-top 10px
	vertical-align middle
	border-bottom 1px solid #cccccc
	
	
.pan-ancien
	position absolute
	top headersize
	float left
	transition 0.5s easeOutQuart
	background-color #f0f0f0
.scoring
	transition max-height 0.3s ease-out
	font-size 0.8rem
.vie_quartier
	height 800px
	
.button
	width 170px
	padding 7px 10px 10px 10px
	text-align center
	border-radius radius 5px
	background primaryA400
	color white
	height 30px
	cursor pointer
	box-shadow 0 0 4px alpha(#000, 25%)
	&:hover
		background-color secondary
		color white
	
.maplayer_filtres
	position fixed
	width 500px
	min-height 550px !important
	top calc(50% - 290px)
	left calc(50% - 250px)
	z-index 600
	transition 0.5s easeOutQuart
	padding 7px 10px 10px 10px
	text-align center
	border-radius radius 5px
	background white
	color #666
	cursor pointer
	max-height 900px
	margin-top 1.33333rem
	padding 20px
	pointer-events all
	transform translateY(0)
	opacity 1
	border-radius 0
	box-shadow 0 6px 24px 0 alpha(#000, 25%)
	transition .3s transform
	transition-property transform
	transition-duration 0.3s
	transition-timing-function ease
	transition-delay 0s

.switch-button
	padding 0 0 8px 0px !important
._filtre
	font-weight bold
	text-align left
	
.box-content
	display block
	padding 0 16px
	
.box
	margin 0 0 24px 0
	&:first-child
		padding-top 16px
.box-title
	padding 0 16px
	margin 0 0 4px 0
	font-family volte, sans-serif
	font-weight 600
	color #333
	line-height 16px
	text-align left
	padding-bottom 8px
	padding-top 8px

.switches
	margin-left -8px
	&:after
		content ''
		display block
		clear both
	
.closeBtn
	float right
	top 0px
	z-index 999
	
.choix
	width: 430px
	height: 80px
.unchoix
	top: -6px

	
.dropdown.is-spaced .dropdown-item.is-media
	display flex
	align-items center

.dropdown .dropdown-menu .dropdown-item
	color hsl(232deg, 14%, 68%)
	font-family "Roboto", sans-serif
.dropdown-item
	padding 0.5rem 1rem
	font-size 0.85rem
	color hsl(232deg, 14%, 68%)
	transition all 0.3s
a.dropdown-item, button.dropdown-item
	padding-right 3rem
	text-align inherit
	white-space nowrap
	width 100%

.dropdown-item
	color hsl(0deg, 0%, 29%)
	display block
	font-size 0.875rem
	line-height 1.5
	padding 0.375rem 1rem
	position relative
	
.dropdown-divider
	background-color #ccc
	border none
	display block
	height 1px
	margin 0.5rem 0
	
.button.v-button:not([disabled])
	cursor pointer

.button.is-fullwidth
	display flex
	width 100%

.button.is-primary
	background-color palette0
	border-color transparent
	color #ededed
	&:hover
		opacity 0.9
		box-shadow var(--primary-box-shadow)

.button
	-webkit-appearance none
	align-items center
	border 1px solid transparent
	border-radius 4px
	box-shadow none
	display inline-flex
	font-size 1rem
	height 2.5em
	justify-content flex-start
	line-height 1.5
	padding-bottom calc(0.5em - 1px)
	padding-left calc(0.75em - 1px)
	padding-right calc(0.75em - 1px)
	padding-top calc(0.5em - 1px)
	position relative
	vertical-align top

.button.v-button
	height 38px
	line-height 1.1
	font-size 0.85rem
	font-family "Roboto", sans-serif
	transition all 0.3s
	justify-content center
	text-align center
	white-space nowrap
	border-width 1px

.business-dashboard
	width 330px !important
	
.is-dark-bordered-12
	width 50% !important
	text-align center
	.item-inner
		text-align center
		.dropdown-item
			padding 0 !important
			padding-top 10px !important
			padding-left 15px !important
			padding-right 15px !important

.unmodule_detail
	border-bottom: 1px solid #ccc;
	
.unmodule_sous1
	height 40px
	width 440px !important
	padding-left 10px
	padding-top 12px
	vertical-align middle
	background-color #fafafa
	border-bottom 1px solid #cccccc
	cursor pointer
	vertical-align middle
	line-height 30px

.snacks 
	display flex
	flex-wrap wrap  
	.snack 
		margin 0 4px 8px
	.snack
		cursor pointer
		display inline-block
		background var(--fade-grey-light-2)
		height 30px
		width auto
		border-radius 500px
		border 1px solid var(--fade-grey-dark-3)
		transition all 0.3s
		&:hover 
			box-shadow var(--light-box-shadow)
		&.is-white
			background var(--white)
		&.is-small
			height 30px
.snack-media
	height 32px
	width 32px
	margin-right 4px
	&.is-icon
		height 30px
		width 30px
		svg
			height 15px
			width 15px
		img
			height 30px
			width 30px
.snack-text
	font-size 0.9rem
.snack-action
	top -9px
	margin 0 10px 0 6px
.snack-media
	position relative
	top -1px
	height 40px
	width 40px
	display inline-block
	margin-right 6px
	.snack-icon 
		position absolute
		top 50%
		left 50%
		transform translate(-50%, -50%)
		svg 
			height 18px
			width 18px
			stroke-width 1.6px
			color var(--light-text)
			vertical-align 0 !important
			transform rotate(0) translate(-50%, -50%) !important
		img 
			display inline-block
			height 38px
			width 38px
			border-radius var(--radius-rounded)
	.snack-text 
		display inline-block
		position relative
		color var(--dark-text)
	.snack-action 
		position relative
		top -14px
		display inline-block
		margin 0 16px 0 10px
		cursor pointer
		svg 
			height 14px
			width 14px
			color var(--light-text)
.filtreancien
	width 60px;
	height 60px;
	position absolute
	left 60px
	border-right 1px solid #ccc
	text-align center
	line-height 60px
	cursor pointer
		
.snack.is-small .snack-media.is-icon .fas, .snack.is-small .snack-media.is-icon .far, .snack.is-small .snack-media.is-icon .fad, .snack.is-small .snack-media.is-icon .fal, .snack.is-small .snack-media.is-icon .fab 
	font-size 13px
.snack.is-small .snack-media.is-icon .lnil, .snack.is-small .snack-media.is-icon .lnir 
	font-size 16px
.snack .snack-media.is-icon.is-solid .fas, .snack .snack-media.is-icon.is-solid .far, .snack .snack-media.is-icon.is-solid .fad, .snack .snack-media.is-icon.is-solid .fal, .snack .snack-media.is-icon.is-solid .fab, .snack .snack-media.is-icon.is-solid .lnil, .snack .snack-media.is-icon.is-solid .lnir 
	color var(--white) !important
.snack .snack-media.is-icon.is-primary .fas, .snack .snack-media.is-icon.is-primary .far, .snack .snack-media.is-icon.is-primary .fad, .snack .snack-media.is-icon.is-primary .fal, .snack .snack-media.is-icon.is-primary .fab, .snack .snack-media.is-icon.is-primary .lnil, .snack .snack-media.is-icon.is-primary .lnir 
	color var(--primary)
.snack .snack-media.is-icon.is-success .fas, .snack .snack-media.is-icon.is-success .far, .snack .snack-media.is-icon.is-success .fad, .snack .snack-media.is-icon.is-success .fal, .snack .snack-media.is-icon.is-success .fab, .snack .snack-media.is-icon.is-success .lnil, .snack .snack-media.is-icon.is-success .lnir 
	color var(--success)
.snack .snack-media.is-icon.is-info .fas, .snack .snack-media.is-icon.is-info .far, .snack .snack-media.is-icon.is-info .fad, .snack .snack-media.is-icon.is-info .fal, .snack .snack-media.is-icon.is-info .fab, .snack .snack-media.is-icon.is-info .lnil, .snack .snack-media.is-icon.is-info .lnir 
	color var(--info)
.snack .snack-media.is-icon.is-warning .fas, .snack .snack-media.is-icon.is-warning .far, .snack .snack-media.is-icon.is-warning .fad, .snack .snack-media.is-icon.is-warning .fal, .snack .snack-media.is-icon.is-warning .fab, .snack .snack-media.is-icon.is-warning .lnil, .snack .snack-media.is-icon.is-warning .lnir 
	color var(--warning)
.snack .snack-media.is-icon.is-danger .fas, .snack .snack-media.is-icon.is-danger .far, .snack .snack-media.is-icon.is-danger .fad, .snack .snack-media.is-icon.is-danger .fal, .snack .snack-media.is-icon.is-danger .fab, .snack .snack-media.is-icon.is-danger .lnil, .snack .snack-media.is-icon.is-danger .lnir 
	color var(--danger)
.snack .snack-media.is-icon .fas, .snack .snack-media.is-icon .far, .snack .snack-media.is-icon .fad, .snack .snack-media.is-icon .fal, .snack .snack-media.is-icon .fab 
	font-size 15px
	color var(--light-text)
.snack .snack-media.is-icon .lnil, .snack .snack-media.is-icon .lnir 
	font-size 18px
	color var(--light-text)
.is-dark 
.snack 
	background var(--dark-sidebar-light-2)
	border-color var(--dark-sidebar-light-4)
.snack-media 
	&.is-icon 
		&:not(.is-solid) 
			background var(--dark-sidebar-light-4)
	&.is-primary 
	&:not(.is-solid) 
		border-color var(--primary)
		svg 
			color var(--primary)
	&.is-solid 
		background var(--primary)
		border-color var(--primary)
.snack-text 
	color var(--dark-dark-text)
.is-dark .snack .snack-media.is-icon.is-primary:not(.is-solid) .fas, .is-dark .snack .snack-media.is-icon.is-primary:not(.is-solid) .far, .is-dark .snack .snack-media.is-icon.is-primary:not(.is-solid) .fad, .is-dark .snack .snack-media.is-icon.is-primary:not(.is-solid) .fab, .is-dark .snack .snack-media.is-icon.is-primary:not(.is-solid) .fal, .is-dark .snack .snack-media.is-icon.is-primary:not(.is-solid) .lnil, .is-dark .snack .snack-media.is-icon.is-primary:not(.is-solid) .lnir 
	color var(--primary)


.business-dashboard
	width 330px !important

.unmodule_sous2
	vertical-align middle
	padding 0px
	text-align center
	width PaneLeftSize
	width calc(100% - 240px)
	left 120px
	line-height 60px
	p
		font-size 18px	
		color #333 !important
		font-weight 600 !important
.neuf
	width 60px;
	height 60px;
	position absolute
	left 0px
	text-align center
	line-height 60px
	cursor pointer
	&.idf
		right 0px !important

.filters
	cursor pointer
	transition 0.25s easeOutQuart
	font-weight 400
	color #666
	&:hover
		color #6C21F9

	
.column {
	display: flex;
	flex-basis: 0;
	flex-grow: 1;
	flex-shrink: 1;
	padding-right: 0.75rem;
	width:100%;
	padding: 0.75rem;
	margin-right: -0.75rem;
	margin-left: -0.75rem;
}
.spark-tile {
	width: 100%;
	background-color: var(--white);
	border-radius: 6px;
	border: 1px solid var(--fade-grey-dark-3);
	transition: all 0.3s;
	padding: 0;
	background: #fff;
}

.apexcharts-title-text {
	font-size: 1rem !important;
	font-weight: 600 !important;
	font-family: "Montserrat", sans-serif;
}

.dashboard-tile {
	padding: 20px;
	background-color: var(--white);
	border-radius: 6px;
	border: 1px solid var(--fade-grey-dark-3);
	transition: all 0.3s;
	font-family: var(--font), sans-serif;
}
.dashboard-tile2 {
	padding: 10px;
	background-color: var(--white);
	border-radius: 6px;
	border: 1px solid var(--fade-grey-dark-3);
	transition: all 0.3s;
	font-family: var(--font), sans-serif;
}
.dashboard-tile .tile-head {
	display: flex;
	align-items: center;
	justify-content: space-between;

}
.dashboard-tile .tile-body {
	font-size: 2rem;
	padding: 4px 0 8px;
	text-align: center
}
.dashboard-tile .tile-foot span:first-child {
	font-weight: 500;
}

.text-h-p {
	color: #6c21f9;
	font-size: 24px;
}

.dashboard-tile .tile-foot span:nth-child(2) {
	color: var(--light-text);
	font-size: 0.9rem;
}

.is-4 {
	flex: none;
	width: 25%;
	padding: 0.75rem;
}
.is-2 {
	width: 50%;
}

.le {
	color: var(--light-text);
	font-size: 0.9rem;
	text-align: right;
	position: absolute;
	right: 10px;
}


.columns {
	margin-left: -0.75rem;
	margin-right: -0.75rem;
	margin-top: -0.75rem;
}
.columns:last-child {
	margin-bottom: -0.75rem;
}
.columns:not(:last-child) {
	margin-bottom: calc(1.5rem - 0.75rem);
}
.columns.is-centered {
	justify-content: center;
}
.columns.is-gapless {
	margin-left: 0;
	margin-right: 0;
	margin-top: 0;
}
.columns.is-gapless > .column {
	margin: 0;
	padding: 0 !important;
}
.columns.is-gapless:not(:last-child) {
	margin-bottom: 1.5rem;
}
.columns.is-gapless:last-child {
	margin-bottom: 0;
}
.columns.is-mobile {
	display: flex;
}
.columns.is-multiline {
	flex-wrap: wrap;
}
.columns.is-vcentered {
	align-items: center;
}

.dashboard-tile .tile-head h3 {
	font-family: var(--font-alt), sans-serif;
	color: var(--dark-text);
	font-weight: 600;
	line-height: 1px;
	font-size: 14px
}

.company-dashboard .company-header {
	display: flex;
	padding: 20px;
	background: var(--white);
	border: 1px solid var(--fade-grey-dark-3);
	border-radius: 6px;
	margin-bottom: 1.5rem;
}
.company-dashboard .company-header .header-item {
	width: 50%;
	border-right: 1px solid var(--fade-grey-dark-3);
}
.company-dashboard .company-header .header-item:last-child {
	border-right: none;
}
.company-dashboard .company-header .header-item .item-inner {
	text-align: center;
}
.company-dashboard .company-header .header-item .item-inner .lnil, .company-dashboard .company-header .header-item .item-inner .lnir {
	font-size: 1.8rem;
	margin-bottom: 6px;
	color: var(--primary);
}

.lnil {
	display: inline-block;
	font: normal normal normal 1em/1 'LineIconsPro Light';
	speak: none;
	text-transform: none;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
.company-dashboard .company-header .header-item .item-inner span {
	display: block;
	font-family: var(--font), sans-serif;
	font-weight: 600;
	font-size: 1.6rem;
	color: var(--dark-text);
}
.company-dashboard .company-header .header-item .item-inner p {
	font-family: var(--font-alt), sans-serif;
	font-size: 0.85rem;
}


.r {
	font-family: var(--font-alt), sans-serif !important;
	font-size: 0.85rem !important;
	font-family: var(--font), sans-serif !important;
	font-weight: 300 !important;
	color: var(--light-text) !important;
	display: initial !important;
}


.company-dashboard .dashboard-card.is-base-chart {
	padding: 0;
	display: flex;
	flex-direction: column;
}

.company-dashboard .dashboard-card {
	flex: 1;
	display: inline-block;
	width: 100%;
	padding: 20px;
	background-color: var(--white);
	border-radius: var(--radius-large);
	border: 1px solid var(--fade-grey-dark-3);
	transition: all 0.3s;
	height: 100%;
}
.company-dashboard .dashboard-card.is-base-chart .content-box {
	padding: 30px;
}
.company-dashboard .dashboard-card.is-base-chart .chart-container {
	margin-top: auto;
}
.company-dashboard .dashboard-card.is-base-chart .content-box .revenue-stats {
	display: flex;
	padding-bottom: 20px;
	border-bottom: 1px solid var(--fade-grey-dark-3);
}
.company-dashboard .dashboard-card.is-base-chart .content-box .revenue-stats .revenue-stat {
	margin-right: 30px;
	font-family: var(--font), sans-serif;
}
.company-dashboard .dashboard-card.is-base-chart .content-box .revenue-stats .revenue-stat span:first-child {
	text-transform: uppercase;
	font-family: var(--font-alt), sans-serif;
	font-size: 0.75rem;
	color: var(--light-text);
}

.company-dashboard .dashboard-card.is-base-chart .content-box .revenue-stats .revenue-stat span {
	display: block;
}
.company-dashboard .dashboard-card.is-base-chart .content-box .revenue-stats .revenue-stat span.current {
	color: var(--primary);
}

.company-dashboard .dashboard-card.is-base-chart .content-box .revenue-stats .revenue-stat span:nth-child(2) {
	color: var(--dark-text);
	font-size: 1.6rem;
	font-weight: 600;
}

:deep(.p-button-label) {
	font-weight: 400 !important;
}	

</style>
