<template>
	<transition name="cdc">
		<div class="pane pane-cdc" style="overflow-x: hidden; overflow-y: hidden;height:calc(100% - 60px) !important">
			<div class="page-content-wrapper" style="height:100% !important">
				<div class="account-wrapper" style="height:100% !important">
					<div class="columns" style="height:100% !important" v-if="app.view == 'etudecdc'">
						<iframe
						:src="iframeUrl"
						frameborder="0"
						style="width: 100%; height: 100%;"
						allowfullscreen>
						</iframe>
					</div>
				</div>
			</div>
		</div>

	</transition>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	name: 'PaneCdc',

	data: function() {
		return {
			loading: false,
		}
	},

	computed: Object.assign(mapGetters([ 'app', 'user']), {
		iframeUrl: function () {
			return process.env.VUE_APP_CDC_URL + '?token=' + this.app.user.gacu_token;
		}
	}),

	mounted: function () {
		//console.log(this.iframeUrl)
	},

	watch: {
	},

	methods: {
	},

	// eslint-disable-next-line
	components: {  }
}
</script>


<style lang="stylus" scoped>
@import '../assets/css/app'


.cdc-enter, .cdc-leave-to
	transform translateX(-100%)

.opacity-enter, .opacity-leave-to
	opacity 0

.full
	max-height 500px
._filtre
	padding 20px
	border-bottom 1px solid #ccc
	height 60px
	vertical-align middle

.content
	height calc(100% - 100px)

.profil
	border 1px solid #ccc
	border-radius 5px
	padding 20px
	font-weight 500

.value
	color #999
	padding-bottom 20px

.value2
	color #999
	padding-bottom 5px

.menu
	vertical-align middle
	text-align center
	height 48px
	padding-top 16px
	vertical-align middle !important
	cursor pointer
	display inline-block
	&:hover
		background-color violetlogo
		color #fff
.encours
	color violetlogo
	font-weight 500
	font-size: 1.5em
	padding-top 10px

.pane-cdc
	background-color #f0f0f0
	position absolute
	top 60px
	float left
	//padding-top 25px
	height calc(100% - 55px)
	width 100%
	bottom 0
	transition 0.5s easeOutQuart
	z-index 500
	.programs
		height 100%
		overflow hidden
		background #fff
		border-right 1px solid #ddd
		.programs-list
			height 100%
			.programs-list-content
				height 100%
	.loading
		position absolute
		right 50%
		top 50%
	.loading-lib
		top -30px
		left 100px
	.infinite-loader
		position absolute
		.loader
			display inline-block
			background main_color
			border-radius 100%
			color #333
			.icon
				display block
				animation rotate 1.5s infinite linear
	.logo
		position absolute
		right 16px
		top 16px
		padding 0
		height 150px
		border-radius 12px
		overflow hidden
		img
			height 100%
			max-height 150px
	.home-search
		position absolute
		left 50%
		top calc(50% - 64px)
		transform translate(-50%, -50%)
		color #333
		.slogan
			margin 0 0 24px 0
			white-space nowrap
			font-family volte, sans-serif
			font-size 20px
			text-align center
		.form
			width 615px
			margin 0 auto
			.input
				background #fff
				border-radius 40px
				input
					display block
					width 100%
					height 48px
					margin 0 4px 0 0
					padding 0 24px
					border none
					background none
					background-size 24px 24px
					outline none
					font inherit
					color #333
					&::-webkit-input-placeholder
						font-weight 400
				button
					position absolute
					right 0
					top 0
					height 48px
					width 48px
					border none
					background none
					border-radius 40px
					outline none
					cursor pointer
					opacity 0.5
					&:hover, &:active
						opacity 0.75
			.hint
				padding 0 0 0 32px
				margin 8px 0 0 0
				opacity 0.35
				font-size 12px
				line-height 16px
				.icon
					position absolute
					left 0
					top 50%
					transform translateY(-50%)
	.home-user-history
		position absolute
		left 24px
		bottom 24px
		color #fff
		.history-label
			opacity 0.5
			font-family volte, sans-serif
			font-size 14px
			text-transform uppercase
			line-height 24px
		.history-value
			> span
				display inline-block
				padding 4px 16px 4px 12px
				margin 0 0 0 -12px
				cursor pointer
				border-radius 40px
				line-height 24px
				&:hover
					background-color alpha(#fff, 10%)
				.icon
					float left
					margin 0 8px 0 0

@keyframes rotate
	from
		transform rotate(0deg)
	to
		transform rotate(359deg)

</style>
