<template>
	<div class="stat-box">
		<div class="stat-box-header">
			<div class="flex align-items-center surface-card">
				<div style="width:48px;height:48px;border-radius:10px" class="bg-blue-100 inline-flex align-items-center justify-content-center mr-3">
					<i class="pi pi-building text-blue-500 text-3xl"></i>
				</div>
				<div>
					<span class="text-900 text-xl font-medium mb-2">{{ $t('historique') }}</span>
					<p class="mt-1 mb-0 text-600 font-medium text-sm">{{ $t('de_la_ventilation_de_loffre') }}
						<tool-source url='' :txt="'Adequation au ' + app.stats.prix_avec_stat.intitule_colonne[0]"/>
					</p>
				</div>
				<div class="ml-auto">
					<div class="speeddial-tooltip-demo">
						<SpeedDial className="speeddial-left" :model="items" direction="down" showIcon="pi pi-bars" hideIcon="pi pi-times" buttonClassName="p-button-outlined"/>
					</div>
				</div>
			</div>
		</div>
		<div class="stat-box-content">
			<div class="table">
				<table id='datatable_offre'>
					<tbody>
						<tr>
							<th id="offre_11"></th>
							<th id="offre_12">{{app.livedata.volume_offre.intitule_colonne[0]}}</th>
							<th id="offre_13">{{app.livedata.volume_offre.intitule_colonne[1]}}</th>
							<th id="offre_14">{{app.livedata.volume_offre.intitule_colonne[2]}}</th>
							<th id="offre_15">{{app.livedata.volume_offre.intitule_colonne[3]}}</th>
							<th id="offre_16">{{app.livedata.volume_offre.intitule_colonne[4]}}</th>
							<th id="offre_17">{{app.livedata.volume_offre.intitule_colonne[5]}}</th>
							<th id="offre_18">{{app.livedata.volume_offre.intitule_colonne[6]}}</th>
							<th id="offre_19">{{app.livedata.volume_offre.intitule_colonne[7]}}</th>
						</tr>
						<tr>
							<th id="offre_21">{{app.livedata.volume_offre.offre_plan.libelle}}</th>
							<td>{{app.livedata.volume_offre.offre_plan.tableau[0]}}</td>
							<td>{{app.livedata.volume_offre.offre_plan.tableau[1]}}</td>
							<td>{{app.livedata.volume_offre.offre_plan.tableau[2]}}</td>
							<td>{{app.livedata.volume_offre.offre_plan.tableau[3]}}</td>
							<td>{{app.livedata.volume_offre.offre_plan.tableau[4]}}</td>
							<td>{{app.livedata.volume_offre.offre_plan.tableau[5]}}</td>
							<td>{{app.livedata.volume_offre.offre_plan.tableau[6]}}</td>
							<td>{{app.livedata.volume_offre.offre_plan.tableau[7]}}</td>
						</tr>
						<tr>
							<th id="offre_31">{{app.livedata.volume_offre.offre_chantier.libelle}}</th>
							<td>{{app.livedata.volume_offre.offre_chantier.tableau[0]}}</td>
							<td>{{app.livedata.volume_offre.offre_chantier.tableau[1]}}</td>
							<td>{{app.livedata.volume_offre.offre_chantier.tableau[2]}}</td>
							<td>{{app.livedata.volume_offre.offre_chantier.tableau[3]}}</td>
							<td>{{app.livedata.volume_offre.offre_chantier.tableau[4]}}</td>
							<td>{{app.livedata.volume_offre.offre_chantier.tableau[5]}}</td>
							<td>{{app.livedata.volume_offre.offre_chantier.tableau[6]}}</td>
							<td>{{app.livedata.volume_offre.offre_chantier.tableau[7]}}</td>
						</tr>
						<tr>
							<th id="offre_41">{{app.livedata.volume_offre.offre_chantier_livre.libelle}}</th>
							<td>{{app.livedata.volume_offre.offre_chantier_livre.tableau[0]}}</td>
							<td>{{app.livedata.volume_offre.offre_chantier_livre.tableau[1]}}</td>
							<td>{{app.livedata.volume_offre.offre_chantier_livre.tableau[2]}}</td>
							<td>{{app.livedata.volume_offre.offre_chantier_livre.tableau[3]}}</td>
							<td>{{app.livedata.volume_offre.offre_chantier_livre.tableau[4]}}</td>
							<td>{{app.livedata.volume_offre.offre_chantier_livre.tableau[5]}}</td>
							<td>{{app.livedata.volume_offre.offre_chantier_livre.tableau[6]}}</td>
							<td>{{app.livedata.volume_offre.offre_chantier_livre.tableau[7]}}</td>
						</tr>
						<tr>
							<th id="offre_51">{{app.livedata.volume_offre.offre_livre.libelle}}</th>
							<td>{{app.livedata.volume_offre.offre_livre.tableau[0]}}</td>
							<td>{{app.livedata.volume_offre.offre_livre.tableau[1]}}</td>
							<td>{{app.livedata.volume_offre.offre_livre.tableau[2]}}</td>
							<td>{{app.livedata.volume_offre.offre_livre.tableau[3]}}</td>
							<td>{{app.livedata.volume_offre.offre_livre.tableau[4]}}</td>
							<td>{{app.livedata.volume_offre.offre_livre.tableau[5]}}</td>
							<td>{{app.livedata.volume_offre.offre_livre.tableau[6]}}</td>
							<td>{{app.livedata.volume_offre.offre_livre.tableau[7]}}</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import SpeedDial from 'primevue/speeddial';
import ToolSource from '../../../../global/ToolSource';

export default {
	name: 'ArrayOffre',

	data: function() {
		return {
			items: [
				{label: 'Data', icon: 'pi pi-fw pi-download', command:() => {this.$store.dispatch('ExportToXls',{id: 'datatable_offre', name: 'Ventilation Offre'});} },
				{label: 'Image', icon: 'pi pi-fw pi-image', command:() => {this.$store.dispatch('ExportToPng',{id: 'datatable_offre'});} },
			]			

		}
	},

	computed: Object.assign(mapGetters([ 'app' ]), {
		
	}),

	methods: {
		
	},

	components: { ToolSource, SpeedDial }
}
</script>

<style lang="stylus" scoped>
@import '../../../../../assets/css/app'




</style>
