<template>
	<div class="stat-box">
		<div class="stat-box-header">
			<div class="flex align-items-center surface-card">
				<div style="width:48px;height:48px;border-radius:10px" class="bg-teal-100 inline-flex align-items-center justify-content-center mr-3">
					<i class="pi pi-euro text-teal-600 text-3xl"></i>
				</div>
				<div>
					<span class="text-900 text-xl font-medium mb-2">{{ $t('historique_des_prix') }}</span>
					<p class="mt-1 mb-0 text-600 font-medium text-sm">{{ $t('en_libre_hors_parking.') }}
						<tool-source url='' :txt="'Adequation au ' + app.stats.prix_avec_stat.intitule_colonne[0]"/>
					</p>
				</div>
				<div class="ml-auto">
					<div class="speeddial-tooltip-demo">
						<SpeedDial className="speeddial-left" :model="items" direction="down" showIcon="pi pi-bars" hideIcon="pi pi-times" buttonClassName="p-button-outlined"/>
					</div>
				</div>
			</div>
			<TabPeriode/>
		</div>
		<div class="stat-box-content">
			<div class="table">
				<table id='datatable_prix_hors_parking'>
					<tbody v-if="app.tab == 'trimestres'">
						<tr>
							<th id="phptrim_11">{{app.livedata.prix_hors_stat_2.titre}}</th>
							<th id="phptrim_12">{{app.livedata.prix_hors_stat_2.intitule_colonne[0]}}</th>
							<th id="phptrim_13">{{app.livedata.prix_hors_stat_2.intitule_colonne[1]}}</th>
							<th id="phptrim_14">{{app.livedata.prix_hors_stat_2.intitule_colonne[2]}}</th>
						</tr>
						<tr>
							<th id="phptrim_21">{{app.livedata.prix_hors_stat_2.prix_vente.libelle}}</th>
							<td>{{enprix(app.livedata.prix_hors_stat_2.prix_vente.tableau[0])}}</td>
							<td>{{enprix(app.livedata.prix_hors_stat_2.prix_vente.tableau[1])}}</td>
							<td>{{enprix(app.livedata.prix_hors_stat_2.prix_vente.tableau[2])}}</td>
						</tr>
						<tr>
							<th id="phptrim_31">{{app.livedata.prix_hors_stat_2.prix_vente_evolution.libelle}}</th>
							<td>{{percent(app.livedata.prix_hors_stat_2.prix_vente_evolution.tableau[0])}}</td>
							<td>{{percent(app.livedata.prix_hors_stat_2.prix_vente_evolution.tableau[1])}}</td>
							<td>{{percent(app.livedata.prix_hors_stat_2.prix_vente_evolution.tableau[2])}}</td>
						</tr>
						<tr>
							<th id="phptrim_41">{{app.livedata.prix_hors_stat_2.prix_offre.libelle}}</th>
							<td>{{enprix(app.livedata.prix_hors_stat_2.prix_offre.tableau[0])}}</td>
							<td>{{enprix(app.livedata.prix_hors_stat_2.prix_offre.tableau[1])}}</td>
							<td>{{enprix(app.livedata.prix_hors_stat_2.prix_offre.tableau[2])}}</td>
						</tr>
						<tr>
							<th id="phptrim_51">{{app.livedata.prix_hors_stat_2.prix_offre_evolution.libelle}}</th>
							<td>{{percent(app.livedata.prix_hors_stat_2.prix_offre_evolution.tableau[0])}}</td>
							<td>{{percent(app.livedata.prix_hors_stat_2.prix_offre_evolution.tableau[1])}}</td>
							<td>{{percent(app.livedata.prix_hors_stat_2.prix_offre_evolution.tableau[2])}}</td>
						</tr>
					</tbody>
					<tbody v-if="app.tab == 'mois'">
						<tr>
							<th id="phptrim_61">{{app.livedata.prix_hors_stat.titre}}</th>
							<th id="phptrim_62">{{app.livedata.prix_hors_stat.intitule_colonne[0]}}</th>
							<th id="phptrim_63">{{app.livedata.prix_hors_stat.intitule_colonne[1]}}</th>
							<th id="phptrim_64">{{app.livedata.prix_hors_stat.intitule_colonne[2]}}</th>
							<th id="phptrim_65">{{app.livedata.prix_hors_stat.intitule_colonne[3]}}</th>
							<th id="phptrim_66">{{app.livedata.prix_hors_stat.intitule_colonne[4]}}</th>
							<th id="phptrim_67">{{app.livedata.prix_hors_stat.intitule_colonne[5]}}</th>
							<th id="phptrim_68">{{app.livedata.prix_hors_stat.intitule_colonne[6]}}</th>
							<th id="phptrim_69">{{app.livedata.prix_hors_stat.intitule_colonne[7]}}</th>
						</tr>
						<tr>
							<th id="phptrim_71">{{app.livedata.prix_hors_stat.prix_vente.libelle}}</th>
							<td>{{enprix(app.livedata.prix_hors_stat.prix_vente.tableau[0])}}</td>
							<td>{{enprix(app.livedata.prix_hors_stat.prix_vente.tableau[1])}}</td>
							<td>{{enprix(app.livedata.prix_hors_stat.prix_vente.tableau[2])}}</td>
							<td>{{enprix(app.livedata.prix_hors_stat.prix_vente.tableau[3])}}</td>
							<td>{{enprix(app.livedata.prix_hors_stat.prix_vente.tableau[4])}}</td>
							<td>{{enprix(app.livedata.prix_hors_stat.prix_vente.tableau[5])}}</td>
							<td>{{enprix(app.livedata.prix_hors_stat.prix_vente.tableau[6])}}</td>
							<td>{{enprix(app.livedata.prix_hors_stat.prix_vente.tableau[7])}}</td>
						</tr>
						<tr>
							<th id="phptrim_81">{{app.livedata.prix_hors_stat.prix_vente_evolution.libelle}}</th>
							<td>{{percent(app.livedata.prix_hors_stat.prix_vente_evolution.tableau[0])}}</td>
							<td>{{percent(app.livedata.prix_hors_stat.prix_vente_evolution.tableau[1])}}</td>
							<td>{{percent(app.livedata.prix_hors_stat.prix_vente_evolution.tableau[2])}}</td>
							<td>{{percent(app.livedata.prix_hors_stat.prix_vente_evolution.tableau[3])}}</td>
							<td>{{percent(app.livedata.prix_hors_stat.prix_vente_evolution.tableau[4])}}</td>
							<td>{{percent(app.livedata.prix_hors_stat.prix_vente_evolution.tableau[5])}}</td>
							<td>{{percent(app.livedata.prix_hors_stat.prix_vente_evolution.tableau[6])}}</td>
							<td>{{percent(app.livedata.prix_hors_stat.prix_vente_evolution.tableau[7])}}</td>
						</tr>
						<tr>
							<th id="phptrim_91">{{app.livedata.prix_hors_stat.prix_offre.libelle}}</th>
							<td>{{enprix(app.livedata.prix_hors_stat.prix_offre.tableau[0])}}</td>
							<td>{{enprix(app.livedata.prix_hors_stat.prix_offre.tableau[1])}}</td>
							<td>{{enprix(app.livedata.prix_hors_stat.prix_offre.tableau[2])}}</td>
							<td>{{enprix(app.livedata.prix_hors_stat.prix_offre.tableau[3])}}</td>
							<td>{{enprix(app.livedata.prix_hors_stat.prix_offre.tableau[4])}}</td>
							<td>{{enprix(app.livedata.prix_hors_stat.prix_offre.tableau[5])}}</td>
							<td>{{enprix(app.livedata.prix_hors_stat.prix_offre.tableau[6])}}</td>
							<td>{{enprix(app.livedata.prix_hors_stat.prix_offre.tableau[7])}}</td>
						</tr>
						<tr>
							<th id="phptrim_101">{{app.livedata.prix_hors_stat.prix_offre_evolution.libelle}}</th>
							<td>{{percent(app.livedata.prix_hors_stat.prix_offre_evolution.tableau[0])}}</td>
							<td>{{percent(app.livedata.prix_hors_stat.prix_offre_evolution.tableau[1])}}</td>
							<td>{{percent(app.livedata.prix_hors_stat.prix_offre_evolution.tableau[2])}}</td>
							<td>{{percent(app.livedata.prix_hors_stat.prix_offre_evolution.tableau[3])}}</td>
							<td>{{percent(app.livedata.prix_hors_stat.prix_offre_evolution.tableau[4])}}</td>
							<td>{{percent(app.livedata.prix_hors_stat.prix_offre_evolution.tableau[5])}}</td>
							<td>{{percent(app.livedata.prix_hors_stat.prix_offre_evolution.tableau[6])}}</td>
							<td>{{percent(app.livedata.prix_hors_stat.prix_offre_evolution.tableau[7])}}</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import SpeedDial from 'primevue/speeddial';
import TabPeriode from '../TabPeriode.vue';
import ToolSource from '../../../../global/ToolSource';

export default {
	name: 'ArrayPrixParkinginclus',

	data: function() {
		return {
			tab: 'mois',
			items: [
				{label: 'Data', icon: 'pi pi-fw pi-download', command:() => {this.$store.dispatch('ExportToXls',{id: 'datatable_prix_hors_parking', name: 'Historique des prix hors parking'});} },
				{label: 'Image', icon: 'pi pi-fw pi-image', command:() => {this.$store.dispatch('ExportToPng',{id: 'datatable_prix_hors_parking'});} },
			]
		}
	},

	computed: Object.assign(mapGetters([ 'app' ]), {
        
	}),

    methods: {
		ChangeTab: function(periodicite) {
			this.$store.dispatch('change_periodicite', { periodicite: periodicite })
		},

		percent: function(str) {
			if (str) {
				let n = parseFloat(str)
				if (n)
					return Math.round(n) + ' %'
			}
            return ""
        },

		enmois: function(str) {
			if (str) {
				let n = parseFloat(str)
				if (n)
					return Math.round(n) + ' mois'
			}
            return ""
        },
		
		enprix: function(str) {
			if (str) {
				let n = parseFloat(str)
                if (n)
                    n = Math.round(n/10)*10         
					return n.toLocaleString('fr-FR', {style: "decimal", currency: "EUR", maximumFractionDigits : "0"}) + " €"
			}
            return ""
        },
    },
	components: { ToolSource, SpeedDial, TabPeriode }

}
</script>

<style lang="stylus" scoped>
@import '../../../../../assets/css/app'




</style>
