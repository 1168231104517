<template>
	<popper
	trigger="hover"
	:options="{
	placement: 'top',
	modifiers: { offset: { offset: '0,10px' } }
	}">
		<div class="popper">
			{{ txt }}<br v-if="url">
			<a v-if="url" :href="url">En savoir plus</a>												
		</div>
		<i class="pi pi-info-circle" slot="reference" style="margin-left:10px"></i>
	</popper>
</template>

<script>
import Popper from 'vue-popperjs';
import 'vue-popperjs/dist/vue-popper.css';
import "@fontsource/poppins"; // Style normal

export default {
	name: "ToolSource",
	props: {
		url: {
			type: String,
			required: false,
			default: ''
		},
		txt: {
			type: String,
			required: false,
			default: ''
		},
	},
	methods: {
		
	},
	components: { Popper }
};
</script>


<style scoped>
.popper {
	min-width: 100px;
	white-space: nowrap;
	padding: 8px;
	font-family: 'Poppins';
}
</style>
