<template>
	<transition name="slide">
		<div class="pane pane-insee" :class="app.viewlayer" v-if="false">
			<div class="header">
				<div class="back-button" v-if="app.viewlayer == 'insee'" @click="set_view('')"><icon file="x" :size="32" /></div>
				<div class="texte">{{ $t('donnees_insee') }}</div>
			</div>
			<div class="right-bar" v-if="app.viewlayer == 'insee'">
				<div class="right-bar-content" v-bar>
					<div class="content">
						<div>
							<br/>
							<div class="surface-card border-round p-4" id="insee_rs_chiffres_img" style="background: #f6f6f6!important; width: calc(100% - 7px);">
								<div class="flex align-items-start mb-5">
									<div class="ml-3" style="text-align: left;">
										<span class="block text-900 mb-1 text-xl font-medium">{{ app.adr_init.territoire }}</span>
										<p class="text-600 mt-0 mb-0">{{ $t('revenu_median_mensuel_par_tranches_dâges') }}</p>
									</div>
								</div>
								<ul class="list-none p-0 m-0">
									<li class="mb-5">
										<div class="flex justify-content-between align-items-center">
											<span class="text-900 inline-flex justify-content-between align-items-center">
												<span class="font-medium text-900">- {{ $t('median') }}</span>
											</span>
											<span class="text-purple-600 font-medium">{{ app.insee[0].data[app.insee[0].data.length-1].lodgment.household.average_salary_household }} {{$t('€/mois')}}</span>
										</div>
										<div class="surface-300 w-full mt-2" style="height: 7px; border-radius: 4px">
											<div class="bg-purple-500" style="height: 7px; border-radius: 4px" :style="{ width : Math.round((app.insee[0].data[app.insee[0].data.length-1].lodgment.household.average_salary_household) / max_mediane * 100) + '%'}"></div>
										</div>
									</li>
									<li class="mb-5">
										<div style="display:flex">
											<div style="width:100%">
												<div class="flex justify-content-between align-items-center">
													<span class="text-900 inline-flex justify-content-between align-items-center">
														<span class="font-medium text-900">- {{ $t('de_30_ans_et_moins') }}</span>
													</span>
													<span class="text-teal-600 font-medium">{{ app.insee[0].data[app.insee[0].data.length-1].household.age_division.age_0_30.median_income }} {{$t('€/mois')}}</span>
												</div>
												<div class="surface-300 mt-2" style="height: 7px; border-radius: 4px;">
													<div class="bg-teal-500" style="height: 7px; border-radius: 4px" :style="{ width : Math.round((app.insee[0].data[app.insee[0].data.length-1].household.age_division.age_0_30.median_income) / max_mediane * 100) + '%'}"></div>
												</div>
											</div>
										</div>
									</li>
									<li class="mb-5">
										<div style="display:flex">
											<div style="width:100%">
												<div class="flex justify-content-between align-items-center">
													<span class="text-900 inline-flex justify-content-between align-items-center">
														<span class="font-medium text-900">- {{ $t('de_30_a_39_ans') }}</span>
													</span>
													<span class="text-teal-600 font-medium">{{ app.insee[0].data[app.insee[0].data.length-1].household.age_division.age_30_39.median_income }} {{$t('€/mois')}}</span>
												</div>
												<div class="surface-300 mt-2" style="height: 7px; border-radius: 4px;">
													<div class="bg-teal-500" style="height: 7px; border-radius: 4px" :style="{ width : Math.round((app.insee[0].data[app.insee[0].data.length-1].household.age_division.age_30_39.median_income) / max_mediane * 100) + '%'}"></div>
												</div>
											</div>
										</div>
									</li>
									<li class="mb-5">
										<div style="display:flex">
											<div style="width:100%">
												<div class="flex justify-content-between align-items-center">
													<span class="text-900 inline-flex justify-content-between align-items-center">
														<span class="font-medium text-900">- {{ $t('de_40_a_49_ans') }}</span>
													</span>
													<span class="text-teal-600 font-medium">{{ app.insee[0].data[app.insee[0].data.length-1].household.age_division.age_40_49.median_income }} {{$t('€/mois')}}</span>
												</div>
												<div class="surface-300 mt-2" style="height: 7px; border-radius: 4px;">
													<div class="bg-teal-500" style="height: 7px; border-radius: 4px" :style="{ width : Math.round((app.insee[0].data[app.insee[0].data.length-1].household.age_division.age_40_49.median_income) / max_mediane * 100) + '%'}"></div>
												</div>
											</div>
										</div>
									</li>
									<li class="mb-5">
										<div style="display:flex">
											<div style="width:100%">
												<div class="flex justify-content-between align-items-center">
													<span class="text-900 inline-flex justify-content-between align-items-center">
														<span class="font-medium text-900">- {{ $t('de_50_a_59_ans') }}</span>
													</span>
													<span class="text-teal-600 font-medium">{{ app.insee[0].data[app.insee[0].data.length-1].household.age_division.age_50_59.median_income }} {{$t('€/mois')}}</span>
												</div>
												<div class="surface-300 mt-2" style="height: 7px; border-radius: 4px;">
													<div class="bg-teal-500" style="height: 7px; border-radius: 4px" :style="{ width : Math.round((app.insee[0].data[app.insee[0].data.length-1].household.age_division.age_50_59.median_income) / max_mediane * 100) + '%'}"></div>
												</div>
											</div>
										</div>
									</li>
									<li class="mb-5">
										<div style="display:flex">
											<div style="width:100%">
												<div class="flex justify-content-between align-items-center">
													<span class="text-900 inline-flex justify-content-between align-items-center">
														<span class="font-medium text-900">- {{ $t('de_60_a_74_ans') }}</span>
													</span>
													<span class="text-teal-600 font-medium">{{ app.insee[0].data[app.insee[0].data.length-1].household.age_division.age_60_74.median_income }} {{$t('€/mois')}}</span>
												</div>
												<div class="surface-300 mt-2" style="height: 7px; border-radius: 4px;">
													<div class="bg-teal-500" style="height: 7px; border-radius: 4px" :style="{ width : Math.round((app.insee[0].data[app.insee[0].data.length-1].household.age_division.age_60_74.median_income) / max_mediane * 100) + '%'}"></div>
												</div>
											</div>
										</div>
									</li>
									<li class="mb-5">
										<div style="display:flex">
											<div style="width:100%">
												<div class="flex justify-content-between align-items-center">
													<span class="text-900 inline-flex justify-content-between align-items-center">
														<span class="font-medium text-900">- {{ $t('de_75_ans_et_plus') }}</span>
													</span>
													<span class="text-teal-600 font-medium">{{ app.insee[0].data[app.insee[0].data.length-1].household.age_division.age_75_more.median_income }} {{$t('€/mois')}}</span>
												</div>
												<div class="surface-300 mt-2" style="height: 7px; border-radius: 4px;">
													<div class="bg-teal-500" style="height: 7px; border-radius: 4px" :style="{ width : Math.round((app.insee[0].data[app.insee[0].data.length-1].household.age_division.age_75_more.median_income) / max_mediane * 100) + '%'}"></div>
												</div>
											</div>
										</div>
									</li>
								</ul>
							</div>
							<br/>
							<div class="surface-card border-round p-4" id="insee_rs_chiffres_img" style="background: #f6f6f6!important; width: calc(100% - 7px);">
								<div class="flex align-items-start mb-5">
									<div class="ml-3" style="text-align: left;">
										<p class="text-600 mt-0 mb-0">{{$t('Répartition de la population par tranches d\'âges')}}</p>
									</div>
								</div>
								<div class="surface-border border-1 border-round p-3 mb-4">
									<div class="flex justify-content-between align-items-center">
										<span class="text-900 inline-flex justify-content-between align-items-center">
											<i class="pi pi-users mr-2"></i>
											<span class="font-medium text-900">{{ $t('population_globale') }}</span>
										</span>
										<span class="text-teal-600 font-medium">{{ max_pop }} {{ $t('menages') }}</span>
									</div>
									<ul class="mt-3 list-none p-0 mx-0 flex" style="height: 7px;">
										<li :style="{ width : rep_30+ '%'}" class="bg-indigo-500 border-round-left"></li>
										<li :style="{ width : rep_39 + '%'}" class="bg-orange-500"></li>
										<li :style="{ width : rep_49 + '%'}" class="bg-blue-500"></li>
										<li :style="{ width : rep_59 + '%'}" class="bg-purple-500"></li>
										<li :style="{ width : rep_74 + '%'}" class="bg-yellow-500"></li>
										<li :style="{ width : rep_75 + '%'}" class="bg-cyan-500  border-round-right"></li>
									</ul>
								</div>
								<ul class="mt-4 list-none p-0 mx-0">
									<li class="flex align-items-center pb-3">
										<span style="width:1rem;height:1rem" class="border-round bg-indigo-500 mr-3 flex-shrink-0"></span>
										<span class="text-m font-medium text-90">{{$t('30 ans et moins')}}</span>
										<span class="text-600 text-m font-medium ml-auto">{{app.insee[0].data[app.insee[0].data.length-1].household.age_division.age_0_30.number}}</span>
									</li>
									<li class="flex align-items-center py-3">
										<span style="width:1rem;height:1rem" class="border-round bg-orange-500 mr-3 flex-shrink-0"></span>
										<span class="text-m font-medium text-90">{{$t('30 à 39 ans')}}</span>
										<span class="text-600 text-m font-medium ml-auto">{{app.insee[0].data[app.insee[0].data.length-1].household.age_division.age_30_39.number}}</span>
									</li>
									<li class="flex align-items-center py-3">
										<span style="width:1rem;height:1rem" class="border-round bg-blue-500 mr-3 flex-shrink-0"></span>
										<span class="text-m font-medium text-90">{{$t('39 à 49 ans')}}</span>
										<span class="text-600 text-m font-medium ml-auto">{{app.insee[0].data[app.insee[0].data.length-1].household.age_division.age_40_49.number}}</span>
									</li>
									<li class="flex align-items-center py-3">
										<span style="width:1rem;height:1rem" class="border-round bg-purple-500 mr-3 flex-shrink-0"></span>
										<span class="text-m font-medium text-90">{{$t('49 à 59 ans')}}</span>
										<span class="text-600 text-m font-medium ml-auto">{{app.insee[0].data[app.insee[0].data.length-1].household.age_division.age_50_59.number}}</span>
									</li>
									<li class="flex align-items-center py-3">
										<span style="width:1rem;height:1rem" class="border-round bg-yellow-500 mr-3 flex-shrink-0"></span>
										<span class="text-m font-medium text-90">{{$t('60 à 74 ans')}}</span>
										<span class="text-600 text-m font-medium ml-auto">{{app.insee[0].data[app.insee[0].data.length-1].household.age_division.age_60_74.number}}</span>
									</li>
									<li class="flex align-items-center py-3">
										<span style="width:1rem;height:1rem" class="border-round bg-cyan-500 mr-3 flex-shrink-0"></span>
										<span class="text-m font-medium text-90">{{$t('75 ans et plus')}}</span>
										<span class="text-600 text-m font-medium ml-auto">{{app.insee[0].data[app.insee[0].data.length-1].household.age_division.age_75_more.number}}</span>
									</li>
								</ul>
							</div>
							<br/>
							<div class="surface-card border-round p-4" id="insee_rs_chiffres_img" style="background: #f6f6f6!important; width: calc(100% - 7px);">
								<div class="flex align-items-start mb-5">
									<div class="ml-3" style="text-align: left;">
										<p class="text-600 mt-0 mb-0">{{$t('Provenance des emménagés récents')}}</p>
									</div>
								</div>
								<div class="surface-border border-1 border-round p-3 mb-4">
									<div class="flex justify-content-between align-items-center">
										<span class="text-900 inline-flex justify-content-between align-items-center">
											<i class="pi pi-user-plus mr-2"></i>
											<span class="font-medium text-900">{{$t('Emménagés récents')}}</span>
										</span>
										<span class="text-teal-600 font-medium">{{ max_em }} {{$t('Emménagés')}}</span>
									</div>
									<ul class="mt-3 list-none p-0 mx-0 flex" style="height: 7px;">
										<li :style="{ width : Math.round(app.insee[0].data[app.insee[0].data.length-2].household.last_year_rotation.same_lodgment / max_em * 100) + '%'}" class="bg-indigo-500 border-round-left"></li>
										<li :style="{ width : Math.round(app.insee[0].data[app.insee[0].data.length-2].household.last_year_rotation.change_lodgment_same_city / max_em * 100) + '%'}" class="bg-orange-500"></li>
										<li :style="{ width : Math.round(app.insee[0].data[app.insee[0].data.length-2].household.last_year_rotation.change_city_same_agglomeration / max_em * 100) + '%'}" class="bg-blue-500"></li>
										<li :style="{ width : Math.round(app.insee[0].data[app.insee[0].data.length-2].household.last_year_rotation.change_city_same_department / max_em * 100) + '%'}" class="bg-purple-500"></li>
										<li :style="{ width : Math.round(app.insee[0].data[app.insee[0].data.length-2].household.last_year_rotation.change_departement_same_region / max_em * 100) + '%'}" class="bg-yellow-500"></li>
										<li :style="{ width : Math.round(app.insee[0].data[app.insee[0].data.length-2].household.last_year_rotation.change_region_mainland / max_em * 100) + '%'}" class="bg-cyan-500"></li>
										<li :style="{ width : Math.round(app.insee[0].data[app.insee[0].data.length-2].household.last_year_rotation.change_no_mainland / max_em * 100) + '%'}" class="surface-500"></li>
									</ul>
								</div>
								<ul class="mt-4 list-none p-0 mx-0">
									<li class="flex align-items-center pb-3">
										<span style="width:1rem;height:1rem" class="border-round bg-indigo-500 mr-3 flex-shrink-0"></span>
										<span class="text-m font-medium text-90">{{$t('Même logement')}}</span>
										<span class="text-600 text-m font-medium ml-auto">{{app.insee[0].data[app.insee[0].data.length-2].household.last_year_rotation.same_lodgment}}</span>
									</li>
									<li class="flex align-items-center py-3">
										<span style="width:1rem;height:1rem" class="border-round bg-orange-500 mr-3 flex-shrink-0"></span>
										<span class="text-m font-medium text-90">{{$t('Depuis la même ville')}}</span>
										<span class="text-600 text-m font-medium ml-auto">{{app.insee[0].data[app.insee[0].data.length-2].household.last_year_rotation.change_lodgment_same_city}}</span>
									</li>
									<li class="flex align-items-center py-3">
										<span style="width:1rem;height:1rem" class="border-round bg-blue-500 mr-3 flex-shrink-0"></span>
										<span class="text-m font-medium text-90">{{$t('Depuis la même agglomération')}}</span>
										<span class="text-600 text-m font-medium ml-auto">{{app.insee[0].data[app.insee[0].data.length-2].household.last_year_rotation.change_city_same_agglomeration}}</span>
									</li>
									<li class="flex align-items-center py-3">
										<span style="width:1rem;height:1rem" class="border-round bg-purple-500 mr-3 flex-shrink-0"></span>
										<span class="text-m font-medium text-90">{{$t('Depuis le même département')}}</span>
										<span class="text-600 text-m font-medium ml-auto">{{app.insee[0].data[app.insee[0].data.length-2].household.last_year_rotation.change_city_same_department}}</span>
									</li>
									<li class="flex align-items-center py-3">
										<span style="width:1rem;height:1rem" class="border-round bg-yellow-500 mr-3 flex-shrink-0"></span>
										<span class="text-m font-medium text-90">{{$t('Depuis la même région')}}</span>
										<span class="text-600 text-m font-medium ml-auto">{{app.insee[0].data[app.insee[0].data.length-2].household.last_year_rotation.change_departement_same_region}}</span>
									</li>
									<li class="flex align-items-center py-3">
										<span style="width:1rem;height:1rem" class="border-round bg-cyan-500 mr-3 flex-shrink-0"></span>
										<span class="text-m font-medium text-90">{{$t('Depuis une autre région')}}</span>
										<span class="text-600 text-m font-medium ml-auto">{{app.insee[0].data[app.insee[0].data.length-2].household.last_year_rotation.change_region_mainland}}</span>
									</li>
									<li class="flex align-items-center py-3">
										<span style="width:1rem;height:1rem" class="border-round surface-500 mr-3 flex-shrink-0"></span>
										<span class="text-m font-medium text-90">{{$t('Depuis l\'étranger')}}</span>
										<span class="text-600 text-m font-medium ml-auto">{{app.insee[0].data[app.insee[0].data.length-2].household.last_year_rotation.change_no_mainland}}</span>
									</li>
								</ul>
							</div>
							<highcharts v-if="false" :options="chartOptions_men_tranche"></highcharts>
						</div>
					</div>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
import { mapGetters } from 'vuex'
import { Chart } from 'highcharts-vue'

export default {
	name: 'PaneInsee',

	data () {
		return {
			visible: false,
			chartOptions_men_tranche: {
				accessibility: {
					enabled: false
				},
				credits: {
					enabled: false
				},
				title: '',
				chart: {
					height: 280,
					type: 'pie',
					style: {
						fontFamily: 'Nunito',
					}
				},
				series: [{data: [	{name: 'Agriculteur', y: 0},{name: 'Entrepreneur', y: 0},{name: 'Sans activité', y: 0},{name: 'Sénior', y: 0},{name: 'Employé', y: 0},{name: 'Travailleur indépendant', y: 0},{name: 'Professions libérales', y: 0},{name: 'Retiré', y: 0}],}],
				xAxis: {
					type : 'category',
					categories : ['', '', '', '', '', '', '', ''],
					title: {
						text: ''
					},
					allowDecimals: false,
					lineWidth: 1,
					lineColor: '#ddd',
				},
				yAxis: {
					title: {
						text: ''
					},
					lineWidth: 0,
					gridLineColor: '#ddd',
					gridLineDashStyle: 'dash'
				},
				
				plotOptions: {
					series: {
						label: {
							connectorAllowed: false
						},
					}
				},
				tooltip: {
					shared: true,
					crosshairs: true,
					backgroundColor: '#fff',
					borderWidth: 0,
					borderRadius: 4
				},
			},
		}
	},

	methods: {
		maj_age: function() {
			if (this.app.insee && this.app.insee.length) {
				this.chartOptions_men_tranche.series[0].data = [
					{name: 'Agriculteur', y: this.app.insee[0].data[this.app.insee[0].data.length-1].employment.psc.farmer_rate},
					{name: 'Entrepreneur', y: this.app.insee[0].data[this.app.insee[0].data.length-1].employment.psc.entrepreneur_rate},
					{name: 'Sans activité', y: this.app.insee[0].data[this.app.insee[0].data.length-1].employment.psc.without_activity_rate},
					{name: 'Sénior', y: this.app.insee[0].data[this.app.insee[0].data.length-1].employment.psc.senior_executive_rate},
					{name: 'Employé', y: this.app.insee[0].data[this.app.insee[0].data.length-1].employment.psc.employee_rate},
					{name: 'Travailleur indépendant', y: this.app.insee[0].data[this.app.insee[0].data.length-1].employment.psc.worker_rate},
					{name: 'Professions libérales', y: this.app.insee[0].data[this.app.insee[0].data.length-1].employment.psc.intermediaite_profession_rate},
					{name: 'Retiré', y: this.app.insee[0].data[this.app.insee[0].data.length-1].employment.psc.retired_rate}
				]
			}
		},

		click: function(pin_type) {
			this.visible=false
			this.$store.dispatch('pin_type', {pin_type: pin_type})
		},

		removeall: function() {
			this.$store.dispatch('select_programs', { programs: this.app.programs, selected: false })
		},

		set_view: function() {
			this.$store.dispatch('set_view_layer', { view: '' })
		},
	},

	computed: Object.assign(mapGetters([ 'app', 'report', 'selected_programs' ]), {
		rep_30: function () {
			return Math.round((this.app.insee[0].data[this.app.insee[0].data.length-1].household.age_division.age_0_30.number) / this.max_pop * 100)
		},

		rep_39: function () {
			return Math.round((this.app.insee[0].data[this.app.insee[0].data.length-1].household.age_division.age_30_39.number) / this.max_pop * 100)
		},

		rep_49: function () {
			return Math.round((this.app.insee[0].data[this.app.insee[0].data.length-1].household.age_division.age_40_49.number) / this.max_pop * 100)
		},

		rep_59: function () {
			return Math.round((this.app.insee[0].data[this.app.insee[0].data.length-1].household.age_division.age_50_59.number) / this.max_pop * 100)
		},

		rep_74: function () {
			return Math.round((this.app.insee[0].data[this.app.insee[0].data.length-1].household.age_division.age_60_74.number) / this.max_pop * 100)
		},

		rep_75: function () {
			return Math.round((this.app.insee[0].data[this.app.insee[0].data.length-1].household.age_division.age_75_more.number) / this.max_pop * 100)
		},

		max_mediane:function() {
			let n = this.app.insee[0].data[this.app.insee[0].data.length-1].household.age_division.age_75_more.median_income
			if (this.app.insee[0].data[this.app.insee[0].data.length-1].household.age_division.age_60_74.median_income > n) n = this.app.insee[0].data[this.app.insee[0].data.length-1].household.age_division.age_60_74.median_income
			if (this.app.insee[0].data[this.app.insee[0].data.length-1].household.age_division.age_50_59.median_income > n) n = this.app.insee[0].data[this.app.insee[0].data.length-1].household.age_division.age_50_59.median_income
			if (this.app.insee[0].data[this.app.insee[0].data.length-1].household.age_division.age_40_49.median_income > n) n = this.app.insee[0].data[this.app.insee[0].data.length-1].household.age_division.age_40_49.median_income
			if (this.app.insee[0].data[this.app.insee[0].data.length-1].household.age_division.age_30_39.median_income > n) n = this.app.insee[0].data[this.app.insee[0].data.length-1].household.age_division.age_30_39.median_income
			if (this.app.insee[0].data[this.app.insee[0].data.length-1].household.age_division.age_0_30.median_income > n) n = this.app.insee[0].data[this.app.insee[0].data.length-1].household.age_division.age_0_30.median_income
			return ((Math.round(n/1000,0)+1) * 1000)
		},

		max_pop:function() {
			let n = this.app.insee[0].data[this.app.insee[0].data.length-1].household.age_division.age_75_more.number
			n = n + this.app.insee[0].data[this.app.insee[0].data.length-1].household.age_division.age_60_74.number
			n = n + this.app.insee[0].data[this.app.insee[0].data.length-1].household.age_division.age_50_59.number
			n = n + this.app.insee[0].data[this.app.insee[0].data.length-1].household.age_division.age_40_49.number
			n = n + this.app.insee[0].data[this.app.insee[0].data.length-1].household.age_division.age_30_39.number
			n = n + this.app.insee[0].data[this.app.insee[0].data.length-1].household.age_division.age_0_30.number
			return n
		},

		max_em:function() {
			let n = this.app.insee[0].data[this.app.insee[0].data.length-2].household.last_year_rotation.same_lodgment
			n = n + this.app.insee[0].data[this.app.insee[0].data.length-2].household.last_year_rotation.change_lodgment_same_city
			n = n + this.app.insee[0].data[this.app.insee[0].data.length-2].household.last_year_rotation.change_city_same_agglomeration
			n = n + this.app.insee[0].data[this.app.insee[0].data.length-2].household.last_year_rotation.change_city_same_department
			n = n + this.app.insee[0].data[this.app.insee[0].data.length-2].household.last_year_rotation.change_departement_same_region
			n = n + this.app.insee[0].data[this.app.insee[0].data.length-2].household.last_year_rotation.change_region_mainland
			n = n + this.app.insee[0].data[this.app.insee[0].data.length-2].household.last_year_rotation.change_no_mainland
			return n
		}
		
	}),

	mounted: function() {
		this.maj_age()
	},

	components: { highcharts: Chart }
}
</script>

<style lang="stylus" scoped>
@import '../assets/css/app'

.mrk
	left 33%
	width 24px
	height 24px
	color #f0f2f4
	font-weight 600
	border-radius 100%
	text-align center
	line-height 24px
	cursor pointer
	-webkit-user-select none
	-moz-user-select none
	-ms-user-select none
	user-select none
	&.projet
		background #ccc
	&.actif
		background #29b6f6
	&.termine
		background #0086c3
	&.entree
		background #fcc434
	&.milieu
		background #ee8d3e
	&.haut
		background #d74a22
	&.col
		background #ff4081
	&.ind
		background #f8bbd0

.pane-insee
	position fixed
	left 100%
	top headersize
	bottom 0
	height calc(100% - 155px)
	width 400px
	background-color #333
	color #ccc
	transition 0.5s easeOutQuart
	border-left 1px solid darken(light, 10%)
	z-index 500
	&.insee
		transform translateX(-100%)
	&.slide-enter, &.slide-leave-to
		transform translateX(0)
	.header
		background secondary
		height 60px
		.back-button
			position absolute
			right 10px
			top 6px
			padding 8px
			color #fff
			cursor pointer
			border-radius 100%
			transition 0.25s easeOutQuart
			&:hover
				background palette2
		.texte
			top 22px
			left 10px
			height 48px
			width 200px
			color white
		.tabs
			padding 0 32px
			color #fff
			&:after
				content ''
				display block
				clear both
			.tab
				float left
				margin 0 32px 0 0
				height 96px
				width 96px
				display flex
				align-items center
				justify-content center
				flex-direction column
				opacity 0.5
				font-size 12px
				font-weight 500
				border-bottom 4px solid transparent
				text-align center
				text-transform uppercase
				cursor pointer
				&:hover
					opacity 0.75
				&.active
					opacity 1
					border-color light
				.icon
					display block
					margin 0 0 4px 0
				
	.right-bar
		padding-left 10px
		height 100%
		background white
		width 100%
		color #333
		.right-bar-content
			height 100%
			.content
				height 100%

		.scroll-dots
			position absolute
			left -40px
			top 24px
			width 32px
			background alpha(green, 50%)
			border-radius radius
			z-index 2
			transition 0.25s easeOutQuart
			&:hover
				background alpha(green, 75%)
			.dot
				display block
				width 8px
				height 8px
				margin 12px
				background #fff
				opacity 0.25
				border-radius 100%
				transition 0.25s easeOutQuart
				cursor pointer
				&:hover, &.active
					opacity 1
					
.choix
	padding-bottom 20px


</style>
