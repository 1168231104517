<template>
	<div class="animated-tabs" :style="{ '--tabs-count': tabs.length }">
	<div class="tabs-container_" ref="tabsContainer">
		<div 
		ref="indicator"
		class="tab-indicator_"
		></div>
		<button 
		v-for="(tab, index) in tabs" 
		:key="index"
		:ref="`tab-${index}`"
		class="tab_" 
		:class="{ active: index === activeIndex }"
		@click="setActiveTab(index)"
		:v-tooltip = "tab.tooltip"
		:title = "tab.tooltip"
		>
		{{ tab.label }}
		</button>
	</div>
	<div class="tab-content_" v-if="showContent">
		<slot :name="tabs[activeIndex].name"></slot>
	</div>
	</div>
</template>

<script>

export default {
	name: 'AnimatedTabs',
	props: {
	tabs: {
		type: Array,
		required: true,
		/*
		validator: tabs => {
		return tabs.every(tab => 'label' in tab && 'name' in tab && 'tooltip' in tab);
		}
		*/
	},
	initialActiveIndex: {
		type: Number,
		default: 0
	},
	showContent: {
		type: Boolean,
		default: true
	}
	},
	data() {
	return {
		activeIndex: this.initialActiveIndex
	};
	},
	watch: {
	initialActiveIndex(newValue) {
		this.activeIndex = newValue;
		this.$nextTick(() => {
		this.moveIndicator(newValue);
		});
	}
	},
	methods: {
	setActiveTab(index) {
		if (index !== this.activeIndex) {
		this.activeIndex = index;
		this.moveIndicator(index);
		this.$emit('tab-change', {
			index: index,
			tab: this.tabs[index]
		});
		}
	},
	
	moveIndicator(index) {
		const indicator = this.$refs.indicator;
		const activeTabRef = this.$refs[`tab-${index}`][0];
		
		if (!indicator || !activeTabRef) return;
		
		// Accès direct à l'élément DOM
		const containerRect = this.$refs.tabsContainer.getBoundingClientRect();
		const buttonRect = activeTabRef.getBoundingClientRect();
		
		// Positionnement précis basé sur la position réelle des éléments
		indicator.style.width = `${buttonRect.width}px`;
		indicator.style.left = `${buttonRect.left - containerRect.left}px`;
	}
	},
	mounted() {
	this.$nextTick(() => {
		// Positionnement initial de l'indicateur
		this.moveIndicator(this.activeIndex);
		
		// Mise à jour lors du redimensionnement de la fenêtre
		window.addEventListener('resize', () => {
		this.moveIndicator(this.activeIndex);
		});
	});
	},
	beforeDestroy() {
	window.removeEventListener('resize', this.moveIndicator);
	},
	components: {  }
};
</script>

<style scoped>

.animated-tabs {
	--primary: #6c5ce7;
	--primary-light: #8b6fff;
	--primary-dark: #4920d5;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: -10px;
}

.tabs-container_ {
	position: relative;
	display: inline-flex;
	background-color: #f0f2f5;
	border-radius: 25px;
	padding: 5px;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
	margin-left: auto;
	margin-right: auto;
	width: auto;
}

.tab_ {
	padding: 6px 25px;
	cursor: pointer;
	background-color: transparent;
	border: none;
	border-radius: 25px;
	text-align: center;
	transition: color 0.3s;
	font-weight: 600;
	color: #333;
	font-size: 14px;
	position: relative;
	z-index: 2;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
	white-space: nowrap;
}

.tab_:hover:not(.active) {
	background-color: rgba(98, 54, 255, 0.1);
}

.tab_.active {
	color: white;
    background-color: #6c5ce7 !important;
}

.tab-indicator_ {
	position: absolute;
	height: calc(100% - 10px);
	background-color: var(--primary);
	border-radius: 25px;
	transition: all 0.3s ease-in-out;
	z-index: 1;
	top: 5px;
	box-shadow: 0 4px 8px rgba(98, 54, 255, 0.3);
}

.tab-content_ {
	margin-top: 20px;
	width: 100%
}

@media (max-width: 480px) {
	.tabs-container_ {
	max-width: 100%;
	}
	
	.tab_ {
	padding: 10px 15px;
	font-size: 14px;
	}
}
</style>