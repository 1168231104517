<template>
	<transition name="pane-gisements">
		<div class="paneleft pane pane-gisements" :class="app.view">
			<div class="module_ret">
				<div class="head_filter" @click.stop="cv = !cv" title="Filtrer ma recherche">
					<icon file="filter_list" :size="36" class="filters" />&nbsp;
				</div>
			</div>
			<div class="unmodule_detail_titre" style="height:84px !important;position: absolute;top:60px; border-right: 1px solid #ccc">
				<!--<div class="icone_module"><img src="../assets/img/setec.png" alt="cityscan" style="height:20px;width:60px;text-align:left;"></div>-->
				<div class="thematique">{{ $t('module') }}</div>
				<div class="titre_sommaire"><b>{{ $t('Gisements fonciers') }}</b></div>					
				<!--<div style="position:absolute;color:grey;bottom:2px;right:5px;font-size:12px">{{ Annonces.length }} offre<span v-if="Annonces.length>1">s</span></div>	-->
			</div>
			<div class="_filtre">
				<div class="box">
					<transition name="fade">
						<div v-if="cv" style="border-bottom: 4px solid #41b983; padding-bottom: 10px; padding-top: 10px; padding-top: 10px;height:670px">
							<div class="_box-title2">								
								<p class="box-title3">{{ $t('Surface') }}</p>
								<div class="box-title3">
									<div style="display:flex;vertical-align: middle;align-content: center;align-items: center;">
										De&nbsp;&nbsp;<div class="p-inputgroup" style="height:36px"><InputNumber inputStyle="width:25%;text-align:right" id="integeronly" v-model="filtre.area[0]" @focus="selectContent"/><span class="p-inputgroup-addon">m²</span></div>&nbsp;&nbsp;à&nbsp;&nbsp;<div class="p-inputgroup" style="height:36px"><InputNumber inputStyle="width:25%;text-align:right" id="integeronly" v-model="filtre.area[1]" @focus="selectContent"/><span class="p-inputgroup-addon">m²</span></div>
									</div>
								</div>
								<div class="box-content">
									<br/>
									<Slider v-model="filtre.area" :range="true" :min="0" :max="250000" />
								</div>
							</div>	
							<br>
							<div class="_box-title2">								
								<p class="box-title3">{{ $t('Score vocation habitat') }}</p>
								<div class="box-title3">
									<div style="display:flex;vertical-align: middle;align-content: center;align-items: center;">
										De&nbsp;&nbsp;<div class="p-inputgroup" style="height:36px"><InputNumber inputStyle="width:25%;text-align:right" id="integeronly" v-model="filtre.scorehabitat[0]" @focus="selectContent" disabled /></div>&nbsp;&nbsp;à&nbsp;&nbsp;<div class="p-inputgroup" style="height:36px"><InputNumber inputStyle="width:25%;text-align:right" id="integeronly" v-model="filtre.scorehabitat[1]" @focus="selectContent" disabled /></div>
									</div>
								</div>
								<div class="box-content">
									<br/>
									<Slider v-model="filtre.scorehabitat" :range="true" :min="0" :max="100" :step="10" />
								</div>
							</div>	
							<br>
							<div class="_box-title2">								
								<p class="box-title3">{{ $t('Score vocation économique') }}</p>
								<div class="box-title3">
									<div style="display:flex;vertical-align: middle;align-content: center;align-items: center;">
										De&nbsp;&nbsp;<div class="p-inputgroup" style="height:36px"><InputNumber inputStyle="width:25%;text-align:right" id="integeronly" v-model="filtre.scoreeconomie[0]" @focus="selectContent" disabled /></div>&nbsp;&nbsp;à&nbsp;&nbsp;<div class="p-inputgroup" style="height:36px"><InputNumber inputStyle="width:25%;text-align:right" id="integeronly" v-model="filtre.scoreeconomie[1]" @focus="selectContent" disabled /></div>
									</div>
								</div>
								<div class="box-content">
									<br/>
									<Slider v-model="filtre.scoreeconomie" :range="true" :min="0" :max="100" :step="10" />
								</div>
							</div>	
							<br>
							<table style="width:100%">
								<tr>
									<td>
										<p class="box-title3">{{ $t('Bâti / Non bâti') }}</p>
									</td>
								</tr>
								<tr>
									<td>
										<div class="_box-title2">
											<div class="box-content" style="padding:0px">
												<div class="switches" style="display: flex;justify-content: space-around;align-items: center;font-size: 9pt;">
													<div style="width:50%">
														<div style="display: flex;justify-content: flex-start;align-items: center;margin-bottom:10px"><InputSwitch v-model="filtre.bati" />&nbsp;&nbsp;{{ $t('Bâti') }}</div>
													</div>
													<div style="width:50%">
														<div style="display: flex;justify-content: flex-start;align-items: center;margin-bottom:10px"><InputSwitch v-model="filtre.nonbati" />&nbsp;&nbsp;{{ $t('Non bâti') }}</div>
													</div>
												</div>

												<!--
												<div>
													<switch-button size2="40px" :label="$t('Bâti')" divise="two" @click.native="inverse('bati')" :class="{ active: filtre.bati }" />
													<switch-button size2="40px" :label="$t('Non bâti')" divise="two" @click.native="inverse('nonbati')" :class="{ active: filtre.nonbati }" />
												</div>
												-->
											</div>
										</div>
									</td>
								</tr>
								<tr>
									<td>
										<p class="box-title3">{{ $t('Sans les contraintes environnementales') }}</p>
									</td>
								</tr>
								<tr>
									<td>
										<div class="_box-title2">								
											<div class="box-content" style="padding:0px">
												<div class="switches" style="display: flex;justify-content: space-around;align-items: center;font-size: 9pt;">
													<div style="width:50%">
														<div style="display: flex;justify-content: flex-start;align-items: center;margin-bottom:10px"><InputSwitch v-model="filtre.biodiversite" />&nbsp;&nbsp;{{ $t('Biodiversité') }}</div>
														<div style="display: flex;justify-content: flex-start;align-items: center;margin-bottom:10px"><InputSwitch v-model="filtre.patrimoine" />&nbsp;&nbsp;{{ $t('Patrimoine') }}</div>
													</div>
													<div style="width:50%">
														<div style="display: flex;justify-content: flex-start;align-items: center;margin-bottom:10px"><InputSwitch v-model="filtre.risques_naturels" />&nbsp;&nbsp;{{ $t('Risques naturels') }}</div>
														<div style="display: flex;justify-content: flex-start;align-items: center;margin-bottom:10px"><InputSwitch v-model="filtre.risques_industriels" />&nbsp;&nbsp;{{ $t('Risques industriels') }}</div>
													</div>
												</div>
													<!--
												<div class="switches" style="display: flex;justify-content: space-around;align-items: center;">
													<switch-button size2="80px" :label="$t('Biodiversité')" divise="four" @click.native="inverse('biodiversite')" :class="{ active: filtre.biodiversite }" />
													<switch-button size2="80px" :label="$t('Patrimoine')" divise="four" @click.native="inverse('patrimoine')" :class="{ active: filtre.patrimoine }" />
													<switch-button size2="80px" :label="$t('Risques naturels')" divise="four" @click.native="inverse('risques_naturels')" :class="{ active: filtre.risques_naturels }" />
													<switch-button size2="80px" :label="$t('Risques industriels')" divise="four" @click.native="inverse('risques_industriels')" :class="{ active: filtre.risques_industriels }" />
												</div>
													-->
											</div>
										</div>	
									</td>
								</tr>
								<tr>
									<td style="text-align: center;display: flex;justify-content: center;">
										<div class="app_filtre" style="width:200px" @click="applique_filtre()">{{ $t('appliquer_les_filtres') }}</div>
									</td>
								</tr>
							</table>
						</div>	
					</transition>				
				</div>
				
				<div class="programs-list" style="height: 100%; background-color: white;">
					<div class="programs-list-content" v-bar :class="{ff:cv}" style="background-color: white;">
						<div class="infinite-loader" v-if="app.loading_gisements" style="text-align: center;">
							<div class="text">
								<p>{{$t('Recherche des gisements fonciers')}}</p>
								<p>{{ $t('en_cours') }} ...</p>
								<p>&nbsp;</p>
							</div>
							<div class="loader"><icon file="loader" :size="64" style="color:#333" /></div>
						</div>
						<div class="content" style="width:100% !important;" ref="scrollable_programs" v-else>

						</div>
					</div>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
import { mapGetters } from 'vuex'
import Panel from 'primevue/panel';
import InputText from 'primevue/inputtext';
import Textarea  from 'primevue/textarea';
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import Checkbox from 'primevue/checkbox';
import InputNumber from 'primevue/inputnumber';
// eslint-disable-next-line
import SwitchButtonDpe from './global/SwitchButtonDpe'
// eslint-disable-next-line
import SwitchButton from './global/SwitchButton'
import Slider from 'primevue/slider';
// eslint-disable-next-line
import InputSwitch from 'primevue/inputswitch';

export default {
	name: 'PaneGisements',

	data: function() {
		return {
			nbp:0,
			cv: true,
			filtre : {
				scoreeconomie: [0, 100],
				scoreeconomie_min: 0,
				scoreeconomie_max: 100,
				scorehabitat: [0, 100],
				scorehabitat_min: 0,
				scorehabitat_max: 100,
				area: [0, 250000],				
				bati: true,
				nonbati: true,
				biodiversite: false,
				patrimoine: false,
				risques_humains: false,
				risques_industriels: false,
				risques_naturels: false,
				area_min: 0,
				area_max: 999999999,
			}
		}
	},

	mounted: function() {
		this.filtre.bati = this.app.filtre_gisement.bati
		this.filtre.nonbati = this.app.filtre_gisement.nonbati
		this.filtre.biodiversite = this.app.filtre_gisement.biodiversite
		this.filtre.patrimoine = this.app.filtre_gisement.patrimoine
		this.filtre.risques_humains = this.app.filtre_gisement.risques_humains
		this.filtre.risques_industriels = this.app.filtre_gisement.risques_industriels
		this.filtre.risques_naturels = this.app.filtre_gisement.risques_naturels
		this.filtre.area_min = this.app.filtre_gisement.area_min
		this.filtre.area_max = this.app.filtre_gisement.area_max
		this.filtre.scorehabitat_min = this.app.filtre_gisement.scorehabitat_min
		this.filtre.scorehabitat_max = this.app.filtre_gisement.scorehabitat_max
		this.filtre.scoreeconomie_min = this.app.filtre_gisement.scoreeconomie_min
		this.filtre.scoreeconomie_max = this.app.filtre_gisement.scoreeconomie_max
	},

	computed: Object.assign(mapGetters([ 'app', 'map' ]), {

	}),

	watch: {
	},

	methods: {
		inverse: function(f) {
			this.filtre[f] = !this.filtre[f]
		},

        selectContent(event) {
          // Sélectionner le contenu de l'input
          event.target.select();
        },

		applique_filtre: function() {
			this.filtre.area_min = this.filtre.area[0]
			this.filtre.area_max = this.filtre.area[1]

			this.filtre.scorehabitat_min = this.filtre.scorehabitat[0]
			this.filtre.scorehabitat_max = this.filtre.scorehabitat[1]

			this.filtre.scoreeconomie_min = this.filtre.scoreeconomie[0]
			this.filtre.scoreeconomie_max = this.filtre.scoreeconomie[1]
			this.$store.dispatch('set_filters_gisement', { filtre_gisement: this.filtre })
		},		
	},

	// eslint-disable-next-line
	components: { Panel, InputSwitch, Dialog, Button, InputText, Textarea, Checkbox, InputNumber, SwitchButtonDpe, SwitchButton, Slider }
}
</script>

<style lang="stylus" scoped>
@import '../assets/css/app'

.ff
	height calc(100% - 460px) !important

.head_filter
	width 60px;
	height 60px;
	position absolute
	left 0px
	text-align center
	line-height 60px
	cursor pointer
	&.idf
		right 0px !important

._filtre
	top 84px
	height calc(100% - 144px) !important
	width 100%

.box
	background #f0f0f0
	
.box-content
	display block
	padding 0 16px
.box-title
	padding 0 16px
	margin 0 0 4px 0
	font-family volte, sans-serif
	font-weight 600
	color #333
	line-height 16px
	text-align left
	padding-bottom 8px
	padding-top 8px
._box-title2
	padding 0 8px
	margin 0 0 4px 0
	font-family volte, sans-serif
	color #333
	line-height 16px
	text-align left
	padding-bottom 8px
	padding-top 8px
	font-family "Roboto", arial, sans-serif
.box-title3
	padding 0 8px
	margin 0 0 4px 0
	font-family volte, sans-serif
	color #333
	line-height 16px
	text-align left
	padding-bottom 2px
	font-family "Roboto", arial, sans-serif

.module_ret
	width PaneLeftSize
	height 60px
	vertical-align middle
	background-color palette_gris
	border-bottom 1px solid palette_gris_bordure
	border-right 1px solid palette_gris_bordure

.aide
	width 60px
	left 0px
	position absolute
	border-right 1px solid palette_gris_bordure
	text-align center
	line-height 60px
	cursor pointer
	&.idf
		left 0px !important

.select-all-co
	position absolute
	right 16px
	top 28%
	width 24px
	height 24px
	border-radius radius
	border 2px solid #CCC
	display block
	cursor pointer
	display flex
	background-color #FFF !important
	&.disabled
		cursor default
	&.checked
		background-color transparent !important
		background-color palette0 !important
		.chk .icon
			color #FFF
			display block
	&:hover
		background-color palette0 !important
	input
		position absolute
		top 0
		display none
	.chk
		float left
		width 24px
		height 24px
		border-radius (radius / 2)
		.icon
			position absolute
			left 2px
			top 2px
			display none
	.text
		display block
		line-height 32px
		white-space nowrap


.select-all
	position absolute
	right 16px
	top 22px
	width 24px
	height 24px
	border-radius radius
	border 2px solid palette0
	display block
	cursor pointer
	display flex
	&.disabled
		cursor default
	&.checked
		background-color transparent !important
		background-color palette0 !important
		.chk .icon
			color #FFF
			display block
	&:hover
		border-color palette2
	input
		position absolute
		top 0
		display none
	.chk
		float left
		width 24px
		height 24px
		border-radius (radius / 2)
		.icon
			position absolute
			left 2px
			top 2px
			display none
	.text
		display block
		line-height 32px
		white-space nowrap

.pane-gisements-enter
	transform translateX(100%)
.pane-gisements-leave-to
	transform translateX(-100%)

.module
	width PaneLeftSize
	height 40px
	padding-left 10px
	padding-top 10px
	vertical-align middle
	background-color #fafafa
	border-bottom 1px solid #cccccc

.loading_graph
	.infinite-loader
		.loader
			display inline-block
			background main_color
			border none
			border-radius 100%
			color #ccc
			.icon
				color #ccc
				display block
				animation rotate 1.5s infinite linear

.pane-gisements
	position absolute
	top headersize
	float left
	height calc(100% - 126px)
	width PaneLeftSize
	transition 0.5s easeOutQuart
	.infinite-loader
		margin-top 20px
		position relative
		right auto
		top auto
		//left 16px
		//top calc(50% - 64px)
		.loader
			display inline-block
			background main_color
			border none
			border-radius 100%
			color #fff
			.icon
				display block
				animation rotate 1.5s infinite linear
	.programs
		top 0px
		height 100%
		overflow hidden
		background #fff
		&.empty
			.programs-no-result
				display flex
		.programs-footer
			bottom 0px
			height 68px
			padding 16px
			font-weight 600
			border-top 1px solid #ccc
		.programs-header
			padding 16px
			width PaneLeftSize
			font-weight 600
			border-bottom 1px solid #ccc
			> p
				line-height 16px
			.select-all-save
				position absolute
				right 16px
				top 12px
				width 24px
				height 24px
				background #fff
				border 2px solid #ccc
				border-radius radius
				cursor pointer
				&:hover
					border-color blue
				&:active
					border-color blue
				&.chk
					background-color #999
					border 2px solid #fff
			.select-allA
				position absolute
				right 16px
				top 12px
				width 24px
				height 24px
				background #fff
				border 2px solid #ccc
				border-radius radius
				cursor pointer
				&:hover
					border-color blue
				&:active
					border-color blue
				&.chk
					background #fff url(../assets/img/checked.png) center center no-repeat
					background-size 14px 14px

		.programs-no-result
			position absolute
			left 0
			top 0
			right 0
			bottom 0
			height 100%
			padding 0 24px
			display none
			flex-direction column
			justify-content center
			align-items center
			background #fff
			color #999
			font-family volte, sans-serif
			text-align center
			font-weight 600
			font-size 20px
			line-height 24px
			user-select none
			.icon
				color #aaa
			.text
				margin 24px 0 0 0
				p
					padding 0 0 8px 0
					&:last-child
						padding 0
			.button
				display inline-block
				height 48px
				margin 24px 0 0 0
				padding 0 24px
				border-radius radius
				cursor pointer
				background-color blue
				font-weight 500
				font-size 20px
				color #fff
				line-height @height
				&:hover
					background-color darken(blue, 10%)
				&:active
					background-color darken(blue, 20%)
					transform translateY(1px)
.nolive
	height calc(100% - 50px) !important

.commune
	background secondary
	padding 16px
	overflow hidden
	cursor pointer
	height 48px
	width 100%
	text-align center
	color white
	border-bottom 1px solid white

.buttonv
	border 0
	border-radius 0
	background primaryA400
	border-radius 18px
	font-family Montserrat, sans-serif
	font-style normal
	text-align center
	letter-spacing 0.75px
	text-transform uppercase
	color #FFFFFF
	width 215px
	height 36px
	cursor pointer
	&:hover
		background secondary
	> a
		color #FFFFFF !important








.buttonv_inactive
	right 0
	border 0
	border-radius 0
	background #AAAAAA
	border-radius 18px
	font-style normal
	font-weight 500
	font-size 12px
	line-height 16px
	text-align center
	letter-spacing 0.75px
	text-transform uppercase
	color #FFFFFF
	width 200px
	height 36px
.module
	width PaneLeftSize
	height 40px
	padding-left 10px
	padding-top 10px
	vertical-align middle
	background-color #fafafa
	border-bottom 1px solid #cccccc
	cursor pointer

.panecontent
	border-right 1px solid #cccccc

.paneleft
	border 0 !important

.loading_graph
	width PaneLeftSize
	height 280px

.stat
	width 60px;
	height 60px;
	position absolute
	right 180px
	border-left 1px solid #ccc
	text-align center
	line-height 60px
	cursor pointer
	&.idf
		right 120px
	&.city
		right 120px
	&.citynotidf
		right 60px !important
.res
	position absolute
	width auto
	right 50px
	top 25px
	font-weight 500
	font-size 14px


.prg_pc
	width 50%
	line-height 60px
	text-align center
	vertical-align middle
	cursor pointer
	display block
	height 40px
	border-bottom 1px solid #ccc
	background-color #eee
	&.last
		border-left 1px solid #ccc
	&.active		
		background-color #fcfcfc
		border-bottom 4px solid #6C21F9
		font-weight 600
		&:hover
			border-bottom 4px solid #6C21F9
	&:hover
		border-bottom 4px solid #333


.column {
	padding-left: 10px;
	padding-right: 10px;
	padding-bottom: 10px;
}

.dashboard-card {
	flex: 1;
	display: inline-block;
	width: 92%;
	padding: 20px;
	background-color: var(--white);
	border-radius: var(--radius-large);
	border: 1px solid var(--fade-grey-dark-3);
	transition: all 0.3s;	
}

.dashboard-card:not(:last-child) {
	margin-bottom: 1.5rem;
}
.dashboard-card.flex-chart {
	height: calc(50% - 0.75rem);
}

.dashboard-card .card-head {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 20px;
}
.dashboard-card .card-head h3 {
	font-family: var(--font-alt), sans-serif;
	font-size: 0.9rem;
	font-weight: 600;
	color: var(--dark-text);
}
.dashboard-card .revenue-stats {
	display: flex;
	margin-bottom: 10px;
}
.dashboard-card .revenue-stats .revenue-stat {
	margin-right: 30px;
	font-family: var(--font), sans-serif;
}
.dashboard-card .revenue-stats .revenue-stat span {
	display: block;
}
.dashboard-card .revenue-stats .revenue-stat span:first-child {
	color: var(--light-text);
	font-size: 0.9rem;
}
.dashboard-card .revenue-stats .revenue-stat span:nth-child(2) {
	color: var(--dark-text);
	font-size: 1.2rem;
	font-weight: 600;
}
.dashboard-card .revenue-stats .revenue-stat span.current {
	color: var(--primary);
}
.dashboard-card .chart-media {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.dashboard-card .chart-media .meta {
	margin-right: 30px;
	align-items: center;
	font-family: var(--font), sans-serif;
}
.dashboard-card .chart-media .meta h4 {
	font-family: var(--font-alt), sans-serif;
	font-size: 1rem;
	font-weight: 600;
	color: var(--dark-text);
}
.dashboard-card .chart-media .meta span {
	display: block;
	color: var(--dark-text);
	font-size: 1.2rem;
	font-weight: 600;
	margin-bottom: 8px;
}
.dashboard-card .chart-media .meta p {
	font-size: 0.9rem;
}
.dashboard-card .chart-media .chart-container {
	min-width: 110px;
}
.dashboard-card .chart-group {
	display: flex;
}
.dashboard-card .chart-group .group {
	text-align: center;
	width: 33.3%;
}
.dashboard-card .chart-group .group .chart-container {
	margin-top: -5px;
}
.dashboard-card .chart-group .group span {
	display: block;
	margin-top: -25px;
	font-family: var(--font), sans-serif;
	color: var(--dark-text);
	font-weight: 600;
	font-size: 1.2rem;
}
.dashboard-card .chart-group .group p {
	font-size: 0.9rem;
}
	
.oir_but {
	position: absolute;
	width: 250px;
	height: 50px;
	bottom:10px;
	left:calc(50% - 125px);
	background: #F0E63C;
	border-radius: 77px;
	font-family: 'Montserrat', sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 50px;
	align-items: center;
	text-align: center;
	letter-spacing: 0.01em;
	color: #000000;
	cursor: pointer;
}
		
:deep(.p-panel-content) {
	padding: 0px !important;
}

:deep(.p-panel-header) {
	border-top-right-radius: 0px !important;
    border-top-left-radius: 0px !important;
	border-bottom: 0px !important;
	border-right: 0px !important;
	border-left: 0px !important;
	background: #eee !important;
    font-weight: 700 !important;
	font-size: 0.9rem !important;
	padding-top: 0.6rem !important;
    padding-bottom: 0.6rem !important;
}

@keyframes rotate
	from
		transform rotate(0deg)
	to
		transform rotate(359deg)

</style>
